<template>
  <div>
    <v-container
      fluid
    >
      <app-header
        title="Student Results"
      >
        <template #actionbar>
          <v-btn
            dark
            class="btn-default btn-default__primary btn-default__app-header"
            @click="goToDownloads()"
          >
            Export Downloads
          </v-btn>
        </template>
      </app-header>

      <app-header
        :is-secondary="true"
        :title="`${groupName} - ${summaryName}`"
      >

        <template #actionbar>
          <div v-if="!switchingGroupSelection">
            <v-btn
              class="btn-default btn-default__secondary"
              @click="switchingGroupSelection=true">
              <v-icon
                size="18"
                class="mr-2"
                >
                mdi-swap-horizontal
              </v-icon>
              Switch Report
            </v-btn>
          </div>

          <div v-else
            class="d-flex align-center justify-end"
          >
            <v-col
              cols="5"
              class="pa-0"
            >
              <v-autocomplete
                v-model="selectedSection"
                :items="sectionArray"
                item-text="displayName"
                item-value="id"
                menu-props="auto"
                dense
                background-color="#FFFFFF"
                hide-details
                class="input-default input-default__table mt-0"
                placeholder="Class"
                @change="onSectionChange(...arguments)"
              />
            </v-col>

            <v-col
              cols="4"
              class="pa-0 mx-2"
            >
              <v-autocomplete
                v-model="selectedAssessment"
                :items="getAssessmentChoices()"
                item-text="name"
                :return-object="true"
                menu-props="auto"
                dense
                background-color="#FFFFFF"
                hide-details
                class="input-default input-default__table mt-0"
                placeholder="Assessment"
                @change="onAssessmentChange(...arguments)"
              />
            </v-col>


              <v-btn
                class="btn-default btn-default__secondary"
                @click="switchingGroupSelection=false">
                <v-icon
                  size="18"
                  class="mr-2"
                  >
                  mdi-cancel
                </v-icon>
                Cancel
              </v-btn>
          </div>
        </template>
      </app-header>
    </v-container>

    <v-row
      class="mb-3"
      dense
    >
      <v-col
        cols="12"
        sm="auto"
      >
        <app-tabs-secondary
          :tabs="tabs"
          :active-tab="tabSelected"
          @onTabChange="onLoadCategory(...arguments)"
        />
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        sm="auto"
        class="px-4 px-sm-1 pr-sm-0 pl-sm-4"
      >
        <v-btn
          v-if="canBatchExport()"
          class="btn-default btn-default__secondary"
          block
          :loading="exportLoading"
          @click="onResponseExport()"
        >
          <v-icon
            size="18"
            class="mr-2"
          >
            mdi-download
          </v-icon>
          Response Export
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="auto"
        class="px-4 px-sm-1 pr-sm-0 pl-sm-4"
      >
        <v-btn
          v-if="canBatchExport()"
          class="btn-default btn-default__secondary"
          block
          :loading="exportLoading"
          @click="onResultExcelExport()"
        >
          <v-icon
            size="18"
            class="mr-2"
          >
            mdi-download
          </v-icon>
          Score Export
        </v-btn>
      </v-col>

      <v-col
        cols="12"
        sm="auto"
        class="px-4 px-sm-1 pr-sm-4 pl-sm-4"
      >
        <v-btn
          v-if="canBatchExport()"
          class="btn-default btn-default__secondary"
          block
          :loading="exportLoading"
          @click.stop="showBatchExportDialog()"
        >
          <v-icon
            size="18"
            class="mr-2"
          >
            mdi-download
          </v-icon>
          PDF Export
        </v-btn>
      </v-col>
    </v-row>

    <router-view :key="$route.fullPath" />

    <dialog-batch-pdf-export
      :active="dialogPdf"
      :assessment-set-id="assessmentSetId"
      :assessment-type-id="assessmentTypeId"
      :section-id="sectionId"
      @closeDialog="dialogPdf = false"
    />
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";
import DialogBatchPdfExport from "./widgets/DialogBatchPdfExport"

import {createNamespacedHelpers} from 'vuex'

const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')
const {mapState: mapAssessmentState} = createNamespacedHelpers('assessments')
const {mapGetters: mapAssessmentGetters} = createNamespacedHelpers('assessments')
const {mapGetters: mapUserGetters} = createNamespacedHelpers('user')

import {StaffSchoolApi, StaffSectionApi, StaffStudentResultsApi} from "@/api"

export default {
  name: "StaffStudentResults",
  components: {
    AppTabsSecondary,
    AppHeader,
    DialogBatchPdfExport
  },

  props: {
    sectionId: {
      type: [Number, String],
      required: false
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
    assessmentSummariesCount: {
      type: [Number, String],
      required: true
    },
  },

  data() {
    return {
      switchingGroupSelection: false,
      dialogPdf: false,
      exportLoading: false,
      groupName: '',
      tabSelected: 0,
      selectedSection: null,
      sectionArray: [],
      sectionListLoaded: false,
      selectedAssessment: null,
      tabs: [
        {
          tabText: 'Summary',
          name: 'Staff/StudentResults/Summary'
        },
        {
          tabText: 'Subsection Details',
          name: 'Staff/StudentResults/SubsectionDetails'
        },
      ],
    }
  },

  computed: {
    ...mapAssessmentState(['assessmentSets', 'assessmentTypes']),
    ...mapUserGetters(["user", "userRole"]),
    summaryName() {
      let set = this.assessmentSets.find(a => a.id == this.assessmentSetId)
      let aType = this.assessmentTypes.find(a => a.id == this.assessmentTypeId)
      return `${aType.courseType.toUpperCase()} ${set.name}`
    }
  },

  watch: {
    $route() {
      this.setActiveTab()
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.setSelectedAssessment()
    this.setGrowthReportingTab()
    this.setActiveTab()
    this.getSectionList()

    if (this.sectionId != null) {
      this.loadSection()
    } else {
      this.groupName = "Entire School"
    }
  },

  methods: {
    ...mapSectionGetters(["getAllSections"]),
    ...mapAssessmentGetters(["getAvailableAssessmentTypes", "getAvailableAssessmentSets", "getAssessmentChoices"]),

    async getSectionList() {
      try {
        const response = await StaffStudentResultsApi.getStudentResultsClasses({
          assessmentTypeId: this.assessmentTypeId,
          assessmentSetId: this.assessmentSetId
        })
        this.sectionArray = response.sections

        this.sectionArray.unshift({id: 0, displayName: 'Entire School'})

        if (this.sectionId != null) {
          this.selectedSection = this.sectionId
        } else {
          this.selectedSection = 0
        }
        this.sectionListLoaded = true
      } catch {
        this.addNotification('error', 'Failed to load class list')
      } finally {
        this.loadingSections = false
      }
    },

    setGrowthReportingTab() {
      if (this.assessmentSummariesCount > 1) {
        this.tabs.push({
          tabText: 'Growth Reporting',
          name: 'Staff/StudentResults/GrowthReporting'
        })
      }

    },

    async loadSection() {
      try {
        // check store first
        let section = this.getAllSections().find(section => section.id == this.sectionId)

        if (section == null) {
          const response = await StaffSectionApi.get(this.sectionId)
          section = response.section
        }

        this.groupName = section.displayName
      } catch (e) {
        this.$router.push({name: "StaffHome"})
        this.addNotification('error', "Could not load class data.")
      }

    },

    async onSectionChange(selectedSection) {
      if (selectedSection === 0) {
        await this.$router.push({
          name: 'Staff/StudentResults/Summary',
          params: {
            assessmentSetId: this.assessmentSetId,
            assessmentTypeId: this.assessmentTypeId,
            assessmentSummariesCount: this.assessmentSummariesCount
          }
        })
        this.groupName = "Entire School"
      } else {
        await this.$router.push({
          name: 'Staff/Section/StudentResults/Summary',
          params: {
            sectionId: selectedSection,
            assessmentSetId: this.assessmentSetId,
            assessmentTypeId: this.assessmentTypeId,
            assessmentSummariesCount: this.assessmentSummariesCount
          }
        })
        this.loadSection()
      }
    },

    setSelectedAssessment() {
      this.selectedAssessment = this.getAssessmentChoices().find((t) => t.assessmentSet.id == this.assessmentSetId && t.assessmentType.id == this.assessmentTypeId)
    },

    async onAssessmentChange(assessmentChoice) {
      if (this.selectedSection === 0 || this.selectedSection === undefined) {
        await this.$router.push({
          name: 'Staff/StudentResults/Summary',
          params: {
            assessmentSetId: assessmentChoice.assessmentSet.id,
            assessmentTypeId: assessmentChoice.assessmentType.id,
            assessmentSummariesCount: this.assessmentSummariesCount
          }
        })
        this.groupName = "Entire School"
      } else {
        await this.$router.push({
          name: 'Staff/Section/StudentResults/Summary',
          params: {
            sectionId: this.sectionId,
            assessmentSetId: assessmentChoice.assessmentSet.id,
            assessmentTypeId: assessmentChoice.assessmentType.id,
            assessmentSummariesCount: this.assessmentSummariesCount
          }
        })
        this.loadSection()
      }
    },

    onLoadCategory(tab) {
      if (this.tabSelected != tab) {
        this.tabSelected = tab

        let pathName = this.tabs[tab].name
        if (this.sectionId != null) {
          let paths = pathName.split("/")
          paths.splice(1, 0, "Section")
          pathName = paths.join("/")
        }

        this.$router.push({
          name: pathName,
          params: {
            sectionId: this.$route.params.sectionId,
            assessmentSetId: this.assessmentSetId,
            assessmentTypeId: this.assessmentTypeId
          }
        })
      }
    },

    setActiveTab() {
      this.tabSelected = this.tabs.findIndex(tab => tab.name.split('/').at(-1) == this.$route.name.split('/').at(-1))
    },

    showBatchExportDialog() {
      this.dialogPdf = true
    },

    async onResultExcelExport() {
      try {
        this.exportLoading = true
        let api, id

        if (this.sectionId != null) {
          api = StaffSectionApi
          id = this.sectionId
        } else {
          api = StaffSchoolApi
          id = this.user.school.id
        }
        await api.studentResultExportExcel(id, this.assessmentSetId, this.assessmentTypeId)
        this.addNotification('info', 'The export is currently being generated. You can view progress and download the file from the ‘Export Downloads’ page once complete, accessible by the blue button at the top right of the page.', 8000)

      } catch (error) {
        if (error.response?.data?.error === "There is an ongoing job already.") {
          this.addNotification('warning', "You already have a pending request for this report.")
        } else {
          this.addNotification('error', "Something went wrong. Please try again.")
        }
      } finally {
        this.exportLoading = false
      }
    },

    async onResponseExport() {
      try {
        this.exportLoading = true
        let api, id

        if (this.sectionId != null) {
          api = StaffSectionApi
          id = this.sectionId
        } else {
          api = StaffSchoolApi
          id = this.user.school.id
        }
        await api.studentResultResponseExport(id, this.assessmentSetId, this.assessmentTypeId)
        this.addNotification('info', 'The export is currently being generated. You can view progress and download the file from the ‘Export Downloads’ page once complete, accessible by the blue button at the top right of the page.', 8000)

      } catch (error) {
        if (error.response?.data?.error === "There is an ongoing job already.") {
          this.addNotification('warning', "You already have a pending request for this report.")
        } else {
          this.addNotification('error', "Something went wrong. Please try again.")
        }
      } finally {
        this.exportLoading = false
      }
    },

    canBatchExport() {
      if (this.userRole === "Teacher Admin" || this.userRole === "District Admin") {
        return true
      } else {
        if (this.sectionId != null) {
          return true
        } else {
          return false
        }
      }
    },

    goToDownloads() {
      this.$router.push({name: "StaffPdfDownloads"})
    },

  },
}
</script>

<style scoped>
.flex-align-right {
  display: flex;
  justify-content: flex-end;
}
</style>
