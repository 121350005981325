<template>
  <v-container fluid>
    <app-header title="Students" />

    <div v-if="loadingStudents">
      <v-skeleton-loader
        type="list-item-avatar@4"
      />
    </div>
    <div v-else-if="studentList.length > 0">
      <v-sheet
        v-for="(student, index) in studentList"
        :key="index"
        class="sheet-main"
        color="#f2f4f8"
      >
        <div class="d-flex align-center flex-wrap">
          <div class="flex-grow-1">
            <h4 class="title-main font-weight-500">
              {{ student.fullName }}
            </h4>
          </div>

          <div>
            <v-btn
              class="btn-default btn-default__secondary btn-default__white"
              outlined
              @click="onLoadStudent(student)"
            >
              Select <span class="highlight arrow">→</span>
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </div>
    <div v-else>
      No students.
    </div>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapActions} = createNamespacedHelpers('app_config')
const {mapGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import {ParentStudentsApi} from "@/api";

export default {
  name: "ParentDashboard",
  components: {AppHeader},

  data() {
    return {
      loadingStudents: false,
      studentList: []
    }
  },

  mounted() {
    this.updateMenu({role: this.user().role, data: this.user()})
    this.loadStudents()
  },

  methods: {
    ...mapGetters(["user"]),
    ...mapActions(['updateMenu',]),

    async loadStudents() {
      try {
        this.loadingStudents = true
        const response = await ParentStudentsApi.getStudents()
        this.studentList = response.students
      } catch {
        this.addNotification('error', 'There was a problem getting the students')
      } finally {
        this.loadingStudents = false
      }
    },

    onLoadStudent(item) {
      this.$router.push({name: 'ParentStudentReports', params: {studentId: item.id, studentName: item.fullName}})
      this.updateMenu({role: this.user().role, data: this.user(), showParentReportsNav: true})
    }

  },

}
</script>

<style scoped lang="scss">
.sheet-main {
  background-color: #f2f4f8;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 13px 20px;
  margin-bottom: 10px;
}

.sheet-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-right: 13px;
}

.sheet-subtitle {
  font-size: 16px;
  color: #505050;
}

.title-main {
  font-size: 20px;
}

.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 10px;
}
</style>