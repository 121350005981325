<template>
  <v-dialog
    :value="dialog"
    max-width="1500px"
    persistent
  >
    <v-card class="card-dialog">
      <app-header
        title="Edit Parents"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />


      <v-container
        fluid
        class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-form>
          <v-row>
            <v-col cols="12">
              <h4>Student Information</h4>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="firstNameStudentDialog"
              >
                First Name
              </label>
              <v-text-field
                id="firstNameStudentDialog"
                v-model="studentData.firstName"
                :hide-details="!$v.studentData.firstName.$error"
                error-messages="Please enter a valid first name"
                solo
                flat
                class="input-default input-default__small mt-1"
              >
                <template #prepend-inner>
                  <div style="width:24px">
                    <v-img
                      height="20"
                      width="20"
                      :src="require('@/assets/icons/icon-portrait.svg')"
                    />
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="lastNameStudentDialog"
              >
                Last Name
              </label>
              <v-text-field
                id="lastNameStudentDialog"
                v-model="studentData.lastName"
                :hide-details="!$v.studentData.lastName.$error"
                error-messages="Please enter a valid last name"
                solo
                flat
                class="input-default input-default__small mt-1"
              >
                <template #prepend-inner>
                  <div style="width:24px">
                    <v-img
                      height="20"
                      width="20"
                      :src="require('@/assets/icons/icon-portrait.svg')"
                    />
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="emailStudentDialog"
              >
                Email Address
              </label>
              <v-text-field
                id="emailStudentDialog"
                v-model="studentData.email"
                :hide-details="!$v.studentData.email.$error"
                error-messages="Please enter a valid email"
                solo
                flat
                class="input-default input-default__small mt-1"
              >
                <template #prepend-inner>
                  <div style="width:24px">
                    <v-img
                      height="20"
                      width="20"
                      :src="require('@/assets/icons/icon-email.svg')"
                    />
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="studentIdStudentDialog"
              >
                Student ID
              </label>
              <div class="d-flex">
                <v-text-field
                  id="studentIdStudentDialog"
                  v-model="studentData.studentIdNumber"
                  :hide-details="!$v.studentData.studentIdNumber.$error"
                  error-messages="ID is required and must consist only of numbers"
                  solo
                  flat
                  class="input-default input-default__small mt-1"
                />
                <v-tooltip
                  v-if="studentData.errors?.student"
                  left
                  color="error"
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="red"
                      class="ml-2 icon-no-ripple"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information
                    </v-icon>
                  </template>
                  <div
                    v-for="(item, i) in studentData.errors?.student"
                    :key="i"
                  >
                    <span>{{ i + 1 }}. {{ item }}</span>
                  </div>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>

          <!-- Parents information -->
          <v-row>
            <v-col cols="12">
              <h4>Parents Information</h4>
            </v-col>
          </v-row>


          <!-- Parents Information -->
          <div
            v-for="(item, key) in parents"
            :key="item.title"
          >
            <v-row v-if="key !== 'student'">
              <v-col
                cols="12"
                sm="4"
              >
                <label
                  class="label-default label-default__small"
                >
                  First Name
                </label>
                <v-text-field
                  v-model="item.firstName"
                  :hide-details="!$v.parents.$each[key]?.firstName.$error"
                  error-messages="Please enter a valid first name"
                  solo
                  flat
                  class="input-default input-default__small mt-1"
                >
                  <template #prepend-inner>
                    <div style="width:24px">
                      <v-img
                        height="20"
                        width="20"
                        :src="require('@/assets/icons/icon-portrait.svg')"
                      />
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <label
                  class="label-default label-default__small"
                >
                  Last Name
                </label>
                <v-text-field
                  v-model="item.lastName"
                  :hide-details="!$v.parents.$each[key]?.lastName.$error"
                  error-messages="Please enter a valid last name"
                  solo
                  flat
                  class="input-default input-default__small mt-1"
                >
                  <template #prepend-inner>
                    <div style="width:24px">
                      <v-img
                        height="20"
                        width="20"
                        :src="require('@/assets/icons/icon-portrait.svg')"
                      />
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <label
                  class="label-default label-default__small"
                >
                  Email Address
                </label>
                <div class="d-flex">
                  <v-text-field
                    v-model="item.email"
                    :hide-details="!$v.parents.$each[key]?.email.$error"
                    error-messages="Please enter a valid email"
                    solo
                    flat
                    class="input-default input-default__small mt-1"
                  >
                    <template #prepend-inner>
                      <div style="width:24px">
                        <v-img
                          height="20"
                          width="20"
                          :src="require('@/assets/icons/icon-email.svg')"
                        />
                      </div>
                    </template>
                  </v-text-field>
                  <v-tooltip
                    v-if="item.errors?.length > 0"
                    left
                    color="error"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        color="red"
                        class="ml-2 icon-no-ripple"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <div
                      v-for="(error, i) in item.errors"
                      :key="i"
                    >
                      <span>{{ i + 1 }}. {{ error }}</span>
                    </div>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-row
            dense
            class="mt-6"
            justify="end"
          >
            <v-col
              cols="12"
              sm="auto"
              class="pr-sm-2"
            >
              <v-btn
                class="btn-default btn-default__secondary btn-default__small"
                block
                @click="onCloseDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="auto"
              class="pl-sm-2"
            >
              <v-btn
                dark
                class="btn-default btn-default__primary btn-default__small"
                block
                @click="onSubmitForm()"
              >
                <span>Update Parents</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="$v.$error"
            dense
            justify="end"
          >
            <v-col cols="auto">
              <h6 class="color-danger">
                Please check your inputs
              </h6>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import functions from "@/api/shared/functions";
import {email, numeric, required} from "vuelidate/lib/validators";

export default {
  name: "DialogParentsForm",
  components: {AppHeader},
  props: {
    active: Boolean,
    studentProp: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },

  data() {
    return {
      dialog: false,
      studentData: {},
      parents: {},
    }
  },

  validations() {
    return {
      studentData: {
        firstName: {required},
        lastName: {required},
        email: {required, email},
        studentIdNumber: {required, numeric},
      },
      parents: {
        $each: {
          firstName: {required},
          lastName: {required},
          email: {required, email},
        }
      }
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.studentData = functions.deepCopySync(this.studentProp)

        for (const [key, value] of Object.entries(this.studentData.errors)) {
          if (key !== 'student') {
            this.parents[key] = value
          }
        }
        this.dialog = value
      } else {
        this.resetData()
        this.dialog = value
      }

    }
  },

  methods: {

    async onSubmitForm() {
      this.loading = true
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$emit('closeDialogUpdate', this.studentData)

    },

    resetData() {
      this.$v.$reset()
      this.studentData = {}
      this.parents = {}
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },
  }
}
</script>
