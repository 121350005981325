import moment from "moment/moment";

export default class AssessmentAssignmentSettings {
  constructor () {
    this.id = null
    this.pauseEnabled = false
    this.saveEnabled = false
    this.readspeakerEnabled = false
    this.gradeLevels = [8,9,10,11,12]
    this.timing = 'timed'
    this.autosubmitDelay = null

    this.revealAnswersAsap = null
    this.revealReportAsap = null
    this.revealAnswersAt = null
    this.revealReportAt = null
  }

  static fromJson(json) {
    var obj = Object.assign(new AssessmentAssignmentSettings(), json)

    if (obj.revealReportAt !== null) {
      obj.revealReportAt = new Date(obj.revealReportAt)
    }

    if (obj.revealAnswersAt !== null) {
      obj.revealAnswersAt = new Date(obj.revealAnswersAt)
    }

    return obj
  }

  get requestParamsJson() {
    let params = {
      pause_enabled: this.pauseEnabled,
      save_enabled: this.saveEnabled,
      readspeaker_enabled: this.readspeakerEnabled,
      grade_levels: this.gradeLevels,
      autosubmit_delay: this.autosubmitDelay,
      timing: this.timing,
      reveal_answers_asap: this.revealAnswersAsap,
      reveal_answers_at: this.revealAnswersAt,
      reveal_report_asap: this.revealReportAsap,
      reveal_report_at: this.revealReportAt
    }

    if (this.id) {
      return Object.assign({}, {id: this.id}, params)
    } else {
      return params
    }
  }

  // Reveal Answers Date
  get revealAnswersAtDate() {
    if (this.revealAnswersAt != null) {
      return moment(this.revealAnswersAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set revealAnswersAtDate(value) {
    if (value !== null) {
      const prevDate = this.revealAnswersAt
      const dateTemp = new Date(value)
      this.revealAnswersAt = new Date(dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset() * 60000))

      if (prevDate != null) {
        this.revealAnswersAt.setHours(prevDate.getHours())
        this.revealAnswersAt.setMinutes(prevDate.getMinutes())
      } else {
        this.revealAnswersAt.setHours(new Date().getHours())
        this.revealAnswersAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.revealAnswersAt = null
    }
  }

  get formattedRevealAnswersAtDate() {
    if (this.revealAnswersAt != null) {
      return this.revealAnswersAt.toDateString()
    } else {
      return ""
    }
  }

  // Reveal Answers Time
  get revealAnswersAtTime() {
    if (this.revealAnswersAt != null) {
      return `${this.revealAnswersAt.getHours().toString().padStart(2, '0')}:${this.revealAnswersAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set revealAnswersAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.revealAnswersAt != null) {
        this.revealAnswersAt = new Date(this.revealAnswersAt.getFullYear(), this.revealAnswersAt.getMonth(), this.revealAnswersAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        let today = new Date()
        this.revealAnswersAt = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.revealAnswersAt = null
    }
  }

  // Reveal Reports Date
  get revealReportAtDate() {
    if (this.revealReportAt != null) {
      return moment(this.revealReportAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set revealReportAtDate(value) {
    if(value != null){
    const prevDate = this.revealReportAt
    const dateTemp = new Date(value)
    this.revealReportAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

    if (prevDate != null) {
      this.revealReportAt.setHours(prevDate.getHours())
      this.revealReportAt.setMinutes(prevDate.getMinutes())
    } else {
      this.revealReportAt.setHours(new Date().getHours())
      this.revealReportAt.setMinutes(new Date().getMinutes())
    }
    } else {
      this.revealReportAt = null
    }
  }

  get formattedRevealReportAtDate() {
    if (this.revealReportAt != null) {
      return this.revealReportAt.toDateString()
    } else {
      return ""
    }
  }

  // Reveal Reports Time
  get revealReportAtTime() {
    if (this.revealReportAt != null) {
      return `${this.revealReportAt.getHours().toString().padStart(2, '0')}:${this.revealReportAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set revealReportAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0, 0, 0, str[0], str[1])
      if (this.revealReportAt != null) {
        this.revealReportAt = new Date(this.revealReportAt.getFullYear(), this.revealReportAt.getMonth(), this.revealReportAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        let today = new Date()
        this.revealReportAt = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.revealReportAt = null
    }
  }
}