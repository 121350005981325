<template>
  <div>
    <v-container
      fluid
      class="pt-0 pb-0"
    >
      <app-header
        title="Class Settings"
      />

      <app-header
        :title="className"
        :is-secondary="true"
        :section-editable="sectionEditable"
      />
    </v-container>

    <app-tabs-secondary
      :tabs="tabs"
      :active-tab="tabSelected"
      @onTabChange="onLoadCategory(...arguments)"
    />

    <router-view :section-editable="sectionEditable" />
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";

import { createNamespacedHelpers } from 'vuex'
const { mapGetters: mapSectionGetters } = createNamespacedHelpers('staffSections')
const { mapGetters: mapUserGetters } = createNamespacedHelpers('user')

import { StaffSectionApi } from "@/api"

export default {
  name: "StaffAssessments",
  components: {
    AppTabsSecondary,
    AppHeader
  },

  props: {
    sectionId: {
      type: [Number, String],
      required: true
    }
  },

  data() {
    return {
      className: '',
      sectionEditable: true,
      tabSelected: 0,
      tabs: [
        {
          tabText: 'Students',
          text: 'Students',
          name: 'StaffClassStudents'
        },
        {
          tabText: 'Assessments',
          text: 'Assessments',
          name: 'StaffClassAssessments'
        },
        {
          tabText: 'Settings',
          text: 'Settings',
          name: 'StaffClassSettings'
        },
      ],
    }
  },

  watch: {
    $route () {
      this.setActiveTab()
    }
  },

  mounted() {
    this.setActiveTab()
    this.loadSection()
  },

  methods: {
    ...mapSectionGetters(["getAllSections"]),
    ...mapUserGetters(["userRole"]),

    async loadSection() {
      try {
        // check store first
        let section = this.getAllSections().find(section => section.id == this.sectionId)

        if (section == null) {
          const response = await StaffSectionApi.get(this.sectionId)
          section = response.section
        }

        this.className = section.displayName
        this.sectionEditable = section.editable

        // Remove Settings from secondary tab if teacher is secondary
        if (section.role === "Secondary Teacher" && this.userRole() !== 'Teacher Admin') {
          this.tabs.pop()
        }
      } catch (e) {
        this.$router.push({ name: "StaffHome" })
        this.addNotification('error', "Could not load class data.")
      }

    },

    onLoadCategory(tab) {
      if (this.tabSelected != tab) {
        this.tabSelected = tab
        this.$router.push({ name: this.tabs[tab].name, params: { sectionId: this.$route.params.sectionId } })
      }
    },

    setActiveTab() {
      this.tabSelected = this.tabs.findIndex(tab => tab.name == this.$route.name)
    }
  },
}
</script>

<style scoped>

</style>