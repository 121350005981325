<template>
  <v-card flat>
    <div class="section-title section-title__logo">
      <v-img
        contain
        :src="require(`@/assets/img/${filename}`)"
        alt="class-logo"
        max-width="48"
        max-height="48"
      />
      <span class="font-weight-600 font-size-16">
        {{ sectionAssessment.category.name }}
      </span>
    </div>
    <v-form
      ref="multiBuilderForm"
    >
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <!--Window Open Date-->
            <assessment-datepicker
              v-if="courseType === 'SAT'"
              :date="assessmentData.p1StartAtDate"
              :formatted-date="assessmentData.formattedP1StartAtDate"
              :rules="assessmentData.rules.p1StartAt"
              :label="`${sectionAssessment?.category?.name} ${brokenAdministration ? 'Part 1' : ''} Window Open Date`"
              @update:date="e => assessmentData.p1StartAtDate = e"
            />

            <assessment-datepicker
              v-else
              :date="assessmentData.startAtDate"
              :formatted-date="assessmentData.formattedStartAtDate"
              :rules="assessmentData.rules.startAt"
              :label="`${sectionAssessment?.category?.name} Window Open Date`"
              @update:date="e => assessmentData.startAtDate = e"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-timepicker
              v-if="courseType === 'SAT'"
              :time="assessmentData.p1StartAtTime"
              :label="`${sectionAssessment?.category?.name} ${brokenAdministration ? 'Part 1' : ''} Window Open Time`"
              @update:time="e => assessmentData.p1StartAtTime = e"
            />
            <assessment-timepicker
              v-else
              :time="assessmentData.startAtTime"
              :label="`${sectionAssessment.category.name} Window Open Time`"
              @update:time="e => assessmentData.startAtTime = e"
            />
          </v-col>
        </v-row>

        <!-- P1 Window Closed Date and Time (if broken administration) -->
        <v-row v-if="brokenAdministration">
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-datepicker
              v-if="courseType === 'SAT'"
              :date="assessmentData.p1EndAtDate"
              :formatted-date="assessmentData.formattedP1EndAtDate"
              :rules="assessmentData.rules.p1EndAt"
              :label="`${sectionAssessment.category.name} Part 1 Window Closed Date (Optional)`"
              :clearable-prop="true"
              @update:date="e => assessmentData.p1EndAtDate = e"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-timepicker
              :time="assessmentData.p1EndAtTime"
              :label="`${sectionAssessment.category.name} Part 1 Window Closed Time (Optional)`"
              :clearable-prop="true"
              @update:time="e => assessmentData.p1EndAtTime = e"
            />
          </v-col>
        </v-row>

        <!-- P2 Window Start Date and Time (if broken administration) -->
        <v-row v-if="brokenAdministration">
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-datepicker
              v-if="courseType === 'SAT'"
              :date="assessmentData.p2StartAtDate"
              :formatted-date="assessmentData.formattedP2StartAtDate"
              :rules="assessmentData.rules.p2StartAt"
              :label="`${sectionAssessment.category.name} Part 2 Window Start Date`"
              :clearable-prop="true"
              @update:date="e => assessmentData.p2StartAtDate = e"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-timepicker
              :time="assessmentData.p2StartAtTime"
              :label="`${sectionAssessment.category.name} Part 2 Window Start Time`"
              @update:time="e => assessmentData.p2StartAtTime = e"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-datepicker
              v-if="courseType === 'SAT'"
              :date="assessmentData.p2EndAtDate"
              :formatted-date="assessmentData.formattedP2EndAtDate"
              :rules="assessmentData.rules.p2EndAt"
              :label="`${sectionAssessment?.category?.name} ${brokenAdministration ? 'Part 2' : ''} Window Closed Date (Optional)`"
              :clearable-prop="true"
              @update:date="e => assessmentData.p2EndAtDate = e"
            />

            <assessment-datepicker
              v-else
              :date="assessmentData.endAtDate"
              :formatted-date="assessmentData.formattedEndAtDate"
              :rules="assessmentData.rules.endAt"
              :label="`${sectionAssessment.category.name} Window Closed Date (Optional)`"
              :clearable-prop="true"
              @update:date="e => assessmentData.endAtDate = e"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <assessment-timepicker
              v-if="courseType === 'SAT'"
              :time="assessmentData.p2EndAtTime"
              :label="`${sectionAssessment.category.name} ${brokenAdministration ? 'Part 2' : ''} Window Closed Time (Optional)`"
              :clearable-prop="true"
              @update:time="e => assessmentData.p2EndAtTime = e"
            />
            <assessment-timepicker
              v-else
              :time="assessmentData.endAtTime"
              :rules="assessmentData.rules.endAtTime"
              :label="`${sectionAssessment.category.name} Window Closed Time (Optional)`"
              :clearable-prop="true"
              @update:time="e => assessmentData.endAtTime = e"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-card
              elevation="0"
              outlined
            >
              <div class="section-title section-title__flex-column">
                <span class="font-weight-600 font-size-16">
                  Reveal Score Report
                </span>
                <span class="font-weight-500 font-size-14 mt-1">
                  Would you like students to view their score report for this section immediately after completion?
                </span>
              </div>
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="auto">
                    <div class="answer-box-container">
                      <assessment-radio-box-small
                        v-model="assessmentData.settings.revealReportAsap"
                        radio-name="reveal-answer"
                        :input-value="true"
                        title="Yes"
                      />
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div class="answer-box-container">
                      <assessment-radio-box-small
                        v-model="assessmentData.settings.revealReportAsap"
                        radio-name="reveal-answer"
                        :input-value="false"
                        title="No"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  v-if="assessmentData.settings.revealReportAsap === false"
                >
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <assessment-datepicker
                      :date="assessmentData.settings.revealReportAtDate"
                      :formatted-date="assessmentData.settings.formattedRevealReportAtDate"
                      :label="`${sectionAssessment.category.name} Score Report Reveal Date (Optional)`"
                      :clearable-prop="true"
                      @update:date="e => assessmentData.settings.revealReportAtDate = e"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <assessment-timepicker
                      :time="assessmentData.settings.revealReportAtTime"
                      :label="`${sectionAssessment.category.name} Score Report Reveal Time (Optional)`"
                      :clearable-prop="true"
                      @update:time="e => assessmentData.settings.revealReportAtTime = e"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-card
              elevation="0"
              outlined
            >
              <div class="section-title section-title__flex-column">
                <span class="font-weight-600 font-size-16">
                  Reveal Correct Answers
                </span>
                <span class="font-weight-500 font-size-14 mt-1">
                  Would you like students to view the correct answers on their score report immediately after completion?
                </span>
              </div>
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="auto">
                    <div class="answer-box-container">
                      <assessment-radio-box-small
                        v-model="assessmentData.settings.revealAnswersAsap"
                        radio-name="reveal-correct-answer"
                        :input-value="true"
                        title="Yes"
                      />
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div class="answer-box-container">
                      <assessment-radio-box-small
                        v-model="assessmentData.settings.revealAnswersAsap"
                        radio-name="reveal-correct-answer"
                        :input-value="false"
                        title="No"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  v-if="assessmentData.settings.revealAnswersAsap === false"
                >
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <assessment-datepicker
                      :date="assessmentData.settings.revealAnswersAtDate"
                      :formatted-date="assessmentData.settings.formattedRevealAnswersAtDate"
                      :label="`${sectionAssessment.category.name} Reveal Answers Date (Optional)`"
                      :clearable-prop="true"
                      @update:date="e => assessmentData.settings.revealAnswersAtDate = e"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <assessment-timepicker
                      :time="assessmentData.settings.revealAnswersAtTime"
                      :label="`${sectionAssessment.category.name} Reveal Answers Time (Optional)`"
                      :clearable-prop="true"
                      @update:time="e => assessmentData.settings.revealAnswersAtTime = e"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import SectionAssessment from '@/models/section_assessment'
import SectionAssessmentGroup from "@/models/section_assessment_group";
import AssessmentDatepicker from '@/components/shared/AssessmentDatepicker'
import AssessmentTimepicker from '@/components/shared/AssessmentTimepicker'
import AssessmentRadioBoxSmall from "@/components/staff/AssessmentsAssign/AssessmentRadioBoxSmall.vue";

export default {

  components: {
    AssessmentRadioBoxSmall,
    AssessmentDatepicker,
    AssessmentTimepicker
  },
  props: {
    sectionAssessment: {
      type: Object,
      required: true
    },
    filename: {
      type: String,
      default: ''
    },
    courseType: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      assessmentData: new SectionAssessment()
    }
  },

  computed: {
    brokenAdministration() {
      return this.assessmentData.assessmentAdministrationType === 'broken'
    }
  },

  created() {
    if (this.courseType === "SAT") this.assessmentData = new SectionAssessmentGroup()

    this.assessmentData = this.sectionAssessment
  },

  watch: {
    'assessmentData.p1EndAtTime' () {
      this.$refs.multiBuilderForm.validate()
    },

    'assessmentData.p2StartAtTime' () {
      this.$refs.multiBuilderForm.validate()
    },

    'assessmentData.p2EndAtTime' () {
      this.$refs.multiBuilderForm.validate()
    },

    'assessmentData.startAtTime'() {
      this.$refs.multiBuilderForm.validate()
    },

    'assessmentData.endAtTime'() {
      this.$refs.multiBuilderForm.validate()
    },

    'assessmentData.assessmentAdministrationType' (value) {
      if(value === 'continuous') this.resetToContinuesAssessment()
    }
  },

  methods: {
    validateForm() {
      return this.$refs.multiBuilderForm.validate()
    },

    resetToContinuesAssessment(){
      this.assessmentData.p1EndAtDate = null
      this.assessmentData.p1EndAtTime = null
      this.assessmentData.p2StartAtDate = null
      this.assessmentData.p2StartAtTime = null
    }
  }
}
</script>

<style scoped lang="scss">

.section-title {
  padding: 16px;
  background-color: #edf4fa;
  color: #2a3446;
  border-radius: 5px !important;
  display: flex;
  align-items: center;

  &__flex-column {
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  &__logo {
    padding: 5px;
  }

}

.answer-box-container {
  width: 156px;
}

.selected {
  background-color: #1378d1 !important;
  color: #fff;
}
</style>