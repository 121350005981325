import DistrictSchools from "@/components/district_admin/DistrictSchools.vue";
import ExportGenerate from "@/components/district_admin/ExportGenerate.vue";
import DistrictReports from "@/components/district_admin/ReportsDashboard.vue";

export default [
    {
        path: '/district-admin/schools',
        name: 'DistrictSchools',
        component: DistrictSchools,
        meta: {
            breadcrumb: [
                {text: 'Schools'}
            ],
            roles: ['District Admin']
        },
    },
    {
        path: '/district-admin/export',
        name: 'ExportGenerate',
        component: ExportGenerate,
        meta: {
            breadcrumb: [
                {text: 'Exports'}
            ],
            roles: ['District Admin']
        },
    },
    {
        path: '/district-admin/reports',
        name: 'DistrictReports',
        component: DistrictReports,
        meta: {
            breadcrumb: [
                {text: 'Reports'}
            ],
            roles: ['District Admin']
        },
    },
    {
        path: '/district-admin/reports/group',
        component: () => import('@/components/district_admin/DistrictReportsGroup.vue'),
        props: true,
        meta: {
          breadcrumb: [
            { text: "Reports" }
          ],
          roles: ['District Admin']
        },
        children: [
          {
            path: '',
            name: '',
            redirect: {name: 'District/Reports/Group/Summary'},
          },
          {
            path: 'summary',
            props: true,
            name: 'District/Reports/Group/Summary',
            component: () => import('@/components/shared/student_results/ReportsGroupSummary.vue'),
            meta: {
              breadcrumb: [
                { text: "Reports", to: { name: "DistrictReports" } },
                { text: "Summary" }
              ],
              roles: ['District Admin']
            },
          },
          {
            path: 'subsection-details',
            name: 'District/Reports/Group/SubsectionDetails',
            props: true,
            component: () => import('@/components/shared/student_results/ReportsGroupSubsection.vue'),
            meta: {
              breadcrumb: [
                { text: "Reports", to: { name: "DistrictReports" } },
                { text: "Subsection Details" }
              ],
              roles: ['District Admin']
            },
          },
        ]
        },
        {
        path: '/district-admin/reports/group/comparison',
        name: 'District/Reports/Group/Comparison',
        component: () => import('@/components/shared/student_results/ReportsGroupComparison.vue'),
        props: true,
        meta: {
          breadcrumb: [
            { text: "Reports", to: { name: "DistrictReports" } },
            { text: "Comparison" }
          ],
          roles: ['District Admin']
        },
    },
]
