<template>
  <v-container fluid>
    <v-row class="tabs-container-primary">
      <v-tabs
        v-if="isDialog"
        v-model="tabSelected"
        :vertical="isMobile"
        hide-slider
        :height="!isMobile? 51 : undefined"
        @change="onTabChange()"
      >
        <v-tab
          v-for="tab of tabs"
          :key="tab.tabText"
          class="tabs-link pb-0 mr-0 mr-sm-7 text-none"
          active-class="router-link-active"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>

      <v-col
        v-for="item in tabs"
        v-else
        :key="item.id"
        cols="12"
        sm="auto"
        class="col-tab-primary"
        :class="isMobile ? 'col-tab-primary-mobile' : undefined"
      >
        <router-link
          class="tabs-link"
          :to="{name: item.routeName, params: $route.params}"
        >
          {{ item.name }}
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')

export default {
  name: "AppTabsPrimary",
  props: {
    isDialog: Boolean,
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    activeTab: {
      type: Number,
      default: () => {
        return 0
      },
      required: false
    }
  },
  
  data() {
    return {
      tabSelected: ''
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    })
  },

  watch: {
    activeTab() {
      this.tabSelected = this.activeTab
    }
  },

  methods: {
    onTabChange() {
      this.$emit('onTabChange', this.tabSelected)
    }
  }

}
</script>

<style scoped lang="scss">


.tabs-container-primary {
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 6px;

  .col-tab-primary {
    padding: 10px 30px 12px 0;
  }

  .tabs-link {
    font-family: Inter-Medium, sans-serif;
    color: #4d535f !important;
    text-decoration: none;
    padding: 0 5px 13px;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: normal;

    &.router-link-active {
      border-bottom: 2px solid #3d5d74;
      color: #3d5d74 !important;
    }

  }

  .col-tab-primary-mobile {
    padding: 10px 0 12px 0;

    &:last-child {
      padding-bottom: 22px;
    }

    .tabs-link {
      padding: 0 0 7px;
    }


  }

}
</style>
