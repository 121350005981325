<template>
  <v-dialog
    :value="dialog"
    max-width="1500"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        title="Users that couldn't be uploaded"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              height="400"
              item-key="id"
              :headers="headers"
              :headers-length="headers.length + 1"
              :items="errorsList"
              class="table-default table-default__header-fixed"
              fixed-header
              :footer-props="{
                showCurrentPage: true,
                itemsPerPageOptions:[itemsPerPage],
                disableItemsPerPage: true
              }"
            >
              <template #[`footer.page-text`]="items">
                <span class="font-weight-700">{{ items.pageStart }}</span> to
                <span class="font-weight-700">{{ items.pageStop }}</span> of
                <span class="font-weight-700">{{ items.itemsLength }}</span>
              </template>

              <template
                v-if="!isMobile"
                #[`item.errors`]="{ item }"
              >
                <div v-if="role === 'Parent'">
                  {{ Object.keys(item.errors).length }}
                </div>
                <div v-else>
                  <div
                    v-for="(error, key) in item.errors"
                    :key="key"
                  >
                    {{ `${key}: ${error}` }}
                  </div>
                </div>
              </template>

              <template
                v-if="!isMobile"
                #[`item.actions`]="{ item }"
              >
                <td>
                  <v-row dense>
                    <v-col class="d-flex justify-end">
                      <v-btn
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        @click="onEditDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        @click="onDeleteDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template
                v-if="isMobile"
                #item="{ item }"
              >
                <tr>
                  <td>
                    <ul class="flex-content">
                      <li
                        v-for="header in headers.slice(0, -2)"
                        :key="header.value"
                        class="flex-item"
                        :data-label="header.text"
                      >
                        {{ item[header.value] }}
                      </li>
                      <li
                        class="flex-item"
                        data-label="Errors"
                      >
                        <div
                          v-for="(error, key) in item.errors"
                          :key="key"
                        >
                          {{ `${key}: ${error}` }}
                        </div>
                      </li>
                      <v-col
                        cols="12"
                        class="d-flex"
                      >
                        <v-btn
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          @click="onEditDialog(item)"
                        >
                          <v-icon size="18">
                            mdi-square-edit-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          class="btn-table btn-table__icon"
                          outlined
                          icon
                          @click="onDeleteDialog(item)"
                        >
                          <v-icon size="18">
                            mdi-trash-can-outline
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </ul>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-3"
          justify="end"
        >
          <v-col
            cols="12"
            sm="auto"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            class="pl-sm-2"
          >
            <v-btn
              :disabled="errorsList.length === 0"
              :dark="errorsList.length !== 0"
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onAddUsers()"
            >
              Add Users
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <dialog-student-form
      title="Edit Student"
      :active="dialogStudent"
      :from-upload-bulk="true"
      type="edit"
      :student-prop="userProp"
      @closeDialog="onCloseDialogStudent()"
      @closeDialogUpdate="onCloseDialogStudent(true, ...arguments)"
    />

    <dialog-parents-form
      :active="dialogParents"
      :from-upload-bulk="true"
      :student-prop="userProp"
      @closeDialog="onCloseDialogParents()"
      @closeDialogUpdate="onCloseDialogParents(true, ...arguments)"
    />

    <dialog-admin-form
      :title="`Edit ${role}`"
      :active="dialogAdmin"
      :from-upload-bulk="true"
      type="edit"
      user-type="Teacher"
      :user-prop="userProp"
      @closeDialog="onCloseDialogAdmin()"
      @closeDialogUpdate="onCloseDialogAdmin(true, ...arguments)"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveUser()"
    />
  </v-dialog>
</template>

<script>

import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
const {mapState} = createNamespacedHelpers('app_config')
import AppHeader from "@/components/shared/AppHeader.vue";
import functions from "@/api/shared/functions";
import DialogStudentForm from "@/components/shared/DialogStudentForm.vue";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogAdminForm from "@/components/shared/DialogAdminForm.vue";
import {AdminSchoolApi, AdminSectionApi} from "@/api";
import DialogParentsForm from "@/components/shared/DialogParentsForm.vue";


export default {
  name: "DialogUploadUsersErrors",
  components: {DialogParentsForm, DialogAdminForm, DialogDeleteConfirmation, AppHeader, DialogStudentForm},
  props: {
    active: Boolean,
    fromClass: Boolean,
    errorsListProp: {
      type: Object,
      default() {
        return {}
      }
    },
    role: {
      type: String,
      default: ''
    },
    schoolId: {
      type: [Number, String],
      default: null,
    },
    sectionId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      dialogStudent: false,
      dialogParents: false,
      dialogAdmin: false,
      dialogDeleteConfirmation: false,
      itemsPerPage: 10,
      editedIndex: null,
      errorsList: [],
      errorObject: [],
      userProp: {},
      headers: [],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  watch: {
    active(value) {
      if (value) {
        this.loadHeaders()
        this.errorsList = functions.deepCopySync(this.errorsListProp.errors)
      }
      this.dialog = value
    }
  },

  methods: {
    ...mapGetters(["userRole"]),


    loadHeaders() {
      this.headers = [
        {
          text: 'First Name',
          value: 'firstName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Name',
          value: 'lastName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Email',
          value: 'email',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Errors',
          value: 'errors',
          align: 'left',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 150,
        }
      ]

      if (this.role === 'Parent') {
        const arrayTemp = [
          {
            text: 'Student Id',
            value: 'studentIdNumber',
            align: 'left',
            sortable: true,
          },
        ]
        this.headers.splice(3, 0, ...arrayTemp)
      }

      if (this.role === 'Student') {
        const arrayTemp = [
          {
            text: 'Student Id',
            value: 'studentIdNumber',
            align: 'left',
            sortable: true,
          },
          {
            text: 'Grade',
            value: 'gradeLevel',
            align: 'left',
            sortable: true,
          },
        ]
        this.headers.splice(3, 0, ...arrayTemp)
      }
    },

    async onAddUsers() {
      try {
        this.loading = true

        const usersArray = this.errorsList.map((user) => {
          let userObj = functions.deepCopySync(user)

          if (this.role === 'Parent') {

            const clone = Object.assign({}, userObj.errors);
            delete clone.student;
            userObj = {...userObj, ...clone};
          }
          this.removeErrorKey(userObj)
          return userObj
        })

        let response = {}

        if (this.fromClass) {
          response = await AdminSectionApi.createMultipleUsers(this.sectionId, usersArray)
        } else {
          response = await AdminSchoolApi.createMultipleUsers(this.schoolId, this.role, usersArray)
        }

        if (response.errorCount > 0) {
          this.errorObject = response
          this.errorsList = this.errorObject.errors
          if (this.errorObject.importedCount > 0) this.$emit('updateTable')
          throw Error('usersError')
        }
        this.addNotification('success', 'Users added successfully')
        this.$emit('updateTable')
        this.$emit('closeDialog')
      } catch (e) {
        if (e.message === 'usersError') {
          this.addNotification('warning', `Imported ${this.errorObject.importedCount} users, Failed to import ${this.errorObject.errorCount} users`)
        } else {
          this.addNotification('error', 'Failed to upload users. Please try again.')
        }
      } finally {
        this.loading = false
      }
    },

    removeErrorKey(obj) {
      if (typeof obj !== 'object' || obj === null) {
        return
      }

      if ('errors' in obj) {
        delete obj.errors
      }

      Object.values(obj).forEach(value => {
        this.removeErrorKey(value)
      });

    },

    onEditDialog(user) {
      this.editedIndex = this.errorsList.indexOf(user)
      this.userProp = functions.deepCopySync(user)
      this.dialogTitle = `Edit ${this.role}`
      if (this.role === 'Student') {
        this.dialogStudent = true
      } else if (this.role === 'Parent') {
        this.dialogParents = true
      } else {
        this.dialogAdmin = true
      }
    },

    onCloseDialogStudent(updateTable, editedItem) {
      if (updateTable) {
        Object.assign(this.errorsList[this.editedIndex], editedItem)
      }
      this.dialogStudent = false
    },

    onCloseDialogParents(updateTable, editedItem) {
      if (updateTable) {
        Object.assign(this.errorsList[this.editedIndex], editedItem)
      }
      this.dialogParents = false
    },

    //  Teacher methods
    onCloseDialogAdmin(updateTable, editedItem) {
      if (updateTable) {
        Object.assign(this.errorsList[this.editedIndex], editedItem)
      }
      this.dialogAdmin = false
    },

    //  Delete methods

    onDeleteDialog(userProp) {
      this.userProp = functions.deepCopySync(userProp)
      this.editedIndex = this.errorsList.indexOf(userProp)
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    onRemoveUser() {
      this.errorsList.splice(this.editedIndex, 1)
      this.dialogDeleteConfirmation = false
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>
