<template>
  <v-col
    :id="id"
    class="section-pane"
  >
    <div class="accordion-trigger">
      <div
        class="left-pane"
        tabindex="0"
        @click="triggerSelect()"
      >
        <v-img
          v-if="dragging"
          src="@/assets/icons/drag.png"
          class="drag-icon"
          contain
          alt="icon-add"
          max-width="15"
          max-height="15"
        />
        <rotating-button :is-active="selected" />
        <div class="section-text d-flex align-center">
          <h2 class="font-size-20">
            {{ sectionHead.name }}
          </h2>
          <span
            v-if="sectionHead.memo"
            class="text-memo"
          >
            {{ sectionHead.memo }}
          </span>
        </div>
      </div>
    </div>
    <div :hidden="!selected">
      <div>
        <div
          v-for="(lesson, index ) in section.lessons"
          :key="'lesson' + index"
        >
          <course-lesson
            :id="id + '-lesson-' + (lesson ? lesson.id : '')"
            :lesson="lesson"
            :section_id="section ? section.id : null"
            :section_order="section.order"
            :course-id="courseId"
            :section-list="sectionList"
            @section-update="$emit('section-update')"
          />
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import RotatingButton from '@/components/shared/course_activities/rotating_button.vue'
import CourseLesson from './CourseLesson.vue'

export default {

  components: {
    RotatingButton,
    CourseLesson
  },

  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    section: {
      type: Object,
      default() {
        return {}
      }
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    courseId: {
      type: Number,
      default: null
    },
    sectionList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selected: false,
      dialogDeleteConfirmation: false,
      sectionHead: {
        name: this.section.name,
        memo: this.section.memo,
      },
    }
  },

  methods: {
    triggerSelect() {
      this.selected = this.selected !== true
    },
  },
}

</script>
<style scoped lang="scss">
.accordion-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .left-pane {
    display: flex;
    width: 100%;
    align-items: center;

    .section-text {
      margin-left: 15px;
    }
  }
}

.text-memo {
  background-color: #ebebeb;
  border-radius: 10px;
  margin-left: 15px;
  padding: 7px 15px;
  font-size: 12px;
}

.section-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border: 1px solid #eaecf0;
  border-radius: 14px;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
}

.drag-icon {
  width: 25px;
  height: 25px;
  margin-left: -20px;
}
</style>
