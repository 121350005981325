import {Notification} from "@/models";

export default {
    methods: {
        addNotification(type= 'info', message = '', timeOnScreen = 3000){
            this.$store.dispatch('app_config/addNotification', new Notification(type, message, timeOnScreen))
        },
        hasProperty(obj, prop) {
            return obj && obj !== null && Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] !== null;
        },
    }
}
