<template>
  <v-dialog
    :value="dialog"
    max-width="400px"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="submittingForm">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        title="PDF Report Export Options"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-1 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row
          dense
        >
          <v-col
            cols="12"
          >
            <label
              class="label-default label-default__small"
              for="exportType"
            >
              Export Type
            </label>
            <v-select
              id="exportType"
              v-model="selectedContainerOption"
              dense
              flat
              class="input-default input-default__small"
              hide-details
              item-text="name"
              item-value="type"
              :items="containerOptions"
            />
          </v-col>
        </v-row>

        <v-row
          dense
        >
          <v-col
            cols="12"
          >
            <label
              class="label-default label-default__small"
              for="sortOrder"
            >
              Sort Order
            </label>
            <v-select
              id="sortOrder"
              v-model="selectedSortOption"
              dense
              flat
              class="input-default input-default__small"
              hide-details
              item-text="name"
              item-value="type"
              :items="sortOptions"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col
            cols="12"
            class="mt-6"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onSubmit()"
            >
              Export
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import {StaffReportsApi} from '@/api'

export default {
  name: "DialogBatchPdfExport",
  components: {AppHeader},
  props: {
    active: Boolean,
    reportFilters: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      dialog: false,
      submittingForm: false,
      selectedContainerOption: null,
      selectedSortOption: null,
      containerOptions: [
        {
          type: "pdf",
          name: "One PDF with all score reports" 
        },
        {
          type: "zip",
          name: "Zip file with PDF score report for each student"
        }
      ],
      sortOptions: [
        {
          type: "name",
          name: "Last Name" 
        },
        {
          type: "id",
          name: "Student ID"
        }
      ],
    }
  },

  watch: {
    active(value) {
      this.dialog = value

      if (value) {
        this.selectedContainerOption = this.containerOptions[0].type
        this.selectedSortOption = this.sortOptions[0].type
      }
    }
  },

  methods: {
    ...mapGetters(['userRole']),

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    onSubmit() {
      this.requestBatchPdf()
    },

    async requestBatchPdf() {
      try {
        this.submittingForm = true
        await StaffReportsApi.reportExportPdf({
          assessmentTypeId: this.reportFilters.assessment.assessmentType.id,
          assessmentSetId: this.reportFilters.assessment.assessmentSet.id,
          schoolYear: this.reportFilters.schoolYear,
          sections: this.reportFilters.classes,
          gradeLevels: this.reportFilters.gradeLevels,
          containerType: this.selectedContainerOption,
          sortOrder: this.selectedSortOption,
        })
        this.addNotification('info', "The export is currently being generated. You can view progress and download the file from the ‘Export Downloads’ page once complete, accessible by the blue button at the top right of the page.", 8000)
        this.onCloseDialog()
      } catch (error) {
        if (error.response?.data?.error === "There is an ongoing job already.") {
          this.addNotification('warning', "You already have a pending request for this report.")
        } else {
          this.addNotification('error', "Something went wrong. Please try again.")
        }
      } finally {
        this.submittingForm = false
      }
    },

  }
}
</script>