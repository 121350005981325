<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col cols="12">
        <h3>Edit User Information</h3>
      </v-col>
    </v-row>

    <v-form class="form-default">
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
              for="firstName"
            >
              First Name
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="5"
          class="pt-0 pt-sm-3"
        >
          <v-text-field
            id="firstName"
            v-model="userData.firstName"
            :hide-details="!$v.userData.firstName.$error"
            error-messages="Please enter a valid first name"
            solo
            flat
            class="input-default mt-1"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
              for="lastName"
            >
              Last Name
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="5"
          class="pt-0 pt-sm-3"
        >
          <v-text-field
            id="lastName"
            v-model="userData.lastName"
            :hide-details="!$v.userData.lastName.$error"
            error-messages="Please enter a valid last name"
            solo
            flat
            class="input-default mt-1"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="auto"
          class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
        >
          <div class="label-default-container">
            <label
              class="label-default"
              for="email"
            >
              Email Address
            </label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="5"
          class="pt-0 pt-sm-3"
        >
          <v-text-field
            id="email"
            v-model="userData.email"
            :hide-details="!$v.userData.email.$error"
            error-messages="Please enter a valid email"
            solo
            flat
            class="input-default mt-1"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row
      dense
      justify="end"
      class="mt-6"
    >
      <v-col
        cols="12"
        sm="auto"
      >
        <v-btn
          dark
          :loading="submittingForm"
          class="btn-default btn-default__primary"
          block
          @click="onSubmitForm()"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/diskette.png')"
            alt="icon-add"
            max-width="19"
            max-height="19"
          />
          Save Settings
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="$v.$error"
      dense
      justify="end"
    >
      <v-col cols="auto">
        <h6 class="color-danger">
          Please check your inputs
        </h6>
      </v-col>
    </v-row>

    <v-row class="mt-6" v-if="user().multipleLoginsAvailable">
      <v-col cols="12">
        <h3>Change School</h3>
      </v-col>
    </v-row>
    <v-row class="mt-1" v-if="user().multipleLoginsAvailable">
      <v-col cols="12">
        <div
          v-if="schools.length > 0">
          <v-sheet
            v-for="(school, index) in schools"
            :key="index"
            class="sheet-main"
            color="#f2f4f8"
          >
            <div class="d-flex align-center flex-wrap">
              <div class="flex-grow-1">
                <h4 class="title-main font-weight-500 my-1">
                  {{ school.name }}
                </h4>
              </div>

              <div v-if="currentSchool(school)"
                class="py-4"
              >
                Currently logged in.
              </div>
              <div v-else>
                <v-btn
                  class="btn-default btn-default__secondary btn-default__white"
                  outlined
                  @click="switchSchool(school)"
                >
                  Switch <span class="highlight arrow">⟳</span>
                </v-btn>
              </div>
            </div>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const {mapGetters, mapMutations} = createNamespacedHelpers('user')
const { mapActions: mapSectionActions } = createNamespacedHelpers('staffSections')
import {AdminUserApi, StaffUserApi, StaffMultipleSchoolApi, StaffSectionApi} from "@/api"
import {validationMixin} from "vuelidate/src"
import {email, required} from "vuelidate/lib/validators"
import SharedFunctions from "@/api/shared/functions"
import Mixin from "@/mixins/mixin"

export default {
  mixins: [Mixin, validationMixin],
  data() {
    return {
      submittingForm: false,
      userData: {
        firstName: '',
        lastName: '',
        email: '',
      },
      schools: []
    }
  },
  validations: {
    userData: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
    }
  },
  mounted() {
    this.getUserData()

    if (this.user().multipleLoginsAvailable) {
      this.getAssociatedSchools()
    }
  },
  methods: {
    ...mapGetters(['user', 'userRole']),
    ...mapMutations(['updateSessionInfo']),
    ...mapSectionActions(['storeSections']),

    getUserData() {
      this.userData = SharedFunctions.deepCopySync(this.user())
    },

    async onSubmitForm() {
      try {
        this.submittingForm = true
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        if (this.userRole() === 'Horizon Admin') {
          await AdminUserApi.updateUser(this.userData.userId, this.userData)
          this.$emit('updateAdminTable')
        } else {
          await StaffUserApi.updateUser(this.userData.userId, this.userData)
        }
        await this.updateSessionInfo(this.userData)
        this.getUserData()
        this.addNotification('success', 'User edited successfully')
      } catch (error) {
        const errorData = error.response?.data
        if (typeof errorData === 'object' && errorData !== null) {
          for (let key in errorData) {
            this.addNotification('error', errorData[key][0])
          }
        } else {
          this.addNotification('error', 'Failed to edit the user. Please try again.')
        }
      } finally {
        this.submittingForm = false
      }
    },

    async getAssociatedSchools() {
      const response = await StaffMultipleSchoolApi.list()
      this.schools = response.schools
    },

    currentSchool(school) {
      return this.user().school.id == school.id
    },

    async switchSchool(school) {
      const response = await StaffMultipleSchoolApi.switchSchool({ schoolId: school.id })
      await this.updateSessionInfo(response)

      let sections = (await StaffSectionApi.list()).sections
      this.storeSections(sections)
    }

  }

}

</script>

<style scoped lang="scss">

.sheet-main {
  background-color: #f2f4f8;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 13px 20px;
  margin-bottom: 10px;
}

.sheet-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-right: 13px;
}

.sheet-subtitle {
  font-size: 16px;
  color: #505050;
}

.title-main {
  font-size: 20px;
}

.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 10px;
}
</style>