<template>
  <v-dialog
    :value="dialog"
    max-width="400"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6">
        <v-row>
          <v-col
            cols="12"
          >
            <v-card
              min-height="140px"
              max-height="140px"
              ripple
              flat
              :class="['dropzone', dragging ? 'dropzone-over' : '']"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
              @mouseleave="dragging = false"
            >
              <div
                class="text-center d-flex flex-column align-center justify-space-between h-100"
              >
                <span class="icon-container">
                  <v-img
                    contain
                    :src="require('@/assets/icons/icon-upload.svg')"
                    alt="icon-upload"
                    max-width="19"
                    max-height="19"
                  />
                </span>

                <div>
                  <h5 class="color-primary d-inline">
                    Click to upload
                  </h5>
                  <span class="dropzone__text-regular"> or drag and drop</span>
                  <span class="dropzone__text-regular dropzone__text-small d-block">CSV or XLS</span>
                </div>
              </div>
              <input
                id="file-upload"
                ref="file"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                title=" "
                @change="onFilePicked"
              >
            </v-card>
          </v-col>
          <v-col
            v-if="filePresent"
            cols="12"
            class="pt-0"
          >
            <v-icon
              size="24"
              color="#f47e26"
              class="mr-1"
            >
              mdi-file-document
            </v-icon>
            <span class="text-document-upload font-weight-500 color-primary">
              {{ fileUpload.fileName }}.{{ fileUpload.fileExt }}</span>
            <v-icon
              class="icon-remove"
              size="18"
              color="#3d5d74"
              @click="onResetImport()"
            >
              mdi-close
            </v-icon>
          </v-col>
          <v-col
            v-if="wrongFileType"
            cols="12"
            class="pt-0"
          >
            <span class="text-document-upload color-danger">Wrong document type</span>
          </v-col>
          <v-col
            v-if="missingFile"
            cols="12"
            class="pt-0"
          >
            <span class="text-document-upload color-danger">Upload a file first</span>
          </v-col>
          <v-col
            cols="12"
            class="pt-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__secondary btn-default__small font-weight-400"
              block
              @click="onDownloadTemplate()"
            >
              <v-img
                class="mr-1"
                contain
                :src="require('@/assets/icons/download-document.svg')"
                alt="icon-add"
                max-width="20"
                max-height="20"
              />
              <span class="font-weight-400">Download Template</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-3"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onUploadFile()"
            >
              Upload File
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="missingFile || wrongFileType"
          dense
          justify="end"
        >
          <v-col cols="auto">
            <h6 class="color-danger">
              Please check your inputs
            </h6>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <dialog-upload-users-errors
      :active="dialogUploadError"
      :errors-list-prop="errorsList"
      :role="role"
      :school-id="schoolId"
      :section-id="sectionId"
      :from-class="fromClass"
      @closeDialog="closeUsersErrorsDialog()"
      @updateTable="onTableUpdate()"
    />
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import Mixin from "@/mixins/mixin.js"
import {AdminSchoolApi, AdminSectionApi, StaffSectionApi} from "@/api";
import DialogUploadUsersErrors from "@/components/shared/DialogUploadUsersErrors.vue";

export default {
  name: "DialogUploadUsers",
  components: {DialogUploadUsersErrors, AppHeader},
  mixins: [Mixin],
  props: {
    active: Boolean,
    fromClass: Boolean,
    title: {
      type: String,
      required: true,
      default: '',
    },
    schoolId: {
      type: [Number, String],
      default: null,
    },
    role: {
      type: String,
      required: true,
      default: '',
    },
    sectionId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      dialogUploadError: false,
      loading: false,
      dragging: false,
      wrongFileType: false,
      missingFile: false,
      fileSizeInfo: '',
      fileUpload: {
        file: '',
        fileName: '',
        fileExt: '',
      },
      errorsList: {}
    }
  },

  computed: {
    filePresent() {
      return !this.isBlank(this.fileUpload.file.name)
    }
  },

  watch: {
    active(value) {
      this.wrongFileType = false
      this.missingFile = false
      this.dialog = value
    }
  },

  methods: {
    ...mapGetters(["userRole"]),


    // Template download
    onDownloadTemplate() {
      if (this.role === 'Student') {
        window.location = '/template/student_import_sample.csv'
      } else if(this.role === 'Parent') {
        window.location = '/template/parent_import_sample.csv'
      } else {
        window.location = '/template/staff_import_sample.csv'
      }
    },


    // File upload
    onFilePicked(e) {
      this.resetValidation()
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      if (!['text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'].includes(e.target.files[0].type)) {
        this.wrongFileType = true
        this.onResetImport()
        return
      }
      this.fileSizeInfo = files[0].size / 1000 + ' KB'
      this.attachImage(files[0])
    },

    attachImage(file) {
      this.fileUpload.fileExt = file.name.split('.').pop();
      this.fileUpload.fileName = file.name.replace(/\.[^/.]+$/, '')
      this.fileUpload.file = file
    },

    onResetImport() {
      this.fileUpload = {file: '', fileName: '', fileExt: ''}
      this.$refs.file.value = null;
    },

    async onUploadFile() {
      try {
        if (!this.isFileValid()) {
          return
        }
        this.loading = true
        let response = {}

        if (this.fromClass) {
          if (this.userRole() == "Horizon Admin") {
            response = await AdminSectionApi.importStudents(this.sectionId, this.role, this.fileUpload.file)
          } else {
            response = await StaffSectionApi.importStudents(this.sectionId, this.role, this.fileUpload.file)
          }
        } else if(this.role === 'Parent') {
          response = await AdminSchoolApi.importParents(this.schoolId, this.role, this.fileUpload.file)
        } else {
          response = await AdminSchoolApi.importUsers(this.schoolId, this.role, this.fileUpload.file)
        }

        if (response.errorCount > 0) {
          this.errorsList = response
          this.dialogUploadError = true
          throw Error('usersError')
        }
        this.onResetImport()
        this.addNotification('success', 'Import was successful')
        this.$emit('closeDialogUpdate')
      } catch (e) {
        if (e.message === 'usersError') {
          this.addNotification('warning', `Imported ${this.errorsList.importedCount} users. Failed to import ${this.errorsList.errorCount} users`)
        } else {
          this.addNotification('error', 'Failed to upload file. Please try again.')
        }
      } finally {
        this.loading = false
      }
    },

    isFileValid() {
      if (this.wrongFileType) {
        return false
      }

      if (!this.filePresent) {
        this.missingFile = true
        return false
      }

      return true
    },

    resetValidation() {
      this.wrongFileType = false
      this.missingFile = false
    },

    closeUsersErrorsDialog() {
      this.dialogUploadError = false
    },

    onTableUpdate() {
      this.$emit('updateTable')
    },

    onCloseDialog() {
      this.onResetImport()
      this.$emit('closeDialog')
    },
  }
}
</script>

<style lang="scss" scoped>

.dropzone {
  height: 140px;
  position: relative;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 16px 24px;

  &__text-regular {
    font-size: 14px;
    color: #475467;
  }

  &__text-small {
    font-size: 12px;
  }
}

.dropzone img {
  width: 100%;
  max-height: 100%;
}

.dropzone input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropzone:hover {
  background-color: #ebebeb
}

.dropzone-over {
  background: #5C5C5C;
  opacity: 0.8;
}

.text-document-upload {
  font-size: 14px;
}

.input_label {
  font-size: 14px;
  color: #B1B1B1;
  margin: 0;
}

.icon-container {
  padding: 10px;
  border-style: solid;
  border-width: 6px;
  border-color: #f9fafb;
  border-radius: 28px;
  background-color: #f2f4f7;
  width: 52px;
  height: 52px;
}

.icon-remove {
  float: right;
}

</style>