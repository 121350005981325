import HorizonApi from '@/api/horizon_api'

const REPORTS_PATH = '/api/staff/reports'
const STAFF_BATCH_PDF_EXPORTS_PATH = '/api/staff/batch_pdf_exports'

export default {
    reportExportScore(assessmentTypeId, assessmentSetId, schoolYear, sections, gradeLevels) {
        const params = {
            'assessment_type_id': assessmentTypeId,
            'assessment_set_id': assessmentSetId,
            'school_year_id ': schoolYear,
            'section_ids': sections,
            'grade_levels': gradeLevels,
        }
        return HorizonApi.get(`${REPORTS_PATH}/excel_report`, {params: params})
    },

    reportExportResponse(assessmentTypeId, assessmentSetId, schoolYear, sections, gradeLevels) {
        const params = {
            'assessment_type_id': assessmentTypeId,
            'assessment_set_id': assessmentSetId,
            'school_year_id': schoolYear,
            'section_ids': sections,
            'grade_levels': gradeLevels,
        }
        return HorizonApi.get(`${REPORTS_PATH}/response_export`, {params: params})
    },

    reportExportPdf({assessmentTypeId, assessmentSetId, schoolYear, sections, gradeLevels, containerType, sortOrder} = {}) {
        const params = {
            assessment_type_id: assessmentTypeId,
            assessment_set_id: assessmentSetId,
            school_year_id: schoolYear,
            section_ids: sections,
            grade_levels: gradeLevels,
            sort_order: sortOrder,
            container_type: containerType,
        }
        return HorizonApi.post(STAFF_BATCH_PDF_EXPORTS_PATH, params)
    },

}