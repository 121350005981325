<template>
  <v-container
    fluid
    class="mt-6"
  >
    <v-row v-if="activitiesLoading">
      <v-col>
        <v-skeleton-loader
          type="list-item-avatar"
          class="mb-3"
        />
        <v-skeleton-loader
          type="list-item-avatar"
          class="mb-3"
        />
        <v-skeleton-loader
          type="list-item-avatar"
          class="mb-3"
        />
        <v-skeleton-loader
          type="list-item-avatar"
          class="mb-3"
        />
        <v-skeleton-loader
          type="list-item-avatar"
          class="mb-3"
        />
        <v-skeleton-loader
          type="list-item-avatar"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="!activitiesLoading"
      class="mb-4"
    >
      <v-col cols="12">
        <h3 class="pb-3 border-bottom">
          {{ courseText }}
        </h3>
      </v-col>
    </v-row>
    <v-row v-if="courseActivity?.sections?.length > 0 && !activitiesLoading">
      <v-col cols="12">
        <div class="sections-container border-bottom pb-3">
          <transition-group tag="div">
            <template v-if="courseActivity && courseActivity.sections">
              <course-section
                v-for="section in courseActivity.sections"
                :id="'section-' + (section ? section.id : '')"
                :key="'section' + (section ? section.id : '')"
                :section="section"
                :course-id="courseActivity ? courseActivity.id : null"
                :section-list="sectionList"
                @section-update="loadCourse()"
              />
            </template>
          </transition-group>
        </div>
      </v-col>
    </v-row>
    <v-row
      justify="end"
      align="center"
    >
      <v-col
        cols="12"
        sm="auto"
        class="px-0"
      >
        <v-btn
          block
          dark
          class="btn-default btn-default__secondary"
          @click="backToCourses()"
        >
          Back
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')
import CourseSection from "./CourseSection.vue"
import {StaffCourseActivitiesApi, StaffSchoolApi} from '@/api'

export default {
  name: "CourseActivity",

  components: {
    CourseSection
  },

  data() {
    return {
      activitiesLoading: false,
      courseActivity: {},
      courseText: '',
      course_id: null,
      section_id: null,
      primaryTeacher: "Primary Teacher",
      teacherAdmin: "Teacher Admin",
      sectionList: [],
    }
  },

  computed: {
    ...mapUserState(["user"]),
  },

  async mounted() {
    this.course_id = this.$route.params.courseTemplate;
    this.section_id = this.$route.params.sectionId;
    await this.getSections();
    this.loadCourse();
  },

  methods: {
    ...mapSectionGetters(["getPrimarySections"]),

    async loadCourse() {
      try {
        this.activitiesLoading = true
        const response = await StaffCourseActivitiesApi.getCourseActivity(
            this.course_id)
        this.courseActivity = response.course_activity
        this.courseText = response.course_activity.course_name
      } catch (error) {
        this.addNotification('error', 'There was a problem getting course activites')
      } finally {
        this.activitiesLoading = false
      }
    },

    backToCourses() {
      this.$router.push({name: 'StaffCourseActivities'})
    },

    async getSections() {
      try {
        if (this.user.role === 'Teacher') {
          this.sectionList = this.getPrimarySections()
        } else {
          const response = await StaffSchoolApi.getAllClassesMinimal()
          this.sectionList = response.sections
        }
      } catch {
        this.addNotification('error', 'Failed to load classes of this school.')
      }
    },
  }
}
</script>

<style scoped lang="scss">
.border-bottom {
  border-bottom: 1px solid #eaecf0;
}

.sections-container {
  width: 100%
}

.course-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px 0px 15px;
  margin-top: 0px;
  padding: 0px 0px 20px 0px;
  flex-direction: column;

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
  }

}

.course-pane {
  border-color: #c7c7c7;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  padding: 10px 15px 15px;
}

</style>
