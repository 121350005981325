<template>
  <div
    :class="containerClass"
    class="donut-svg-container svg-container"
    :height="height"
  >
    <svg
      :id="chartId"
      :width="width"
      :height="height"
      xmlns="http://www.w3.org/2000/svg"
    />
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'DonutChart',
  props: {
    chartId: {
      type: String,
      required: true
    },
    chartTitle: {
      type: String,
      required: false,
      default: ''
    },
    chartTitleFontSize: {
      type: String,
      required: false,
      default: () => null
    },
    chartRange: {
      type: Boolean,
      default: true
    },
    isAverageScore: {
      type: Boolean,
      default: () => true
    },
    displayPercentage: {
      type: Boolean,
      default: false
    },
    averageScore: {
      type: [Number, String],
      default: '',
      required: true,
    },
    minScore: {
      type: Number,
      default: null,
    },
    maxScore: {
      type: Number,
      default: null,
    },
    dataSet: {
      type: Array,
      default: () => [{
        fillColor: false,
        index: 1
      }, {
        fillColor: false,
        index: 2
      }, {
        fillColor: false,
        index: 3
      }, {
        fillColor: false,
        index: 4
      }, {
        fillColor: false,
        index: 5
      }, {
        fillColor: false,
        index: 6
      }, {
        fillColor: false,
        index: 7
      }, {
        fillColor: false,
        index: 8
      }, {
        fillColor: false,
        index: 9
      }, {
        fillColor: false,
        index: 10
      }, {
        fillColor: false,
        index: 11
      }, {
        fillColor: false,
        index: 12
      }, {
        fillColor: false,
        index: 13
      }, {
        fillColor: false,
        index: 14
      }, {
        fillColor: false,
        index: 15
      }, {
        fillColor: false,
        index: 16
      }, {
        fillColor: false,
        index: 17
      }, {
        fillColor: false,
        index: 18
      }, {
        fillColor: false,
        index: 19
      }, {
        fillColor: false,
        index: 20
      }, {
        fillColor: false,
        index: 21
      }, {
        fillColor: false,
        index: 22
      }, {
        fillColor: false,
        index: 23
      }, {
        fillColor: false,
        index: 24
      }, {
        fillColor: false,
        index: 25
      }, {
        fillColor: false,
        index: 26
      }, {
        fillColor: false,
        index: 27
      }, {
        fillColor: false,
        index: 28
      }, {
        fillColor: false,
        index: 29
      }, {
        fillColor: false,
        index: 30
      }, {
        fillColor: false,
        index: 31
      }, {
        fillColor: false,
        index: 32
      }]
    },
    color: {
      type: String,
      default: () => '#F4701F'
    },
  },
  data () {
    return {
      properties: {},
      width: 0,
      height: 0,
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      radius: 0,
      thickness: 20,
      totalQty: 0
    }
  },
  computed: {
    containerClass () {
      return this.chartId + 'Container'
    },
    scorePercentage() {
      const normalizedRange = this.maxScore - this.minScore
      const normalizedScore = this.averageScore - this.minScore
      return normalizedScore / normalizedRange
    }
  },
  watch: {
    averageScore: function () {
      this.render()
    },
    dataSet: {
      handler: function () {
        this.render()
      },
      deep: true
    }
  },
  mounted () {
    this.render()
    window.addEventListener('resize', this.onWindowResized)
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResized)
  },
  methods: {
    onWindowResized () {
      this.render()
    },

    setupDimensions () {
      const chartContainer = d3.select('.' + this.containerClass)
      const containerWidth = chartContainer.node() ? chartContainer.node().getBoundingClientRect().width : false
      const containerHeight = chartContainer.node() ? (containerWidth * .73) : false

      this.properties.containerWidth = containerWidth
      this.properties.containerHeight = containerHeight

      this.width = containerWidth - this.margin.left - this.margin.right
      this.height = containerHeight - this.margin.top - this.margin.bottom
      this.radius = this.width / 2
    },

    initViz () {
      const { width, height, margin, radius } = this
      const svg = d3.select('#' + this.chartId)

      svg.selectAll('*').remove()
      svg.attr('class', 'visualization')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)

      const container = svg.append('g')
        .attr('class', 'donut')
        .attr('transform', 'translate(' + (radius + margin.left) + ',' + radius + ')')

      return { container }
    },

    render () {
      this.setupDimensions()

      const { radius } = this

      const data = this.dataSet

      // create the arc function
      const arc = d3.arc()
        .innerRadius(radius * 0.7)
        .outerRadius(radius)

      // create the pie function
      const chartAngle = 0.60 * Math.PI
      const pie = d3.pie()
        .startAngle(-chartAngle)
        .endAngle(chartAngle)
        .value(1 / 32)

      const { container } = this.initViz()
      this.renderArcs(container, data, pie, arc)
      this.chartText(container)
    },

    renderArcs (container, data, pie, arc) {
      const arcSpread = (this.width / 65)
      const singleArc = container.append('g')
        .attr('class', 'donut-arcs')
        .selectAll('path')
        .data(pie(data))
        .enter()
        .append('g')

      singleArc.append('path')
        .attr('d', arc)
        .attr('stroke', '#fff')
        .attr('stroke-width', arcSpread) // <-- Distance between bars
        .attr('fill', (d) => (d.index / 32) < this.scorePercentage ? this.color : '#CECECE')
    },

    chartText (container) {
      const chartText = container.append('g')
      const chartFontSize = this.chartTitleFontSize  || `${(this.width + this.height)/2 * 0.021}em`
      const innerDonutFontSize = `${(this.width + this.height)/2 * 0.006}em`
      const averageScorePlacement = this.chartTitle ? ((this.width + this.height)/2 * 0.24) : ((this.width + this.height)/2 * 0.13)
      const mainScoreFormat = this.displayPercentage ? this.averageScore+'%' : this.averageScore
      const scorePlacement = this.isAverageScore ? ((this.width + this.height)/2 * 0.23) : averageScorePlacement

      // Main Score
      chartText.append('text')
        .attr('text-anchor', 'middle')
        .attr('class', 'inner-donut-text')
        .attr('font-size', chartFontSize)
        .text(mainScoreFormat)

      // Title text
      if (this.chartTitle) {
        chartText.append('text')
          .attr('text-anchor', 'middle')
          .attr('class', 'inner-donut-child-text')
          .attr('y', ((this.width + this.height)/2 * 0.12))
          .attr('font-size', innerDonutFontSize)
          .text(this.chartTitle)
      }

      // Min max Scores
      if (this.chartRange) {
        chartText.append('text')
          .attr('text-anchor', 'middle')
          .attr('class', 'inner-donut-child-text')
          .attr('font-size', innerDonutFontSize)
          .attr('y', scorePlacement)
          .text('(' + this.minScore + '-' + this.maxScore + ')')
      }

      if(this.isAverageScore) {
        chartText.append('text')
        .attr('text-anchor', 'middle')
        .attr('class', 'inner-donut-child-text')
        .attr('font-size', innerDonutFontSize)
        .attr('y', averageScorePlacement)
        .text('Average Score')
      }
    }
  }
}
</script>

<style scoped>
</style>
