import HorizonApi from '@/api/horizon_api'

const SCORE_REPORT_PATH = `/api/student/score_reports`

export default {
  list () {
    return HorizonApi.get(`${SCORE_REPORT_PATH}`)
  },

  getCompositeReport ({assessmentType, assessmentSet, schoolYearId} = {}) {
    return HorizonApi.get(`${SCORE_REPORT_PATH}?assessment_type=${assessmentType}&assessment_set=${assessmentSet}&school_year_id=${schoolYearId}`)
  },

  get (assessmentId) {
    return HorizonApi.get(`/api/student/student_assessments/${assessmentId}`)    
  },

  questions_and_answers ({assessmentId, multipart} = {}) {
    return HorizonApi.get(`${SCORE_REPORT_PATH}/${assessmentId}/questions_and_answers?multipart=${multipart}`)
  },

  downloadPdf ({assessmentType, assessmentSet, schoolYearId} = {}) {
    let params = {
      assessment_type: assessmentType,
      assessment_set: assessmentSet,
      school_year_id: schoolYearId
    }
    return HorizonApi.downloadInline(`${SCORE_REPORT_PATH}/pdf_report`, params)    
  },

  getTopicAreaBreakdown({ studentAssessmentId, topicAreaId, multipart }) {
    let params = {
      params: {
        topic_area_id: topicAreaId,
        multipart: multipart
      }
    }

    return HorizonApi.get(`${SCORE_REPORT_PATH}/${studentAssessmentId}/topic_area_breakdown`, params)  
  }
}
