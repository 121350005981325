import HorizonApi from '@/api/horizon_api'
import store from '@/store'

// const STAFF_ASSESSMENTS_PATH = `/api/staff/class_assessments`

export default {
  supportedAssessments() {
    return HorizonApi.get('/api/staff/school/supported_assessments')
  },

  list (sectionId, page = 1) {
    return HorizonApi.get(`/api/staff/sections/${sectionId}/class_assessments?page=${page}`)
  },

  schedule () {
    return HorizonApi.get(`/api/staff/assessments/schedule`)
  },

  create (sectionAssessment) {
    let assessment = store.state.assessments.assessments.find(a => a.assessmentTypeId == sectionAssessment.assessmentType.id && a.assessmentSetId == sectionAssessment.assessmentSet.id && a.categoryId == sectionAssessment.category.id)

    if (sectionAssessment.sections.length == 1) {
      let section = sectionAssessment.sections[0].id
      let sectionAssessmentParams = {
        section_id: section,
        assessment_id: assessment.id,
        start_at: sectionAssessment.startAt,
        end_at: sectionAssessment.endAt,
        reveal_assessments_at: sectionAssessment.revealAt,
        assessment_assignment_setting_attributes: sectionAssessment.settings.requestParamsJson
      }

      return HorizonApi.post(`/api/staff/sections/${section}/class_assessments`, sectionAssessmentParams)
    } else {
      // bulk create endpoint
      let sectionAssessmentParams = {
        assessment_id: assessment.id,
        section_ids: sectionAssessment.sections.map(s => s.id),
        start_at: sectionAssessment.startAt,
        end_at: sectionAssessment.endAt,
        reveal_assessments_at: sectionAssessment.revealAt,
        assessment_assignment_setting_attributes: sectionAssessment.settings.requestParamsJson
      }

      return HorizonApi.post(`/api/staff/class_assessments/bulk_assign_assessment`, sectionAssessmentParams)
    }
  },

  createSat (sectionAssessmentGroup) {
    let assessment = store.state.assessments.assessments
      .find(a => a.assessmentTypeId == sectionAssessmentGroup.assessmentType.id && a.assessmentSetId == sectionAssessmentGroup.assessmentSet.id && a.categoryId == sectionAssessmentGroup.category.id && a.difficulty == "default")

    if (!assessment) {
      return Promise.reject(new Error('Assessment does not exist'))
    }

    if (sectionAssessmentGroup.sections.length == 1) {
      let section = sectionAssessmentGroup.sections[0].id
      let sectionAssessmentGroupParams = {
        default_assessment_id: assessment.id,
        section_id: section,
        assessment_administration_type: sectionAssessmentGroup.assessmentAdministrationType,
        p1_start_at: sectionAssessmentGroup.p1StartAt,
        p1_end_at: sectionAssessmentGroup.p1EndAt,
        p2_start_at: sectionAssessmentGroup.p2StartAt,
        p2_end_at: sectionAssessmentGroup.p2EndAt,
        reveal_start_at: sectionAssessmentGroup.revealAt,
        assessment_assignment_setting_attributes: sectionAssessmentGroup.settings.requestParamsJson
      }

      return HorizonApi.post(`/api/staff/sections/${section}/class_assessments_sat/`, sectionAssessmentGroupParams)
    } else {
      // bulk create endpoint
      let sectionAssessmentGroupParams = {
        section_ids: sectionAssessmentGroup.sections.map(s => s.id),
        section_assessment_group: {
          default_assessment_id: assessment.id,
          assessment_administration_type: sectionAssessmentGroup.assessmentAdministrationType,
          p1_start_at: sectionAssessmentGroup.p1StartAt,
          p1_end_at: sectionAssessmentGroup.p1EndAt,
          p2_start_at: sectionAssessmentGroup.p2StartAt,
          p2_end_at: sectionAssessmentGroup.p2EndAt,
          reveal_start_at: sectionAssessmentGroup.revealAt,
        assessment_assignment_setting_attributes: sectionAssessmentGroup.settings.requestParamsJson
        }
      }

      return HorizonApi.post(`/api/staff/class_assessments_sat/bulk_assign_assessment`, sectionAssessmentGroupParams)
    }
  },

  update (sectionAssessment) {
    if (sectionAssessment.sections.length == 1) {
      let section = sectionAssessment.sections[0].id
      let sectionAssessmentParams = {
        start_at: sectionAssessment.startAt,
        end_at: sectionAssessment.endAt,
        reveal_assessments_at: sectionAssessment.revealAt,
        assessment_assignment_setting_attributes: sectionAssessment.settings.requestParamsJson
      }

      return HorizonApi.patch(`/api/staff/sections/${section}/class_assessments/${sectionAssessment.id}`, sectionAssessmentParams)
    }
  },

  updateSat (sectionAssessmentGroup) {
    let section = sectionAssessmentGroup.sections[0].id
    let sectionAssessmentGroupParams = {
      p1_start_at: sectionAssessmentGroup.p1StartAt,
      p1_end_at: sectionAssessmentGroup.p1EndAt,
      p2_start_at: sectionAssessmentGroup.p2StartAt,
      p2_end_at: sectionAssessmentGroup.p2EndAt,
      reveal_start_at: sectionAssessmentGroup.revealAt,
      assessment_assignment_setting_attributes: sectionAssessmentGroup.settings.requestParamsJson
    }

    return HorizonApi.patch(`/api/staff/sections/${section}/class_assessments_sat/${sectionAssessmentGroup.id}`, sectionAssessmentGroupParams)
  },

  get (assessment) {
    let section = assessment.sections[0].id
    let sectionAssessmentId = assessment.id    
    return HorizonApi.get(`/api/staff/sections/${section}/class_assessments/${sectionAssessmentId}`)
  },

  getAssessmentGroup (assessment) {
    let section = assessment.sections[0].id
    let sectionAssessmentGroupId = assessment.sectionAssessmentGroupId
    return HorizonApi.get(`/api/staff/sections/${section}/class_assessments_sat/${sectionAssessmentGroupId}`)
  },

  monitoring(sectionId, assessmentSetId, assessmentTypeId, page = 1, perPage, sort, order, searchProp, filters)  {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    }

    for(let filter of Object.keys(filters)){
      params[filter] = filters[filter]
    }

    return HorizonApi.get(`/api/staff/assessments/monitoring/${sectionId}/${assessmentSetId}/${assessmentTypeId}`, {params: params})
  },

  liveMonitoring(monitoring)  {
    const params = {
      'page': monitoring.page,
      'per_page': monitoring.perPage,
      'subject_name': monitoring.subjectName,
      'course_type': monitoring.courseType,
      'difficulty': monitoring.selectedDifficulty,
      'query': monitoring.query,
    }
    return HorizonApi.get(`/api/staff/assessments/monitoring/live/${monitoring.sectionId}/${monitoring.assessmentSetId}/${monitoring.assessmentTypeId}`, {params: params})
  },

  delete (sectionAssessment) {
    return HorizonApi.delete(`/api/staff/sections/${sectionAssessment.sections[0].id}/class_assessments/${sectionAssessment.id}`)
  }, 

  deleteSat (sectionAssessment) {
    let section = sectionAssessment.sections[0].id
    let sectionAssessmentGroupId = sectionAssessment.sectionAssessmentGroupId
    return HorizonApi.delete(`/api/staff/sections/${section}/class_assessments_sat/${sectionAssessmentGroupId}`)
  }


}