<template>
  <v-dialog
    :value="dialog"
    max-width="700"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        title="Upload Assessment Results"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <label
              class="label-default label-default__small"
              for="assessment"
            >
              Which Assessment?
            </label>
            <v-select
              id="assessment"
              v-model="assessment"
              placeholder="Select Assessment"
              :items="assessmentsArray"
              item-text="name"
              :return-object="true"
              :hide-details="!$v.assessment.$error"
              :error-messages="$v.assessment.$error ? 'Please select an assessment' : null"
              :loading="loadingAssessments"
              dense
              flat
              class="input-default input-default__small mt-2"
              :menu-props="{ maxHeight: '250' }"
            />
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
          >
            <label
              class="label-default label-default__small"
            >
              Upload Document
            </label>
            <v-row v-show="!filePresent">
              <v-col
                cols="12"
                sm="auto"
                class="mt-2"
                :class="wrongFileType || missingFile ? 'pb-0' : undefined"
              >
                <div class="upload-document-container">
                  <v-btn
                    class="btn-default btn-default__secondary btn-default__small btn-template mr-0"
                    block
                  >
                    <v-img
                      class="mr-2"
                      contain
                      :src="require('@/assets/icons/importlist.png')"
                      alt="icon-add"
                      max-width="19"
                      max-height="19"
                    />
                    <span class="font-weight-400">Select Document</span>
                  </v-btn>
                  <input
                    id="file-upload"
                    ref="fileAssessment"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    title=" "
                    @change="onFilePicked"
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="filePresent"
            cols="12"
            class="pt-0"
          >
            <v-icon
              size="24"
              color="#f47e26"
              class="mr-1"
            >
              mdi-file-document
            </v-icon>
            <span class="font-size-14 font-weight-500 color-primary">
              {{ fileUpload.fileName }}.{{ fileUpload.fileExt }}
            </span>
            <v-icon
              class="icon-remove"
              size="18"
              color="#3d5d74"
              @click="onResetImport()"
            >
              mdi-close
            </v-icon>
          </v-col>
          <v-col
            v-if="wrongFileType"
            cols="12"
            class="pt-0"
          >
            <span class="font-size-12 color-danger">
              Wrong document type
            </span>
          </v-col>
          <v-col
            v-if="missingFile"
            cols="12"
            class="pt-0"
          >
            <span class="font-size-12 color-danger">
              Please upload a file
            </span>
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
          >
            <label
              class="label-default label-default__small"
            >
              Download Templates
            </label>
            <div class="mt-2">
              <v-btn
                v-for="template in templatesArray"
                :key="template.type"
                class="btn-default btn-default__secondary btn-default__small btn-template"
                @click="onTemplateDownload(template.type)"
              >
                <span class="font-weight-400">{{ template.text }}</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onUploadFile()"
            >
              Upload Results
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="missingFile || wrongFileType"
          dense
          justify="end"
        >
          <v-col cols="auto">
            <h6 class="color-danger">
              Please check your inputs
            </h6>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapGetters: mapAssessmentGetters, mapActions: mapAssessmentActions} = createNamespacedHelpers('assessments')
import AppHeader from "@/components/shared/AppHeader.vue"
import Mixin from "@/mixins/mixin.js"
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate/src";
import {AdminAssessmentApi, AdminSchoolApi} from "@/api";

export default {
  name: "DialogAssessmentResultUpload",
  components: {AppHeader},
  mixins: [Mixin, validationMixin],
  props: {
    active: Boolean,
    courseTypeProp: {
      type: String,
      default: '',
    },
    schoolId: {
      type: Number,
      default: null,
    }
  },

  data() {
    return {
      dialog: false,
      loading: false,
      loadingAssessments: false,
      assessment: {},
      wrongFileType: false,
      missingFile: false,
      fileUpload: {
        file: '',
        fileName: '',
        fileExt: '',
      },
      assessmentsArray: [],
      courseTypeArrays: {
        SAT: {
          templates: [
            {type: 'sat', text: 'SAT Template'},
            {type: 'psat89', text: 'PSAT 89 Template',},
            {type: 'psat10', text: 'PSAT 10 Template'},
          ],
        },
        ACT: {
          templates: [
            {type: 'act', text: 'ACT Template'},
            {type: 'preact9', text: 'PreACT 9 Template',},
            {type: 'preact10', text: 'PreACT 10 Template'},
          ],
        }
      }
    }
  },

  validations: {
    assessment: {required},
  },

  computed: {

    templatesArray() {
      return this.courseTypeArrays[this.courseTypeProp].templates
    },

    filePresent() {
      return !this.isBlank(this.fileUpload.file.name)
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.loadAssessments()
        this.$v.$reset()
      }
      this.resetValidation()
      this.clearForm()
      this.dialog = value
    }
  },

  methods: {
    ...mapAssessmentGetters(['getAssessmentChoices']),
    ...mapAssessmentActions(['storeAssessmentData', 'resetAssessmentState']),

    onTemplateDownload(type) {
      window.location = '/template/' + type + '_result.xlsx'
    },

    async loadAssessments() {
      try {
        this.loadingAssessments = true
        this.resetAssessmentState()
        const assessmentData = await AdminAssessmentApi.supportedAssessments({schoolId: this.schoolId})
        this.storeAssessmentData(assessmentData)
        this.assessmentsArray = this.getAssessmentChoices()
      } catch {
        this.addNotification('error', 'Failed to load assessments. Try reopening this dialog')
      } finally {
        this.loadingAssessments = false

      }
    },

    onFilePicked(e) {
      this.resetValidation()
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      if (!['text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel'].includes(e.target.files[0].type)) {
        this.wrongFileType = true
        this.onResetImport()
        return
      }
      this.fileSizeInfo = files[0].size / 1000 + ' KB'
      this.attachImage(files[0])
    },

    attachImage(file) {
      this.fileUpload.fileExt = file.name.split('.').pop();
      this.fileUpload.fileName = file.name.replace(/\.[^/.]+$/, '')
      this.fileUpload.file = file
    },

    async onUploadFile() {
      try {
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid || !this.isFileValid()) {
          return
        }
        this.loading = true
        await AdminSchoolApi.uploadAssessmentResults(this.schoolId, this.fileUpload.file, this.assessment.assessmentType?.id, this.assessment.assessmentSet?.id)
        this.onResetImport()
        this.addNotification('success', 'Upload was successful')
        this.$emit('closeDialog')
      } catch {
        this.addNotification('error', 'Failed to upload file. Please try again.')
      } finally {
        this.loading = false
      }
    },

    isFileValid() {
      if (this.wrongFileType) {
        return false
      }

      if (!this.filePresent) {
        this.missingFile = true
        return false
      }

      return true
    },

    onResetImport() {
      this.fileUpload = {file: '', fileName: '', fileExt: ''}
      this.$refs.fileAssessment.value = null;
    },

    resetValidation() {
      this.wrongFileType = false
      this.missingFile = false
    },

    clearForm() {
      this.assessment = {}
    },

    onCloseDialog() {
      this.onResetImport()
      this.$emit('closeDialog')
    },
  },
}
</script>

<style scoped lang="scss">

.btn-template {
  font-size: 14px !important;
  margin: 4px 15px 0 0;


}

.upload-document-container {
  position: relative;

  input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.icon-remove {
  float: right;
}

</style>