import StaffDashboard from "@/components/staff/StaffDashboard.vue";
import StaffHome from "@/components/staff/StaffHome.vue";
import StaffClasses from "@/components/staff/StaffClasses.vue";
import StaffAssessments from "@/components/staff/StaffAssessments.vue";
import AssessmentSchedules from "@/components/shared/AssessmentSchedules.vue";
import StaffAssessmentMonitoring from "@/components/staff/StaffAssessmentMonitoring.vue";
import StaffCourseActivities from "@/components/staff/StaffCourseActivities";
import StaffCourseActivitiesClass from "@/components/staff/StaffCourseActivitiesClass";
import StaffCourseActivitiesSchool from "@/components/staff/StaffCourseActivitiesSchool";
import StaffCourseActivitiesCourse from "@/components/staff/course/CourseActivity";
import StaffCourseActivitiesAssignments from "@/components/staff/StaffCourseActivitiesAssignments";
import StaffCourseActivitiesGradebook from "@/components/staff/StaffCourseActivitiesGradebook";
import StaffClass from "@/components/staff/StaffClass.vue";
import ClassSettings from "@/components/shared/ClassSettings.vue";
import StaffReportComposite from "@/components/staff/StaffReportComposite.vue";
import StudentList from "@/components/shared/StudentList.vue";
import StaffSettings from "@/components/staff/StaffSettings.vue";
import StaffStudentResultsDashboard from "@/components/staff/StaffStudentResultsDashboard.vue";
import StaffStudentResults from "@/components/staff/StaffStudentResults.vue";
import StaffStudentResultsSummary from "@/components/shared/student_results/Summary.vue";
import StaffStudentResultsGrowthReporting from "@/components/shared/student_results/GrowthReporting.vue";
import StaffIndividualStudentComparison from "@/components/shared/student_results/IndividualStudentComparison.vue";
import SubsectionDetails from "@/components/shared/student_results/SubsectionDetails.vue";
import StaffPdfDownloads from "@/components/staff/StaffPdfDownloads.vue";
import AdminUsers from "@/components/admin/AdminUsers.vue";
import AdminClasses from "@/components/admin/AdminClasses.vue";
import AdminClass from "@/components/admin/AdminClass.vue";
import SessionDetailByItemReport from "@/components/student/reports/SessionDetailByItemReport.vue";
import ResponseAnalysisByItem from "@/components/staff/StaffResponseAnalysisByItem.vue";
import ActivityAnalysisByItem from "@/components/staff/StaffActivityAnalysisByItem.vue";
import StaffAssessmentMultibuilder from "@/components/staff/StaffAssessmentMultibuilder.vue";
import StaffAssessmentLiveMonitoring from "@/components/staff/StaffAssessmentLiveMonitoring.vue";
import AdminTerms from "@/components/admin/AdminTerms.vue";
import StaffReportsDashboard from "@/components/staff/StaffReportsDashboard.vue";
import ContactUs from "@/components/shared/ContactUs.vue";

export default [
  {
    path: '/staff',
    component: StaffDashboard,
    children: [
      {
        path: '',
        name: '',
        redirect: {name: 'StaffHome'},
      },
      {
        path: 'refresh',
        name: 'ResetParamHelper',
        meta: {
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        }
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        component: ContactUs,
          meta: {
              breadcrumb: [
                  {text: "Contact Us"}
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
          }
      },
      {
        path: 'dashboard',
        name: 'StaffHome',
        component: StaffHome,
        props: true,
        meta: {
          breadcrumb() {
            const school = this.$route.params.school;
            return [
              {
                text: school,
              }

            ]
          },
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        }
      },
      {
        path: 'classes',
        name: 'StaffClasses',
        component: StaffClasses,
        meta: {
          breadcrumb: [
            { text: "Classes", to: { name: "StaffClasses" } }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        }
      },
      {
        path: 'settings',
        props: true,
        name: 'StaffSettings',
        component: StaffSettings,
        meta: {
          roles: ['District Admin', 'Teacher', 'Teacher Admin'],
          breadcrumb: [
            {text: 'Settings'}
          ],
        }
      },
      {
        path: 'classes/:sectionId',
        name: 'StaffClass',
        component: StaffClass,
        props: true,
        meta: {
          breadcrumb: [
            { text: "Classes" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
        children: [
          {
            path: 'students',
            name: 'StaffClassStudents',
            props: true,
            component: StudentList,
            meta: {
              breadcrumb: [
                { text: "Classes", to: { name: "StaffClasses" } },
                { text: "Students" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'settings',
            name: 'StaffClassSettings',
            props: true,
            component: ClassSettings,
            meta: {
              breadcrumb: [
                { text: "Classes", to: { name: "StaffClasses" } },
                { text: "Settings" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'assessments',
            name: 'StaffClassAssessments',
            props: true,
            component: AssessmentSchedules,
            meta: {
              breadcrumb: [
                { text: "Classes", to: { name: "StaffClasses" } },
                { text: "Assessments" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin'],
              classSpecific: true,
            },
          },
        ]
      },
      {
        path: '/staff/course_activities',
        name: 'StaffCourseActivities',
        component: StaffCourseActivities,
        meta: {
            roles: ['District Admin', 'Teacher', 'Teacher Admin'],
            breadcrumb: [
                { text: "Course Activities", to: {name: "StaffCourseActivities"}},
            ]
        },
      },
      {
        path: '/staff/course_activities/course',
        component: StaffCourseActivitiesSchool,
        props: true,
        meta: {
            roles: ['District Admin', 'Teacher', 'Teacher Admin'],
            breadcrumb: [
                { text: "Course Activities", to: {name: "StaffCourseActivitiesSchool"}},
            ]
        },
        children: [
          {
            path: 'activities/:courseTemplate(\\d+)',
            name: 'StaffCourseActivitiesSchoolCourse',
            component: StaffCourseActivitiesCourse,
            props: true,
            meta: {
                roles: ['District Admin', 'Teacher', 'Teacher Admin'],
                breadcrumb: [
                    { text: "Course Activities"}
                ]
            },
          },
          {
            path: 'assignments/:courseTemplate(\\d+)',
            name: 'StaffCourseActivitiesSchoolAssignments',
            component: StaffCourseActivitiesAssignments,
            props: true,
            meta: {
                roles: ['District Admin', 'Teacher', 'Teacher Admin'],
                breadcrumb: [
                    { text: "Course Activities"}
                ]
            },
          },
          {
            path: 'gradebook/:courseTemplate(\\d+)',
            name: 'StaffCourseActivitiesSchoolGradebook',
            component: StaffCourseActivitiesGradebook,
            props: true,
            meta: {
                roles: ['District Admin', 'Teacher', 'Teacher Admin'],
                breadcrumb: [
                    { text: "Course Activities"}
                ]
            },
          },
        ],
      },
      {
        path: '/staff/course_activities/class',
        component: StaffCourseActivitiesClass,
        props: true,
        meta: {
            roles: ['District Admin', 'Teacher', 'Teacher Admin'],
            breadcrumb: [
                { text: "Course Activities", to: {name: "StaffCourseActivities"}},
            ]
        },
        children: [
          {
            path: ':sectionId(\\d+)/activities',
            name: 'StaffCourseActivitiesCourse',
            component: StaffCourseActivitiesCourse,
            props: true,
            meta: {
                roles: ['District Admin', 'Teacher', 'Teacher Admin'],
                breadcrumb: [
                    { text: "Course Activities"}
                ]
            },
          },
          {
            path: ':sectionId(\\d+)/assignments',
            name: 'StaffCourseActivitiesAssignments',
            component: StaffCourseActivitiesAssignments,
            props: true,
            meta: {
                isSectionSpecific: true,
                roles: ['District Admin', 'Teacher', 'Teacher Admin'],
                breadcrumb: [
                    { text: "Course Activities"}
                ]
            },
          },
          {
            path: ':sectionId(\\d+)/gradebook',
            name: 'StaffCourseActivitiesGradebook',
            component: StaffCourseActivitiesGradebook,
            props: true,
            meta: {
                roles: ['District Admin', 'Teacher', 'Teacher Admin'],
                breadcrumb: [
                    { text: "Course Activities"}
                ]
            },
          },
        ],
      },

      {
        path: '/staff/assessments',
        component: StaffAssessments,
        meta: {
          breadcrumb: [
            { text: "Assessments" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
        children: [
          {
            path: '',
            name: 'StaffAssessmentSchedules',
            component: AssessmentSchedules,
            meta: {
              breadcrumb: [
                {text: "Assessments"},
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'monitoring/:sectionId(\\d+)?/:assessmentSetId(\\d+)?/:assessmentTypeId(\\d+)?',
            name: 'StaffAssessmentMonitoring',
            props: true,
            component: StaffAssessmentMonitoring,
            meta: {
              breadcrumb: [
                {text: "Assessments"},
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
        ]
      },

      {
        path: '/staff/assessments/assign',
        name: 'StaffAssessmentMultibuilder',
        props: true,
        component: StaffAssessmentMultibuilder,
        meta: {
          breadcrumb: [
            {text: "Assessments"},
            {text: "Assign Assessments"},
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
      },

      {
        path: '/staff/reports',
        name: "StaffReportsDashboard",
        component: StaffReportsDashboard,
        meta: {
          breadcrumb: [
            { text: "Student Results" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        }
      },

      {
          path: '/staff/reports/individual/compare',
          name: "StaffIndividualStudentComparison",
          component: StaffIndividualStudentComparison,
          props: true,
          meta: {
            breadcrumb: [
              { text: "Student Results", to: { name: "StaffReportsDashboard" } },
              { text: "Individual Comparison" }
            ],
            roles: ['District Admin', 'Teacher', 'Teacher Admin']
          }
      },

      {
        path: '/staff/student_results',
        name: "StaffStudentResultsDashboard",
        component: StaffStudentResultsDashboard,
        meta: {
          breadcrumb: [
            { text: "Student Results" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        }
      },
      {
        path: '/staff/student_results/:assessmentSetId(\\d+)/:assessmentTypeId(\\d+)/:assessmentSummariesCount(\\d+)',
        component: StaffStudentResults,
        props: true,
        meta: {
          breadcrumb: [
            { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
        children: [
          {
            path: '',
            props: true,
            name: 'Staff/StudentResults/Summary',
            component: StaffStudentResultsSummary,
            meta: {
              breadcrumb: [
                { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                { text: "Summary" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'subsection_details',
            name: 'Staff/StudentResults/SubsectionDetails',
            props: true,
            component: SubsectionDetails,
            meta: {
              breadcrumb: [
                { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                { text: "Subsection Details" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'growth_reporting',
            name: 'Staff/StudentResults/GrowthReporting',
            props: true,
            component: StaffStudentResultsGrowthReporting,
            meta: {
              breadcrumb: [
                { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                { text: "Growth Reporting" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          }
        ]
      },
      {
        path: '/staff/student_results/class/:sectionId(\\d+)/:assessmentSetId(\\d+)/:assessmentTypeId(\\d+)/:assessmentSummariesCount(\\d+)',
        component: StaffStudentResults,
        props: true,
        meta: {
          breadcrumb: [
            { text: "Student Results" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
        children: [
          {
            path: '',
            props: true,
            name: 'Staff/Section/StudentResults/Summary',
            component: StaffStudentResultsSummary,
            meta: {
              breadcrumb: [
                { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                { text: "Summary" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'subsection_details',
            name: 'Staff/Section/StudentResults/SubsectionDetails',
            props: true,
            component: SubsectionDetails,
            meta: {
              breadcrumb: [
                { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                { text: "Subsection Details" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'growth_reporting',
            name: 'Staff/Section/StudentResults/GrowthReporting',
            props: true,
            component: StaffStudentResultsGrowthReporting,
            meta: {
              breadcrumb: [
                { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                { text: "Growth Reporting" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
        ]
      },
      {
        path: 'reports/:assessmentSetId/:assessmentTypeId/:studentId/:sectionId(\\d+)?',
        props: route => ({ ...route.query, ...route.params }),
        name: 'StaffReportComposite',
        component: StaffReportComposite,
        meta: {
          roles: ['District Admin', 'Teacher', 'Teacher Admin'],
          breadcrumb: [
            { text: "Student Reports" }
          ]
        },
      },
      {
          path: 'reports/class/:sectionId/:assessmentId/session_detail_by_item',
          name: 'StaffSessionDetailByItemReportWithSection',
          component: SessionDetailByItemReport,
          props: true,
          meta: {
              breadcrumb: [
                  { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                  {text: "View Questions and Correct Answers"}
              ],
              roles: ['District Admin', 'Teacher', "Teacher Admin"]
          },
      },
      {
          path: 'reports/:assessmentId/session_detail_by_item',
          name: 'StaffSessionDetailByItemReport',
          component: SessionDetailByItemReport,
          props: true,
          meta: {
              breadcrumb: [
                  { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                  {text: "View Questions and Correct Answers"}
              ],
              roles: ['District Admin', 'Teacher', "Teacher Admin"]
          },
      },
      {
        path: 'reports/class/:sectionId/:lessonId/:courseId/:activityId/activity_analysis_by_item',
        name: 'StaffActivityAnalysisByItem',
        component: ActivityAnalysisByItem,
        props: true,
        meta: {
          breadcrumb: [
            {
              text: 'Course Activity',
              to: 'StaffCourseActivities'
            },
          ],
          roles: ['District Admin', 'Teacher', "Teacher Admin"]
        },
      },
      {
          path: 'reports/class/:sectionId/:assessmentId/response_analysis_by_item',
          name: 'StaffResponseAnalysisByItemWithSection',
          component: ResponseAnalysisByItem,
          props: route => ({ ...route.query, ...route.params }),
          meta: {
              breadcrumb: [
                  { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                  {text: "Question and Answer Analysis"}
              ],
              roles: ['District Admin', 'Teacher', "Teacher Admin"]
          },
      },
      {
          path: 'reports/:assessmentId/response_analysis_by_item',
          name: 'StaffResponseAnalysisByItem',
          component: ResponseAnalysisByItem,
          props: route => ({ ...route.query, ...route.params }),
          meta: {
              breadcrumb: [
                  { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
                  {text: "Question and Answer Analysis"}
              ],
              roles: ['District Admin', 'Teacher', "Teacher Admin"]
          },
      },
      {
        path: 'reports/monitoring/live/:sectionId/:assessmentSetId/:assessmentTypeId/live_activitystatus_by_user/:subjectName/:difficulty',
        name: 'StaffAssessmentLiveMonitoring',
        component: StaffAssessmentLiveMonitoring,
        props: true,
        meta: {
          breadcrumb: [
            {
              text: 'Live Monitoring',
            },
          ],
          roles: ['District Admin', 'Teacher', "Teacher Admin"]
        },
      },
      {
        path: '/staff/student_results/downloads',
        name: "StaffPdfDownloads",
        component: StaffPdfDownloads,
        meta: {
          breadcrumb: [
            { text: "Student Results", to: { name: "StaffStudentResultsDashboard" } },
            { text: "Exports" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        }
      },
      {
        path: '/staff/school-dashboard/:id',
        props: true,
        component: () => import('@/components/admin/AdminDashboard.vue'),
        meta: {
          breadcrumb() {
            const school = this.$route.params.school;
            return [
              {
                text: 'Schools',
                to: {name: 'Schools'}
              },
              {
                text: school,
              }

            ]
          },
          roles: ['District Admin', 'Teacher Admin'],
        },
        children: [
          {
            path: '',
            name: '',
            redirect: {name: 'SchoolDashboard/Users'},
          },
          {
            path: 'users',
            name: 'SchoolDashboard/Users',
            component: AdminUsers,
            props: true,
            meta: {
              breadcrumb() {
                const school = this.$route.params.school;
                return [
                  {
                    text: school,
                  }
                ]
              },
              roles: ['District Admin', 'Teacher Admin']
            },
          },
          {
            path: 'classes',
            name: 'SchoolDashboard/Classes',
            component: AdminClasses,
            props: true,
            meta: {
              breadcrumb() {
                const school = this.$route.params.school;
                return [
                  {
                    text: school,
                  }
                ]
              },
              roles: ['District Admin', 'Teacher Admin'],
            },
          },
          {
            path: 'classes/:sectionId',
            name: 'TeacherAdminClass',
            component: AdminClass,
            props: true,
            meta: {
              breadcrumb() {
                const school = this.$route.params.school;
                return [
                  {
                    text: school,
                  }
                ]
              },
              roles: ['District Admin', 'Teacher Admin'],
              fromSchoolDashboard: true,
            },
          },
          {
            path: 'terms',
            name: 'SchoolDashboard/Terms',
            props: true,
            component: AdminTerms,
            meta: {
              breadcrumb() {
                const school = this.$route.params.school;
                return [
                  {
                    text: school,
                  }
                ]
              },
              roles: ['District Admin', 'Teacher Admin'],
            },
          },
          {
            path: 'assessments',
            name: 'SchoolDashboard/Assessments',
            props: true,
            component: AssessmentSchedules,
            meta: {
              breadcrumb() {
                const school = this.$route.params.school;
                return [
                  {
                    text: school,
                  }
                ]
              },
              roles: ['District Admin', 'Teacher Admin'],
              fromSchoolDashboard: true
            },
          },
        ]
      },

      {
        path: '/staff/reports/group',
        component: () => import('@/components/staff/StaffReportsGroup.vue'),
        props: true,
        meta: {
          breadcrumb: [
            { text: "Reports" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
        children: [
          {
            path: '',
            name: '',
            redirect: {name: 'Staff/Reports/Group/Summary'},
          },
          {
            path: 'summary',
            props: true,
            name: 'Staff/Reports/Group/Summary',
            component: () => import('@/components/shared/student_results/ReportsGroupSummary.vue'),
            meta: {
              breadcrumb: [
                { text: "Reports", to: { name: "StaffReportsDashboard" } },
                { text: "Summary" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'subsection-details',
            name: 'Staff/Reports/Group/SubsectionDetails',
            props: true,
            component: () => import('@/components/shared/student_results/ReportsGroupSubsection.vue'),
            meta: {
              breadcrumb: [
                { text: "Reports", to: { name: "StaffReportsDashboard" } },
                { text: "Subsection Details" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
          {
            path: 'student-performance',
            name: 'Staff/Reports/Group/StudentPerformance',
            props: true,
            component: () => import('@/components/shared/student_results/ReportsIndividualPerformance.vue'),
            meta: {
              breadcrumb: [
                { text: "Reports", to: { name: "StaffReportsDashboard" } },
                { text: "Student Performance" }
              ],
              roles: ['District Admin', 'Teacher', 'Teacher Admin']
            },
          },
        ]
      },
      {
        path: '/staff/reports/group/comparison',
        name: 'ReportsGroupComparison',
        component: () => import('@/components/shared/student_results/ReportsGroupComparison.vue'),
        props: true,
        meta: {
          breadcrumb: [
            { text: "Reports", to: { name: "StaffReportsDashboard" } },
            { text: "Comparison" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin']
        },
      },
      {
        path: '/staff/reports/score-export',
        name: "StaffPdfDownloads",
        component: () => import('@/components/shared/student_results/ReportExports.vue'),
        meta: {
          breadcrumb: [
            { text: "Reports", to: { name: "StaffReportsDashboard" } },
            { text: "Score Export" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin'],
          type: 'score'
        }
      },
      {
        path: '/staff/reports/response-export',
        name: "StaffPdfDownloads",
        component: () => import('@/components/shared/student_results/ReportExports.vue'),
        meta: {
          breadcrumb: [
            { text: "Reports", to: { name: "StaffReportsDashboard" } },
            { text: "Response Export" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin'],
          type: 'response'
        }
      },
      {
        path: '/staff/reports/pdf-export',
        name: "StaffPdfDownloads",
        component: () => import('@/components/shared/student_results/ReportExports.vue'),
        meta: {
          breadcrumb: [
            { text: "Reports", to: { name: "StaffReportsDashboard" } },
            { text: "PDF Export" }
          ],
          roles: ['District Admin', 'Teacher', 'Teacher Admin'],
          type: 'pdf'
        }
      },
    ]
  },

  {
    path: '/staff/course-activities/learnosity/:activityId',
    name: 'StaffCourseActivitiesLearnosity',
    component: () => import('@/components/staff/course/LearnosityActivity.vue'),
    meta: {
        breadcrumb: [
            {
                text: 'Course Activity',
                to: 'StaffCourseActivities'
            },
            {
                text: 'Learnosity'
            }
        ],
        roles: ['District Admin', 'Teacher', 'Teacher Admin']
    }
  }
]
