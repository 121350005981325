<template>
  <div>
    <v-container
      fluid
    >
      <app-header :title="courseName" />
    </v-container>

    <app-tabs-secondary
      :tabs="tabs"
      :active-tab="tabSelected"
      @onTabChange="onLoadCategory(...arguments)"
    />

    <router-view />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex"

const {mapState} = createNamespacedHelpers('staffSections')
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";
import {StaffCourseActivitiesApi} from "@/api";
import AppHeader from "@/components/shared/AppHeader.vue";

export default {
  name: "StaffCourseActivitiesSchool",

  components: {
    AppHeader,
    AppTabsSecondary
  },

  data() {
    return {
      tableLoading: true,
      sectionId: null,
      section: {},
      tabSelected: null,
      courseName: '',
      tabs: [
        {
          tabText: 'Course Activities',
          text: 'Assessment Schedule',
          name: 'StaffCourseActivitiesSchoolCourse',
        },
        {
          tabText: 'Assignments',
          text: 'Assessments',
          name: 'StaffCourseActivitiesSchoolAssignments',
        },
        {
          tabText: 'Gradebook',
          text: 'Gradebook',
          name: 'StaffCourseActivitiesSchoolGradebook',
        }
      ],
    }
  },

  watch: {
    $route() {
      this.setActiveTab()
    }
  },

  mounted() {
    this.sectionId = this.$route.params.sectionId;
    this.setActiveTab();
    this.loadCourseName()
  },

  methods: {
    ...mapState(['allSections']),

    async loadCourseName() {
      try {
        const response = await StaffCourseActivitiesApi.getCourseActivities(this.$route.params.courseTemplate, this.sectionId)
        this.courseName = response.course_activity?.course_name
      } catch {
        this.addNotification('error', 'Failed to load course name.')
      }
    },

    onLoadCategory(tab) {
      this.$router.push({
        name: this.tabs[tab].name,
        params: {
          courseTemplate: this.$route.params.courseTemplate,
        }
      })
    },

    setActiveTab() {
      for (let [index, tab] of this.tabs.entries()) {
        if (tab.name === this.$route.name) {
          this.tabSelected = index
        }
      }
    }
  },
}
</script>

