import HorizonApi from '@/api/horizon_api'

const STUDENTS_PATH = '/api/parent/students'

export default {
    getStudents() {
        return HorizonApi.get(STUDENTS_PATH)
    },

    getStudentsScoreReports(userId) {
        return HorizonApi.get(`${STUDENTS_PATH}/score_reports/${userId}`)
    },

    getCompositeReport({studentId, assessmentType, assessmentSet, schoolYearId} = {}) {
        return HorizonApi.get(`${STUDENTS_PATH}/score_reports/${studentId}?assessment_type=${assessmentType}&assessment_set=${assessmentSet}&school_year_id=${schoolYearId}`)
    },

    questionsAndAnswers({studentId, assessmentId, multipart} = {}) {
        return HorizonApi.get(`${STUDENTS_PATH}/questions_and_answers/${studentId}/${assessmentId}?multipart=${multipart}`)
    },

    downloadPdf({studentId, assessmentSetId, assessmentTypeId, schoolYearId} = {}) {
        let params = {
            assessment_type: assessmentTypeId,
            assessment_set: assessmentSetId,
            school_year_id: schoolYearId
        }
        return HorizonApi.downloadInline(`${STUDENTS_PATH}/pdf_report/${studentId}`, params)
    },
}