<template>
  <v-dialog
    :value="dialog"
    max-width="700"
    persistent
  >
    <v-card class="card-dialog">
      <app-header
        title="Change Course Template"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col cols="12">
            <h5>WARNING:<span class="font-weight-500">{{ bodyText }}</span></h5>
            <v-select
              id="courseTemplate"
              v-model="courseTemplate"
              :items="courseTemplateList"
              item-text="name"
              item-value="id"
              class="input-default"
              clearable
              dense
              flat
              hide-details
              return-object
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-9"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onConfirmChange()"
            >
              Change Course
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import functions from "@/api/shared/functions";

export default {
  name: "DialogCourseTemplateChange",
  components: {AppHeader},
  props: {
    active: Boolean,
    courseTemplateProp: {
      type: Object,
      default() {
        return {}
      }
    },
    courseTemplateList: {
      type: Array,
      default() {
        return []
      }
    },
  },

  data() {
    return {
      dialog: false,
      courseTemplate: '',
      bodyText: ' Changing the course template will delete ALL existing course activity data in the gradebook for ' +
          'every student at the school. Please make sure to export all existing gradebook data before proceeding.'
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.courseTemplate = functions.deepCopySync(this.courseTemplateProp)
      }
      this.dialog = value
    }
  },

  methods: {
    onConfirmChange() {
      this.$emit('confirmChange', this.courseTemplate)
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>