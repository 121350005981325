<template>
  <div>
    <v-container
      fluid
      class="pt-0"
    >
      <app-tabs-primary
        :is-dialog="true"
        :tabs="tabs"
        :active-tab="activeTab"
        @onTabChange="switchTab(...arguments)"
      />
    </v-container>
    


    <div v-if="activeTab === 0">
      <group-comparison
        :section-id="sectionId"
        :assessment-set-id="assessmentSetId"
        :assessment-type-id="assessmentTypeId"
      />
    </div>
    <div v-else-if="activeTab === 1">
      <student-comparison
        :section-id="sectionId"
        :assessment-set-id="assessmentSetId"
        :assessment-type-id="assessmentTypeId"
      />
    </div>
    <div v-else>
      There was a problem generating this report.
    </div>
  </div>
</template>

<script>
import StudentComparison from "./GrowthReportStudentComparison";
import AppTabsPrimary from "@/components/shared/AppTabsPrimary.vue";
import GroupComparison from "@/components/shared/student_results/GroupComparison.vue";

export default {
  name: "StaffStudentResultsGrowthReporting",

  components: {
    GroupComparison,
    AppTabsPrimary,
    StudentComparison
  },

  props: {
    sectionId: {
      type: [Number, String],
      required: false,
      default: null
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
    assessmentId: {
      type: [Number, String],
      required: false
    },
  },

  data() {
    return {
      tabs: [
        {
          tabText: 'Group Comparison',
          name: 'Group Comparison',
        },
        {
          tabText: 'Student Comparison',
          name: 'Student Comparison',
        },
      ],
      activeTab: 0
    }
  },

  methods: {
    switchTab(index) {
      this.activeTab = index
    },
  },
}
</script>
