<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <v-row dense>
      <v-col cols="12">
        <h3 class="title-select">
          Select which assessments to compare:
        </h3>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          id="firstAssessment"
          v-model="firstAssessment"
          placeholder="Select Assessment"
          dense
          flat
          solo
          class="input-default"
          item-text="name"
          :return-object="true"
          :items="getAssessmentChoices()"
          hide-details="auto"
          required
          @change="onFirstAssessmentChange()"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          id="secondAssessment"
          v-model="secondAssessment"
          placeholder="Select Assessment"
          dense
          flat
          solo
          class="input-default"
          item-text="name"
          :return-object="true"
          :items="getAssessmentChoices()"
          hide-details="auto"
          required
          @change="onSecondAssessmentChange()"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
      >
        <!--Report's Summary-->
        <v-card
          class="cards-container"
          outlined
        >
          <!--Composite Score Comparison-->
          <v-card-title class="card-title">
            <h3 class="font-weight-700">
              Composite Score Comparison
            </h3>
          </v-card-title>
          <div class="item-container">
            <!--First Results-->
            <v-card
              flat
              class="card-item"
            >
              <v-card-title class="card-subtitle">
                <h3 class="font-weight-700">
                  {{ firstAssessment.name }}
                </h3>
              </v-card-title>
              <div v-if="isEmptyObject(firstAssessmentOverview)">
                <div class="container-reports ma-6">
                  No data.
                </div>
              </div>
              <div v-else>
                <v-card-subtitle class="d-flex align-center">
                  <v-icon
                    size="20"
                    color="#38566b"
                    class="mr-1"
                  >
                    mdi-account-group
                  </v-icon>
                  <span class="font-size-16">
                    Number of Students Assessed:
                    <span class="font-weight-700 color-dark-orange">
                      {{ firstAssessmentOverview?.studentAssessedCount }} Students
                    </span>
                  </span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row
                    class="mt-2"
                    justify="center"
                  >
                    <v-col
                      ref="donutChartContainer"
                      cols="12"
                      sm="7"
                      lg="8"
                    >
                      <donut-chart
                        chart-id="summaryDonutChart"
                        :average-score="firstAssessmentOverview.composite?.score"
                        :is-average-score="true"
                        :min-score="firstAssessmentOverview.composite?.minScore"
                        :max-score="firstAssessmentOverview.composite?.maxScore"
                      />
                    </v-col>
                    <v-col
                      v-if="!firstResultsLoading"
                      cols="12"
                      class="bar-chart-dashboard bar-chart-container"
                      :style="{height: barChartHeight}"
                    >
                      <bar-chart
                        chart-id="summaryBarChart"
                        :data-set="firstAssessmentOverview.composite?.segments"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>

            <!--Second Results-->
            <v-card
              outlined
              class="card-item"
            >
              <v-card-title class="card-subtitle">
                <h3 class="font-weight-700">
                  {{ secondAssessment.name }}
                </h3>
              </v-card-title>
              <div v-if="isEmptyObject(secondAssessmentOverview)">
                <div class="container-reports ma-6">
                  No data.
                </div>
              </div>
              <div v-else>
                <v-card-subtitle class="d-flex align-center">
                  <v-icon
                    size="20"
                    color="#38566b"
                    class="mr-1"
                  >
                    mdi-account-group
                  </v-icon>
                  <span class="font-size-16">
                    Number of Students Assessed:
                    <span class="font-weight-700 color-dark-orange">
                      {{ secondAssessmentOverview?.studentAssessedCount }} Students
                    </span>
                  </span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row
                    class="mt-2"
                    justify="center"
                  >
                    <v-col
                      ref="donutChartContainerSecond"
                      cols="12"
                      sm="7"
                      lg="8"
                    >
                      <donut-chart
                        chart-id="summaryDonutChartSecond"
                        :average-score="secondAssessmentOverview.composite?.score"
                        :is-average-score="true"
                        :min-score="secondAssessmentOverview.composite?.minScore"
                        :max-score="secondAssessmentOverview.composite?.maxScore"
                      />
                    </v-col>
                    <v-col
                      v-if="!secondResultsLoading"
                      cols="12"
                      class="bar-chart-dashboard bar-chart-container"
                      :style="{height: barChartHeight}"
                    >
                      <bar-chart
                        chart-id="summaryBarChartSecond"
                        :data-set="secondAssessmentOverview.composite?.segments"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
          </div>

          <!--Section Scores Comparison-->
          <v-card-title class="card-title">
            <h3 class="font-weight-700">
              Section Scores Comparison
            </h3>
          </v-card-title>
          <div class="item-container">
            <!--First Results-->
            <v-card
              flat
              class="card-item"
            >
              <v-card-title class="card-subtitle">
                <h3 class="font-weight-700">
                  {{ firstAssessment.name }}
                </h3>
              </v-card-title>
              <div v-if="isEmptyObject(firstAssessmentOverview)">
                <div class="container-reports ma-6">
                  No data.
                </div>
              </div>
              <div v-else>
                <v-card-subtitle class="d-flex align-center">
                  <v-icon
                    size="20"
                    color="#38566b"
                    class="mr-1"
                  >
                    mdi-account-group
                  </v-icon>
                  <span class="font-size-16">
                    Number of Students Assessed:
                    <span class="font-weight-700 color-dark-orange">
                      {{ firstAssessmentOverview?.studentAssessedCount }} Students
                    </span>
                  </span>
                </v-card-subtitle>
                <v-card-text>
                  <div
                    v-for="category in firstAssessmentOverview.categories"
                    :key="category.category"
                  >
                    <h4 class="section-title font-weight-700">
                      {{ category.category }}
                    </h4>
                    <div
                      v-for="benchmarkScore in category.benchmarkScores"
                      :key="benchmarkScore.name"
                      class="section-subtitle"
                    >
                      {{ benchmarkScore?.name }}: {{ benchmarkScore?.score }}
                    </div>
                    <v-row
                      class="mt-2"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        sm="7"
                        lg="8"
                      >
                        <donut-chart
                          :chart-id="`section-score-${category?.category?.split(' ')[0]}-donut`"
                          :average-score="category?.score"
                          :is-average-score="true"
                          :min-score="category?.minScore"
                          :max-score="category?.maxScore"
                          :color="setSectionColor(category?.category)"
                        />
                      </v-col>
                      <v-col
                        v-if="!firstResultsLoading"
                        cols="12"
                        class="bar-chart-dashboard bar-chart-container"
                        :style="{height: barChartHeight}"
                      >
                        <bar-chart
                          :include-benchmark="firstAssessment?.assessmentType?.id != 5"
                          :chart-id="`section-score-${category?.category.split(' ')[0]}-bar`"
                          :benchmark-placement="category?.benchmarkPlacement"
                          :data-set="category?.segments"
                          :color="setSectionColor(category?.category)"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </div>
            </v-card>

            <!--Second Results-->
            <v-card
              outlined
              class="card-item"
            >
              <v-card-title class="card-subtitle">
                <h3 class="font-weight-700">
                  {{ secondAssessment.name }}
                </h3>
              </v-card-title>
              <div v-if="isEmptyObject(secondAssessmentOverview)">
                <div class="container-reports ma-6">
                  No data.
                </div>
              </div>
              <div v-else>
                <v-card-subtitle class="d-flex align-center">
                  <v-icon
                    size="20"
                    color="#38566b"
                    class="mr-1"
                  >
                    mdi-account-group
                  </v-icon>
                  <span class="font-size-16">
                    Number of Students Assessed:
                    <span class="font-weight-700 color-dark-orange">
                      {{ secondAssessmentOverview?.studentAssessedCount }} Students
                    </span>
                  </span>
                </v-card-subtitle>
                <v-card-text>
                  <div
                    v-for="category in secondAssessmentOverview.categories"
                    :key="category.category"
                  >
                    <h4 class="section-title font-weight-700">
                      {{ category.category }}
                    </h4>
                    <div
                      v-for="benchmarkScore in category.benchmarkScores"
                      :key="benchmarkScore.name"
                      class="section-subtitle"
                    >
                      {{ benchmarkScore?.name }}: {{ benchmarkScore?.score }}
                    </div>
                    <v-row
                      class="mt-2"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        sm="7"
                        lg="8"
                      >
                        <donut-chart
                          :chart-id="`section-score-${category?.category?.split(' ')[0]}-donut-second`"
                          :average-score="category?.score"
                          :is-average-score="true"
                          :min-score="category?.minScore"
                          :max-score="category?.maxScore"
                          :color="setSectionColor(category?.category)"
                        />
                      </v-col>
                      <v-col
                        v-if="!secondResultsLoading"
                        cols="12"
                        class="bar-chart-dashboard bar-chart-container"
                        :style="{height: barChartHeight}"
                      >
                        <bar-chart
                          :include-benchmark="secondAssessment?.assessmentType?.id != 5"
                          :chart-id="`section-score-${category?.category.split(' ')[0]}-bar-second`"
                          :benchmark-placement="category?.benchmarkPlacement"
                          :data-set="category?.segments"
                          :color="setSectionColor(category?.category)"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </div>
            </v-card>
          </div>
        </v-card>


        <!--Subsection Details Comparison-->
        <v-card
          class="summary-container cards-container"
          outlined
        >
          <v-card-title class="card-title">
            <h3 class="font-weight-700">
              Subsection Details Comparison
            </h3>
          </v-card-title>
          <div class="">
            <v-card-subtitle class="instructions">
              Click on each tab to view individual subsection details.
            </v-card-subtitle>
            <v-card-text class="pb-0">
              <v-tabs
                v-model="tab"
                :vertical="isMobile"
                :grow="isMobile"
                active-class="active-tab"
                hide-slider
                show-arrows
              >
                <v-tab
                  v-for="category in firstCategories"
                  :key="category.id"
                  @click="onTabClicked(category)"
                >
                  {{ category.name }}
                </v-tab>
              </v-tabs>
            </v-card-text>
          </div>
          <div class="item-container">
            <!--First Results-->
            <v-card
              flat
              class="card-item"
            >
              <v-card-text class="pt-0 pr-0">
                <v-sheet
                  outlined
                  class="border-right-remove"
                >
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(categoryDetail, idx) in firstCategoryDetails"
                      :key="idx"
                      :transition="false"
                    >
                      <v-card-title class="card-subtitle">
                        <h3 class="font-weight-700">
                          {{ firstAssessment.name }}
                        </h3>
                      </v-card-title>
                      <subsection-detail-tab
                        :from-group-comparison="true"
                        :data="categoryDetail"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-sheet>
              </v-card-text>
            </v-card>

            <!--Second Results-->
            <v-card
              flat
              class="card-item"
            >
              <v-card-text class="pt-0 pl-0">
                <v-sheet outlined>
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="(categoryDetail, idx) in secondCategoryDetails"
                      :key="idx"
                      :transition="false"
                    >
                      <v-card-title class="card-subtitle">
                        <h3 class="font-weight-700">
                          {{ secondAssessment.name }}
                        </h3>
                      </v-card-title>
                      <subsection-detail-tab
                        :from-group-comparison="true"
                        :data="categoryDetail"
                        :duplicated-chart="true"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-sheet>
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapGetters} = createNamespacedHelpers('assessments')

import DonutChart from "@/components/shared/charts/DonutChart"
import BarChart from "@/components/shared/charts/BarChart"
import SubsectionDetailTab from "@/components/shared/student_results/SubsectionDetailTab"

import {StaffStudentResultsApi} from "@/api";
import functions from "@/api/shared/functions";
import Mixin from "@/mixins/mixin";

export default {
  name: "GroupComparison",
  components: {
    DonutChart,
    BarChart,
    SubsectionDetailTab
  },

  mixins: [Mixin],
  props: {
    sectionId: {
      type: [Number, String],
      default: null
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
  },

  data() {
    return {
      firstResultsLoading: false,
      secondResultsLoading: false,
      firstAssessment: {},
      secondAssessment: {},

      barChartHeight: '',

      // Report's Summary
      overview: {},
      firstAssessmentOverview: {},
      secondAssessmentOverview: {},

      // Subsection Details Comparison
      tab: null,
      categoryDetails: [],

      firstCategories: [],
      firstCategoryDetails: [],
      secondCategories: [],
      secondCategoryDetails: [],
      loadedReportFirst: {},
      loadedReportSecond: {},
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  mounted() {
    this.populateSelects()
    window.addEventListener("resize", this.setBarChartHeight)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setBarChartHeight);
  },

  methods: {
    ...mapGetters(["getAssessmentChoices"]),

    populateSelects() {
      this.firstAssessment = this.getAssessmentChoices().find((el) => el.assessmentSet.id == this.assessmentSetId && el.assessmentType.id == this.assessmentTypeId)
      this.loadFirstCategories()
      this.loadFirstResults()

      const assessmentChoicesLength = this.getAssessmentChoices().length
      const firstAssessmentIndex = this.getAssessmentChoices().indexOf(this.firstAssessment)
      let secondAssessmentChoices = this.getAssessmentChoices().filter((el) => (el.assessmentType.id == this.firstAssessment.assessmentType.id) && (el.name != this.firstAssessment.name))
      
      if (secondAssessmentChoices.length > 0) {
        this.secondAssessment = secondAssessmentChoices[0]
      } else {
        if (firstAssessmentIndex + 1 < assessmentChoicesLength) {
          this.secondAssessment = this.getAssessmentChoices()[firstAssessmentIndex + 1]
        } else {
          this.secondAssessment = this.getAssessmentChoices()[0]
        }        
      }
      this.loadSecondResults()
    },

    onFirstAssessmentChange() {
      this.tab = 0
      this.loadFirstResults()
      this.loadFirstCategories()
    },

    onSecondAssessmentChange() {
      this.tab = 0
      this.loadSecondResults()
      this.loadFirstCategories()
    },

    setBarChartHeight() {
      if (!this.isEmptyObject(this.firstAssessmentOverview)) {
        this.barChartHeight = this.$refs.donutChartContainer.clientHeight + 'px'
        return
      }

      if (!this.isEmptyObject(this.secondAssessmentOverview)) {
        this.barChartHeight = this.$refs.donutChartContainerSecond.clientHeight + 'px'
      }
    },

    // Report's Summary
    async loadFirstResults() {
      try {
        this.firstResultsLoading = true
        this.firstAssessmentOverview = {}
        const response = await StaffStudentResultsApi.getOverview(
            {
              sectionId: this.sectionId,
              assessmentSetId: this.firstAssessment?.assessmentSet?.id,
              assessmentTypeId: this.firstAssessment?.assessmentType?.id
            }
        )
        this.firstAssessmentOverview = response.overview
      } catch {
        this.addNotification('error', 'There was a problem loading this assessment. Please check if there are results for this assessment')
      } finally {
        this.$nextTick(() => {
          this.barChartHeight = this.$refs.donutChartContainer?.clientHeight + 'px'
          this.firstResultsLoading = false
        })
      }
    },

    async loadSecondResults() {
      try {
        this.secondResultsLoading = true
        this.secondAssessmentOverview = {}
        const response = await StaffStudentResultsApi.getOverview(
            {
              sectionId: this.sectionId,
              assessmentSetId: this.secondAssessment?.assessmentSet?.id,
              assessmentTypeId: this.secondAssessment?.assessmentType?.id
            }
        )
        this.secondAssessmentOverview = response.overview
      } catch {
        this.addNotification('error', 'There was a problem loading this assessment. Please check if there are results for this assessment')
      } finally {
        this.$nextTick(() => {
          this.barChartHeight = this.$refs.donutChartContainerSecond?.clientHeight + 'px'
          this.secondResultsLoading = false
        })
      }
    },

    setSectionColor(section) {
      let greenCharts = ["Evidence-Based Reading and Writing", "English", "Reading"]
      let blueCharts = ["Math", "Science"]

      if (greenCharts.includes(section)) {
        return "#32c443"
      }
      if (blueCharts.includes(section)) {
        return "#6199d4"
      }
    },

    // Subsection Details Comparison
    async loadFirstCategories() {
      try {
        this.firstCategoryDetails = []
        const response = await StaffStudentResultsApi.getCategoryTabs(
            {
              sectionId: this.sectionId,
              assessmentSetId: this.firstAssessment?.assessmentSet?.id,
              assessmentTypeId: this.firstAssessment?.assessmentType?.id
            })
        this.firstCategories = response.assessments

        this.firstCategoryDetails = Array.apply(null, {length: this.firstCategories.length}).map(function () {
        })
        await this.loadFirstCategoryDetail(this.firstCategories[0])
        this.loadSecondCategories()
      } catch {
        this.addNotification('error', 'There was a problem loading Subsection Tabs. Please try another assessment')
      }
    },

    async loadFirstCategoryDetail(category) {
      let index = this.firstCategories.findIndex(cat => cat == category)

      try {
        this.loadedReportFirst = {}
        let response = await StaffStudentResultsApi.getCategoryDetails(
            {
              sectionId: this.sectionId,
              assessmentId: category.id,
            })

        this.loadedReportFirst = functions.deepCopySync(response)


        this.$set(this.firstCategoryDetails, index, {
          assessmentId: category.id,
          report: response
        })
      } catch {
        this.$set(this.firstCategoryDetails, index, null)
      }
    },

    async loadSecondCategories() {
      try {

        this.secondCategoryDetails = []
        const response = await StaffStudentResultsApi.getCategoryTabs(
            {
              sectionId: this.sectionId,
              assessmentSetId: this.secondAssessment?.assessmentSet?.id,
              assessmentTypeId: this.secondAssessment?.assessmentType?.id
            })
        this.secondCategories = response.assessments

        this.secondCategoryDetails = Array.apply(null, {length: this.secondCategories.length}).map(function () {
        })
        this.loadSecondCategoryDetail(this.firstCategories[0])
      } catch {
        this.addNotification('error', 'There was a problem loading Subsection Tabs. Please try another assessment')
      }
    },

    async loadSecondCategoryDetail(category) {
      const index = this.firstCategories.findIndex(cat => cat == category)
      const secondCategoryToLoad = this.secondCategories[index]

      try {
        this.loadedReportSecond = {}
        let response = await StaffStudentResultsApi.getCategoryDetails(
            {
              sectionId: this.sectionId,
              assessmentId: secondCategoryToLoad.id,
            })

        this.loadedReportSecond = functions.deepCopySync(response)

        this.manipulateCategoryReports(index, category, secondCategoryToLoad)
      } catch (e) {
        this.$set(this.secondCategoryDetails, index, null)
      }
    },

    async onTabClicked(category) {
      await this.loadFirstCategoryDetail(category)
      await this.loadSecondCategoryDetail(category)
    },


    manipulateCategoryReports(index, firstCategoryToLoad, secondCategoryToLoad) {
      try {

        if (this.loadedReportFirst?.categoryDetails?.subsectionDetails.length > 0 && this.loadedReportSecond?.categoryDetails?.subsectionDetails.length > 0) {


          for (let i = 0; i < this.loadedReportFirst.categoryDetails.subsectionDetails.length; i++) {
            let tempFirst = []
            let tempSecond = []

            if (this.loadedReportFirst.categoryDetails.subsectionDetails[i].topicAreaDetails) {
              tempFirst = this.loadedReportFirst.categoryDetails.subsectionDetails[i].topicAreaDetails
            }
            if (this.loadedReportSecond.categoryDetails.subsectionDetails[i].topicAreaDetails) {
              tempSecond = this.loadedReportSecond.categoryDetails.subsectionDetails[i].topicAreaDetails
            }

            const uniqueTopicAreas = new Set();
            const mergedArray = [
              ...tempFirst.filter(obj => {
                if (!uniqueTopicAreas.has(obj.topicArea)) {
                  uniqueTopicAreas.add(obj.topicArea);
                  return true;
                }
                return false;
              }),
              ...tempSecond.filter(obj => !uniqueTopicAreas.has(obj.topicArea)),
            ];

            const newFirstArray = []
            const newSecondArray = []
            for (let item of mergedArray) {
              const matchingObj1 = tempFirst.find(obj => obj.topicArea === item.topicArea);
              const matchingObj2 = tempSecond.find(obj => obj.topicArea === item.topicArea);
              newFirstArray.push(matchingObj1 ? matchingObj1 : {topicArea: item.topicArea})
              newSecondArray.push(matchingObj2 ? matchingObj2 : {topicArea: item.topicArea})
            }

            this.loadedReportFirst.categoryDetails.subsectionDetails[i].topicAreaDetails = newFirstArray
            this.loadedReportSecond.categoryDetails.subsectionDetails[i].topicAreaDetails = newSecondArray
          }
        }

        this.$set(this.firstCategoryDetails, index, {
          assessmentId: firstCategoryToLoad.id,
          report: this.loadedReportFirst
        })

        this.$set(this.secondCategoryDetails, index, {
          assessmentId: secondCategoryToLoad.id,
          report: this.loadedReportSecond
        })

      } catch (e) {
        console.log(e)
      }
    }

  }

}
</script>

<style scoped lang="scss">

.title-select {
  color: $primaryColor;
}

.cards-container {
  .item-container {
    display: flex;
  }

  .card-item {
    border-radius: 0 !important;
    width: 50% !important;
  }

  .card-title {
    background-color: #38566b;
    color: #fff;
    min-height: 68px;

    h3 {
      color: #fff;
    }
  }

  .card-subtitle {
    background-color: #F3F3F3;

    h3 {
      font-size: 18px !important;
      color: #345165;
    }
  }

  .section-title {
    color: #345165;
  }

  .section-subtitle {
    color: #333;
    font-size: 16px;
  }

  .bar-chart-container {
    //height: 29vh;
  }

}


.v-tab {
  text-transform: none !important;
  letter-spacing: 0;
  cursor: pointer;
  transition: .2s;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #aaa !important;
  line-height: 24px;
  padding: 13px 32px;
  text-align: center;
  font-size: 18px;
}

.v-card__title {
  background-color: #38566b;
  color: #fff;

  h3 {
    color: #fff;
  }
}

.active-tab {
  background-color: #38566b;
  color: #fff !important;
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
}

.border-right-remove {
  border-right: none
}

</style>