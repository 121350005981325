<template>
  <v-dialog
    id="horizon-modal"
    :value="active"
    persistent
  >
    <v-card
      class="card-dialog"
      role="document"
    >
      <app-header
        :title="modalTitle"
        :is-dialog="true"
        @closeDialog="closeModal"
      />
      <v-container class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6">
        <v-row class="modal-body">
          <v-col class="modal-container">
            <div class="modal-slot-content">
              <slot name="content" />
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="hasFooter()"
          class="modal-footer"
        >
          <v-col
            v-if="hasPagination()"
            class="modal-navigation"
          >
            <div
              v-if="hasPrevPage()"
              class="paginate-button btn btn-outline-primary"
              @click="prevPage"
            >
              Prev {{ pageLimit() }}
              <i
                aria-hidden="true"
                class="dropdown-icon fa fa-chevron-left text-orange"
              />
            </div>
            <div
              v-if="hasNextPage()"
              class="paginate-button btn btn-outline-primary"
              @click="nextPage"
            >
              Next {{ pageLimit() }}
              <i
                aria-hidden="true"
                class="dropdown-icon fa fa-chevron-right text-orange"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

export default {
  name: "HorizonModalFullWidth",

  components: {
    AppHeader
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {
      }
    },
    modalTitle: {
      type: String,
      required: true
    },
    pages: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    currentPage: {
      type: Number,
      required: false,
      default: 0
    }
  },

  methods: {
    closeModal() {
      this.onClose()
    },

    prevPage() {
      this.$emit('prev-page')
    },

    nextPage() {
      this.$emit('next-page')
    },

    pageLimit() {
      if (this.hasProperty(this.pages, 'page_limit')) {
        return this.pages.page_limit
      }
      return ''
    },

    hasPagination() {
      return this.hasProperty(this.pages, 'page_count') && this.pages.page_count > 1
    },

    hasFooter() {
      return this.hasPagination()
    },

    hasNextPage() {
      if (this.hasPagination() && (this.currentPage < this.pages.page_count)) {
        return true
      }
      return false
    },

    hasPrevPage() {
      if (this.hasPagination() && (this.currentPage > 1)) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped lang='scss'>

.horizon-modal {
  .card-dialog {
    max-width: 95%;
    width: fit-content;
    top: 0;
    right: 0;
    margin: 15% auto !important;

    .modal-content {
      .modal-body {
        .modal-container {
          .modal-slot-content {
            min-height: 20px !important;
            overflow: auto;
            padding: 15px;
          }
        }
      }

      .modal-footer {
        .modal-navigation {
          div.paginate-button {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      width: fit-content;
      max-width: max-content;
    }
  }
}

@media screen and (max-width: $mobileScreenLarge) {
  .horizon-modal {
    .card-dialog {
      margin-top: 20% !important;

      .modal-content {
        .modal-body {
          .modal-container {

            .modal-slot-content {
              border-left: 0;
              width: 100%;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobileScreenMedium) {
  .horizon-modal {
    .card-dialog {
      max-width: 450px;
      width: 450px;

      .modal-content {
        max-width: 450px;
        width: 450px;
      }
    }
  }
}

@media screen and (max-width: 492px) {
  .horizon-modal {
    .card-dialog {
      max-width: 350px;
      width: 350px;

      .modal-content {
        max-width: 350px;
        width: 350px;
      }
    }
  }
}

@media screen and (max-width: $mobileScreenExtraSmall) {
  .horizon-modal {
    .card-dialog {
      margin-top: 15% !important;
      max-width: 280px;
      width: 280px;

      .modal-content {
        max-width: 280px;
        width: 280px;
      }
    }
  }
}
</style>
