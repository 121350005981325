export default class Section {
  constructor(json) {
    this.id = json.id
    this.displayName = json.displayName
    this.className = json.className
    this.schoolId = json.schoolId
    this.active = json.active
    this.studentCount = json.studentCount
    this.role = json.role
    this.heCourseTemplateId = json.heCourseTemplateId
  }
}