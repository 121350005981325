<template>
  <v-container fluid>
    <app-header
      v-if="!fromStudentsDialog"
      title="Score Reports"
    />
    <div v-if="loadingReports">
      <v-skeleton-loader
        type="list-item-avatar@2"
      />
    </div>
    <div v-else-if="scoreReports.length > 0">
      <ScoreReportListItem
        v-for="(report, i) in scoreReports"
        :key="i"
        :report="report"
        @goto-report="loadReport(report)"
      />
    </div>
    <div v-else>
      No data.
    </div>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
import {StaffUserApi, StudentScoreReportApi} from '@/api'
import AppHeader from "@/components/shared/AppHeader.vue";
import ScoreReportListItem from './widgets/ScoreReportListItem.vue'

export default {
  components: {
    AppHeader,
    ScoreReportListItem
  },
  props: {
    fromStudentsDialog: Boolean,
    studentId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      scoreReports: [],
      loadingReports: true
    }
  },
  mounted() {
    this.loadScoreReports()
  },
  methods: {
    ...mapGetters(["userRole"]),

    async loadScoreReports() {
      try {
        let reportsResponse = {}

        if (this.userRole() === 'Student') {
          reportsResponse = await StudentScoreReportApi.list()
        } else {
          reportsResponse = await StaffUserApi.getStudentsScoreReport(this.studentId)
        }

        this.scoreReports = reportsResponse.compositeReports
      } catch (e) {
        this.addNotification('error', "Sorry, we could not load the student reports.")
      } finally {
        this.loadingReports = false
      }
    },

    loadReport(assessment) {
      let route = {}

      if (this.userRole() === 'Student') {
        route = {
          name: 'StudentReportComposite',
          params: {assessmentTypeId: assessment.assessmentType.id, assessmentSetId: assessment.assessmentSet.id},
          query: {year: assessment.schoolYear.id}
        }
      } else {
        // sectionId missing below for staff
        route = {
          name: 'StaffReportComposite',
          params: {assessmentTypeId: assessment.assessmentType.id, assessmentSetId: assessment.assessmentSet.id, studentId: assessment.user.id},
          query: {year: assessment.schoolYear.id}
        }
      }

      this.$router.push(route);
    }

  }
}
</script>
