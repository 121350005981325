import HorizonApi from '@/api/horizon_api'

const CLEVER_SYNC_PATH = `/api/clever`

export default {

  syncSchools() {
    return HorizonApi.post(`${CLEVER_SYNC_PATH}/sync_schools`)
  },

  syncLogs(sync_type){
    return HorizonApi.get(`${CLEVER_SYNC_PATH}/sync_logs`, {'params': {'sync_type': sync_type}})
  },

  syncRunLogs(sync_name){
    return HorizonApi.get(`${CLEVER_SYNC_PATH}/sync_run_logs`, {'params': {'sync_name': sync_name}})
  },

  districts(){
    return HorizonApi.get(`${CLEVER_SYNC_PATH}/districts`)
  },

  alignDistrictSchools(district) {
    return HorizonApi.post(`${CLEVER_SYNC_PATH}/align_district_schools`, {district: district})
  },

  syncDistrictParents(district) {
    return HorizonApi.post(`${CLEVER_SYNC_PATH}/sync_district_parents`, {district: district})
  },

  fixCleverDuplicates() {
    return HorizonApi.post(`${CLEVER_SYNC_PATH}/fix_clever_duplicates`)
  },

  getCleverDuplicatesFixLastLog() {
    return HorizonApi.get(`${CLEVER_SYNC_PATH}/fix_clever_duplicates_last_log`)
  },

}
