import axios from 'axios'

class HorizonApi {
  constructor () {
    axios.defaults.withCredentials = true

    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 60000,
      xsrfCookieName: "CSRF-TOKEN",
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        'Content-Type': 'application/json'
      }
    })

    this.defaultHeaders = {
      'Content-Type': 'application/json'
    }
  }

  get (path, params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.get(path, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }


  post (path, params, customHeaders = null) {
    return new Promise((resolve, reject) => {
      this.instance.post(path, params, {
          headers: customHeaders == null ? this.defaultHeaders : customHeaders
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }


  patch (path, params, customHeaders = null) {
    return new Promise((resolve, reject) => {
      this.instance.patch(path, params, {
          headers: customHeaders == null ? this.defaultHeaders : customHeaders
        })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  put (path, params) {
    return new Promise((resolve, reject) => {
      this.instance.put(path, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

  delete (path, params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.delete(path, params)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        });
    });
  }


  downloadInline (path, params = {}) {
    return new Promise((resolve, reject) => {
      this.instance.get(path, { params: params, responseType: 'arraybuffer' })
        .then((response) => {
          let blob = new Blob([response.data])
          let header = response.headers['content-disposition'];
          let filename = header.match(/filename\*?=((['"])[\s\S]*?\2|[^;\n]*)/)[1];
          
          resolve({filename, blob})
        })
        .catch((error) => {
          reject(error)
        });
    });
  }

}

const instance = new HorizonApi();
Object.freeze(instance);

export default instance;
