<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card class="dashboard-header justify-center justify-sm-space-between">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <staff-dashboard-boxes
                  title="Training Center"
                  description="Training & Webinars"
                  :to-new-window="true"
                  filename="icon-resource-center.png"
                  link="https://horizoneducation.com/training/live-online-training"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <staff-dashboard-boxes
                  title="Support Center"
                  description="Help Documentation"
                  :to-new-window="true"
                  filename="icon-support-center.png"
                  link="https://support.horizoneducation.com/"
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <staff-dashboard-boxes
                  title="Contact Us"
                  description="Customer Support Team"
                  filename="icon-contact-us.png"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mx-4 mx-sm-0"
    >
      <v-col
        cols="12"
        sm="8"
        class="text-center pt-8 pb-8 border-bottom"
      >
        <h1 class="color-primary mb-2">
          Contact Us
        </h1>
        <h5 class="font-weight-400 font-size-16">
          Horizon Education's Customer Support Team
        </h5>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="4"
        class="d-flex flex-column align-center"
      >
        <div class="pt-4 pt-sm-7 mb-5">
          <img
            width="150px"
            height="150px"
            :src="require('@/assets/img/icon-support-email.png')"
            alt="icon-email"
          >
        </div>
        <h3 class="mb-5" :class="isMobile ? 'font-size-18' : undefined">
          Email Support
        </h3>
        <h5 class="text-description font-size-16 font-weight-400">
          Contact us at
          <a
            target="_blank"
            href="mailto:support@horizoneducation.com"
          >support@horizoneducation.com</a>.
          The Implementation and Support team is available to help with
          technical issues, curriculum questions, and to schedule virtual meetings.
        </h5>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex flex-column align-center"
      >
        <div class="pt-4 pt-sm-7 mb-5">
          <img
            width="150px"
            height="150px"
            :src="require('@/assets/img/icon-support-chat.png')"
            alt="icon-chat"
          >
        </div>
        <h3 class="mb-5" :class="isMobile ? 'font-size-18' : undefined">
          Chat Support
        </h3>
        <h5 class="text-description font-size-16 font-weight-400 mb-5">
          Look for the blue chat icon in the bottom right corner within the Horizon platform.
        </h5>

        <h5 class="text-description font-size-16 font-weight-400">
          <span class="font-weight-600">Chat Hours:</span> Monday-Friday, 8am-5pm Eastern
        </h5>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
import StaffDashboardBoxes from "@/components/staff/widgets/StaffDashboardBoxes.vue";

export default {
  name: "ContactUs",
  components: {StaffDashboardBoxes},

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },
}
</script>

<style scoped lang="scss">
.dashboard-header {
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  justify-content: space-between;
  padding: 8px;
  background: linear-gradient(#eaf4ff, #dbe6f2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2) !important;
  border-radius: 12px;
}

.border-bottom {
  border-bottom: 1px solid #bbb
}

.text-description {
  text-align: center;
  width: 90%;
  line-height: 24px;
}

</style>