<template>
  <div class="assessment-check-box assessment-check-box__big">
    <input
      v-model="model"
      type="checkbox"
      :value="inputValue"
    >
    <v-img
      v-if="hasLogo"
      class="mb-2"
      contain
      :src="require(`@/assets/img/${filename}`)"
      alt="class-logo"
      max-width="64"
      max-height="64"
    />
    <span class="box-title">{{ title }}</span>
    <span
      v-if="hasSubtitlePrimary && sectionProp.duration"
      class="box-subtitle"
    >
      {{ sectionProp.duration }} minutes
    </span>
    <span
      v-if="hasSubtitleSecondary && sectionProp.questionCount"
      class="box-subtitle"
    >
      {{ sectionProp.questionCount }} items
    </span>
    
    <div
      v-if="isSelected"
      class="checkbox-icon-container"
    >
      <v-icon
        size="20"
        color="white"
      >
        mdi-check
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssessmentCheckBoxDefault",
  props: {
    hasLogo: Boolean,
    hasSubtitlePrimary: Boolean,
    hasSubtitleSecondary: Boolean,
    title: {
      type: String,
      default: ''
    },
    inputValue: {
      type: [Number, String],
      default: ''
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    sectionProp: {
      type: Object,
      default() {
        return {}
      }
    },
    filename: {
      type: String,
      default: ''
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("inputChanged", value);
      },
    },

    isSelected(){
      return this.value.indexOf(this.inputValue) !== -1
    }
  },
}
</script>

<style scoped>
</style>