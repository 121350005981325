<template>
  <div class="container">
    <br>
    <div id="learnosity_assess" />
  </div>
</template>

<script>
import { CourseActivitiesApi } from '@/api'

export default {
  name: "StudentCourseLearnosityActivity",

  mounted() {
    this.loadScripts()
  },

  methods: {
    loadScripts() {
      let readSpeakerScript = document.createElement('script')
      readSpeakerScript.src = '//cdn-na.readspeaker.com/script/13323/webReaderForEducation/learnosity/current/ReadSpeaker.Learnosity.js'
      readSpeakerScript.addEventListener('load', this.initializeItemsAPI)
      document.head.appendChild(readSpeakerScript)
    },

    async initializeItemsAPI() {
      let eventOptions = {
        readyListener: () => {

          const assessApp = itemsApp.assessApp()
          let isMath = false
          const arrayTemp = Object.values(itemsApp.getQuestions())
          for (const i of arrayTemp) {
            if (i.is_math) {
              isMath = true
              break
            }
          }
          if (isMath) {
            assessApp.append({
              features: [{
                "type": "customfeature",
                "js": "//questions-va.learnosity.com/latest/customassets/redirect?customType=calculator-feature&assetType=js&clientReference=988e92a0",
                "css": "https://questions-va.learnosity.com/latest/customassets/redirect?customType=calculator-feature&assetType=css&clientReference=988e92a0",
                "width": "640",
                "height": "420",
                "lrn_theme": false
              }]
            }).then(function (data) {
              let graphingCalculator = data.features['customfeature-0'];

              // dispatch the public method that is defined inside graphing calculator customfeature
              assessApp
                  .on('button:desmos_calculator:clicked', function () {
                    graphingCalculator.toggle();
                  })
                  .on('test:pause', function () {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
                  .on('item:warningOnChange', function () {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
                  .on('test:finished:submit', () => {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
                  .on('test:panel:show', function () {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
                  .on('test:save', function () {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
                  .on('test:save:error', function () {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
                  .on('test:save:success', function () {
                    if (graphingCalculator !== undefined) {
                      setTimeout(() => {
                        graphingCalculator.stop();
                      }, 100)
                    }
                  })
            });
          }

          window.rsCallbacks.readyListener()
        },
        errorListener: (err) => {
          console.log(err)
        },
      }

      const res = await CourseActivitiesApi.answerLearnosityActivity(
        this.$route.params.lessonId,
        this.$route.params.activityId
      )

      const itemsApp = window.LearnosityItems.init(res, eventOptions)

    },
  },
}
</script>
