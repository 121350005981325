import HorizonApi from '@/api/horizon_api'

const STAFF_COURSE_ACTIVITIES_PATH= `/api/staff/course_activities`

export default {

  list () {
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}`)
  },

  listMinimal() {
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/minimal`)
  },

  getTemplate(template_id) {
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/templates/${template_id}`)
  },

  getCourseActivities(course_id,section_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/${section_id}`)
  },

  getCourseActivity(course_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/activity`)
  },

  getAssignedActivities(section_id) {
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/assignments`, { params: { section_id: section_id }})
  },

  updateLessonTiming(course_id,lesson_id,data = {}){
    return HorizonApi.put(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/lesson/${lesson_id}`, {'lesson': data})
  },

  getGradebook(course_id,section_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/gradebook/table_data/${section_id}`)
  },

  deleteResponse(course_id,section_id,response_id){
    return HorizonApi.delete(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/gradebook/${section_id}/response/${response_id}`)
  },

  exportGradebook(course_id,section_id){
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${course_id}/gradebook/export/${section_id}`)
  },

  getItemAnalysisConf(data){
    let params = {
      params: {
        query: data.query
      }
    }
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/${data.course_id}/lesson/item_analysis_report/${data.lesson_id}/${data.activity_id}/${data.section_id}`, params)
  },

  getSectionAssignments({page = 1, perPage = 100, sort, order, searchProp, status, sectionId}) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'section_id': sectionId,
      'status': status,
    }
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/section_assignments`, { params: params})
  },

  getCourseAssignments({page = 1, perPage = 100, sort, order, searchProp, status, courseId}) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'course_id': courseId,
      'status': status,
    }
    return HorizonApi.get(`${STAFF_COURSE_ACTIVITIES_PATH}/course_assignments`, { params: params})
  },

}