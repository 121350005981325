import HorizonApi from '@/api/horizon_api'

const STAFF_SECTIONS_PATH = `/api/staff/sections`

export default {
  list (page = 1, perPage= 500, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    }
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}`, {params: params})
  },

  getAssignedSections(page = 1, perPage= 500, sort, order, searchProp, archived) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'archived': archived,
    }
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/staff_sections`, {params: params})
  },

  create (section) {
    return HorizonApi.post(STAFF_SECTIONS_PATH, {section: section})
  },

  getAllSections(){
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/all`)
  },

  get (sectionId) {
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/${sectionId}`)
  },

  update (newSection, oldSection) {
    let params = {}
    if (newSection.primaryTeachers != oldSection.primaryTeachers || newSection.secondaryTeachers != oldSection.secondaryTeachers) {
      // removed teachers
      let newTeachers = [...newSection.primaryTeachers, ...newSection.secondaryTeachers]
      let oldTeachers = [...oldSection.primaryTeachers, ...oldSection.secondaryTeachers]

      let removedTeacherIds = oldTeachers.filter(teacher => !newTeachers.map(t => t.id).includes(teacher.id)).map(teacher => teacher.id)
      params['removedTeacherIds'] = removedTeacherIds
      params['primaryTeacherIds'] = newSection.primaryTeachers.map(t => t.id)
      params['secondaryTeacherIds'] = newSection.secondaryTeachers.map(t => t.id)
    }

    params['className'] = newSection.name
    params['displayName'] = newSection.displayName
    params['termId'] = newSection.term.id

    return HorizonApi.patch(`${STAFF_SECTIONS_PATH}/${oldSection.id}`, { section: params })
  },

  getStudents(sectionId, page = 1, perPage, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    }
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/${sectionId}/students`, {params: params})
  },

  delete (sectionId) {
    return HorizonApi.delete(`${STAFF_SECTIONS_PATH}/${sectionId}`)
  },

  removeUserBulk(sectionId, userIdArray) {
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/bulk_delete`, {userIds: userIdArray})
  },

  addStudent(sectionId, userObj){
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students`, {user: userObj})
  },

  importStudents (sectionId, role, csvFile) {
    let fd = new FormData();

    fd.append("file", csvFile)
    fd.append("role", role)

    let headers = {
      "Content-Type": "multipart/form-data"
    }

    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/import_students`, fd, headers)
  },

  createMultipleUsers (sectionId, usersArray) {
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/bulk_create`, {users: usersArray})
  },

  editStudent(sectionId, studentId, studentObj){
      return HorizonApi.put(`${STAFF_SECTIONS_PATH}/${sectionId}/students/${studentId}`, {user: studentObj})
  },

  setUntimed(sectionId, userIdArray){
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/bulk_update`, {userIds: userIdArray, untimed_assessment: true})
  },

  setTimed(sectionId, userIdArray){
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/bulk_update`, {userIds: userIdArray, untimed_assessment: false})
  },

  transferStudents(sectionId, userIdArray, newSectionId) {
    const paramObj = {
      userIds: userIdArray,
      newSectionId: newSectionId
    }
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/transfer_to_section`, paramObj)
  },

  getUnassignedStudents(sectionId, page = 1, perPage, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    }
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/${sectionId}/students/unassigned_students`, {params: params})
  },

  assignUserBulk(sectionId, userIdArray) {
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/bulk_add`, {userIds: userIdArray})
  },

  unassignUserBulk(sectionId, userIdArray) {
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${sectionId}/students/unassign`, {userIds: userIdArray})
  },

  removeStudent(sectionId, studentId) {
    return HorizonApi.delete(`${STAFF_SECTIONS_PATH}/${sectionId}/students/${studentId}`)
  },

  assignStudentsBulk(classId, studentIdArray){
    return HorizonApi.post(`${STAFF_SECTIONS_PATH}/${classId}/bulk_assign_students`, {studentIds: studentIdArray})
  },

  deletionBulk(sectionIdArray) {
    let params = sectionIdArray.map(section => `section_ids[]=${section}`).join("&")
    return HorizonApi.delete(`${STAFF_SECTIONS_PATH}/bulk_delete?${params}`)
  },

  bulkStatusChange(idArray, status) {
    const sections = {
      section_ids: idArray,
      active: status
    }
    return HorizonApi.put(`${STAFF_SECTIONS_PATH}/bulk_hide`, sections)
  },

  getAllClassesMinimal ({schoolYear = null, primaryTeacher = null, secondaryTeacher = null, active = null, archived = null, terms} = {}) {
    const params = {
      school_year_id: schoolYear,
      primaryTeacher: primaryTeacher,
      secondaryTeacher: secondaryTeacher,
      active: active,
      archived: archived,
      term: terms,
    }
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/minimal_index`, {params: params})
  },

  studentResultExportExcel(sectionId, assessmentSetId, assessmentTypeId){
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/${sectionId}/student_assessments/excel_report?assessment_set_id=${assessmentSetId}&assessment_type_id=${assessmentTypeId}`)
  },

  studentResultResponseExport(sectionId, assessmentSetId, assessmentTypeId){
    return HorizonApi.get(`${STAFF_SECTIONS_PATH}/${sectionId}/student_assessments/response_export?assessment_set_id=${assessmentSetId}&assessment_type_id=${assessmentTypeId}`)
  },

  studentRosterExport({ sectionId }) {
    return HorizonApi.downloadInline(`${STAFF_SECTIONS_PATH}/${sectionId}/students/export`)
  }

}