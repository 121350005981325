<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div>
          <v-data-table
            height="212px"
            item-key="id"
            :headers="headers"
            :headers-length="headers.length + 1"
            :items="parentList"
            :loading="tableLoading ? '#3d5d74' : false"
            class="table-default table-default__header-fixed"
            fixed-header
            :footer-props="{
              showCurrentPage: true,
              itemsPerPageOptions:[itemsPerPage],
              disableItemsPerPage: true
            }"
          >
            <template #[`footer.page-text`]="items">
              <span class="font-weight-bold">{{ items.pageStart }}</span> to
              <span class="font-weight-bold">{{ items.pageStop }}</span> of
              <span class="font-weight-bold">{{ items.itemsLength }}</span>
            </template>

            <template
              v-if="!isMobile"
              #[`item.createdAt`]="{ item }"
            >
              <td>
                <span>{{ formatDateTable(item.createdAt) }}</span>
              </td>
            </template>

            <template
              v-if="!isMobile"
              #[`item.lastLoginAt`]="{ item }"
            >
              <td>
                <span>{{ formatDateTable(item.lastLoginAt) }}</span>
              </td>
            </template>

            <template
              v-if="!isMobile"
              #[`item.actions`]="{ item }"
            >
              <td>
                <v-row dense>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      class="btn-table btn-table__icon"
                      outlined
                      icon
                      @click="onEditDialog(item)"
                    >
                      <v-icon
                        size="18"
                        color="#3d5d74"
                      >
                        mdi-square-edit-outline
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="btn-table btn-table__icon"
                      outlined
                      icon
                      @click="onDeleteDialog(item)"
                    >
                      <v-icon
                        size="18"
                        color="#3d5d74"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </template>

            <template
              v-if="isMobile"
              #item="{ item }"
            >
              <tr>
                <td>
                  <ul class="flex-content">
                    <li
                      class="flex-item"
                      data-label="First Name"
                    >
                      {{ item.firstName }}
                      <span
                        v-if="item.untimedAssessment"
                        class="untimed-container"
                      >Untimed</span>
                    </li>
                    <li
                      class="flex-item"
                      data-label="Last Name"
                    >
                      {{ item.lastName }}
                    </li>
                    <li
                      class="flex-item"
                      data-label="Date Added"
                    >
                      {{ formatDateTable(item.createdAt) }}
                    </li>
                    <li
                      class="flex-item"
                      data-label="Last Active"
                    >
                      {{ formatDateTable(item.lastLoginAt) }}
                    </li>
                    <v-col
                      cols="12"
                      class="d-flex"
                    >
                      <v-btn
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        @click="onEditDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-square-edit-outline
                        </v-icon>
                      </v-btn>
                      <v-btn
                        class="btn-table btn-table__icon"
                        outlined
                        icon
                        @click="onDeleteDialog(item)"
                      >
                        <v-icon
                          size="18"
                          color="#3d5d74"
                        >
                          mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </ul>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>

    <dialog-admin-form
      title="Edit Parent/Guardian"
      :active="dialogForm"
      type="edit"
      user-type="Parent"
      :user-prop="userProp"
      :student-id="studentId"
      @closeDialog="onCloseDialogForm()"
      @closeDialogUpdate="onCloseDialogForm(true)"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveUser()"
    />
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogAdminForm from "@/components/shared/DialogAdminForm.vue";
import {AdminStudentApi} from "@/api";
import moment from "moment/moment";
import functions from "@/api/shared/functions";

export default {
  name: "ParentList",
  components: {DialogAdminForm, DialogDeleteConfirmation},
  props: {
    studentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tableLoading: false,
      deletionLoader: false,
      dialogForm: false,
      dialogDeleteConfirmation: false,
      parentList: [],
      userProp: {},
      itemsPerPage: 5,
      headers: [
        {
          text: 'First Name',
          value: 'firstName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Name',
          value: 'lastName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Date Added',
          value: 'createdAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Active',
          value: 'lastLoginAt',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 100,
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  mounted() {
    this.getUsers()
  },

  methods: {
    async getUsers() {
      try {
        this.tableLoading = true
        const response = await AdminStudentApi.getParents(this.studentId)
        this.parentList = response.parents
      } catch {
        this.addNotification('error', 'There was a problem getting parents')
      } finally {
        this.tableLoading = false
      }
    },

    formatDateTable(date) {
      if (date === 'Never') return 'Never'
      return moment.utc(date).format('LL')
    },

    //  Form dialog methods
    onEditDialog(user) {
      this.userProp = functions.deepCopySync(user)
      this.dialogForm = true
    },

    onCloseDialogForm(updateTable) {
      if (updateTable) {
        this.getUsers()
      }
      this.dialogForm = false
    },

    //  Delete methods
    onDeleteDialog(userProp) {
      this.userProp = userProp
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    async onRemoveUser() {
      try {
        this.deletionLoader = true
        await AdminStudentApi.deleteParent(this.studentId, this.userProp.id)
        this.dialogDeleteConfirmation = false
        this.getUsers()
        this.addNotification('success', 'User removed successfully')
      } catch (error) {
        this.addNotification('error', 'Failed to remove the user. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
      this.dialogBulkDeleteConfirmation = false
    },

  }
}
</script>

<style scoped>

</style>