<template>
  <span class="assessment-check-box assessment-check-box__big">
    <input
      type="radio"
      :value="inputValue"
      :name="radioName"
      :checked="inputValue === value"
      @change="updateValue(inputValue)"
    >
    <v-img
      v-if="hasLogo"
      class="mb-2"
      contain
      :src="require(`@/assets/img/${filename}`)"
      alt="class-logo"
      max-width="64"
      max-height="64"
    />
    <span class="box-title">{{ title }}</span>
    <span
      v-if="hasSubtitlePrimary"
      class="box-subtitle"
    >
      45 minutes
    </span>
    <span
      v-if="hasSubtitleSecondary"
      class="box-subtitle"
    >
      75 items
    </span>

    <div
      v-if="isSelected"
      class="checkbox-icon-container"
    >
      <v-icon
        size="20"
        color="white"
      >
        mdi-check
      </v-icon>
    </div>
  </span>
</template>

<script>
export default {
  name: "AssessmentRadioBoxDefault",

  model: {
    event: "change",
  },
  props: {
    hasLogo: Boolean,
    hasSubtitlePrimary: Boolean,
    hasSubtitleSecondary: Boolean,
    title: {
      type: String,
      default: ''
    },
    inputValue: {
      type: [Number, String],
      default: ''
    },
    value: {
      type: [Number, String],
      default: null
    },
    radioName: {
      type: String,
      default: ''
    },
    filename: {
      type: String,
      default: ''
    },
  },

  computed: {
    isSelected(){
      return this.value === this.inputValue
    }
  },

  methods: {
    updateValue(value) {
      this.$emit("change", value);
    },
  },
}
</script>
