<template>
  <v-sheet class="sheet-main">
    <div class="d-flex align-center flex-wrap">
      <div v-if="!untimedAssessment">
        <v-sheet
          class="sheet-inner"
          height="70px"
          width="70px"
          outlined
        >
          <div>
            <h3 class="color-orange">
              {{ getAssessmentDuration(assessment) }}
            </h3>
          </div>
          <span class="sheet-subtitle">Min</span>
        </v-sheet>
      </div>

      <div class="flex-grow-1">
        <h4 class="title-main font-weight-500 mb-2">
          {{ assessment.section }}
        </h4>
        <div>
          <v-chip
            class="chip-small mr-1"
            color="#d7ebe2"
          >
            <span class="chip-text">{{ assessment.set }}</span>
          </v-chip>
          <v-chip
            class="chip-small"
            color="#d7e2eb"
          >
            <span class="chip-text">{{ assessment.questionsCount }} Questions</span>
          </v-chip>
          <v-chip
            v-if="studentAssessment?.schoolYear"
            class="chip-small ml-1"
            color="#fee5b4"
          >
            <span class="chip-text">
              {{ studentAssessment.schoolYear.short_name }}
            </span>
          </v-chip>
        </div>
      </div>

      <div>
        <v-btn
          v-if="status === 'active'"
          class="btn-default btn-default__secondary btn-default__white"
          outlined
          @click="sendStartExam"
        >
          <span v-if="studentAssessment.learnosityStatus === 'not_started'">
            Start this section 
          </span>
          <span v-else>
            Resume section 
          </span>
          <span class="highlight arrow">→</span>
        </v-btn>
        <v-btn
          v-if="canShowNextExamButton"
          class="btn-default btn-default__secondary btn-default__white"
          outlined
          @click="sendStartExam()"
        >
          Take Next Part <span class="highlight arrow">→</span>
        </v-btn>
        <v-btn
          v-if="canShowReportButton"
          class="btn-default btn-default__secondary btn-default__white"
          outlined
          @click="sendReportClick"
        >
          View Score Report <span class="highlight arrow">→</span>
        </v-btn>
        <div v-if="status === 'upcoming'">
          Available on {{ studentAssessment.earliestAvailability() }}
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import Mixin from "@/mixins/mixin";

export default {
  name: "AssessmentSectionListItem",
  mixins: [Mixin],
  props: {
    studentAssessment: {
      type: Object,
      default: () => {
      },
      required: true
    },
    untimed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    untimedAssessment() {
      return this.untimed || this.studentAssessment.settings?.timing == "untimed"
    },

    canShowReportButton() {
      if (this.studentAssessment.status == 'completed') {
        let settings = this.studentAssessment.settings
        if (this.isEmptyObject(settings)) {
          // assumes that the corresponding SectionAssessments are deleted (and so are the settings objects)
          return true
        } else {
          if (settings.revealReportAsap || Date.now() > Date.parse(settings.revealReportAt)) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },

    canShowNextExamButton() {
      if (this.studentAssessment.status == 'completed') {
        if (this.studentAssessment.nextPartId == -1) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },

  data() {
    return {
      assessment: {},
      status: ''
    }
  },
  mounted() {
    this.assessment = this.studentAssessment.assessment
    this.status = this.studentAssessment.status
  },
  methods: {
    sendStartExam() {
      this.$emit('start-exam', this.studentAssessment)
    },

    sendReportClick() {
      this.$emit('goto-report', this.studentAssessment)
    },

    getAssessmentDuration() {
      let settings = this.studentAssessment.settings
      if (settings == null) {
        return this.assessment.duration
      } else {
        var duration = 0
        switch(settings.timing) {
        case 'timed':
          duration = this.assessment.duration
          break;
        case 'time_and_a_half':
          duration = 1.5 * this.assessment.duration
          break;
        case "double":
          duration = 2 * this.assessment.duration
          break;
        default:
          duration = this.assessment.duration
          break;
        }

        return duration
      }
    }
  }
}
</script>

<style scoped lang="scss">

.sheet-main {
  background-color: #f2f4f8;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 13px 20px;
  margin-bottom: 10px;
}

.sheet-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-right: 13px;
}

.sheet-subtitle {
  font-size: 16px;
  color: #505050;
}

.title-main {
  font-size: 20px;
}

.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 10px;
}
</style>