<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div>
          <v-row
            dense
            class="table-header-extension"
          >
            <v-col
              cols="12"
              sm="6"
              md="5"
              class="d-flex align-center"
            >
              <v-text-field
                v-model="tableSearch"
                placeholder="Search"
                prepend-inner-icon="mdi-magnify"
                class="input-default input-default__table"
                clearable
                hide-details
                solo
                flat
              />
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              sm="auto"
              class="d-flex align-center"
            >
              <v-btn
                v-if="canAssignStudent()"
                dark
                class="btn-default btn-default__primary"
                :loading="tableLoading"
                block
                @click="onAddToClass()"
              >
                <v-img
                  class="mr-2"
                  contain
                  :src="require('@/assets/icons/add-1.png')"
                  alt="icon-add"
                  max-width="19"
                  max-height="19"
                />
                Add to Class
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            height="212px"
            item-key="id"
            :server-items-length="totalUsers"
            :options.sync="tableOptions"
            :headers="headers"
            :headers-length="headers.length + 1"
            :items="classesList"
            :loading="tableLoading ? '#3d5d74' : false"
            class="table-default table-default__select table-radius-top-none table-default__header-fixed"
            fixed-header
            :footer-props="{
              showCurrentPage: true,
              itemsPerPageOptions:[itemsPerPage],
              disableItemsPerPage: true,
            }"
          >
            <template #[`footer.page-text`]="items">
              <span class="font-weight-700">{{ items.pageStart }}</span> to
              <span class="font-weight-700">{{ items.pageStop }}</span> of
              <span class="font-weight-700">{{ items.itemsLength }}</span>
            </template>

            <template
              v-if="!isMobile"
              #[`item.primaryTeachers`]="{ item }"
            >
              <td>
                <span
                  v-for="(teacher, index) in item.primaryTeachers"
                  :key="teacher.id"
                >
                  {{
                    `${teacher.firstName} ${teacher.lastName}`
                  }}{{ index !== item.primaryTeachers.length - 1 ? ',' : '' }}
                </span>
              </td>
            </template>

            <template
              v-if="!isMobile"
              #[`item.term`]="{ item }"
            >
              <td>
                <span>{{ item.term === null ? '' : item.term.name }}</span>
              </td>
            </template>

            <template
              v-if="!isMobile"
              #[`item.actions`]="{ item }"
            >
              <td>
                <v-row dense>
                  <v-col class="d-flex justify-end">
                    <v-btn
                      v-if="canUnassignStudent(item)"
                      class="btn-table btn-table__text"
                      outlined
                      @click="onRemoveFromClassDialog(item)"
                    >
                      Remove from Class
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </template>

            <template
              v-if="isMobile"
              #item="{ item }"
            >
              <tr>
                <td>
                  <ul class="flex-content">
                    <li
                      class="flex-item"
                      data-label="Class Name"
                    >
                      {{ item.displayName }}
                    </li>

                    <li
                      class="flex-item"
                      data-label="Primary Teacher"
                    >
                      <span
                        v-for="(teacher, index) in item.primaryTeachers"
                        :key="teacher.id"
                      >
                        {{
                          `${teacher.firstName} ${teacher.lastName}`
                        }}{{ index !== item.primaryTeachers.length - 1 ? ',' : '' }}
                      </span>
                    </li>

                    <li
                      class="flex-item"
                      data-label="Term"
                    >
                      <span>{{ item.term === null ? '' : item.term.name }}</span>
                    </li>

                    <v-col
                      cols="12"
                      class="d-flex"
                    >
                      <v-btn
                        class="btn-table"
                        outlined
                        @click="onRemoveFromClassDialog(item)"
                      >
                        Remove from Class
                      </v-btn>
                    </v-col>
                  </ul>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>

    <dialog-add-to-class
      :active="dialogAddToClass"
      :student-ids="[studentId]"
      @closeDialog="onCloseDialogAddToClass()"
      @closeDialogUpdate="onCloseDialogAddToClass(true)"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      :deletion-loader-prop="deletionLoader"
      @closeDialog="onoCloseDialogDeleteConfirmation()"
      @confirmDeletion="onRemoveUser()"
    />
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapState} = createNamespacedHelpers('app_config');
const {mapGetters} = createNamespacedHelpers('user');
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')

import debounce from 'lodash/debounce';
import {StaffSectionApi, StaffUserApi} from "@/api";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogAddToClass from "@/components/shared/DialogAddToClass.vue";

export default {
  name: "StudentClasses",
  components: {DialogAddToClass, DialogDeleteConfirmation},

  props: {
    studentId: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      pageLoadedOnce: false,
      tableLoading: true,
      dialogDeleteConfirmation: false,
      dialogAddToClass: false,
      deletionLoader: false,
      tableSearch: '',
      classesList: [],
      studentIds: [],
      tableOptions: {},
      itemsPerPage: 100,
      totalUsers: 0,
      headers: [
        {
          text: 'Class Name',
          value: 'displayName',
          align: 'left',
          sortable: true,
          sortBy: 'displayName',
        },
        {
          text: 'Term',
          value: 'term',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Primary Teacher',
          value: 'primaryTeachers',
          align: 'left',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 100
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.getClasses()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.getClasses()
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.getClasses()
  },

  methods: {
    ...mapGetters(['userRole', 'user']),
    ...mapSectionGetters(["getAllSections"]),

    async getClasses() {
      try {
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        const isActive = this.tabSelected === 0
        let order = 'asc'
        let sort = 'displayName'
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        if (sortBy[0]) {
          sort = sortBy[0]
          order = sortDesc[0] ? 'desc' : 'asc'
        }

        const response = await StaffUserApi.getAssignedClasses(this.studentId, page, this.itemsPerPage, sort, order, searchText, isActive)
        this.totalUsers = response?.meta?.totalCount
        this.classesList = response.sections
      } catch {
        this.addNotification('error', 'There was a problem getting classes')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    onCreateClass() {
      this.dialogCreateClass = true
    },

    onCloseCreateClassDialog(update) {
      if (update) {
        this.getClasses()
      }
      this.dialogCreateClass = false
    },

    onoCloseDialogDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
    },


    //  Remove methods

    canUnassignStudent(section) {
      if (this.userRole() === 'Teacher Admin' || this.userRole() === 'Horizon Admin') {
        return true
      }

      return !this.user().school?.schoolAdminOnly && this.getAllSections().find(item => item.id === section.id)?.role === 'Primary Teacher';
    },

    onRemoveFromClassDialog(sectionProp) {
      this.sectionId = sectionProp.id
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true
      })
    },

    async onRemoveUser() {
      try {
        this.deletionLoader = true
        await StaffSectionApi.unassignUserBulk(this.sectionId, [this.studentId])
        this.dialogDeleteConfirmation = false
        this.getClasses()
        this.addNotification('success', 'Student removed successfully')
      } catch {
        this.addNotification('error', 'Failed to remove the student. Please try again.')
      } finally {
        this.deletionLoader = false
      }
    },

    // Add to Class methods
    canAssignStudent() {
      if (this.userRole() === 'Teacher Admin' || this.userRole() === 'Horizon Admin' || this.userRole() === "District Admin") {
        return true
      }

      return !(this.user().school?.schoolAdminOnly && this.userRole() == 'Teacher');
    },

    onAddToClass() {
      this.dialogAddToClass = true
    },

    onCloseDialogAddToClass(updateTable) {
      if (updateTable) {
        this.getClasses()
      }
      this.dialogAddToClass = false
    },
  },

}
</script>

<style scoped>

</style>
