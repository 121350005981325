<template>
  <v-app>
    <app-navigation v-if="showNavigation" />
    <v-main
      v-resize="onResize"
      :class="{borderless: !showNavigation}"
    >
      <v-container
        fluid
        class="container-main"
      >
        <v-row>
          <v-col cols="12">
            <app-bread-crumb v-if="showNavigation" />
            <router-view />
          </v-col>
        </v-row>
      </v-container>
      <notifications-list />
      <div v-if="showSalesForceChat">
        <sales-force-chat />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('app_config')
const { mapActions: mapUserActions, mapGetters: mapUserGetters } = createNamespacedHelpers('user')

import AppNavigation from "@/components/AppNavigation.vue";
import AppBreadCrumb from "@/components/shared/AppBreadCrumb.vue";
import NotificationsList from "@/components/shared/notifications/NotificationsList.vue";
import SalesForceChat from "@/components/shared/SalesForceChat.vue";

export default {
  name: 'App',
  components: {SalesForceChat, NotificationsList, AppBreadCrumb, AppNavigation},

  data: () => ({
    primaryTestColor: {backgroundImage: 'linear-gradient(207deg, #38566b, #414f65)'},

  }),

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),

    showNavigation() {
      return !['login', 'ForgotPassword', 'UpdatePassword', 'AccountActivation', 'ParentAccountActivation'].includes(this.$route.name)
    },

    showSalesForceChat(){
      return !['login', 'ForgotPassword', 'UpdatePassword', 'AccountActivation', null].includes(this.$route.name) && (this.userRole() !== 'Student' || this.userMasqIdent())
    }
  },

  mounted() {
    this.checkSessionValid()
  },

  methods: {
    ...mapActions([
      'showMobile',
      'showWide'
    ]),
    ...mapUserActions([
      'checkSessionValid'
    ]),
    ...mapUserGetters(['userRole', 'userMasqIdent']),


    onResize() {
      if (window.innerWidth < 600 && !this.isMobile) {
        this.showMobile()
      }

      if (window.innerWidth >= 600 && this.isMobile) {
        this.showWide()
      }
    },
  },
};
</script>

<style lang="scss">
@import 'styles/variables.scss';
@import "styles/font.scss";
@import "styles/general.scss";
@import "styles/tableVuetify.scss";
@import "styles/inputVuetify.scss";
@import "styles/buttonsVuetify.scss";
@import "styles/chart.scss";
@import "styles/media.scss";

.borderless > .v-main__wrap {
  border-top-left-radius: 0 !important;
  margin-top: 0 !important;
}

</style>
