<template>
  <v-container fluid>
    <app-header title="Assessments" />

    <div v-if="loadingAssessments">
      <v-skeleton-loader
        type="list-item-avatar@2"
      />
    </div>

    <div
      v-else-if="activeStudentAssessments.length > 0"
      class="mb-5"
    >
      <app-header
        :is-secondary="true"
        :from-dashboard="true"
        title="Active Assessments"
      />
      <AssessmentSectionListItem
        v-for="assessment in activeStudentAssessments"
        :key="assessment.id"
        :student-assessment="assessment"
        :untimed="untimed"
        @start-exam="loadExamView(assessment)"
      />
    </div>

    <div v-if="loadingAssessments">
      <v-skeleton-loader
        type="list-item-avatar@2"
      />
    </div>
    <div
      v-else-if="upcomingStudentAssessments.length > 0"
      class="mb-5"
    >
      <app-header
        :from-dashboard="true"
        :is-secondary="true"
        title="Upcoming Assessments"
      />

      <AssessmentSectionListItem
        v-for="assessment in upcomingStudentAssessments"
        :key="assessment.id"
        :student-assessment="assessment"
        :untimed="untimed"
      />
    </div>

    <div v-if="loadingAssessments">
      <v-skeleton-loader
        type="list-item-avatar@2"
      />
    </div>
    <div
      v-else-if="completedStudentAssessments.length > 0"
      class="mb-5"
    >
      <app-header
        :is-secondary="true"
        :from-dashboard="true"
        title="Completed Assessments"
      />
      <AssessmentSectionListItem
        v-for="assessment in completedStudentAssessments"
        :key="assessment.id"
        :student-assessment="assessment"
        :untimed="untimed"
        @goto-report="gotoReport(assessment)"
        @start-exam="loadExamView(assessment)"
      />
    </div>

    <div v-if="loadingAssessments">
      <v-skeleton-loader
        type="list-item-avatar@2"
      />
    </div>
    <div v-else-if="pastStudentAssessments.length > 0">
      <app-header
        :is-secondary="true"
        :from-dashboard="true"
        title="Past Assessments"
      />
      <AssessmentSectionListItem
        v-for="assessment in pastStudentAssessments"
        :key="assessment.id"
        :student-assessment="assessment"
        :untimed="untimed"
        @goto-report="gotoReport(assessment)"
      />
    </div>
  </v-container>
</template>

<script>
import { StudentAssessmentApi } from '@/api'
import { StudentAssessment } from '@/models'

import AssessmentSectionListItem from './widgets/AssessmentSectionListItem.vue'
import AppHeader from "@/components/shared/AppHeader.vue";

import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('user')

export default {
  components: {
    AppHeader,
    AssessmentSectionListItem
  },
  data() {
    return {
      loadingAssessments: true,
      activeStudentAssessments: [],
      completedStudentAssessments: [],
      upcomingStudentAssessments: [],
      pastStudentAssessments: [],
      headers: [
        { text: 'ID', value: 'id', width: 5 },
        { text: 'Duration', value: 'assessment.duration', width: 20 },
        { text: 'Set', value: 'assessment.set', width: 20 },
        { text: 'Name', value: 'assessment.section', width: 20 },
        { text: 'Completed', value: 'completed', width: 10 },
        { text: 'Actions', value: 'actions', width: 10 },
      ],
      untimed: false
    }
  },
  mounted() {
    this.loadStudentAssessments()

    this.untimed = this.user().untimedAssessment
  },
  methods: {
    ...mapGetters(["user"]),

    async loadStudentAssessments() {
      const response = await StudentAssessmentApi.list()
      const studentAssessments = response.studentAssessments.map( assessment => new StudentAssessment(assessment) )

      this.activeStudentAssessments = studentAssessments.filter((item) => item.status == "active")
      this.completedStudentAssessments = studentAssessments.filter((item) => item.status == "completed")
      this.upcomingStudentAssessments = studentAssessments.filter((item) => item.status == "upcoming")
      this.pastStudentAssessments = studentAssessments.filter((item) => item.status == "past")

      this.loadingAssessments = false
    },

    loadExamView(assessment) {
      this.$router.push( {name: 'StudentExamView', params: { id: assessment.id } } );
    },

    gotoReport(assessment) {
      this.$router.push( {name: 'StudentReportIndividual', params: { studentAssessmentId: assessment.id } } );
    },

  }
}
</script>