<template>
  <div 
    :class="[bgColor, borderClass]"
  >
    <div class="sheet-main">
      <div>
        <h4 class="section-name">
          {{ section.displayName }}
        </h4>
        <div>
          <v-chip
            class="chip-small mr-2"
            :color="roleColor"
          >
            <span class="chip-text">
              {{ section.role }}
            </span>
          </v-chip>
          <span class="color-orange font-weight-600 font-size-14">{{ section.studentCount }}</span> <span class="text-students">Students Enrolled</span>
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <v-btn
          v-if="hasCourseActivity"
          class="btn-default btn-default__secondary btn-default__white mt-1 mr-4"
          @click="courseActivitiesClicked"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/courseactivities_orange.png')"
            max-width="19"
            max-height="19"
          />
          Course Activities
        </v-btn>
        <v-btn
          class="btn-default btn-default__secondary btn-default__white mt-1"
          :class="isPrimaryTeacher ? 'mr-4' : null"
          @click="assessmentsClicked"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/assessments_orange.png')"
            max-width="19"
            max-height="19"
          />
          Assessments
        </v-btn>
        <v-btn
          v-if="isPrimaryTeacher"
          class="btn-default btn-default__secondary btn-default__white mt-1"
          @click="settingsClicked"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/settings_orange.png')"
            max-width="19"
            max-height="19"
          />
          Settings
        </v-btn>              
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('user')
export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      role: "Primary Teacher"
    }
  },
  computed: {
    bgColor() {
      return this.index % 2 === 0 ? "bg-white" : "bg-grey"
    },
    borderClass() {
      if (this.index === 0) {
        return "section-list-top-border"
      } else {
        return "section-list-border"
      }
    },
    isPrimaryTeacher() {
      return this.section.role === "Primary Teacher"
    },
    roleColor() {
      return this.isPrimaryTeacher ? "#d7e2eb" : "#d7ebe2"
    },
    hasCourseActivity() {
      const staffUser = this.user()
      return staffUser?.school?.activitiesAvailable || false
    },
  },
  methods: {
    ...mapGetters(["user"]),
    settingsClicked() {
      this.$emit('settingsClicked', this.section)
    },
    courseActivitiesClicked() {
      this.$emit('courseActivitiesClicked', this.section)
    },
    assessmentsClicked() {
      this.$emit('assessmentsClicked', this.section)
    }
  }

}
</script>

<style scoped>

.sheet-main {
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100px;
}

.section-name {
  color: #2c495e;
  font-size: 20px;
  margin-bottom: 8px;
}

.text-students {
  font-size: 14px;
  color: #101828;
}

.section-list-border {
  border: 1px #eaecf0 solid;
  border-top-style: none; 
}

.section-list-top-border {
  border: 1px #eaecf0 solid;
}

.bg-white {
  background-color: #ffffff !important
}

.bg-grey {
  background-color: #eaecf045 !important
}
</style>