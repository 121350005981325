<template>
  <div>
    <v-container
      class="mt-1 pa-0"
      fluid
    >
      <div
        v-for="course in courseActivity"
        :key="course.id"
      >
        <div
          class="sheet-main"
          tabindex="0"
          :aria-label="course.course_name"
        >
          <div class="d-flex align-center">
            <v-img
              max-width="80"
              max-height="80"
              class="mr-6"
              src="@/assets/img/course_activities.png"
            />
            <h4 class="section-name">
              {{ course.course_name }}
            </h4>
          </div>

          <div class="d-flex flex-wrap">
            <v-btn
              class="btn-default btn-default__secondary mr-4 mt-1"
              outlined
              @click="viewCourseActivitiesClass(course)"
            >
              <v-img
                max-width="19"
                max-height="19"
                class="mr-2"
                src="@/assets/icons/courseactivities_orange.png"
              />
              Course Activities
            </v-btn>
            <v-btn
              class="btn-default btn-default__secondary mr-4 mt-1"
              outlined
              @click="onCourseAssignments(course)"
            >
              <v-img
                max-width="19"
                max-height="19"
                class="mr-2"
                src="@/assets/icons/approve.png"
              />
              Assignments
            </v-btn>
            <v-btn
              class="btn-default btn-default__secondary mt-1"
              outlined
              @click="onCourseGradebook(course)"
            >
              <v-img
                max-width="19"
                max-height="19"
                class="mr-2"
                src="@/assets/icons/assessments_orange.png"
              />
              Gradebook
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "CourseActivitiesTemplate",
  props: {
    heCourseTemplateId: {
      type: [Number, String],
      default: 1
    },
    courseActivity: {
      type: Array,
      default() {
        return []
      }
    },
    assignments: {
      type: Object,
      default() {
        return {}
      }
    },
    gradebook: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  methods: {

    viewCourseActivitiesClass(activity) {
      this.$router.push({
        name: "StaffCourseActivitiesSchoolCourse",
        params: {
          courseTemplate: activity.id,
        }
      })
    },

    onCourseAssignments(activity) {
      this.$router.push({
        name: "StaffCourseActivitiesSchoolAssignments",
        params: {
          courseTemplate: activity.id,
        }
      })
    },

    onCourseGradebook(activity) {
      this.$router.push({
        name: "StaffCourseActivitiesSchoolGradebook",
        params: {
          courseTemplate: activity.id,
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">

.sheet-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 25px;
  margin-bottom: 12px;
  min-height: 100px;
  border: 1px #eaecf0 solid;
  border-radius: 10px;
}

.template-name {
  color: #2c495e;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-left: 10px;
}

.section-name {
  color: #2c495e;
  font-size: 20px;
  margin-bottom: 8px;
}

</style>
