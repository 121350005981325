<template>
  <div
    class="button-arrow-container"
    :class="{'down-orange': isActive}"
    :aria-label="isActive? 'Close detail' : 'Open details'"
  >
    <div class="button-arrow-lines">
      <div
        class="button-arrow-line-1"
        :class="{'down-orange': isActive}"
      />
      <div
        class="button-arrow-line-2"
        :class="{'down-orange': isActive}"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style scoped lang="scss">

.button-arrow-container.down-orange {
  transform: rotate(90deg);
  background-color: #f4701f;
  color: #fff;
}

.button-arrow-container {
  transition: all 0.2s ease;
  display: flex;
  width: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #c7c7c7;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 5px 16px 0 rgba(8, 15, 52, 0.06);
  color: #f4701f;

  .button-arrow-lines {
    position: relative;
    z-index: 200;
    display: flex;
    width: 18px;
    height: 18px;
    padding-bottom: 2px;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
    color: inherit;
    background-color: inherit;
    font-size: 16px;

    .button-arrow-line-1.down-orange, .button-arrow-line-2.down-orange {
      background-color: #fff;
    }

    .button-arrow-line-1 {
      background-color: #f4701f;
      right: 7px;
      transform: rotate(-45deg);
      position: absolute;
      width: 11px;
      min-height: 2px;
      border-radius: 500px;
    }

    .button-arrow-line-2 {
      background-color: #f4701f;
      left: 7px;
      transform: rotate(45deg);
      position: absolute;
      width: 11px;
      min-height: 2px;
      border-radius: 500px;
    }
  }
}
</style>
