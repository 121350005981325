<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <v-row
      justify="space-between"
      align="center"
      class="mb-6 mt-6"
    >
      <v-col>
        <h3>Gradebook: {{ gradebookCourseName }}</h3>
      </v-col>
      <v-col
        class="d-flex justify-end"
        cols="12"
        sm="auto"
      >
        <v-autocomplete
          v-if="courseActivityTemplates.length > 1"
          id="classes"
          v-model="selectedCourseActivityTemplate"
          label="Select Course"
          :menu-props="{ maxHeight: '200' }"
          dense
          solo
          flat
          class="input-default"
          item-value="id"
          item-text="course_name"
          :items="courseActivityTemplates"
          hide-details
          @change="onCourseActivityTemplateChange"
        />
      </v-col>
      <v-col
        class="d-flex justify-end"
        cols="12"
        sm="auto"
      >
        <v-autocomplete
          id="classes"
          v-model="selectedClassId"
          label="Select Classes"
          :menu-props="{ maxHeight: '200' }"
          dense
          solo
          flat
          class="input-default"
          item-value="id"
          item-text="displayName"
          :items="sectionList"
          hide-details
          @change="onSelectedSectionChange"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="auto"
      >
        <v-btn
          class="btn-default btn-default__secondary"
          block
          outlined
          @click="gradebookExport"
        >
          <v-img
            max-width="19"
            max-height="19"
            class="mr-2"
            src="@/assets/icons/cells.png"
          />
          Export Gradebook
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loadingTableData"
      height="320px"
      type="table-thead, table-tbody@2"
    />
    <gradebook
      v-else
      :edit-mode="editMode"
      :course-id="selectedCourseActivityTemplate"
      :section-id="selectedClassId"
      :gradebook-table-data="gradebookTableData"
      @table-update="getGradebook"
    />
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapState} = createNamespacedHelpers('staffSections')
import {StaffCourseActivitiesApi, StaffSchoolApi, StaffSectionApi} from '@/api'
import Gradebook from '@/components/shared/Gradebook.vue'

export default {
  components: {
    Gradebook
  },

  data() {
    return {
      selectedClassId: null,
      section: {},
      gradebookTableData: {},
      loadingTableData: true,
      editMode: false,
      primaryTeacher: "Primary Teacher",
      sectionList: [],
      courseActivityTemplates: [],
      selectedCourseActivityTemplate: [],
    }
  },

  computed: {
    ...mapUserState(["user"]),

    gradebookCourseName() {
      return this.courseActivityTemplates.find((el) => el.id === this.selectedCourseActivityTemplate)?.course_name
    }
  },

  async mounted() {
    this.selectedClassId = this.$route.params.sectionId
    this.selectedCourseActivityTemplate = this.$route.params.courseTemplate
    await this.getSections()
    this.checkEditingRole()
    this.getGradebook()
    this.loadCourseActivities()
  },

  methods: {
    ...mapState(['allSections']),

    async loadCourseActivities() {
      try {
        const response = await StaffCourseActivitiesApi.listMinimal()
        this.courseActivityTemplates = response.course_activities.map(activity => ({
          id: activity.id,
          course_name: activity.course_name,
        }))
      } catch {
        this.addNotification('error', 'Failed to load Course Activities.')
      }
    },

    async getGradebook() {
      this.loadingTableData = true
      try {
        const response = await StaffCourseActivitiesApi.getGradebook(this.selectedCourseActivityTemplate, this.selectedClassId)
        this.gradebookTableData = response.gradebook_table
      } catch {
        this.addNotification('error', 'Failed to load Gradebooks.')
      } finally {
        this.loadingTableData = false
      }
    },

    async gradebookExport() {
      try {
        const response = await StaffCourseActivitiesApi.exportGradebook(this.selectedCourseActivityTemplate, this.selectedClassId)
        const filename = this.gradebookCourseName + '_Gradebook_Export.csv'
        const data = response
        const blob = new Blob([data], {type: 'text/csv'})
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        this.addNotification('error', 'Failed to Export Gradebooks.')
      }
    },

    checkEditingRole() {
      const section = this.allSections().find(section => section.id == this.selectedClassId)
      this.editMode = section?.role === this.primaryTeacher
    },

    async getSections() {
      try {
        let response = {}
        if (this.user.role === 'Teacher') {
          response = await StaffSectionApi.getAllClassesMinimal()
        } else {
          response = await StaffSchoolApi.getAllClassesMinimal()
        }
        this.sectionList = response.sections
        if (!this.selectedClassId) this.selectedClassId = this.sectionList[0]?.id
      } catch {
        this.addNotification('error', 'Failed to load classes of this school.')
      }
    },

    onSelectedSectionChange(sectionId) {
      this.selectedClassId = sectionId;
      this.getGradebook();
      this.checkEditingRole();
    },

    onCourseActivityTemplateChange() {
      this.getGradebook()
    },
  },
}
</script>
<style scoped lang="scss">

</style>