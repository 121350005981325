<template>
  <v-dialog
    :value="dialog"
    max-width="1000"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        title="Add Students"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col cols="12">
            <div class="">
              <v-row
                dense
                class="table-header-extension"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                  class="d-flex align-center"
                >
                  <v-text-field
                    v-model="tableSearch"
                    placeholder="Search"
                    prepend-inner-icon="mdi-magnify"
                    class="input-default input-default__table"
                    clearable
                    hide-details
                    solo
                    flat
                  />
                </v-col>
              </v-row>
              <v-data-table
                v-model="selectedUsers"
                show-select
                item-key="id"
                :server-items-length="totalUsers"
                :options.sync="tableOptions"
                :headers="headers"
                :headers-length="headers.length + 1"
                :items="studentsList"
                :loading="tableLoading ? '#3d5d74' : false"
                height="400"
                class="table-default table-default__select table-radius-top-none table-default__header-fixed"
                fixed-header
                :footer-props="{
                  showCurrentPage: true,
                  itemsPerPageOptions:[itemsPerPage],
                  disableItemsPerPage: true
                }"
              >
                <template #[`footer.page-text`]="items">
                  <span class="font-weight-700">{{ items.pageStart }}</span> to
                  <span class="font-weight-700">{{ items.pageStop }}</span> of
                  <span class="font-weight-700">{{ items.itemsLength }}</span>
                </template>

                <template
                  v-if="isMobile"
                  #item="{ item, isSelected, select }"
                >
                  <tr>
                    <td>
                      <ul class="flex-content">
                        <li
                          class="flex-item"
                          data-label="Selected"
                        >
                          <v-simple-checkbox
                            :value="isSelected"
                            @click="select(!isSelected)"
                          />
                        </li>
                        <li
                          class="flex-item"
                          data-label="First Name"
                        >
                          {{ item.firstName }}
                        </li>
                        <li
                          class="flex-item"
                          data-label="Last Name"
                        >
                          {{ item.firstName }}
                        </li>
                        <li
                          class="flex-item"
                          data-label="Email"
                        >
                          {{ item.email }}
                        </li>
                        <li
                          class="flex-item"
                          data-label="Grade"
                        >
                          {{ item.gradeLevel }}
                        </li>
                        <li
                          class="flex-item"
                          data-label="Student Id"
                        >
                          {{ item.studentIdNumber }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-3"
          justify="end"
        >
          <v-col
            cols="12"
            sm="auto"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            class="pl-sm-2"
          >
            <v-btn
              :disabled="selectedUsers.length === 0"
              :dark="selectedUsers.length !== 0"
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onAddUsers()"
            >
              Add Users
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
const {mapState} = createNamespacedHelpers('app_config')
import AppHeader from "@/components/shared/AppHeader.vue";
import debounce from 'lodash/debounce'
import {AdminSectionApi, StaffSectionApi} from "@/api";

export default {
  name: "DialogAddStudentsToClass",

  components: {AppHeader},
  props: {
    active: Boolean,
    sectionId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      dialog: false,
      pageLoadedOnce: false,
      loading: false,
      tableLoading: false,
      dialogStudent: false,
      dialogAdmin: false,
      dialogDeleteConfirmation: false,
      itemsPerPage: 100,
      selectedUsers: [],
      totalUsers: 0,
      tableOptions: {
        itemsPerPage: 5,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      editedIndex: null,
      studentsList: [],
      errorObject: [],
      userProp: {},
      tableSearch: '',
      headers: [
        {
          text: 'First Name',
          value: 'firstName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Last Name',
          value: 'lastName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Email',
          value: 'email',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Grade',
          value: 'gradeLevel',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Student Id',
          value: 'studentIdNumber',
          align: 'left',
          sortable: true,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  watch: {
    active(value) {
      this.resetSelectedUsers()
      this.dialog = value
      if (value) {
        this.resetTableOptions()
        this.loadStudents()
      } else {
        this.pageLoadedOnce = false
      }
    },

    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.loadStudents()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.loadStudents()
        }
      },
      deep: true,
    },

  },

  methods: {
    ...mapGetters(["userRole"]),

    async loadStudents() {
      try {
        this.tableLoading = true
        this.studentsList = []
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }

        let response = {}
        if (this.userRole() === 'Horizon Admin') {
          response = await AdminSectionApi.getUnassignedStudents(this.sectionId, page, this.itemsPerPage, sortBy[0], order, searchText)
        } else {
          response = await StaffSectionApi.getUnassignedStudents(this.sectionId, page, this.itemsPerPage, sortBy[0], order, searchText)
        }
        this.totalUsers = response?.meta?.totalCount
        this.studentsList = response?.students
      } catch {
        this.addNotification('error', 'Failed to load students.')
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    async onAddUsers() {
      try {
        this.loading = true
        const studentIds = this.selectedUsers.map(obj => obj.id)
        if (this.userRole() === 'Horizon Admin') {
          await AdminSectionApi.assignStudentsBulk(this.sectionId, studentIds)
        } else {
          await StaffSectionApi.assignUserBulk(this.sectionId, studentIds)
        }
        this.$emit('closeDialogUpdate')
        this.addNotification('success', 'Selected students added to class successfully')
      } catch {
        this.addNotification('error', 'Failed to add students. Please try again.')
      } finally {
        this.loading = false
      }
    },

    resetSelectedUsers() {
      this.selectedUsers = []
    },

    resetTableOptions() {
      this.tableSearch = ''
      this.tableOptions = {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [false]
      }
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>

</style>