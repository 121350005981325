import Axios from 'axios'

function initialState () {
  return {
    settings: {},
    user: {},
    userAuthenticated: false,
    userRole: '',
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    userRole: (state) => state.userRole,
    userAuthenticated: (state) => state.userAuthenticated,
    userEmail: (state) => state.user.email,
    userFullName: (state) => {
      if ( state.user == {} ) { return '' }
      return [state.user.firstName, state.user.lastName].join(' ')
    },
    user: (state) => {
      return state.user
    },
    userMasqId: (state) => {
      if ('masqId' in state.user) {
        return state.user.masqId
      }
      return null
    },
    userMasqIdent: (state) => {
      if ('masqIdent' in state.user) {
        return state.user.masqIdent
      }
      return false
    },
    userSchool: (state) => {
      return state.user.school
    },
    districtAdminMasq: (state) => {
      return state.user.districtAdminMasq
    },
    districtAdminSettings: (state) => {
      return state.districtAdminSettings
    },
    schoolSupportsSat: (state) => {
      return state.user.school.courseType.includes("SAT")
    },
    schoolSupportsAct: (state) => {
      return state.user.school.courseType.includes("ACT")
    }
  },
  mutations: {
    resetState: (state) => {
      const s = initialState()
      Object.keys(s).forEach( key => {
        state[key] = s[key]
      })
      sessionStorage.clear();
    },
    updateSessionInfo: (state, payload) => {
      state.user = payload
      state.userAuthenticated = payload.userId != undefined
      state.userRole = payload.userId != undefined ? payload.role : ''
    },
    updateSessionValidity: (state, payload) => {
      state.userAuthenticated = payload.validSession
    },
  },
  actions: {
    loadUserSession: ({ commit, dispatch, state }) => Axios.get('/api/me')
      .then(response => {
        commit('updateSessionInfo', response.data)
        commit('app_config/updateNavigationRole', { role: response.data.role , data: response.data }, { root: true })
        commit('app_config/updateDashboard', state.user, { root: true })
        dispatch('updatePendo')
      }).catch(error => {
        throw Error(error)
      }),
    destroyUserSession: ({ commit, dispatch }) => Axios.delete('/api/logout')
      .then(() => {
        commit('updateSessionInfo', {})
        commit('resetState')
        commit('app_config/clearNavigation', null, { root: true })
        dispatch('updatePendo')
      }).catch(error => {
        throw Error(error)
      }),
    createUserSession: ({ commit, dispatch, state }, payload) => Axios.post('/api/login', payload)
      .then((response) => {
        commit('updateSessionInfo', response.data)
        commit('app_config/updateNavigationRole', { role: state.userRole, data: response.data }, { root: true })
        commit('app_config/updateDashboard', state.user, { root: true })
        dispatch('updatePendo')
      }).catch(error => {
        throw Error(error)
      }),
    checkSessionValid: ({ commit, dispatch }) => Axios.get('/api/valid_session')
      .then((response) => {
        commit('updateSessionValidity', response.data)
        dispatch('updatePendo')
        if (response.data.validSession !== true) {
          commit('resetState')
        }
      }).catch(error => {
        throw Error(error)
      }),
    createGoogleUserSession: ({ commit, dispatch, state }, payload) => Axios.post('/api/google/verify', payload)
      .then(() => {
        return dispatch('loadUserSession').then(() => {
          commit('app_config/updateDashboard', state.user, { root: true })
        })
      }).catch(error => {
        throw Error(error)
      }),
    createCleverAuthUrl: () => Axios.get('/api/clever/auth_uri').then((res) => {
        return res.data.requestUri
      })
      .catch(error => {
        throw Error(error)
      }),
    // eslint-disable-next-line
    createCleverUserSession: ({ commit, dispatch, state }, payload) => Axios.post('/api/clever/login', payload).then((res) => {
        return dispatch('loadUserSession').then(() => {
          commit('app_config/updateDashboard', state.user, { root: true })
        })
      })
      .catch(error => {
        throw Error(error)
      }),
    createClassLinkAuthUrl: () => Axios.get('/api/class_link/auth_uri').then((res) => {
        return res.data.requestUri
      })
      .catch(error => {
        throw Error(error)
      }),
    // eslint-disable-next-line
    createClassLinkUserSession: ({ commit, dispatch, state }, payload) => Axios.post('/api/class_link/login', payload).then((res) => {
        return dispatch('loadUserSession').then(() => {
          commit('app_config/updateDashboard', state.user, { root: true })
        })
      })
      .catch(error => {
        throw Error(error)
      }),
    updatePendo: ({ getters, state } ) => {
      if(getters.userMasqIdent){
        return
      }
      if(Object.keys(state.user).length === 0 && state.user.constructor === Object){
        window.pendo?.clearSession()
        return
      }
      window.pendo?.identify({
        visitor: {
          id: state.user.userId,
          name: `${state.user.firstName} ${state.user.lastName}`,
          role: state.user.role,
          email: state.user.email,
          district_id: state.user.district?.id,
        },
        account: {
          id: state.user.school?.id,
          name: state.user.school?.name,
          district_id: state.user.district?.id,
          district_name: state.user.district?.name,
          state: state.user.school?.state
        }
      });
    }
  }
}
