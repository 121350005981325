<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { StaffSectionApi, StaffAssessmentApi, StaffSchoolApi } from '@/api'
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapSectionActions } = createNamespacedHelpers('staffSections')
const { mapActions: mapTermActions } = createNamespacedHelpers('terms')
const { mapActions: mapAssessmentActions, mapGetters: mapAssessmentGetters } = createNamespacedHelpers('assessments')

export default {
  name: "StaffDashboard",

  mounted() {
    this.loadData()
  },

  methods: {
    ...mapSectionActions(['storeSections']),
    ...mapTermActions(['storeTerms']),
    ...mapAssessmentActions(['storeAssessmentData']),
    ...mapAssessmentGetters(['getAssessmentDataAvailable', 'getAssessmentChoices']),
    async loadData() {
      this.sections = (await StaffSectionApi.list()).sections
      this.storeSections(this.sections)

      const assessmentData = await StaffAssessmentApi.supportedAssessments()
      this.storeAssessmentData(assessmentData)

      const termsData = await StaffSchoolApi.getSchoolTermsAll()
      this.storeTerms(termsData.terms)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
