function initialState () {
  return {
    assessmentDataStored: false,
    assessments: [],
    assessmentTypes: [],
    assessmentSets: [],
    assessmentCategories: [],
    assessmentNameChoices: []
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    isAssessmentDataAvailable: (state) => (state.assessmentDataStored),
    getAvailableAssessments: (state) => (state.assessments),
    getAvailableAssessmentTypes: (state) => (state.assessmentTypes),
    getAvailableAssessmentSets: (state) => (state.assessmentSets),
    getAvailableAssessmentCategories: (state) => (state.assessmentCategories),
    getAssessmentChoices: (state) => (state.assessmentNameChoices),
    getAssessmentNameById: (state) => (id) => {
      try {
        let assessment = state.assessments.find(a => a.id === parseInt(id))
        let category = state.assessmentCategories.find(c => c.id === assessment.categoryId)
        let assessmentSet = state.assessmentSets.find(a => a.id === assessment.assessmentSetId)
        let assessmentType = state.assessmentTypes.find(a => a.id === assessment.assessmentTypeId)
        return `${assessmentType.courseType.toUpperCase()} ${assessmentSet.name} - ${category.name}`
      } catch (error) {
        return ''   
      }
    }
  },
  mutations: {
    resetState: (state) => {
      const s = initialState()
      Object.keys(s).forEach( key => {
        state[key] = s[key]
      })
      sessionStorage.clear();
    },
    updateStoredAssessments: (state, data) => {
      state.assessments = data.assessments
      state.assessmentSets = data.assessmentSets
      state.assessmentCategories = data.categories

      state.assessmentTypes = data.assessmentTypes.map((type) => {
        return {
          id: type.id,
          courseType: type.courseType,
          assessmentType: type.assessmentType,
          displayName: `Horizon Assessment for the ${type.courseType.toUpperCase()}`
        }
      })

      let assessmentNames = []
      data.assessmentTypes.forEach((type) => {
        data.assessmentSets.forEach((set) => {
          assessmentNames.push({
            name: `${type.courseType.toUpperCase()} ${set.name}`,
            assessmentType: type,
            assessmentSet: set
          })
        })
      })

      state.assessmentNameChoices = assessmentNames

      state.assessmentDataStored = true
    }
  },
  actions: {
    storeAssessmentData({commit}, data) {
      commit('updateStoredAssessments', data)
    },
    resetAssessmentState({commit}) {
      commit('resetState')
    }
  }
}
