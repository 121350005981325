<template>
  <v-dialog
    :value="dialog"
    :retain-focus="false"
    max-width="700px"
    persistent
  >
    <v-card class="card-dialog">
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="classes"
              >
                Classes
              </label>
              <v-autocomplete
                id="classes"
                v-model="assessmentData.sections"
                label="Select Classes"
                :disabled="type === 'edit'"
                :menu-props="{ maxHeight: '200' }"
                dense
                multiple
                solo
                flat
                class="input-default input-default__small input-default__height-unset mt-1"
                :return-object="true"
                item-text="displayName"
                item-value="id"
                :items="classChoices"
                :rules="assessmentData.rules.classes"
                hide-details="auto"
                required
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="name"
              >
                Assessment Name
              </label>
              <v-select
                id="name"
                v-model="assessmentData.assessmentName"
                placeholder="Select Assessment"
                :disabled="type === 'edit'"
                dense
                flat
                solo
                class="input-default input-default__small mt-1"
                item-text="name"
                :return-object="true"
                :items="assessmentChoices"
                :rules="assessmentData.rules.assessmentName"
                hide-details="auto"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="assessmentSection"
              >
                Assessment Section
              </label>
              <v-select
                id="assessmentSection"
                v-model="assessmentData.category"
                :disabled="type === 'edit'"
                placeholder="Select Section"
                dense
                solo
                flat
                class="input-default input-default__small mt-1"
                item-text="name"
                :return-object="true"
                :items="categoryChoices"
                :rules="assessmentData.rules.assessmentSection"
                hide-details="auto"
                required
              />
            </v-col>
            <v-col cols="12">
              <label
                class="label-default label-default__small"
                for="assessmentAdministrationType"
              >
                Continuous or Broken Assessment Administration?
              </label>
              <v-select
                id="assessmentAdministrationType"
                v-model="assessmentData.assessmentAdministrationType"
                :disabled="type === 'edit'"
                placeholder="Select Type"
                dense
                solo
                flat
                class="input-default input-default__small mt-1"
                item-text="name"
                item-value="value"
                :items="assessmentAdministrationTypes"
                :rules="assessmentData.rules.assessmentAdministrationType"
                hide-details="auto"
                required
              />
            </v-col>
            <!-- P1 Start Date -->
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <assessment-datepicker
                :date="assessmentData.p1StartAtDate"
                :formatted-date="assessmentData.formattedP1StartAtDate"
                :rules="assessmentData.rules.p1StartAt"
                :label="`${brokenAdministration ? 'Part 1 Start Date' : 'Start Date'}`"
                @update:date="e => assessmentData.p1StartAtDate = e"
              />
            </v-col>
            <!-- / P1 Start Date -->
            <!-- P1 Start Time -->
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <assessment-timepicker
                :time="assessmentData.p1StartAtTime"
                :label="`${brokenAdministration ? 'Part 1 Start Time' : 'Start Time'}`"
                @update:time="e => assessmentData.p1StartAtTime = e"
              />
            </v-col>
            <!-- /P1 Start Time -->
            <!-- P1 End Date -->
            <v-col
              v-if="brokenAdministration"
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <assessment-datepicker
                :date="assessmentData.p1EndAtDate"
                :formatted-date="assessmentData.formattedP1EndAtDate"
                :rules="assessmentData.rules.p1EndAt"
                :label="`${brokenAdministration ? 'Part 1 ' : ''} End Date (Optional)`"
                :clearable-prop="true"
                @update:date="e => assessmentData.p1EndAtDate = e"
              />
            </v-col>
            <!-- /P1 End Date -->
            <!-- P1 End Time -->
            <v-col
              v-if="brokenAdministration"
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <assessment-timepicker
                :time="assessmentData.p1EndAtTime"
                :label="`${brokenAdministration ? 'Part 1 ' : ''} End Time (Optional)`"
                :clearable-prop="true"
                @update:time="e => assessmentData.p1EndAtTime = e"
              />
            </v-col>
            <!-- /P1 End Time -->

            <!-- P2 Start Date -->
            <v-col
              v-if="brokenAdministration"
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <assessment-datepicker
                :date="assessmentData.p2StartAtDate"
                :formatted-date="assessmentData.formattedP2StartAtDate"
                :rules="assessmentData.rules.p2StartAt"
                label="Part 2 Start Date"
                @update:date="e => assessmentData.p2StartAtDate = e"
              />
            </v-col>
            <!-- /P2 Start Date -->
            <!-- P2 Start Time -->
            <v-col
              v-if="brokenAdministration"
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <assessment-timepicker
                :time="assessmentData.p2StartAtTime"
                label="Part 2 Start Time"
                @update:time="e => assessmentData.p2StartAtTime = e"
              />
            </v-col>
            <!-- /P2 Start Time -->

            <!-- P2 End Date -->
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <assessment-datepicker
                :date="assessmentData.p2EndAtDate"
                :formatted-date="assessmentData.formattedP2EndAtDate"
                :rules="assessmentData.rules.p2EndAt"
                :label="`${brokenAdministration ? 'Part 2 ' : ''} End Date (Optional)`"
                :clearable-prop="true"
                @update:date="e => assessmentData.p2EndAtDate = e"
              />
            </v-col>
            <!-- /P2 End Date -->
            <!-- P2 End Time -->
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <assessment-timepicker
                :time="assessmentData.p2EndAtTime"
                :label="`${brokenAdministration ? 'Part 2 ' : ''} End Time (Optional)`"
                :clearable-prop="true"
                @update:time="e => assessmentData.p2EndAtTime = e"
              />
            </v-col>
            <!-- /P2 End Time -->

            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="revealScoreReport"
              >
                Reveal Score Report Immediately
              </label>
              <v-switch
                id="revealScoreReport"
                v-model="assessmentData.settings.revealReportAsap"
                :label="`${assessmentData.settings.revealReportAsap ? 'Yes' : 'No'}`"
                color="#496e88"
                class="switch-form switch-form__dialog ml-1"
                inset
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="revealAnswers"
              >
                Reveal Answers Immediately
              </label>
              <v-switch
                id="revealAnswersAsap"
                v-model="assessmentData.settings.revealAnswersAsap"
                :label="`${assessmentData.settings.revealAnswersAsap ? 'Yes' : 'No'}`"
                color="#496e88"
                class="switch-form switch-form__dialog ml-1"
                inset
                hide-details
              />
            </v-col>
            <v-col
              v-if="!assessmentData.settings.revealReportAsap"
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <assessment-datepicker
                :date="assessmentData.settings.revealReportAtDate"
                :formatted-date="assessmentData.settings.formattedRevealReportAtDate"
                label="Reveal Score Report Date (Optional)"
                :clearable-prop="true"
                @update:date="e => assessmentData.settings.revealReportAtDate = e"
              />
            </v-col>
            <v-col
              v-if="!assessmentData.settings.revealReportAsap"
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <assessment-timepicker
                :time="assessmentData.settings.revealReportAtTime"
                label="Score Report Reveal Time (Optional)"
                :clearable-prop="true"
                @update:time="e => assessmentData.settings.revealReportAtTime = e"
              />
            </v-col>
            <v-col
              v-if="!assessmentData.settings.revealAnswersAsap"
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <assessment-datepicker
                :date="assessmentData.settings.revealAnswersAtDate"
                :formatted-date="assessmentData.settings.formattedRevealAnswersAtDate"
                label="Reveal Answers Date (Optional)"
                :clearable-prop="true"
                @update:date="e => assessmentData.settings.revealAnswersAtDate = e"
              />
            </v-col>
            <v-col
              v-if="!assessmentData.settings.revealAnswersAsap"
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <assessment-timepicker
                :time="assessmentData.settings.revealAnswersAtTime"
                label="Reveal Answers Time (Optional)"
                :clearable-prop="true"
                @update:time="e => assessmentData.settings.revealAnswersAtTime = e"
              />
            </v-col>

            <!-- Warning -->
            <v-col
              cols="12"
              sm="12"
              class="pt-0 pr-sm-6"
            >
              <v-sheet
                flat
                color="#edf4fa"
                class="pa-2 text-center"
              >
                <strong><em>Any changes made to the assessment settings below will only apply to students that have not
                  already started the assessment section.</em></strong>
              </v-sheet>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="name"
              >
                Timing Option
              </label>
              <v-select
                id="name"
                v-model="assessmentData.settings.timing"
                placeholder="Select Timing"
                dense
                flat
                solo
                class="input-default input-default__small mt-1"
                item-text="name"
                item-value="value"
                :items="timingOptions"
                hide-details="auto"
                required
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="name"
              >
                Section Auto-submit
              </label>
              <v-select
                id="name"
                v-model="assessmentData.settings.autosubmitDelay"
                placeholder="Select Option"
                dense
                flat
                solo
                class="input-default input-default__small mt-1"
                item-text="name"
                item-value="value"
                :items="autosubmitOptions"
                hide-details="auto"
                required
              />
            </v-col>

            <v-col
              cols="12"
              sm="4"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="pauseEnabled"
              >
                Show Pause Button
              </label>
              <v-switch
                id="pauseEnabled"
                v-model="assessmentData.settings.pauseEnabled"
                :label="`${assessmentData.settings.pauseEnabled ? 'Yes' : 'No'}`"
                color="#496e88"
                class="switch-form switch-form__dialog ml-1"
                inset
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="saveEnabled"
              >
                Show Save/Exit
              </label>
              <v-switch
                id="saveEnabled"
                v-model="assessmentData.settings.saveEnabled"
                :label="`${assessmentData.settings.saveEnabled ? 'Yes' : 'No'}`"
                color="#496e88"
                class="switch-form switch-form__dialog ml-1"
                inset
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="readspeakerEnabled"
              >
                Show Text-to-Speech Reader
              </label>
              <v-switch
                id="readspeakerEnabled"
                v-model="assessmentData.settings.readspeakerEnabled"
                :label="`${assessmentData.settings.readspeakerEnabled ? 'Yes' : 'No'}`"
                color="#496e88"
                class="switch-form switch-form__dialog ml-1"
                inset
                hide-details
              />
            </v-col>
          </v-row>
          <v-row
            dense
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
              class="pr-sm-2"
            >
              <v-btn
                class="btn-default btn-default__secondary btn-default__small"
                block
                @click="onCloseDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pl-sm-2"
            >
              <v-btn
                dark
                class="btn-default btn-default__primary btn-default__small"
                block
                @click="onSubmitForm()"
              >
                <span v-if="type=== 'new'">Assign Assessment</span>
                <span v-else>Update Settings</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AssessmentDatepicker from '@/components/shared/AssessmentDatepicker'
import AssessmentTimepicker from '@/components/shared/AssessmentTimepicker'
import AppHeader from "@/components/shared/AppHeader.vue";
import SectionAssessmentGroup from '@/models/section_assessment_group'

export default {
  name: "DialogAssessmentGroupAssign",
  components: {
    AppHeader,
    AssessmentDatepicker,
    AssessmentTimepicker
  },
  props: {
    active: Boolean,
    type: {
      type: String,
      default: 'new',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    assessmentProp: {
      type: SectionAssessmentGroup,
      required: true,
      default() {
        return {}
      },
    },
    assessmentChoices: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    categoryChoices: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
    classChoices: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },

  data() {
    return {
      valid: true,
      dialog: false,
      assessmentData: new SectionAssessmentGroup(),
      assessmentAdministrationTypes: [
        {
          "name": "Continuous Administration",
          "value": "continuous"
        },
        {
          "name": "Broken Administration",
          "value": "broken"
        }
      ],
      timingOptions: [
        {name: 'Timed', value: 'timed'},
        {name: 'Time and a Half', value: 'time_and_a_half'},
        {name: 'Double Time', value: 'double'},
        {name: 'Untimed', value: 'untimed'},
      ],
      autosubmitOptions: [
        {name: '24 Hours', value: 24},
        {name: '48 Hours', value: 48},
        {name: '72 Hours', value: 72},
        {name: 'Never', value: -1},
      ],
    }
  },
  computed: {
    brokenAdministration() {
      return this.assessmentData.assessmentAdministrationType == "broken"
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.cleanAssessmentForm()
        this.$nextTick(() => {
          this.assessmentData = this.assessmentProp

        })
        this.dialog = value
      } else {
        this.dialog = value
      }
    },

    'assessmentData.p1EndAtTime'() {
      this.$refs.p1EndAtDate?.validate()
    },

    'assessmentData.p2StartAtTime'() {
      this.$refs.p2StartAtDate?.validate()
    },

    'assessmentData.p2EndAtTime'() {
      this.$refs.p2EndAtDate?.validate()
    },
  },

  methods: {

    onSubmitForm() {
      if (this.$refs.form.validate()) {
        this.$emit('submitDialog', this.assessmentData)
      }
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    cleanAssessmentForm() {
      this.$refs.form?.resetValidation()
      this.assessmentData = new SectionAssessmentGroup()
    }
  }
}
</script>