<template>
  <div>
    <label
      class="label-default label-default__small"
    >
      {{ label }}
    </label>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value="localTime"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="localTime"
          type="time"
          class="input-default input-default__small input-default__time-picker-direct-input-enabled mt-1"
          placeholder="Select Time"
          dense
          flat
          :clearable="clearableProp"
          hide-details
          v-bind="attrs"
          v-on="on"
          @keydown.space="(event) => event.preventDefault()"
          @keydown.delete="(event) => event.preventDefault()"
        >
          <template #append>
            <v-icon
              @click="menu = !menu"
            >
              mdi-clock-outline
            </v-icon>
          </template>
        </v-text-field>
      </template>

      <v-time-picker
        v-if="menu"
        v-model="localTime"
        full-width
        scrollable
        @click="$refs.menu.save(localTime)"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    clearableProp: Boolean,
    label: {
      type: String,
      required: true
    },

    time: {
      type: String,
      required: true
    },

    rules: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      menu: false,
      localTime: null,
      localFormattedTime: this.formattedTime,
      localRules: this.rules
    }
  },

  watch: {
    localTime() {
      this.$emit('update:time', this.localTime)
    },

    rules() {
      this.localRules = this.rules
    },

    time() {
      this.setLocalTime()
    }
  },

  mounted() {
    this.setLocalTime()
  },

  methods: {
    setLocalTime() {
      if (this.time !== '') {
        this.localTime = this.time
      } else {
        this.localTime = null
      }
    }
  }
}
</script>