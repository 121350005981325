export default class Assessment {
  constructor (json) {
    this.id = json.id
    this.set = json.set
    this.section = json.section
    this.questionsCount = json.questionsCount
    this.duration = json.duration
    this.category = json.category

    this.assessmentType = json.assessmentType
    this.assessmentSet = json.assessmentSet
  } 

  get simpleAssessmentName() {
    return `${this.assessmentType} ${this.assessmentSet}`
  }

  get fullHorizonAssessmentName() {
    return `Horizon Assessment for the ${this.assessmentType} #${this.assessmentSet[-1]}`
  }
}