<template>
  <v-dialog
    :value="dialog"
    max-width="400px"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="submittingForm">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-0 pr-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="class"
            >
              Class Name
            </label>
            <v-autocomplete
              id="class"
              v-model="classId"
              :items="classesArray"
              :loading="loadingClasses"
              class="input-default input-default__small"
              item-text="displayName"
              item-value="id"
              placeholder="Select Class"
              dense
              flat
              :hide-details="!$v.classId.$error"
              :error-messages="$v.classId.$error ? 'Please select a class' : null"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              class="btn-default btn-default__primary btn-default__small"
              dark
              block
              :loading="loadingClasses"
              @click="onSubmit()"
            >
              Add
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="$v.$error"
          dense
          justify="end"
        >
          <v-col cols="auto">
            <h6 class="color-danger">
              Please check your inputs
            </h6>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapGetters} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')

import AppHeader from "@/components/shared/AppHeader.vue";
import {AdminSchoolApi, AdminSectionApi, StaffSchoolApi, StaffSectionApi} from "@/api";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "DialogAddToClass",
  components: {AppHeader},
  mixins: [validationMixin],
  props: {
    active: Boolean,
    isTransfer: Boolean,
    sectionId: {
      type: [Number, String],
      default: null
    },
    schoolId: {
      type: [Number, String],
      default: null
    },
    studentIds: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      dialog: false,
      submittingForm: false,
      loadingClasses: false,
      title: 'Add Students to Class',
      classId: null,
      classesArray: []
    }
  },

  validations: {
    classId: {required},
  },


  watch: {
    active(value) {
      if (value) {
        this.resetDialog()
        if (this.isTransfer) {
          this.title = 'Transfer Students to Class'
        } else {
          this.title = 'Add Students to Class'
        }

        this.getSections()
      }
      this.dialog = value
    }
  },

  methods: {
    ...mapGetters(['userRole']),
    ...mapSectionGetters(["getPrimarySections"]),

    resetDialog() {
      this.$v.$reset()
      this.classId = null
    },

    async getSections() {
      try {
        this.classesArray = []
        this.loadingClasses = true
        let response

        if (this.userRole() === 'Horizon Admin') {
          response = await AdminSchoolApi.getAllClassesMinimal({
            schoolId: this.schoolId
          })
        } else if (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') {
          response = await StaffSchoolApi.getAllClassesMinimal()
        }

        if (this.userRole() === 'Teacher') {
          this.classesArray = this.getPrimarySections().filter(section => section.id != this.sectionId)
        } else {
          this.classesArray = response.sections?.filter(section => section.id != this.sectionId)
        }
      } catch {
        this.addNotification('error', 'Failed to load classes. Please reopen the popup.')
      } finally {
        this.loadingClasses = false
      }
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    onSubmit() {
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.submittingForm = true

      if (this.isTransfer) {
        this.onTransferToClass()
      } else {
        this.onAddToClass()
      }

    },

    async onAddToClass() {
      try {
        const api = this.userRole() === 'Horizon Admin' ? AdminSectionApi : StaffSectionApi

        await api.assignStudentsBulk(this.classId, this.studentIds)
        this.addNotification('success', 'Students added to class successfully')
        this.$emit('closeDialogUpdate')
      } catch {
        this.addNotification('error', 'Failed to add students to the selected class')
      } finally {
        this.submittingForm = false
      }
    },

    async onTransferToClass() {
      try {
        const api = this.userRole() === 'Horizon Admin' ? AdminSectionApi : StaffSectionApi

        await api.transferStudents(this.sectionId, this.studentIds, this.classId)
        this.addNotification('success', 'Students transferred successfully')
        this.$emit('closeDialogUpdate')
      } catch {
        this.addNotification('error', 'Failed to transfer students to the selected class')
      } finally {
        this.submittingForm = false
      }
    },
  }
}
</script>