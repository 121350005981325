<template>
  <v-dialog
    :value="dialog"
    max-width="900px"
    white
  >
    <v-card
      class="card-dialog"
      max-height="625px"
    >
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="learnosity-container"
      >
        <v-row ref="learnosity-container-row">
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {LearnosityApi} from '@/api'


export default {
  name: "DialogLearnosityPreview",

  components: {
    AppHeader: () => import('@/components/shared/AppHeader.vue')
  },

  props: {
    active: Boolean,
    title: {
      type: String,
      default: 'Question Preview',
    },
    previewId: {
      type: Number
    }
  },

  data() {
    return {
      dialog: false,
    }
  },

  watch: {
    active(value) {
      this.dialog = value
      if (value) {
        this.loadQuestionPreview()
      }
    }
  },

  async mounted() {
    // load script once
    if (document.getElementById("learnosity-preview-dialog-script") === null) {
      const itemsScript = document.createElement('script');
      itemsScript.src = '//items.learnosity.com?v2024.2.LTS';
      itemsScript.id = "learnosity-preview-dialog-script"
      document.head.appendChild(itemsScript);
    }
  },

  methods: {
    onCloseDialog() {
      this.$emit('closeDialog')
      this.removeLearnosityAssessElement()
    },

    onConfirmActionDialog() {
      this.$emit('confirmDialog')
    },

    async loadQuestionPreview() {
      let response = await LearnosityApi.getQuestionPreview(this.previewId)
      this.$nextTick(()=>{
        this.addLearnosityAssessElement()
        window.LearnosityItems.init(response);
      });
    },

    addLearnosityAssessElement() {
      let parent = this.$refs["learnosity-container-row"]
      let element = document.createElement("div")
      element.setAttribute("id", "learnosity_assess")
      parent.appendChild(element)
    },

    removeLearnosityAssessElement() {
      let parent = this.$refs["learnosity-container-row"]
      let element = document.getElementById("learnosity_assess")
      parent.removeChild(element)
    }
  }
}
</script>

<style scoped lang="scss">
#learnosity_assess {
  height: 300px
}

.learnosity-container {
  max-height: 550px;
  overflow: auto;
}

</style>