import HorizonApi from '@/api/horizon_api'

const SCHOOL_PATH = `/api/staff/schools`

export default {
    getUsersByRole(schoolId, role, page, perPage, sort, order, searchProp, filters, archived){
        const params = {
            'role[]': role,
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
            'search': searchProp,
            'archived': archived,
        }

        for(let filter of Object.keys(filters)){
            params[filter] = filters[filter]
        }

        return HorizonApi.get(`${SCHOOL_PATH}/users`, {params: params})
    },

    createTeacher(schoolId, userObj){
        return HorizonApi.post(`${SCHOOL_PATH}/users`, {user: userObj})
    },

    getClasses (schoolId, page, perPage, sort, order, searchProp, filters, active, archived) {
        const params = {
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
            'search': searchProp,
            'active': active,
            'archived': archived
        };

        for(let filter of Object.keys(filters)){
            params[filter] = filters[filter]
        }

        return HorizonApi.get(`${SCHOOL_PATH}/sections`, {params: params})
    },

    getAllClassesMinimal ({schoolYear = null, editable = null, active = true, archived = null, terms} = {}) {
        const params = {
            'school_year_id': schoolYear,
            'editable' : editable,
            'active': active,
            'archived': archived,
            'term': terms,
        }
        return HorizonApi.get(`${SCHOOL_PATH}/sections/minimal_index`, {params: params})
    },

    studentResultExportExcel(schoolId, assessmentSetId, assessmentTypeId){
        return HorizonApi.get(`${SCHOOL_PATH}/student_assessments/excel_report?school_id=${schoolId}&assessment_set_id=${assessmentSetId}&assessment_type_id=${assessmentTypeId}`)
    },

    studentResultResponseExport(schoolId, assessmentSetId, assessmentTypeId){
        return HorizonApi.get(`${SCHOOL_PATH}/student_assessments/response_export?school_id=${schoolId}&assessment_set_id=${assessmentSetId}&assessment_type_id=${assessmentTypeId}`)
    },

    studentRosterExport({ role }) {
        return HorizonApi.downloadInline(`${SCHOOL_PATH}/users/export?role=${role}`)
    },

    getSchoolTerms (school_id, page, perPage, sort, order, searchProp, archived, schoolYear = null) {
        const params = {
            'school_year_id': schoolYear,
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
            'search': searchProp,
            'archived': archived
        };
        return HorizonApi.get(`${SCHOOL_PATH}/terms`, {params: params})
    },

    getSchoolYears() {
        return HorizonApi.get('/api/staff/school_years')
    },

    getSchoolTermsAll({ schoolYear, archived } = {}) {
        const params = {
            'school_year_id': schoolYear,
            'archived': archived
        };
        return HorizonApi.get(`${SCHOOL_PATH}/terms/render_all`, {params: params})
    },

    createTerm({termProp} = {}) {
        const params = {
            term: {
                'name': termProp.name,
                'start_date': termProp.startDate,
                'end_date': termProp.endDate,
            }
        }
        return HorizonApi.post(`${SCHOOL_PATH}/terms`, params)
    },

    updateTerm({termId, termProp} = {}) {
        const params = {
            term: {
                'name': termProp.name,
                'start_date': termProp.startDate,
                'end_date': termProp.endDate,
            }
        }
        return HorizonApi.patch(`${SCHOOL_PATH}/terms/${termId}`, params)
    },

    statusChangeTermsBulk({idArray, status} = {}) {
        const params = {
            term_ids: idArray,
            archive_state: status
        }

        return HorizonApi.post(`${SCHOOL_PATH}/terms/bulk_archive`, params)
    },

    deleteTerm({termId} = {}) {
        return HorizonApi.delete(`${SCHOOL_PATH}/terms/${termId}`)
    },

    bulkDeleteTerms({idArray} = {}) {
        const params = {
            'term_ids': idArray
        }
        return HorizonApi.post(`${SCHOOL_PATH}/terms/bulk_delete`, params)
    },

}
