<template>
  <div class="">
    <v-container fluid>
      <v-row>
        <v-col
          :id="id"
          class="lesson-pane"
        >
          <div class="accordion-trigger">
            <div
              class="left-pane"
              tabindex="0"
              @click.prevent="triggerSelect()"
            >
              <v-img
                v-if="dragging"
                src="@/assets/icons/drag.png"
                class="drag-icon ml-0"
                contain
                alt="icon-add"
                max-width="15"
                max-height="15"
              />
              <rotating-button :is-active="selected" />
              <div
                class="section-text d-flex align-center"
              >
                <div class="section-text-head">
                  <h3 class="font-weight-500">
                    {{ lesson.name }}
                  </h3>
                  <div
                    class="d-flex"
                  >
                    <span>Instructional Time: 
                      <span class="lesson-time">{{ formatLessonTime(lesson.instructional_time) }}</span>
                    </span>
                    <span class="ml-3">Independent Lesson Time: 
                      <span class="lesson-time">{{ formatLessonTime(lesson.independent_practice_time) }}</span>
                    </span>
                  </div>
                </div>
                <div
                  v-if="lesson.memo"
                  class="lesson-memo"
                >
                  {{ lesson.memo }}
                </div>
              </div>
            </div>
            <div class="d-flex align-center text-right">
              <div
                v-if="hasAssignmentSchedule"
                class="lesson-assign d-flex mr-2"
              >
                Lesson Assigned
              </div>
              <v-btn
                dark
                class="btn-default btn-default__secondary btn-default__white btn-courses ml-2"
                @click="addAssignLesson(lesson.name)"
              >
                <v-img
                  class="mr-2"
                  contain
                  :src="require('@/assets/icons/approve.png')"
                  alt="icon-approve"
                  max-width="18"
                  max-height="18"
                />
                Assign Lesson
              </v-btn>
            </div>
          </div>
          <div
            class="lessons pt-4 pb-2"
            :hidden="!selected"
          >
            <div class="lesson-items d-flex text-center">
              <v-container
                v-if="showDefaultRow"
                fluid
              >
                <v-row
                  justify="center"
                  align="center"
                >
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <h5>No Activity Materials available</h5>
                  </v-col>
                </v-row>
              </v-container>
              <v-col
                v-if="hasIndependentLesson"
                class="item-details"
              >
                <h3 class="font-weight-500">
                  Independent Lesson
                </h3>
                <v-container fluid>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="pa-2"
                    >
                      <v-btn
                        dark
                        class="btn-default btn-default__secondary btn-courses"
                        @click="launchIndependentLessonPreview()"
                      >
                        <v-img
                          class="mr-2"
                          contain
                          src="@/assets/icons/courseactivities_orange.png"
                          alt="icon-activity"
                          max-width="18"
                          max-height="18"
                        />
                        View Lesson
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col
                v-if="hasPracticeSet"
                class="item-details"
              >
                <h3 class="font-weight-500">
                  Practice Set
                </h3>
                <v-container fluid>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="pa-2"
                    >
                      <v-btn
                        dark
                        class="btn-default btn-default__secondary btn-courses"
                        @click="launchPracticeSetPreview()"
                      >
                        <v-img
                          class="mr-2"
                          contain
                          src="@/assets/icons/test_1.png"
                          alt="icon-test"
                          max-width="18"
                          max-height="18"
                        />
                        View Practice Set
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col
                v-if="hasSlidePresentation"
                class="item-details"
              >
                <h3 class="font-weight-500">
                  Slide Presentation
                </h3>
                <v-container fluid>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="pa-2"
                    >
                      <v-btn
                        dark
                        class="btn-default btn-default__secondary btn-courses"
                        @click="viewPresentation()"
                      >
                        <v-img
                          class="mr-2"
                          contain
                          src="@/assets/icons/presentation.png"
                          alt="icon-add"
                          max-width="18"
                          max-height="18"
                        />
                        View Presentation
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col
                v-if="hasSupportVideo"
                class="item-details"
              >
                <h3 class="font-weight-500">
                  Support Video
                </h3>
                <v-container fluid>
                  <v-row
                    justify="center"
                    align="center"
                  >
                    <v-col
                      cols="12"
                      sm="auto"
                      class="pa-2"
                    >
                      <v-btn
                        dark
                        class="btn-default btn-default__secondary btn-courses"
                        @click="viewVideo()"
                      >
                        <v-img
                          class="mr-2"
                          contain
                          src="@/assets/icons/video.png"
                          alt="icon-add"
                          max-width="18"
                          max-height="18"
                        />
                        View Video
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <dialog-lesson-material
      :title="lessonMaterialTitle"
      :active="dialogLessonMaterial"
      :media_src="lessonMaterialSource"
      :btn-txt="lessonMaterialBtnTxt"
      @closeDialog="closeDialogLessonMaterial()"
    />

    <dialog-assign-lesson
      :title="dialogAssignLessonTitle"
      :active="dialogAssignLesson"
      :lesson="lesson"
      :new-assignment="true"
      :section-list="filteredSectionList"
      @assignLesson="saveLessonTiming($event)"
      @closeDialog="closeDialogAssignLesson()"
    />
  </div>
</template>

<script>
import RotatingButton from '@/components/shared/course_activities/rotating_button.vue'
import DialogLessonMaterial from './shared/DialogLessonMaterial.vue'
import DialogAssignLesson from './shared/DialogAssignLesson.vue'
import {StaffAssignmentSchedulesApi} from '@/api'

export default {

  components: {
    RotatingButton,
    DialogLessonMaterial,
    DialogAssignLesson
  },

  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    sectionId: {
      type: Number,
      default: null
    },
    sectionOrder: {
      type: Number,
      default: null
    },
    lesson: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    },
    dragging: {
      type: Boolean,
      default: false,
    },
    courseId: {
      type: Number,
      default: null,
    },
    sectionList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selected: false,
      dialogLessonMaterial: false,
      lessonMaterialTitle: '',
      lessonMaterialSource: '',
      lessonMaterialBtnTxt: '',
      dialogAssignLesson: false,
      dialogAssignLessonTitle: '',
      filteredSectionList: []
    }
  },

  computed: {
    showDefaultRow() {
      const activityBanks = this.lesson.learnosity_activity_banks || {};
      const supportMaterials = this.lesson.support_materials || {};

      return (
          !activityBanks.independent_lesson &&
          !activityBanks.practice_set &&
          !supportMaterials.google?.url &&
          !supportMaterials.support_videos?.url
      );
    },

    hasIndependentLesson() {
      return this.hasProperty(this.lesson.learnosity_activity_banks, 'independent_lesson')
          && this.lesson.learnosity_activity_banks.independent_lesson;
    },

    hasPracticeSet() {
      return this.hasProperty(this.lesson.learnosity_activity_banks, 'practice_set')
          && this.lesson.learnosity_activity_banks.practice_set;
    },

    hasSlidePresentation() {
      return this.hasProperty(this.lesson.support_materials, 'google')
          && this.hasProperty(this.lesson.support_materials.google, 'url');
    },

    hasSupportVideo() {
      return this.hasProperty(this.lesson.support_materials, 'support_videos')
          && this.hasProperty(this.lesson.support_materials.support_videos, 'url');
    },

    hasAssignmentSchedule() {
      return this.lesson?.assigned_section_ids.length > 0
    }
  },

  mounted() {
    this.filteredSectionList = this.sectionList.filter(section1 => !this.lesson.assigned_section_ids?.some(sectionId => section1.id === sectionId))
  },


  methods: {

    formatLessonTime(time) {
      return time ? parseInt(time) + ' Minutes' : '0 Minutes';
    },

    triggerSelect() {
      this.selected = this.selected !== true
    },

    openDialogLessonMaterial() {
      this.dialogLessonMaterial = true
    },

    closeDialogLessonMaterial() {
      this.dialogLessonMaterial = false
      this.lessonMaterialSource = ''
    },

    setSourceDialogLessonMaterial(source) {
      this.lessonMaterialSource = source
    },

    setLessonMaterialTitle(title) {
      this.lessonMaterialTitle = title
    },

    viewPresentation() {
      this.setSourceDialogLessonMaterial(this.lesson.support_materials.google.url)
      this.setLessonMaterialTitle('Google Slide Presentation')
      this.lessonMaterialBtnTxt = 'Slide'
      this.openDialogLessonMaterial()
    },

    viewVideo() {
      this.setSourceDialogLessonMaterial(this.lesson.support_materials.support_videos.url)
      this.setLessonMaterialTitle('Support Video')
      this.lessonMaterialBtnTxt = 'Video'
      this.openDialogLessonMaterial()
    },

    async launchIndependentLessonPreview() {
      this.$router.push({
        name: 'StaffCourseActivitiesLearnosity',
        params: {activityId: this.lesson.learnosity_activity_banks.independent_lesson}
      })
    },

    async launchPracticeSetPreview() {
      this.$router.push({
        name: 'StaffCourseActivitiesLearnosity',
        params: {activityId: this.lesson.learnosity_activity_banks.practice_set}
      })
    },

    hasProperty(obj, prop) {
      return obj && obj !== null && Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] !== null;
    },

    openDialogAssignLesson() {
      this.dialogAssignLesson = true
    },

    closeDialogAssignLesson() {
      this.dialogAssignLesson = false
    },

    addAssignLesson(lessonName = '') {
      this.dialogAssignLessonTitle = 'Assign Lesson: ' + lessonName
      this.openDialogAssignLesson()
    },

    async saveLessonTiming(data) {
      try {
        const timingData = {
          "he_course_id": this.courseId,
          'start_at': data.start_at,
          'end_at': data.end_at,
          'lesson_id': this.lesson.id,
          'class_ids': data.class_ids !== undefined ? data.class_ids : null,
        }

        await StaffAssignmentSchedulesApi.createAssignmentScheduleMultipleClass(timingData)
        this.$emit('section-update')
      } catch {
        this.addNotification('error', 'There was a problem assigning this lesson. Please try again.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.lessons {
  width: 100%;
}

.accordion-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-pane {
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;

    .section-text {
      margin-left: 15px;
    }
  }

  .right-pane {
    a {
      width: max-content;
      border-color: #c7c7c7;
      font-size: 12px;
      font-weight: 400;
      background-color: white;

      img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }

    a:hover {
      border-color: #345165;
      color: #345165;
    }
  }
}

.lesson-pane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  cursor: auto;
  border: 1px solid #eaecf0;
  border-radius: 14px;
  margin-top: 10px;
  background-color: #f2f4f8;
  padding: 10px 15px;

  h1 {
    font-size: 18px;
    font-weight: 700;
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
  }

  span {
    font-size: 12px;
    line-height: 16px;
  }
}

.section-text {
  .lesson-memo {
    background-color: #d7e2eb;
    border-radius: 10px;
    margin-left: 15px;
    padding: 7px 15px;
    font-size: 12px;
  }

  .section-text-head {
    min-height: 15px;
  }
}

.drag-icon {
  width: 25px;
  height: 25px;
  margin-left: -20px;
}

.lesson-time {
  color: #3d5d74;
  font-weight: 600;
}

.lesson-items {
  background-color: #fff;

  .item-details {
    padding: 16px 10px;
    border-color: #f2f4f8;
    border-style: solid;
    border-width: 0.5px;

    h3 {
      line-height: 20px;
    }
  }
}

.lesson-assign {
  background-color: #d7ebe2;
  border-radius: 10px;
  padding: 7px 15px;
  font-size: 12px;
}

.btn-courses {
  font-family: Inter, sans-serif;
  font-size: 12px !important;
  padding: 9px 13px !important;
  height: 44px !important;
}

</style>