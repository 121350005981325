<template>
  <div />
</template>

<script>

export default {
  name: "SalesForceChat",

  mounted() {
    this.loadChatTest()
  },

  methods: {
    loadChatTest() {
      const {initEmbeddedMessaging} = this
      if (!window.embedded_svc) {
        var s = document.createElement('script');
        s.setAttribute('src', 'https://usatestprep.my.site.com/ESWHorizonSupportServic1693932248075/assets/js/bootstrap.min.js');
        s.onload = function () {
          initEmbeddedMessaging();
        };
        document.body.appendChild(s);
      } else {
        this.initEmbeddedMessaging();
      }

    },

    initEmbeddedMessaging() {
      try {
        window.embeddedservice_bootstrap.settings.language = 'en_US';
        window.embeddedservice_bootstrap.init(
            '00Df4000003iy4z',
            'Horizon_Support_Service',
            'https://usatestprep.my.site.com/ESWHorizonSupportServic1693932248075',
            {
              scrt2URL: 'https://usatestprep.my.salesforce-scrt.com'
            }
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    }

  }

}
</script>
