<template>
  <v-container fluid>
    <app-header
      title="Assessment Questions & Correct Answers"
    />

    <div id="learnosityContainer">
      <div
        id="report_session_detail_by_item"
        :class="learnosityVisibility"
      />
    </div>

    <div>
      <v-btn 
        v-if="hasPrevPart"
        class="btn-default btn-default__secondary"
        @click="goToReport(prevPartId)">
        View Previous Assessment
      </v-btn>

      <v-btn 
        v-if="hasNextPart"
        class="btn-default btn-default__secondary"
        @click="goToReport(nextPartId)">
        View Next Assessment
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

import { StaffStudentAssessmentApi, StudentAssessmentApi } from '@/api'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('user')

export default {
  components: {
    AppHeader
  },

  props: {
    assessmentId: {
      type: [Number, String],
      required: true
    },

    sectionId: {
      type: [Number, String],
      required: false
    }
  },
  
  data() {
    return {
      learnosityVisible: true,
      nextPartId: null,
      prevPartId: null
    }
  },

  computed: {
    learnosityVisibility() {
      return {
        visibility: this.learnosityVisible ? 'hidden' : 'visible'
      }
    },
    hasNextPart() {
      return this.nextPartId != null && this.nextPartId != this.assessmentId 
    },
    hasPrevPart() {
      return this.prevPartId != null && this.prevPartId != this.assessmentId 
    }
  },

  async mounted() {
    const itemsScript = document.createElement('script');
    itemsScript.src = '//reports.learnosity.com?v2024.2.LTS';
    itemsScript.addEventListener('load', () => {
      this.loadReport()
    });
    document.head.appendChild(itemsScript);
  },

  methods: {
    ...mapGetters(["userRole"]),

    async loadReport() {
      let response = null
      if (this.userRole() === "Student") {
        response = await StudentAssessmentApi.learnositySessionDetailByItem(this.assessmentId)
      } else {
        response = await StaffStudentAssessmentApi.learnositySessionDetailByItem({ 
          assessmentId: this.assessmentId,
          sectionId: this.sectionId
        })
      }

      window.LearnosityReports.init(JSON.parse(response.request));

      this.prevPartId = response.prev_part_id
      this.nextPartId = response.next_part_id
    },

    goToReport(id) {
      if (this.userRole() === "Student") {
        const routeData = this.$router.resolve({name: 'SessionDetailByItemReport', params: {assessmentId: id}});
        window.open(routeData.href, '_blank');
      } else {
        let pathName = "StaffSessionDetailByItemReport"
        if (this.$route.params.sectionId != null) {
          pathName = "StaffSessionDetailByItemReportWithSection"
        } 
        const routeData = this.$router.resolve({name: pathName, params: {assessmentId: id, sectionId: this.$route.params.sectionId }});
        window.open(routeData.href, '_blank');
      }
    }
  }
}
</script>