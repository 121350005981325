<template>
  <v-container fluid>
    <app-header :title="user().district.name" />
    <app-header
      title="Schools"
      :is-secondary="true"
    />

    <div v-if="schools.length > 0">
      <v-sheet
        v-for="(school, index) in schools"
        :key="index"
        class="sheet-main"
        color="#f2f4f8"
      >
        <div class="d-flex align-center flex-wrap">
          <div class="flex-grow-1">
            <h4 class="title-main font-weight-500 mb-2">
              {{ school.name }}
            </h4>
          </div>

          <div>
            <v-btn
              class="btn-default btn-default__secondary btn-default__white"
              outlined
              @click="loginToSchool(school)"
            >
              Select <span class="highlight arrow">→</span>
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </div>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

import AppHeader from "@/components/shared/AppHeader.vue";
const {mapActions, mapGetters} = createNamespacedHelpers('user')
const {mapActions: mapSectionActions} = createNamespacedHelpers('staffSections')
const {mapGetters: mapGettersConfig} = createNamespacedHelpers('app_config')

import {DistrictAdminSchoolsApi, StaffMultipleSchoolApi, StaffSectionApi} from "@/api"


export default {
  components: {
    AppHeader
  },

  data() {
    return {
      schools: []
    }
  },

  mounted() {
    this.loadSchools()
  },

  methods: {
    ...mapGetters(["user", "userMasqId", 'userRole', 'districtAdminSettings']),
    ...mapActions(['createUserSession', 'loadUserSession', 'updateSessionInfo', 'updateDistrictAdminState']),
    ...mapSectionActions(['storeSections']),
    ...mapGettersConfig(['dashboard']),

    async loadSchools() {
      const response = await DistrictAdminSchoolsApi.list(1, 10, "asc", null, null)
      this.schools = response.schools
    },

    async loginToSchool(school) {
      try {
        await StaffMultipleSchoolApi.switchSchool({ schoolId: school['id'] })

        let sections = (await StaffSectionApi.list()).sections
        this.storeSections(sections)

        await this.loadUserSession()
        this.$router.push(this.dashboard())
        this.addNotification('success', `You are now viewing ${school.name}.`)
      } catch (e) {
        this.addNotification('error', 'Could not login District Admin staff account. Please contact a Horizon Education administrator.')
      }
    }
  }
}
</script>

<style scoped lang="scss">

.sheet-main {
  background-color: #f2f4f8;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 13px 20px;
  margin-bottom: 10px;
}

.sheet-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  margin-right: 13px;
}

.sheet-subtitle {
  font-size: 16px;
  color: #505050;
}

.title-main {
  font-size: 20px;
}

.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 10px;
}
</style>