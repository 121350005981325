<template>
  <div>
    <v-container
      fluid
      class="mt-3"
    >
      <v-row
        align="center"
        class="title-min-58 mb-3"
      >
        <v-col
          cols="12"
          sm="auto"
        >
          <h3>
            Class Settings
          </h3>
        </v-col>
      </v-row>
      <v-form class="form-default">
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="name"
              >
                Class Name
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-1 pt-sm-3"
          >
            <v-text-field
              id="name"
              v-model="classData.name"
              :disabled="!['Horizon Admin', 'District Admin', 'Teacher Admin'].includes(userRole()) || !isEditable()"
              :loading="loading"
              :hide-details="!$v.classData.name.$error"
              error-messages="Class name can not be empty!"
              solo
              flat
              class="input-default"
              @change="onClassNameChange()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="displayName"
              >
                Display Name
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-1 pt-sm-3"
          >
            <v-text-field
              id="displayName"
              v-model="classData.displayName"
              :disabled="!isEditable()"
              :hide-details="!$v.classData.displayName.$error"
              error-messages="Display name can not be empty!"
              :loading="loading"
              solo
              flat
              class="input-default"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="term"
              >
                Term
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-1 pt-sm-3"
          >
            <div class="d-flex align-center">
              <v-autocomplete
                v-model="classData.term"
                hide-details
                placeholder="Select Term"
                item-text="name"
                item-value="id"
                solo
                dense
                return-object
                flat
                class="input-default"
                :items="termsList"
              />
            </div>
          </v-col>
        </v-row>
        <v-row
          v-for="(primaryTeacher, index) in classData.primaryTeachers"
          :key="primaryTeacher?.id"
        >
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="exam"
              >
                Primary Teacher {{ index + 1 }}
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-1 pt-sm-3"
          >
            <div class="d-flex align-center">
              <v-autocomplete
                v-model="classData.primaryTeachers[index]"
                hide-details
                placeholder="Select Teacher"
                :item-text="item => `${item.firstName} ${item.lastName}`"
                :disabled="primaryTeacher?.id === getUserId && userRole() !== 'Teacher Admin'"
                item-value="id"
                solo
                dense
                return-object
                flat
                class="input-default"
                :items="filteredPrimaryTeachers(index)"
              />
              <v-img
                v-if="['Horizon Admin', 'District Admin', 'Teacher Admin'].includes(userRole()) || primaryTeacher?.id !== getUserId"
                contain
                class="d-lg-none ml-2 cursor-pointer"
                :src="require('@/assets/icons/trash-1.png')"
                alt="icon-trash"
                max-width="19"
                max-height="19"
                @click="onRemovePrimaryTeacher(index, primaryTeacher.name)"
              />
            </div>
          </v-col>
          <v-col
            v-if="['Horizon Admin', 'District Admin', 'Teacher Admin'].includes(userRole()) || primaryTeacher?.id !== getUserId"
            cols="auto"
            class="d-none d-lg-flex align-center"
          >
            <v-btn
              class="btn-default btn-default__secondary"
              :loading="loading"
              @click="onRemovePrimaryTeacher(index, primaryTeacher.name)"
            >
              <v-img
                contain
                class="mr-2"
                :src="require('@/assets/icons/trash-1.png')"
                alt="icon-trash"
                max-width="19"
                max-height="19"
              />
              Remove
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-btn
              class="btn-default btn-default__secondary"
              :loading="loading"
              @click="onAddPrimaryTeacher()"
            >
              <v-img
                contain
                class="mr-2"
                :src="require('@/assets/icons/user-add.svg')"
                alt="icon-add"
                max-width="19"
                max-height="19"
              />
              Add Primary Teacher
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-for="(secondaryTeacher, index) in classData.secondaryTeachers"
          :key="secondaryTeacher?.id"
        >
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container">
              <label
                class="label-default"
                for="exam"
              >
                Secondary Teacher {{ index + 1 }}
              </label>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="5"
            class="pt-1 pt-sm-3"
          >
            <div class="d-flex align-center">
              <v-autocomplete
                v-model="classData.secondaryTeachers[index]"
                hide-details
                placeholder="Select Teacher"
                :item-text="item => `${item.firstName} ${item.lastName}`"
                item-value="id"
                solo
                dense
                return-object
                flat
                class="input-default"
                :items="filteredSecondaryTeachers(index)"
              />
              <v-img
                contain
                class="d-lg-none ml-2 cursor-pointer"
                :src="require('@/assets/icons/trash-1.png')"
                alt="icon-trash"
                max-width="19"
                max-height="19"
                @click="onRemoveSecondaryTeacher(index, secondaryTeacher.name)"
              />
            </div>
          </v-col>
          <v-col
            cols="auto"
            class="d-none d-lg-flex align-center"
          >
            <v-btn
              class="btn-default btn-default__secondary"
              :loading="loading"
              @click="onRemoveSecondaryTeacher(index, secondaryTeacher.name)"
            >
              <v-img
                contain
                class="mr-2"
                :src="require('@/assets/icons/trash-1.png')"
                alt="icon-trash"
                max-width="19"
                max-height="19"
              />
              Remove
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col
            cols="12"
            sm="auto"
            class="d-flex align-center pb-0 pb-sm-3 pt-0 pt-sm-3"
          >
            <div class="label-default-container" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-btn
              class="btn-default btn-default__secondary"
              :loading="loading"
              @click="onAddSecondaryTeacher()"
            >
              <v-img
                contain
                class="mr-2"
                :src="require('@/assets/icons/user-add.svg')"
                alt="icon-add"
                max-width="19"
                max-height="19"
              />
              Add Secondary Teacher
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row
        dense
        justify="end"
        class="mt-6"
      >
        <v-col
          v-if="isEditable()"
          cols="12"
          sm="auto"
        >
          <v-btn
            :loading="loading"
            class="btn-default btn-default__secondary"
            block
            @click="onDeleteClass()"
          >
            <v-img
              contain
              class="mr-2"
              :src="require('@/assets/icons/trash-1.png')"
              alt="icon-trash"
              max-width="19"
              max-height="19"
            />
            Delete Class
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            :loading="loading"
            class="btn-default btn-default__secondary"
            block
            @click="onCancel()"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            :loading="loading"
            class="btn-default btn-default__primary"
            block
            @click="onSubmit()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/diskette.png')"
              alt="icon-add"
              max-width="19"
              max-height="19"
            />
            Save Settings
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <dialog-action
      :active="dialogRemoveTeacher"
      :body-text-prop="`Do you want to remove ${teacherProp} from this class?`"
      @closeDialog="onCloseDialogRemoveTeacher()"
      @confirmDialog="onConfirmRemoveTeacher()"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteConfirmation"
      @confirmDeletion="onCloseDialogDeleteConfirmation()"
    />
  </div>
</template>

<script>
import DialogAction from "@/components/shared/DialogAction.vue";
import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";

import {createNamespacedHelpers} from 'vuex'

const {mapGetters: mapUserGetters} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters, mapActions: mapSectionActions} = createNamespacedHelpers('staffSections')
const {mapGetters: mapTermGetters} = createNamespacedHelpers('terms')

import {AdminSectionApi, AdminUserApi, AdminSchoolApi, StaffSectionApi, StaffUserApi} from "@/api"
import {validationMixin} from "vuelidate/src";
import {required} from "vuelidate/lib/validators";
import functions from "@/api/shared/functions";

export default {
  name: "ClassSettings",
  components: {DialogDeleteConfirmation, DialogAction},
  mixins: [validationMixin],

  props: {
    sectionId: {
      type: [Number, String],
      required: true
    },
    schoolId: {
      type: [String, Number],
      default: null
    },
  },
  data() {
    return {
      dialogRemoveTeacher: false,
      dialogDeleteConfirmation: false,
      loading: false,
      teacherProp: '',
      classData: {
        name: '',
        displayName: '',
        primaryTeachers: [{}],
        secondaryTeachers: [{}],
      },
      originalClassData: {},
      archivedClass: false,
      teachersList: [],
      sectionParent: {},
      termsList: []
    }
  },

  validations: {
    classData: {
      name: {required},
      displayName: {required},
    }
  },

  computed: {
    ...mapUserGetters(["user"]),

    getUserId() {
      return this.user.userId
    }
  },

  mounted() {
    if (this.userRole() === 'Teacher' || this.userRole() === 'Teacher Admin') {
      this.sectionParent = this.getAllSections().find(section => section.id == this.sectionId)
    }
    this.loadAvailableTeachers()
    this.loadSection()
    this.fetchTerms()
  },

  methods: {
    ...mapUserGetters(["userRole"]),
    ...mapSectionGetters(["getAllSections"]),
    ...mapSectionActions(['storeSections']),
    ...mapTermGetters(["getAllTerms"]),

    async loadSection() {
      try {
        this.loading = true
        if (this.sectionParent?.role === 'Secondary Teacher' && this.userRole() !== 'Teacher Admin') {
          this.addNotification('error', "You do not have the permissions to edit this class.")
          return
        }

        let response = {}
        if (this.userRole() === "Horizon Admin") {
          response = await AdminSectionApi.getSingleSection(this.sectionId)
        } else {
          response = await StaffSectionApi.get(this.sectionId)
        }
        const section = response.section
        this.classData.displayName = section.displayName
        this.classData.name = section.className
        this.classData.primaryTeachers = section.primaryTeachers
        this.classData.secondaryTeachers = section.secondaryTeachers
        this.classData.editable = section.editable
        this.classData.term = section.term
        this.originalClassData = functions.deepCopySync(section)
        this.archivedClass = section.archived
      } catch {
        this.addNotification('error', 'Failed to load class.')
      } finally {
        this.loading = false
      }

    },

    async loadAvailableTeachers() {
      try {
        if (this.sectionParent?.role === "Secondary Teacher" && this.userRole() !== 'Teacher Admin') return
        let response
        if (this.userRole() === "Horizon Admin") {
          response = await AdminUserApi.getTeachersMinimal(this.schoolId)
        } else {
          response = await StaffUserApi.getTeachersMinimal()
        }
        this.teachersList = response?.users
      } catch {
        this.addNotification('error', 'Failed to load teachers.')
      }
    },

    isEditable(){
      return this.classData.editable
    },

    async onSubmit() {
      try {
        this.loading = true
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        if (this.userRole() === 'Horizon Admin') {
          await AdminSectionApi.update(this.classData, this.originalClassData)
        } else {
          await StaffSectionApi.update(this.classData, this.originalClassData)
        }

        this.addNotification('success', "Class Settings saved!")
      } catch {
        this.addNotification('error', "The changes could not be saved. Please try again.")
      } finally {
        this.loading = false
      }
    },

    onCancel() {
      if (this.userRole() === 'Horizon Admin') {
        this.$router.push({name: 'AdminDashboard/Classes', params: {id: this.schoolId}})
      } else {
        this.$router.back()
      }
    },

    onClassNameChange() {
      this.classData.displayName = this.classData.name
    },

    filteredPrimaryTeachers(index) {
      const selectedPrimaryTeachers = this.classData.primaryTeachers
          .filter((_, i) => i !== index)

      const selectedTeachers = [...selectedPrimaryTeachers, ...this.classData.secondaryTeachers]
      return this.teachersList?.filter(item => !selectedTeachers.map(a => a?.id).includes(item.id));
    },

    filteredSecondaryTeachers(index) {
      const selectedSecondaryTeachers = this.classData.secondaryTeachers
          .filter((_, i) => i !== index)

      const selectedTeachers = [...selectedSecondaryTeachers, ...this.classData.primaryTeachers]
      return this.teachersList?.filter(item => !selectedTeachers.map(a => a?.id).includes(item.id));
    },

    onAddPrimaryTeacher() {
      this.classData.primaryTeachers.push({})
    },

    onAddSecondaryTeacher() {
      this.classData.secondaryTeachers.push({})
    },

    onRemovePrimaryTeacher(index, name) {
      if (!name) {
        this.classData.primaryTeachers.splice(index, 1)
        return
      }
      this.teacherProp = name
      this.indexToRemove = index
      this.typeToRemove = 'primaryTeachers'
      this.dialogRemoveTeacher = true
    },

    onRemoveSecondaryTeacher(index, name) {
      if (!name) {
        this.classData.secondaryTeachers.splice(index, 1)
        return
      }
      this.teacherProp = name
      this.indexToRemove = index
      this.typeToRemove = 'secondaryTeachers'
      this.dialogRemoveTeacher = true
    },

    onConfirmRemoveTeacher() {
      this.classData[this.typeToRemove].splice(this.indexToRemove, 1)
      this.dialogRemoveTeacher = false
    },

    onDeleteClass() {
      this.dialogDeleteConfirmation = true
    },

    onCloseDialogRemoveTeacher() {
      this.dialogRemoveTeacher = false
    },

    async onCloseDialogDeleteConfirmation() {
      try {
        this.loading = true
        if (this.userRole() === "Horizon Admin") {
          await AdminSectionApi.delete(this.sectionId)
          this.addNotification('success', `Class '${this.classData.displayName}' has been deleted. You will be redirected back to the dashboard.`)
          this.$router.push({name: 'AdminDashboard/Classes', params: {id: this.schoolId}})
        } else if (this.sectionParent?.role === "Primary Teacher" || this.userRole() === 'Teacher Admin') {
          await StaffSectionApi.delete(this.sectionId)
          this.addNotification('success', `Class '${this.classData.displayName}' has been deleted. You will be redirected back to the dashboard.`)

          // reload section info
          this.sections = (await StaffSectionApi.list()).sections
          this.storeSections(this.sections)
          this.$router.push({name: 'StaffHome'})
        } else {
          this.addNotification('error', "You do not have the permissions to delete this class.")
        }
      } catch {
        this.addNotification('error', "This class could not be deleted.")
        this.dialogDeleteConfirmation = false
      } finally {
        this.loading = false
      }
    },

    async fetchTerms() {
      let terms = null
      if (this.userRole() === "Horizon Admin") {
        terms = (await AdminSchoolApi.getSchoolTermsAll(this.schoolId)).terms
      } else {
        terms = this.getAllTerms()
      }

      // filter terms in the dropdown based on the class's archived status (or lack thereof)
      if (!this.archivedClass || this.classData.term == null || !this.classData.term.archived) {
        this.termsList = terms.filter((t) => !t.archived)
      } else {
        this.termsList = terms
      }
    }

  },
}
</script>

<style scoped lang="scss">

</style>