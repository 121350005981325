<template>
  <div v-if="dataAvailable">
    <v-card flat>
      <v-card-title class="pt-1 pl-0 pr-0">
        <v-row justify="space-between">
          <v-col
            class="col-auto align-self-center"
          >
            <h3>{{ assessmentType.courseType.toUpperCase() }} Student Results</h3>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="mt-3 pl-0 pr-0">
        <v-tabs
          v-model="tab"
          :vertical="isMobile"
          :grow="isMobile"
          active-class="active-tab"
          hide-slider
          show-arrows
        >
          <v-tabs-slider />

          <v-tab
            v-for="assessmentSet in assessmentSummaries"
            :key="assessmentSet.id"
          >
            <span class="font-size-16">{{ assessmentSet.name }}</span>
          </v-tab>
        </v-tabs>

        <v-sheet
          class="pa-6"
          outlined
        >
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="assessmentSet in assessmentSummaries"
              :key="assessmentSet.id"
              :transition="false"
              :eager="false"
            >
              <summary-tab
                :assessment-set-id="assessmentSet.id"
                :assessment-type-id="assessmentType.id"
                :assessment-summaries-count="assessmentSummaries.length"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
  <div v-else>
    No data available.
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')

import SummaryTab from './StaffStudentResultSummaryItemTab'

export default {
  components: {
    SummaryTab
  },

  props: {
    assessmentType: {
      type: Object,
      required: true
    },

    assessmentSummaries: {
      type: Array,
      default: () => null,
      required: true
    }
  },

  data() {
    return {
      tab: 0
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
    dataAvailable() {
      return this.assessmentSummaries && this.assessmentSummaries.length > 0
    }
  },
}
</script>

<style lang="scss" scoped>

h3 {
  font-weight: 700;
  color: #345165;
}

.v-tab {
  text-transform: none !important;
  letter-spacing: 0;
  cursor: pointer;
  transition: .2s;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #aaa !important;
  line-height: 24px;
  padding: 13px 32px;
  text-align: center;
  font-size: 18px;
}

.active-tab {
  background-color: #38566b;
  color: #fff !important;
}

</style>
