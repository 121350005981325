import HorizonApi from '@/api/horizon_api'

const STUDENT_ASSESSMENT_PATH = `/api/student/student_assessments`

export default {
  list (filter = null) {
    if (filter == null) {
      return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}`)
    } else {
      return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}?filter=${filter}`)
    }
  },

  get (assessment_id) {
    return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}/${assessment_id}`)
  },

  initialize_test (assessment_id) {
    return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}/${assessment_id}/initialize_test`)
  },

  get_partial_score (assessment_id) {
    return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}/${assessment_id}/partial_score`)
  },

  get_next_assessment (assessment_id) {
    return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}/${assessment_id}/next_assessment`)
  },

  learnosity_callback (assessment_id, learnosity_event) {
    return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}/${assessment_id}/learnosity_callback?learnosity_event=${learnosity_event}`)
  },

  learnositySessionDetailByItem (assessment_id) {
    return HorizonApi.get(`${STUDENT_ASSESSMENT_PATH}/${assessment_id}/learnosity_session_detail_by_item`)
  }

}
