import {createNamespacedHelpers} from "vuex";
const {mapState: mapUserState} = createNamespacedHelpers('user')

export default {
  computed: {
    ...mapUserState(["user"]),
    supportsSat() {
      if (this.user.role == "Horizon Admin") {
        return this.school.courseType.includes("SAT")
      } else {
        return this.user.school.courseType.includes("SAT")
      }
    },
    supportsAct() {
      if (this.user.role == "Horizon Admin") {
        return this.school.courseType.includes("ACT")
      } else {
        return this.user.school.courseType.includes("ACT")
      }
    },
  }
}
