import HorizonApi from '@/api/horizon_api'

const ADMIN_SECTIONS_PATH = '/api/admin/sections'

export default {
    list(page = 1, perPage, sort, order, searchProp, schoolId, active) {
        const params = {
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
            'search': searchProp,
            'schoolId': schoolId,
            'active': active
        };
        return HorizonApi.get(ADMIN_SECTIONS_PATH, {params: params})
    },

    getAllSections(){
        return HorizonApi.get(ADMIN_SECTIONS_PATH)
    },

    getSingleSection(sectionId){
        return HorizonApi.get(`${ADMIN_SECTIONS_PATH}/${sectionId}`)
    },

    createSection(section){
        return HorizonApi.post(ADMIN_SECTIONS_PATH, {section: section})
    },

    update (newSection, oldSection) {
        let params = {}
        if (newSection.primaryTeachers != oldSection.primaryTeachers || newSection.secondaryTeachers != oldSection.secondaryTeachers) {
            // removed teachers
            let newTeachers = [...newSection.primaryTeachers, ...newSection.secondaryTeachers]
            let oldTeachers = [...oldSection.primaryTeachers, ...oldSection.secondaryTeachers]

            let removedTeacherIds = oldTeachers.filter(teacher => !newTeachers.map(t => t.id).includes(teacher.id)).map(teacher => teacher.id)
            params['removedTeacherIds'] = removedTeacherIds
            params['primaryTeacherIds'] = newSection.primaryTeachers.map(t => t.id)
            params['secondaryTeacherIds'] = newSection.secondaryTeachers.map(t => t.id)
        }

        params['className'] = newSection.name
        params['displayName'] = newSection.displayName
        params['termId'] = newSection.term.id

        return HorizonApi.patch(`${ADMIN_SECTIONS_PATH}/${oldSection.id}`, { section: params })
    },

    delete (sectionId) {
        return HorizonApi.delete(`${ADMIN_SECTIONS_PATH}/${sectionId}`)
    },

    bulkStatusChange(idArray, status) {
        const sections = {
            section_ids: idArray,
            active: status
        }
        return HorizonApi.put(`${ADMIN_SECTIONS_PATH}/bulk_hide`, sections)
    },

    deletionBulk(sectionIdArray) {
        let params = sectionIdArray.map(section => `section_ids[]=${section}`).join("&")
        return HorizonApi.delete(`${ADMIN_SECTIONS_PATH}/bulk_delete?${params}`)
    },

    addUser(sectionId, userObj){
        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${sectionId}/create_student`, {user: userObj})
    },

    getStudents(sectionId, page = 1, perPage, sort, order, searchProp) {
        const params = {
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
            'search': searchProp,
        }
        return HorizonApi.get(`${ADMIN_SECTIONS_PATH}/${sectionId}/students`, {params: params})
    },

    getUnassignedStudents(sectionId, page = 1, perPage, sort, order, searchProp) {
        const params = {
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
            'search': searchProp,
        }
        return HorizonApi.get(`${ADMIN_SECTIONS_PATH}/${sectionId}/unassigned_students`, {params: params})
    },

    removeUserBulk(sectionId, userIdArray) {
        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${sectionId}/bulk_remove_students`, {studentIds: userIdArray})
    },

    importStudents (sectionId, role, csvFile) {
        let fd = new FormData();

        fd.append("file", csvFile)
        fd.append("role", role)

        let headers = {
            "Content-Type": "multipart/form-data"
        }

        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${sectionId}/import_students`, fd, headers)
    },

    createMultipleUsers (sectionId, usersArray) {
        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${sectionId}/bulk_create_students`, {users: usersArray})
    },

    assignStudentsBulk(classId, studentIdArray){
        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${classId}/bulk_assign_students`, {studentIds: studentIdArray})
    },

    unassignStudentsBulk(sectionId, userIdArray) {
        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${sectionId}/students/unassign`, {userIds: userIdArray})
    },

    transferStudents(sectionId, userIdArray, newSectionId) {
        const paramObj = {
            studentIds: userIdArray,
            newSectionId: newSectionId
        }
        return HorizonApi.post(`${ADMIN_SECTIONS_PATH}/${sectionId}/bulk_transfer_students`, paramObj)
    },

}
