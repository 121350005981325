<template>
  <v-container
    fluid
  >
    <app-header
      title="Student Results"
    >
      <template #actionbar>
        <v-btn
          dark
          class="btn-default btn-default__primary btn-default__app-header"
          @click="goToDownloads()"
        >
          Export Downloads
        </v-btn>
      </template>
    </app-header>

    <div v-if="loading">
      <v-skeleton-loader
        type="card-avatar, table"
      />
    </div>
    <div v-else>
      <div v-if="summariesAvailable">
        <summary-item
          v-for="(result, idx) in assessmentResults"
          :key="idx"
          :assessment-type="result.assessmentType"
          :assessment-summaries="result.assessmentSets"
        />
      </div>
      <div v-else>
        No results available.
      </div>
    </div>
  </v-container>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import SummaryItem from './widgets/StaffStudentResultSummaryItem';

import { createNamespacedHelpers } from 'vuex'
const { mapGetters: mapSectionGetters } = createNamespacedHelpers('staffSections')

import { StaffStudentResultsApi } from "@/api"

export default {
  name: "StaffStudentResultsDashboard",
  components: {
    AppHeader,
    SummaryItem
  },

  data() {
    return {
      loading: false,
      assessmentResults: []
    }
  },

  computed: {
    summariesAvailable() {
      return this.assessmentResults.length > 0
    }
  },

  mounted() {
    this.loadSummary()
  },

  methods: {
    ...mapSectionGetters(["getAllSections"]),

    async loadSummary() {
      this.loading = true
      const response = await StaffStudentResultsApi.getDashboardSummary()
      this.assessmentResults = response.availableResults
      this.loading = false
    },

    goToDownloads() {
      this.$router.push({ name: "StaffPdfDownloads" })
    }
  },
}
</script>

<style scoped>

</style>