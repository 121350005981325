<template>
  <v-dialog
    :value="dialog"
    max-width="700px"
    persistent
  >
    <v-card class="card-dialog">
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <student-form
        v-if="dialog"
        class="mt-2"
        :type="type"
        :student-prop="studentData"
        :school-id="schoolId"
        :from-upload-bulk="fromUploadBulk"
        :from-class="fromClass"
        :section-id="sectionId"
        @closeDialog="onCloseDialog()"
        @closeDialogUpdate="onCloseDialog(true, ...arguments)"
      />
    </v-card>
  </v-dialog>
</template>

<script>

import AppHeader from "@/components/shared/AppHeader.vue";
import functions from "@/api/shared/functions";
import StudentForm from "@/components/shared/StudentForm.vue";

export default {
  name: "FormStudent",
  components: {StudentForm, AppHeader},
  props: {
    active: Boolean,
    fromUploadBulk: Boolean,
    fromClass: Boolean,
    type: {
      type: String,
      default: 'new',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    studentProp: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    schoolId: {
      type: [Number, String],
      default: null
    },
    sectionId: {
      type: [Number, String],
      default: null
    },
  },

  data() {
    return {
      dialog: false,
      gradeLevelArray: [],
      loading: false,
      studentData: {
        firstName: '',
        lastName: '',
        email: '',
        studentIdNumber: '',
        gradeLevel: '',
        untimedAssessment: false,
      },
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.studentData = functions.deepCopySync(this.studentProp)
        this.dialog = value
      } else {
        this.dialog = value
      }

    }
  },

  methods: {
    onCloseDialog(update, editedItem) {
      if (update) {
        this.$emit('closeDialogUpdate', editedItem)
        return
      }
      this.$emit('closeDialog')
    },
  }
}
</script>