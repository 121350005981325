<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <!-- Subsection Details -->
    <v-card
      outlined
    >
      <v-card-title>
        <h3 class="font-weight-700">
          Subsection Score Details
        </h3>
      </v-card-title>

      <v-divider />

      <v-card-subtitle class="instructions">
        Click on each tab to view individual subsection details.
      </v-card-subtitle>

      <v-card-text>
        <v-tabs
          v-model="tab"
          :vertical="isMobile"
          :grow="isMobile"
          active-class="active-tab"
          hide-slider
          show-arrows
        >
          <v-tab
            v-for="category in categories"
            :key="category.id"
            @click="onTabClicked(category)"
          >
            {{ category.name }}
          </v-tab>
        </v-tabs>

        <v-sheet outlined>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(categoryDetail, idx) in categoryDetails"
              :key="idx"
              :transition="false"
            >
              <subsection-detail-tab 
                :data="categoryDetail"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-card-text>
    </v-card>
    <!-- end Subsection Details -->
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
import SubsectionDetailTab from "@/components/shared/student_results/SubsectionDetailTab"
import { StaffStudentResultsApi } from "@/api"

export default {
  components: {
    SubsectionDetailTab
  },

  props: {
    sectionId: {
      type: [Number, String],
      required: false
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
    assessmentId: {
      type: [Number, String],
      required: false
    },
  },

  data() {
    return {
      tab: null,
      categories: [],
      categoryDetails: []
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  mounted() {
    this.loadCategories()
  },

  methods: {
    async loadCategories() {
      var response = await StaffStudentResultsApi.getCategoryTabs(        
        {
          sectionId: this.sectionId,
          assessmentSetId: this.assessmentSetId,
          assessmentTypeId: this.assessmentTypeId   
        })
      this.categories = response.assessments

      let categoryDetails = Array.apply(null, {length: this.categories.length}).map(function () {})
      this.categoryDetails = categoryDetails
      this.loadCategoryDetail(this.categories[0])
    },

    async loadCategoryDetail(category) {
      let index = this.categories.findIndex(cat => cat == category)
      if (this.categoryDetails[index] !== undefined) {
        return
      }

      try {
        var response = await StaffStudentResultsApi.getCategoryDetails(        
          {
            sectionId: this.sectionId,
            assessmentId: category.id,
          })

        this.$set(this.categoryDetails, index, {
          assessmentId: category.id,
          report: response
        })
      } catch (e) {
        this.$set(this.categoryDetails, index, null)  
      }
    },

    async onTabClicked(category) {
      this.loadCategoryDetail(category)
    },

  }
}
</script>

<style lang="scss" scoped>

.v-tab {
  text-transform: none !important;
  letter-spacing: 0;
  cursor: pointer;
  transition: .2s;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #aaa !important;
  line-height: 24px;
  padding: 13px 32px;
  text-align: center;
  font-size: 18px;
}

.v-card__title {
  background-color: #38566b;
  color: #fff;

  h3 {
    color: #fff;
  }
}

.active-tab {
  background-color: #38566b;
  color: #fff !important;
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
}

</style>