<template>
  <v-card
    :to="linkProp"
    class="report-card"
  >
    <div class="report-card-header">
      <h4>{{ header }}</h4>
    </div>
    <div class="report-img-container">
      <img
        :src="require(`@/assets/img/${filename}`)"
        :alt="`${header}-image`"
      >
    </div>
    <div class="report-card-footer">
      <h6>{{ footer }}</h6>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "StaffReportsCard",
  props: {
    linkProp: {
      type: String,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
    footer: {
      type: String,
      required: true
    },
  }
}
</script>

<style scoped lang="scss">
.report-card {
  min-height: 368px;
  max-width: 362px;
  min-width: 362px;
  box-shadow: 2px 2px 4.9px 0 #0000001A !important;
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  &:hover {
    background-color: #fff !important;
    box-shadow: 0 1px 3px 3px rgba(187, 187, 187, .13) !important;
  }

  &__text-container {
    background: transparent;
    box-shadow: none !important;
    border: none;
    max-width: 270px;
    min-width: 270px;

    &:hover {
      background-color: transparent !important;;
      box-shadow: none !important;
    }
  }

  .report-card-header {
    border-radius: 4px 4px 0 0 !important;
    padding: 20px 24px;
    text-align: center;
    background: linear-gradient(127.02deg, #EAF4FF 14.35%, #DBE6F2 46.14%);

    h4 {
      font-size: 20px;
    }
  }

  .report-img-container {
    padding: 16px 24px;
  }

  .report-card-footer {
    border-radius: 0 0 4px 4px !important;
    padding: 20px 24px;
    background: #F7F7F7;
  }

}
</style>