<template>
  <div class="">
    <create-school
      v-if="schoolLoaded"
      :edit-mode="true"
      :selected-school="school"
    />
  </div>
</template>

<script>
import CreateSchool from "@/components/CreateSchool.vue";
import {AdminSchoolApi, AdminDistrictApi} from "@/api"

export default {
  name: "AdminSchoolSettings",
  components: {CreateSchool},
  data() {
    return {
      school: {},
      schoolLoaded: false
    }
  },
  mounted() {
    AdminSchoolApi.get(this.$route.params.id).then((res) => {
      this.school = res.school
      AdminDistrictApi.get(this.school.schoolDistrictId).then((res) => {
        this.school.districtCleverId = res.schoolDistrict.cleverId
        this.school.districtClasslinkId = res.schoolDistrict.classlinkId
        this.schoolLoaded = true
      }).catch(() => {
        this.addNotification('error', 'There was a problem getting School District data')
      })
    }).catch(() => {
      this.addNotification('error', 'There was a problem getting School data')
    })
  }
}
</script>

<style scoped>

</style>
