<template>
  <v-dialog
    :value="dialog"
    width="90%"
    max-width="1200"
    persistent
  >
    <v-card class="card-dialog">
        <app-header 
          :title="title"
          :is-dialog="true"
          @closeDialog="onCloseDialog()"
        />
        <v-container class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6">
          <v-row>
            <iframe
              :src="media_src"
              width="100%"
              height="600"
              allowfullscreen="true"
              >
            </iframe>
          </v-row>
            <v-row
              justify="end"
              align="center"
            >
              <v-col
                cols="12"
                sm="auto"
              >
                <v-btn
                  dark
                  class="btn-default btn-default__secondary"
                  block
                  :href="media_src" 
                  target="_blank"
                >
                  <v-img 
                    class="mr-2"
                    src="@/assets/icons/tab.png"
                    contain
                    alt="icon-add"
                    max-width="15"
                    max-height="15"
                  />
                  Open {{ btnTxt }} in New Tab
                </v-btn>
              </v-col>
            </v-row>
        </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

export default {
  name: "DialogLessonMaterial",
  components: {
    AppHeader
  },
  props: {
    active: Boolean,
    title: {
      type: String,
      required: true,
      default: '',
    },
    media_src: {
      type: String,
      default: ''
    },
    btnTxt: {
      type: String,
      default: '',
    }
  },
  data () {
      return {
        dialog: false
      }
  },
  watch: {
    active(value) {
      if (value) {
        this.dialog = value
      } else {
        this.dialog = value
      }
    },
    
  },
  methods: {
    onCloseDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>
