import moment from "moment/moment";
import AssessmentAssignmentSettings from '@/models/assessment_assignment_settings'

export default class SectionAssessment {
  constructor() {
    this.id = null
    this.startAt = null
    this.endAt = null
    this.revealAt = null
    this.assessment = null
    this.sections = null
    this.assessmentType = null
    this.assessmentSet = null
    this.assessmentPart = null
    this.sectionAssessmentGroupId = null
    this.category = null
    this.settings = new AssessmentAssignmentSettings()

    this.initializeRules()
  }

  initializeRules() {
    this.rules = {
      assessmentName: [
        v => !!v || 'Assessment Name is required'
      ],
      assessmentSection: [
        v => !!v || 'Assessment Section is required'
      ],
      startAt: [
        v => !!v || 'Start date is required'
      ],
      endAt: [
        v => {
          let condition = v == null || v == "" || ((v != null && v != "") && this.startAt && this.endAt > this.startAt)
          return condition || 'End date must be after start date'
        }
      ],
      classes: [
        v => !!v || 'Please select at least one class.'
      ]
    }
  }

  getStatus() {
    var s = ""
    let startAt = this.startAt

    if (this.endAt == null) {
      if (startAt < Date.now()) {
        s = "active"
      } else if (startAt > Date.now()) {
        s = "upcoming"
      }
    } else {
      let endAt = this.endAt
      if (endAt < Date.now()) {
        s = "past"
      } else {
        if (startAt < Date.now()) {
          s = "active"
        } else if (startAt > Date.now()) {
          s = "upcoming"
        }
      }
    }

    return s
  }

  isEqualTo(sectionAssessment) {
    return this.assessment == sectionAssessment.assessment && this.startAt == sectionAssessment.startAt && this.endAt == sectionAssessment.endAt && this.revealAt == sectionAssessment.revealAt
  }

  get isMultipart() {
    return this.sectionAssessmentGroupId !== null
  }

  // Assessment Schedule table properties
  get formattedCategoryName() {
    if (this.assessmentPart == null) {
      return this.category?.name
    } else {
      return `${this.category?.name}: ${this.assessmentPart}`
    }   
  }

  get formattedStartAt() {
    return this.startAt.toLocaleString()
  }

  get formattedEndAt() {
    return this.endAt == null ? "(not set)" : this.endAt.toLocaleString()
  }

  get formattedRevealAt() {
    return this.revealAt == null ? "(not set)" : this.revealAt.toLocaleString()
  }

  get formattedClassName() {
    return this.sections[0].displayName
  }

  get status() {
    return this.getStatus()
  }

  // property get/setters for dialog
  get assessmentName() {
    if (this.assessmentType != null) {
      return `${this.assessmentType.courseType.toUpperCase()} ${this.assessmentSet.name}`
    } else {
      return ""
    }
  }

  set assessmentName(value) {
    this.assessmentSet = value.assessmentSet
    this.assessmentType = value.assessmentType
  }

  get formattedStartAtDate() {
    if (this.startAt != null) {
      return this.startAt.toDateString()
    } else {
      return ""
    }
  }

  get startAtDate() {
    if (this.startAt != null) {
      let dateFormatted = moment(this.startAt).format().toString().substring(0, 10)
      return dateFormatted
    } else {
      return ""
    }
  }

  set startAtDate(value) {
    const prevDate = this.startAt
    const dateTemp = new Date(value)
    this.startAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

    if (prevDate != null) {
      this.startAt.setHours(prevDate.getHours())
      this.startAt.setMinutes(prevDate.getMinutes())
    } else {
      this.startAt.setHours(new Date().getHours())
      this.startAt.setMinutes(new Date().getMinutes())
    }
  }

  get startAtTime() {
    if (this.startAt != null) {
      return `${this.startAt.getHours().toString().padStart(2, '0')}:${this.startAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set startAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.startAt != null) {
        this.startAt = new Date(this.startAt.getFullYear(), this.startAt.getMonth(), this.startAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        let today = new Date()
        this.startAt = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.getHours(), time.getMinutes())
      }
    }
  }

  get formattedEndAtDate() {
    if (this.endAt != null) {
      return this.endAt.toDateString()
    } else {
      return ""   
    }
  }

  set formattedEndAtDate(value) {
    this.endAt = value
  }

  get endAtDate() {
    if (this.endAt != null) {
      let dateFormatted = moment(this.endAt).format().toString().substring(0, 10)
      return dateFormatted
    } else {
      return ""   
    }
  }

    set endAtDate(value) {
    if (value != null)  {
      const prevDate = this.endAt
      const dateTemp = new Date(value)
      this.endAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

      if (prevDate != null) {
        this.endAt.setHours(prevDate.getHours())
        this.endAt.setMinutes(prevDate.getMinutes())
      } else {
        this.endAt.setHours(new Date().getHours())
        this.endAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.endAt = null
    }
  }

  get endAtTime() {
    if (this.endAt != null) {
      return `${this.endAt.getHours().toString().padStart(2, '0')}:${this.endAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set endAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.endAt != null) {
        this.endAt = new Date(this.endAt.getFullYear(), this.endAt.getMonth(), this.endAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        const nextWeek = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        this.endAt = new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.endAt = null
    }
  }

  get formattedRevealAtDate() {
    if (this.revealAt != null) {
      return this.revealAt.toDateString()
    } else {
      return ""   
    }
  }

  set formattedRevealAtDate(value) {
    this.revealAt = value
  }

  get revealAtDate() {
    if (this.revealAt != null) {
      let dateFormatted = moment(this.revealAt).format().toString().substring(0, 10)
      return dateFormatted
    } else {
      return ""   
    }
  }

  set revealAtDate(value) {
    if (value != null) {
      const prevDate = this.revealAt
      const dateTemp = new Date(value)
      this.revealAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )
      if (prevDate != null) {
        this.revealAt.setHours(prevDate.getHours())
        this.revealAt.setMinutes(prevDate.getMinutes())
      } else {
        this.revealAt.setHours(new Date().getHours())
        this.revealAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.revealAt = null   
    }
  }

  get revealAtTime() {
    if (this.revealAt != null) {
      return `${this.revealAt.getHours().toString().padStart(2, '0')}:${this.revealAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set revealAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.revealAt != null) {
        this.revealAt = new Date(this.revealAt.getFullYear(), this.revealAt.getMonth(), this.revealAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        const nextWeek = new Date(Date.now() + 8 * 24 * 60 * 60 * 1000)
        this.revealAt = new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.revealAt = null
    }
  }
}