<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <component
      :is="assessmentDialogComponent"
      :active="dialogAssessment"
      :title="dialogTitle"
      :type="dialogType"
      :assessment-prop="assessmentProp"
      :assessment-choices="getAssessmentChoices()"
      :category-choices="getAvailableAssessmentCategories()"
      :class-choices="primarySections"
      @closeDialog="onCloseDialogAssessments()"
      @submitDialog="onSubmitAssessment"
    />

    <dialog-delete-confirmation
      :message="deleteDialogConfirmationMessage"
      :active="dialogDeleteConfirmation"
      @confirmDeletion="onDeleteAssessment()"
    />

    <!-- Active Assessments -->
    <v-row
      class="mb-2 mt-0"
      align="center"
      :dense="fromStudentsDialog"
    >
      <v-col>
        <h3>Active Assessments</h3>
      </v-col>
      <v-spacer />
      <v-col
        v-if="!fromStudentsDialog"
        cols="12"
        sm="auto"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="searchAssessments"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          class="input-default"
          clearable
          hide-details
          solo
          flat
          height="50"
        />
      </v-col>
      <v-col
        v-if="!fromStudentsDialog"
        cols="12"
        sm="auto"
      >
        <v-btn
          v-if="canAssignAssessment()"
          dark
          block
          class="btn-default btn-default__primary"
          @click.stop="onNewAssessment"
        >
          <v-img
            class="mr-2"
            contain
            :src="require('@/assets/icons/add-1.png')"
            alt="icon-add"
            max-width="19"
            max-height="19"
          />
          Assign Assessment
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(activeAssessments)"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="activeAssessments"
      :search="searchAssessments"
      :loading="assessmentsLoading ? '#3d5d74' : false"
      loading-text="Loading Assessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>
      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canMonitorAssessment()"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('view', item)"
              >
                <v-icon size="18">
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('edit', item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('delete', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canMonitorAssessment()"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('view', item)"
              >
                <v-icon size="18">
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('edit', item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('delete', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Active Assessments -->

    <!-- Upcoming Assessments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col
        align-self="end"
      >
        <h3>Upcoming Assessments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(upcomingAssessments)"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="upcomingAssessments"
      :search="searchAssessments"
      class="table-default table-default__header-fixed"
      :loading="assessmentsLoading ? '#3d5d74' : false"
      loading-text="Loading Assessments"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>
      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canMonitorAssessment()"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('view', item)"
              >
                <v-icon size="18">
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('edit', item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('delete', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canMonitorAssessment()"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('view', item)"
              >
                <v-icon size="18">
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('edit', item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('delete', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Upcoming Assessments -->

    <!-- Past Assessments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col
        align-self="end"
      >
        <h3>Past Assessments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(pastAssessments)"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="pastAssessments"
      :search="searchAssessments"
      class="table-default table-default__header-fixed"
      :loading="assessmentsLoading ? '#3d5d74' : false"
      loading-text="Loading Assessments"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[100],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>
      <template #[`item.action`]="{ item }">
        <td v-if="!isMobile">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                v-if="canMonitorAssessment()"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('view', item)"
              >
                <v-icon size="18">
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('edit', item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('delete', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
        <td v-else>
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                v-if="canMonitorAssessment()"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('view', item)"
              >
                <v-icon size="18">
                  mdi-magnify
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('edit', item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                v-if="canEditAssessment(item)"
                class="btn-table btn-table__icon"
                outlined
                icon
                @click.stop="handleAction('delete', item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Past Assessments -->
  </v-container>
</template>

<script type="text/javascript">
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')
const {mapGetters: mapAssessmentGetters, mapActions: mapAssessmentActions} = createNamespacedHelpers('assessments')

import {StaffAssessmentApi, AdminSchoolApi, AdminAssessmentApi, StaffSchoolApi, StaffUserApi} from '@/api'
import SectionAssessmentFactory from '@/services/section_assessment_factory'
import SectionAssessmentGroupFactory from '@/services/section_assessment_group_factory'

import DialogDeleteConfirmation from "@/components/shared/DialogDeleteConfirmation.vue";
import DialogAssessmentAssign from "@/components/shared/DialogAssessmentAssign.vue";
import DialogAssessmentGroupAssign from "@/components/shared/DialogAssessmentGroupAssign.vue";

import schoolMixin from '@/mixins/schoolMixin';

export default {
  components: {
    DialogAssessmentAssign,
    DialogAssessmentGroupAssign,
    DialogDeleteConfirmation
  },

  mixins: [
    schoolMixin
  ],

  props: {
    classSpecific: Boolean,
    fromStudentsDialog: Boolean,
    sectionId: {
      default: null,
      type: [Number, String],
      required: false
    },
    id: {
      type: [Number, String],
      default: null
    },
    studentId: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      assessmentDialogComponent: DialogAssessmentAssign,
      dialogAssessment: false,
      assessmentsLoading: false,
      dialogDeleteConfirmation: false,
      dialogTitle: '',
      dialogType: '',
      deleteDialogConfirmationMessage: null,
      assessmentProp: {},
      assessments: [],
      activeAssessments: [],
      upcomingAssessments: [],
      pastAssessments: [],
      schoolId: null,
      school: {},
      primarySections: [],
      headers: [],
      searchAssessments: '',
      tableHeight: 'calc(90vh - 438px)',
    }
  },

  computed: {
    ...mapUserState(["user"]),
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    })
  },

  mounted() {
    this.setup()
  },

  methods: {
    ...mapSectionGetters(["getPrimarySections"]),
    ...mapAssessmentGetters(["getAssessmentChoices", "getAvailableAssessmentCategories"]),
    ...mapAssessmentActions(['storeAssessmentData', 'resetAssessmentState']),

    async setup() {
      window.scrollTo(0, 0)
      this.setHeaders()
      if (this.user.role === "Horizon Admin") {
        this.schoolId = this.id
        this.getSections()
        await this.getSchool()
      } else if (this.user.role === "Teacher Admin") {
        await this.getSections()
      } else {
        this.primarySections = this.getPrimarySections()
      }

      this.loadAssessments()
    },

    async getSections() {
      try {
        const api = this.user.role === 'Teacher Admin' ? StaffSchoolApi : AdminSchoolApi
        const response = await api.getAllClassesMinimal({
          schoolId: this.id
        })
        this.primarySections = response.sections
      } catch {
        this.addNotification('error', 'Failed to load classes of this school.')
      }
    },

    setHeaders() {
      this.headers = [
        {
          text: 'Assessment Name',
          value: 'assessmentName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Assessment Section',
          value: 'formattedCategoryName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Class Name',
          value: 'formattedClassName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Assigned By',
          value: 'createdBy',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Start Time',
          value: 'formattedStartAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'End Time',
          value: 'formattedEndAt',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Reveal Answers',
          value: 'formattedRevealAt',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'action',
          align: 'left',
          sortable: false,
          width: 180,
        }
      ]

      if (this.sectionId) {
        // remove 'Class Name' column if we're viewing a class
        this.headers.splice(2, 1)
      }

      if (this.fromStudentsDialog) {
        this.headers.splice(2, 1)
        this.headers[4] = {
          text: 'Status',
          value: 'status',
          align: 'left',
          sortable: true,
        }
      }

    },

    async getSchool() {
      let response = await AdminSchoolApi.get(this.$route.params.id)
      this.school = response.school
    },

    async loadAssessments() {
      try {
        this.assessmentsLoading = true

        if (this.fromStudentsDialog) {
          const response = await StaffUserApi.getAssignedAssessments(this.studentId)
          console.log(response)
          // Code to map response with the assessments for students
          //
          return
        }

        let api = StaffAssessmentApi

        if (this.user.role === "Horizon Admin") {
          this.resetAssessmentState()
          const assessmentData = await AdminAssessmentApi.supportedAssessments({schoolId: this.$route.params.id})
          this.storeAssessmentData(assessmentData)

          api = AdminAssessmentApi
        }

        if (this.sectionId == null) {
          const response = await api.schedule({schoolId: this.schoolId})
          this.assessments = response.assessments.map(sectionAssessment => SectionAssessmentFactory.createFromScheduleJson(sectionAssessment))
          this.activeAssessments = this.assessments.filter(a => a.status == "active")
          this.upcomingAssessments = this.assessments.filter(a => a.status == "upcoming")
          this.pastAssessments = this.assessments.filter(a => a.status == "past")
        } else {
          const response = await api.list(this.sectionId)
          this.assessments = response.sectionAssessments.map(sectionAssessment => SectionAssessmentFactory.createFromJson(sectionAssessment))
          this.activeAssessments = this.assessments.filter(a => a.status == "active")
          this.upcomingAssessments = this.assessments.filter(a => a.status == "upcoming")
          this.pastAssessments = this.assessments.filter(a => a.status == "past")
        }
      } catch {
        this.addNotification('error', 'There was a problem getting the assessments')
      } finally {
        this.assessmentsLoading = false
      }
    },

    handleAction(action, assessment) {
      switch (action) {
        case 'view':
          this.$router.push({
            name: "StaffAssessmentMonitoring",
            params: {
              sectionId: assessment.sections[0].id,
              assessmentSetId: assessment.assessmentSet.id,
              assessmentTypeId: assessment.assessmentType.id
            },
            props: true
          })
          break
        case 'edit':
          if (assessment.assessmentType.assessmentType == "SAT") {
            this.assessmentDialogComponent = DialogAssessmentGroupAssign
          } else {
            this.assessmentDialogComponent = DialogAssessmentAssign
          }
          this.onEditDialog(assessment)
          break
        case 'delete':
          if (assessment.assessmentType.assessmentType == "SAT") {
            this.deleteDialogConfirmationMessage = "Deleting this will also delete related assessments. Do you want to proceed?"
          } else {
            this.deleteDialogConfirmationMessage = null
          }
          this.onDeleteDialog(assessment)
          break
        default:
          break
      }
    },

    onNewAssessment() {
      if (this.user.role === 'Horizon Admin') {
        this.$router.push({name: 'AdminAssessmentMultibuilder'})
        return
      }

      this.$router.push({name: 'StaffAssessmentMultibuilder'})
    },

    async onEditDialog(assessment) {
      if (assessment.isMultipart) {
        // fetch group details
        if (this.user.role === "Horizon Admin") {
          let groupResponse = await AdminAssessmentApi.getAssessmentGroup(assessment)
          assessment = SectionAssessmentGroupFactory.createFromJson(groupResponse.sectionAssessmentGroup)
        } else {
          let groupResponse = await StaffAssessmentApi.getAssessmentGroup(assessment)
          assessment = SectionAssessmentGroupFactory.createFromJson(groupResponse.sectionAssessmentGroup)
        }
      } else {
        if (this.user.role === "Horizon Admin") {
          let assessmentResponse = await AdminAssessmentApi.get(assessment)
          assessment = SectionAssessmentFactory.createFromJson(assessmentResponse.classAssessment)
        } else {
          let assessmentResponse = await StaffAssessmentApi.get(assessment)
          assessment = SectionAssessmentFactory.createFromJson(assessmentResponse.classAssessment)
        }

        console.log(assessment)
      }

      this.assessmentProp = assessment
      this.dialogTitle = this.classSpecific ? 'Edit Assign Assessment' : 'Edit Bulk Assign Assessment'
      this.dialogType = 'edit'
      this.dialogAssessment = true
    },

    onDeleteDialog(assessment) {
      this.assessmentProp = assessment
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteConfirmation = true

      })
    },

    async onDeleteAssessment() {
      try {
        let api = StaffAssessmentApi
        if (this.user.role === "Horizon Admin") {
          api = AdminAssessmentApi
        }

        if (this.assessmentProp.assessmentType.assessmentType == "SAT") {
          await api.deleteSat(this.assessmentProp)
        } else {
          await api.delete(this.assessmentProp)
        }

        this.dialogDeleteConfirmation = false
        this.loadAssessments()
        this.addNotification('success', "Successfully deleted assessment!")

      } catch (e) {
        this.addNotification('error', "The assessment could not be deleted. Please try again.")
      }
    },

    onCloseDialogAssessments() {
      this.dialogAssessment = false
    },

    async onSubmitAssessment(assessment) {
      try {
        let api = StaffAssessmentApi
        if (this.user.role === "Horizon Admin") {
          api = AdminAssessmentApi
        }

        if (this.assessmentProp.assessmentType.assessmentType == "SAT") {
          await api.updateSat(assessment)
        } else {
          await api.update(assessment)
        }

        this.dialogAssessment = false
        this.addNotification('success', 'Assessment created successfully')
        this.loadAssessments()
      } catch {
        this.addNotification('error', "The assessment could not be created. Please check if this kind of assessment already exists.")
      }
    },

    canEditAssessment(assessment) {
      if (this.user.role === "Horizon Admin" || this.user.role === "Teacher Admin" || this.user.role === "District Admin") {
        return true
      } else if (this.user.role === 'Teacher' && this.user.school?.schoolAdminOnly) {
        return false
      } else {
        return this.primarySections.filter(s => assessment.sections[0].id == s.id).length > 0
      }
    },

    canAssignAssessment() {
      if (this.user.role === 'Teacher' && this.user.school?.schoolAdminOnly) {
        return false
      }

      if (this.sectionId == null) {
        return true
      }
      if (this.user.role === "Horizon Admin" || this.user.role === 'Teacher Admin' || this.user.role === "District Admin") {
        return true
      }
      return !!this.primarySections.find(s => s.id == this.sectionId)
    },

    canMonitorAssessment() {
      return !this.$route.meta.fromSchoolDashboard && (this.user.role === "Teacher" || this.user.role === "Teacher Admin" || this.user.role === "District Admin")
    },

    getTableHeight(array) {
      if (array.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },
  },
}
</script>

<style lang="scss" scoped>

</style>