<template>
  <v-dialog
    :value="dialog"
    max-width="400px"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-form>
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="firstNameAdminDialog"
              >
                First Name
              </label>
              <v-text-field
                id="firstNameAdminDialog"
                v-model="userData.firstName"
                :hide-details="!$v.userData.firstName.$error"
                error-messages="Please enter a valid first name"
                solo
                flat
                class="input-default input-default__small mt-1"
              >
                <template #prepend-inner>
                  <div style="width:24px">
                    <v-img
                      height="20"
                      width="20"
                      :src="require('@/assets/icons/icon-portrait.svg')"
                    />
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="lastNameAdminDialog"
              >
                Last Name
              </label>
              <v-text-field
                id="lastNameAdminDialog"
                v-model="userData.lastName"
                :hide-details="!$v.userData.lastName.$error"
                error-messages="Please enter a valid last name"
                solo
                flat
                class="input-default input-default__small mt-1"
              >
                <template #prepend-inner>
                  <div style="width:24px">
                    <v-img
                      height="20"
                      width="20"
                      :src="require('@/assets/icons/icon-portrait.svg')"
                    />
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="emailAdminDialog"
              >
                Email Address
              </label>
              <v-text-field
                id="emailAdminDialog"
                v-model="userData.email"
                :hide-details="!$v.userData.email.$error"
                error-messages="Please enter a valid email"
                solo
                flat
                class="input-default input-default__small mt-1"
              >
                <template #prepend-inner>
                  <div style="width:24px">
                    <v-img
                      height="20"
                      width="20"
                      :src="require('@/assets/icons/icon-email.svg')"
                    />
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col
              v-if="showUserType()"
              cols="12"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="userTypeAdminDialog"
              >
                User Type
              </label>
              <v-select
                id="userTypeAdminDialog"
                v-model="userData.role"
                dense
                flat
                class="input-default input-default__small"
                hide-details
                :items="teacherTypes"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
              class="pr-sm-2"
            >
              <v-btn
                class="btn-default btn-default__secondary btn-default__small"
                block
                @click="onCloseDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pl-sm-2"
            >
              <v-btn
                dark
                class="btn-default btn-default__primary btn-default__small"
                block
                @click="onSubmitForm()"
              >
                <span v-if="type === 'new'">Send Invite</span>
                <span v-else>Update {{ buttonText }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="$v.$error"
            dense
            justify="end"
          >
            <v-col cols="auto">
              <h6 class="color-danger">
                Please check your inputs
              </h6>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import functions from "@/api/shared/functions";
import {validationMixin} from "vuelidate/src";
import {email, required} from "vuelidate/lib/validators";
import {
  AdminSchoolApi, AdminStudentApi,
  AdminUserApi,
  StaffSchoolApi,
  StaffUserApi
} from "@/api";

export default {
  name: "DialogAdminForm",
  components: {AppHeader},
  mixins: [validationMixin],

  props: {
    active: Boolean,
    fromUserSearch: Boolean,
    fromUploadBulk: Boolean,
    fromSettings: Boolean,
    userProp: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      default: 'new',
      type: String
    },
    title: {
      default: '',
      type: String,
      required: true,
    },
    userType: {
      type: String,
      default: '',
      required: true,
    },
    role: {
      type: String,
      default: '',
    },
    schoolId: {
      type: [Number, String],
      default: null
    },
    studentId: {
      type: Number,
      default: null
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      buttonText: '',
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      },
      teacherTypes: [
        'Teacher',
        'Teacher Admin',
      ]
    }
  },


  validations: {
    userData: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.cleanUserForm()
        if (this.type === 'edit') {
          this.userData = functions.deepCopySync(this.userProp)
          if (this.userType === 'Administrator') {
            this.buttonText = 'Admin'
          } else {
            this.buttonText = this.userType
          }
        }
        this.dialog = value
      } else {
        this.dialog = value
      }

    }
  },

  methods: {
    ...mapGetters(['userRole']),

    async onSubmitForm() {
      try {
        this.loading = true
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        if (this.fromUploadBulk) {
          this.$emit('closeDialogUpdate', this.userData)
          return
        }

        if (this.userType === 'Parent') {
          await this.onEditUserAsParent()
        } else if (this.userRole() === 'Horizon Admin') {
          await this.sendUserAsAdmin()
        } else {
          await this.sendUserAsTeacher()
        }
        this.$emit('closeDialogUpdate')
      } catch (error) {
        const errorData = error.response?.data
        if (typeof errorData === 'object' && errorData !== null) {
          for (let key in errorData) {
            this.addNotification('error', errorData[key][0])
          }
        } else {
          this.addNotification('error', 'Failed to create/edit the user. Please try again.')
        }
      } finally {
        this.loading = false
      }
    },

    async sendUserAsAdmin() {
      if (this.type === 'new') {
        this.userData.role = this.role
        if (this.role === 'Horizon Admin') {
          await AdminUserApi.addNewUser(this.userData)
        } else {
          await AdminSchoolApi.createTeacher(this.schoolId, this.userData)
        }
        this.addNotification('success', 'Teacher created successfully')
      } else {
        await AdminUserApi.updateUser(this.userData.id, this.userData)
        this.addNotification('success', 'Teacher edited successfully')
      }
    },

    async sendUserAsTeacher() {
      if (this.type === 'new') {
        this.userData.role = this.role
        await StaffSchoolApi.createTeacher(this.schoolId, this.userData)
        this.addNotification('success', 'Teacher created successfully')
      } else {
        await StaffUserApi.updateUser(this.userData.id, this.userData)
        this.addNotification('success', 'Teacher edited successfully')
      }
    },

    async onEditUserAsParent() {
      await AdminStudentApi.updateParent(this.studentId, this.userData.id, this.userData)
      this.addNotification('success', 'Parent edited successfully')
    },

    showUserType() {
      return this.type === 'edit' && !this.fromUserSearch && !this.fromUploadBulk && !this.fromSettings && this.userType !== 'Parent'
    },

    cleanUserForm() {
      this.$v.$reset()
      this.userData = {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      }
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },
  }
}
</script>

<style scoped>

</style>