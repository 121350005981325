import HorizonApi from '@/api/horizon_api'

const SCHOOL_PATH = `/api/district_admin/schools`

export default {
  list (page = 1, perPage, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    };
    return HorizonApi.get(SCHOOL_PATH, {params: params})
  },
}