<template>
  <div class="notifications-list">
    <notification-message
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('app_config')
import NotificationMessage from "./NotificationMessage"
export default {
  name: "NotificationsList",
  components: {
    NotificationMessage
  },

  computed: mapState(["notifications"])

}
</script>

<style scoped lang="scss">
.notifications-list {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  width: 320px;
  z-index: 9999;
}
</style>