import ParentDashboard from "@/components/parent/ParentDashboard.vue";
import ParentScoreReports from "@/components/parent/ParentScoreReports.vue";
import ParentReportComposite from "@/components/parent/ParentReportComposite.vue";

export default [
    {
        path: '/parent/students',
        name: 'ParentDashboard',
        component: ParentDashboard,
        meta: {
            breadcrumb: [
                {text: 'Students'}
            ],
            roles: ['Parent']
        },
    },
    {
        path: '/parent/:studentId/score-reports',
        name: 'ParentStudentReports',
        component: ParentScoreReports,
        props: true,
        meta: {
            breadcrumb() {
                const studentName = this.$route.params.studentName;
                return [
                    {
                        text: 'Students',
                        to: {name: 'ParentDashboard'}
                    },
                    {
                        text: studentName,
                    }

                ]
            },
            roles: ['Parent']
        },
    },

    {
        path: '/parent/:studentId/score-reports/:assessmentTypeId/:assessmentSetId',
        name: 'ParentStudentReportComposite',
        component: ParentReportComposite,
        props: route => ({ ...route.query, ...route.params }),
        meta: {
            breadcrumb() {
                const studentName = this.$route.params.studentName;
                return [
                    {
                        text: 'Students',
                        to: {name: 'ParentDashboard'}
                    },
                    {
                        text: studentName,
                    }

                ]
            },
            roles: ['Parent']
        },
    },
]