export default ({
    //   New portal functions:
    deepCopySync: function (dataObj) {
        try {
            let clone

            // primitive types
            if (
                typeof dataObj === 'string' ||
                typeof dataObj === 'number' ||
                typeof dataObj === 'boolean' ||
                typeof dataObj === 'undefined' ||
                dataObj === null
            ) {
                return dataObj
            }

            // date
            if (dataObj instanceof Date) {
                return new Date(dataObj.getTime())
            }

            // object or array
            if (typeof dataObj === 'object') {
                if (Array.isArray(dataObj)) {
                    clone = []
                } else {
                    clone = {}
                }

                const objKeys = Object.keys(dataObj)

                for (let i = 0; i < objKeys.length; i++) {
                    const element = objKeys[i]
                    clone[element] = this.deepCopySync(dataObj[element])
                }

                return clone
            }

            throw new Error('invalid type')
        } catch (err) {
            console.log(err)
            throw err
        }
    },
})