<template>
  <div class="assessment-check-box assessment-check-box__small">
    <input
      type="radio"
      :value="inputValue"
      :name="radioName"
      @change="updateValue(inputValue)"
    >
    <span class="box-title">{{ title }}</span>
    <div
      v-if="isSelected"
      class="checkbox-icon-container"
    >
      <v-icon
        size="20"
        color="white"
      >
        mdi-check
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "AssessmentRadioBoxSmall",

  model: {
    event: "change",
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    inputValue: {
      type: [Number, String, Object, Boolean],
      default: ''
    },
    value: {
      type: [Number, String, Object, Boolean],
      default() {
        return {}
      }
    },
    radioName: {
      type: String,
      default: ''
    },
  },

  computed: {
    isSelected(){
      if(typeof this.inputValue === 'boolean'){
        return this.inputValue === this.value
      }

      return this.value?.type?.id === this.inputValue?.type?.id && this.value?.set?.id === this.inputValue.set?.id;
    }
  },

  methods: {
    updateValue(value) {
      this.$emit("change", value);
    },
  },
}
</script>

<style scoped>
</style>