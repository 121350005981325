<template>
  <v-container fluid>
    <app-header
      title="Exports"
    />

    <v-data-table
      :height="getTableHeight()"
      item-key="id"
      :options.sync="tableOptions"
      :server-items-length="totalExports"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="reports"
      :loading="tableLoading ? '#3d5d74' : false"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[itemsPerPage],
        disableItemsPerPage: true
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template
        #[`item.assessment`]="{ item }"
      >
        {{ item.assessmentType.course_type.toUpperCase() }} {{ item.assessmentSet.name }}
      </template>

      <template
        #[`item.processType`]="{ item }"
      >
        {{ formatType(item.processType) }}
      </template>

      <template
        #[`item.createdAt`]="{ item }"
      >
        {{ formattedDate(item.createdAt) }}
      </template>

      <template
        #[`item.expiresIn`]="{ item }"
      >
        {{ item.expiresIn !== null ? `${item.expiresIn}` : '-' }}
      </template>

      <template
        #[`item.status`]="{ item }"
      >
        {{ formatStatus(item.status) }}
      </template>

      <template
        #[`item.actions`]="{ item }"
      >
        <td class="text-center">
          <v-btn
            v-if="item.status === 'delivered'"
            class="btn-table btn-table__text"
            outlined
            width="100"
            :loading="generatingReport"
            @click="downloadReport(item)"
          >
            Download
          </v-btn>
          <v-btn
            v-if="item.status === 'failed'"
            class="btn-table btn-table__text"
            width="100"
            outlined
            :loading="generatingReport"
            @click="onRetryReport(item)"
          >
            Retry
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from 'moment';

import AppHeader from "@/components/shared/AppHeader.vue";
import {StaffBatchPdfExportApi} from '@/api'

export default {
  components: {AppHeader},
  data() {
    return {
      reports: [],
      tableLoading: false,
      generatingReport: false,
      tableLoadedOnce: false,
      totalExports: 0,
      tableOptions: {},
      itemsPerPage: 100,
      tableHeight: 'calc(90vh - 250px)',
      headers: [
        {
          text: 'Group',
          value: 'group',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Assessment',
          value: 'assessment',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'processType',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Reports',
          value: 'total',
          align: 'left',
          width: 20,
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Requested On',
          value: 'createdAt',
          align: 'center',
          sortable: true,
        },
        {
          text: 'Expires In',
          value: 'expiresIn',
          align: 'center',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 120,
        }
      ]
    }
  },

  watch: {
    tableOptions: {
      handler() {
        if (this.tableLoadedOnce) {
          this.loadReports()
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.loadReports()
  },

  methods: {
    async loadReports() {
      try {
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        const response = await StaffBatchPdfExportApi.list(page, this.itemsPerPage, sortBy[0], order,)
        this.totalExports = response.meta?.totalCount
        this.reports = response.batchProcessJobs
      } catch {
        this.addNotification('error', 'There was a problem getting reports')
      } finally {
        this.tableLoadedOnce = true
        this.tableLoading = false
      }
    },

    formatType(type) {
      if (type === 'XLS_ASSESSMENTS') {
        return 'Score Report'
      }

      if (type === 'XLS_RESPONSE') {
        return 'Response'
      }

      return 'PDF'
    },

    async downloadReport(item) {
      try {
        const response = await StaffBatchPdfExportApi.download(item.id)
        window.open(response.download_url, "_blank");
      } catch {
        this.addNotification('error', 'There was a problem downloading this report')
      }
    },

    async onRetryReport(item) {
      try {
        this.generatingReport = true
        await StaffBatchPdfExportApi.retryExport(item.id)
        this.addNotification('info', 'The report is being generated again. You will be emailed a link to download the report once it is complete.')
        this.tableLoading = true
        this.reports = []
        setTimeout(() => {
          this.loadReports()
        }, 1000)
      } catch {
        this.addNotification('error', 'Something went wrong. Please try again.')
      } finally {
        this.generatingReport = false
      }
    },

    formattedDate(date) {
      return moment(date).format('lll')
    },

    formatStatus(status) {
      if (status === 'delivered') {
        return 'Ready'
      }

      if (status === 'failed') {
        return 'Failed'
      }

      return 'Processing'
    },

    getTableHeight () {
      if (this.reports.length < 6) return undefined

      if(this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 700) return '400px'

      return this.tableHeight
    },

  }
}
</script>