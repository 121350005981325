<template>
  <v-container>
    <div v-if="reportLoading">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <div v-else-if="compositeReport?.studentAssessments?.length > 0">
      <app-header
        :title="studentName"
      />
      <student-report-composite
        :composite-report-prop="compositeReport"
        @downloadPdf="downloadPdf()"
      />
    </div>
    <div
      v-else
      class="pt-4"
    >
      <h4>This report does not exist, or the student did not complete both parts of the assessment section.</h4>
    </div>
  </v-container>
</template>


<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import StudentReportComposite from "@/components/student/reports/StudentReportComposite.vue";

import {StaffStudentAssessmentApi} from "@/api"

export default {
  components: {
    StudentReportComposite,
    AppHeader
  },

  props: {
    studentId: {
      type: [Number, String],
      required: true
    },

    sectionId: {
      type: [Number, String],
      required: false,
      default: null
    },

    assessmentSetId: {
      type: [Number, String],
      required: true
    },

    assessmentTypeId: {
      type: [Number, String],
      required: true
    },

    year: {
      type: [Number, String],
      required: false,
      default: null
    }
  },

  data() {
    return {
      reportLoading: true,
      compositeReport: null,
      questionsAndAnswers: null,
      studentName: ''
    }
  },

  mounted() {
    this.loadReport()
  },

  methods: {
    async loadReport() {
      this.reportLoading = true

      try {
        const response = await StaffStudentAssessmentApi.getCompositeReport({
          studentId: this.studentId,
          sectionId: this.sectionId,
          assessmentSetId: this.assessmentSetId,
          assessmentTypeId: this.assessmentTypeId,
          schoolYearId: this.year
        })
        this.compositeReport = response.compositeReport
        this.studentName = `${this.compositeReport.user.firstName} ${this.compositeReport.user.lastName}`
      } catch (e) {
        this.addNotification('error', "The report could not be loaded.")
      }

      this.reportLoading = false

    },

    downloadPdf() {
      this.addNotification('info', 'Generating PDF report...')
      StaffStudentAssessmentApi.downloadPdf({
        studentId: this.studentId,
        sectionId: this.sectionId,
        assessmentSetId: this.assessmentSetId,
        assessmentTypeId: this.assessmentTypeId,
        schoolYearId: this.year
      })
          .then(({filename, blob}) => {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click();
          }, (responseError) => {
            this.addNotification('error', responseError)
          })
    }
  }
}
</script>