import store from '@/store'
import SectionAssessment from '@/models/section_assessment'
import AssessmentAssignmentSettings from '@/models/assessment_assignment_settings'

export default {
  createFromScheduleJson(json) {
    let sa = new SectionAssessment()

    sa.id = json.id
    sa.startAt = new Date(json.start_at)
    sa.createdBy = json.created_by
    sa.endAt = json.end_at == null ? null : new Date(json.end_at)
    sa.revealAt = json.reveal_assessments_at == null ? null : new Date(json.reveal_assessments_at)
    sa.assessmentType = store.state.assessments.assessmentTypes.find(a => a.courseType == json.course_type)
    sa.assessmentSet = store.state.assessments.assessmentSets.find(a => a.name == json.assessment_set)
    sa.category = store.state.assessments.assessmentCategories.find(a => a.name == json.assessment_section)
    sa.assessment = store.state.assessments.assessments.find(a => a.assessmentTypeId == sa.assessmentType?.id && a.assessmentSetId == sa.assessmentSet?.id && a.categoryId == sa.category?.id)

    if (json.section_assessment_group !== null) {
      sa.assessmentPart = json.assessment_part
      sa.sectionAssessmentGroupId = json.section_assessment_group_id
    }

    let section = store.state.staffSections.allSections.find(a => a.id == json.class_id)
    if (section) {
      sa.sections = [section]
    } else {
      sa.sections = [ {id: json.class_id, displayName: json.class_name } ]
    }

    return sa
  },

  createFromJson(json) {
    let sa = new SectionAssessment()

    sa.id = json.id
    sa.startAt = new Date(json.startAt)
    sa.endAt = json.endAt != null ? new Date(json.endAt) : null
    sa.revealAt = json.revealAssessmentsAt != null ? new Date(json.revealAssessmentsAt) : null
    sa.assessment = store.state.assessments.assessments.find(a => a.id == json.assessment?.id)
    sa.category = store.state.assessments.assessmentCategories.find(a => a.id == sa.assessment?.categoryId)
    sa.assessmentSet = store.state.assessments.assessmentSets.find(a => a.id == sa.assessment?.assessmentSetId)
    sa.assessmentType = store.state.assessments.assessmentTypes.find(a => a.id == sa.assessment?.assessmentTypeId)
    
    if (json.sectionAssessmentGroup !== null) {
      sa.assessmentPart = sa.assessment?.difficulty == "default" ? "Part 1" : "Part 2"
      sa.sectionAssessmentGroupId = json.sectionAssessmentGroup?.id
    }

    let section = store.state.staffSections.allSections.find(a => a.id == json.section?.id)
    if (section) {
      sa.sections = [section]
    } else {
      sa.sections = [ {id: json.section?.id, displayName: json.section?.name } ]
    }

    let settings = json.settings
    sa.settings = AssessmentAssignmentSettings.fromJson(settings)
    
    return sa
  }
}