import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
import LoginView from '../views/LoginView.vue'
import AdminRoutes from './routes/admin.js'
import DistrictAdminRoutes from './routes/district_admin.js'
import StaffRoutes from './routes/staff.js'
import StudentRoutes from './routes/student.js'
import ParentRoutes from './routes/parent.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../components/shared/ForgotPassword.vue')
    },
    {
        path: '/account/:token/activate',
        name: 'AccountActivation',
        props: true,
        component: () => import('../components/shared/AccountActivation.vue')
    },
    {
        path: '/update_password/:token',
        name: 'UpdatePassword',
        component: () => import('../components/shared/UpdatePassword.vue')
    },
    {
        path: '/activate',
        name: 'ParentAccountActivation',
        component: () => import('../components/shared/ParentAccountActivation.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    ...AdminRoutes,
    ...DistrictAdminRoutes,
    ...StaffRoutes,
    ...StudentRoutes,
    ...ParentRoutes
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.query.url || to.query.state || to.query.code) {
        next(to.query.url)
    } else if ( !Store.getters['user/userAuthenticated'] && (to.name === 'ForgotPassword' || to.name === 'UpdatePassword' || to.name === "AccountActivation" || to.name === "ParentAccountActivation")) {
        next()
    } else if (!Store.getters['user/userAuthenticated']) {
        if (to.fullPath !== '/') {
            next({path: '/'})
        } else {
            next()
        }
    } else {
        if ((to.fullPath == '/' && from.fullPath !== '/') || to.name === 'login') {
            next({path: Store.getters['app_config/dashboard']})
        } else {
            if (to.meta.roles && to.meta.roles.includes(Store.getters['user/userRole'])) {
                next()
            } else {
                console.log('This route is unreachable, you should not be here')
            }
        }
    }
})

export default router
