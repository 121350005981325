<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-container
      fluid
      class="pt-4 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
    >
      <v-form>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pr-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="firstNameStudentDialog"
            >
              First Name
            </label>
            <v-text-field
              id="firstNameStudentDialog"
              v-model="studentData.firstName"
              :hide-details="!$v.studentData.firstName.$error"
              error-messages="Please enter a valid first name"
              solo
              flat
              class="input-default input-default__small mt-1"
            >
              <template #prepend-inner>
                <div style="width:24px">
                  <v-img
                    height="20"
                    width="20"
                    :src="require('@/assets/icons/icon-portrait.svg')"
                  />
                </div>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pl-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="lastNameStudentDialog"
            >
              Last Name
            </label>
            <v-text-field
              id="lastNameStudentDialog"
              v-model="studentData.lastName"
              :hide-details="!$v.studentData.lastName.$error"
              error-messages="Please enter a valid last name"
              solo
              flat
              class="input-default input-default__small mt-1"
            >
              <template #prepend-inner>
                <div style="width:24px">
                  <v-img
                    height="20"
                    width="20"
                    :src="require('@/assets/icons/icon-portrait.svg')"
                  />
                </div>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pr-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="emailStudentDialog"
            >
              Email Address
            </label>
            <v-text-field
              id="emailStudentDialog"
              v-model="studentData.email"
              :hide-details="!$v.studentData.email.$error"
              error-messages="Please enter a valid email"
              solo
              flat
              class="input-default input-default__small mt-1"
            >
              <template #prepend-inner>
                <div style="width:24px">
                  <v-img
                    height="20"
                    width="20"
                    :src="require('@/assets/icons/icon-email.svg')"
                  />
                </div>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pl-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="studentIdStudentDialog"
            >
              Student ID
            </label>
            <v-text-field
              id="studentIdStudentDialog"
              v-model="studentData.studentIdNumber"
              :hide-details="!$v.studentData.studentIdNumber.$error"
              error-messages="ID is required and must consist only of numbers"
              solo
              flat
              class="input-default input-default__small mt-1"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pr-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="gradeLevelStudentDialog"
            >
              Grade Level
            </label>
            <v-select
              id="gradeLevelStudentDialog"
              v-model="studentData.gradeLevel"
              item-value="value"
              item-text="name"
              dense
              flat
              class="input-default input-default__small"
              hide-details
              :items="gradeLevelArray"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pt-0 pl-sm-6"
          >
            <label
              class="label-default label-default__small"
              for="accommodationsStudentDialog"
            >
              Accommodations
            </label>
            <v-switch
              id="accommodationsStudentDialog"
              v-model="studentData.untimedAssessment"
              label="Untimed Assessments"
              color="#496e88"
              class="switch-form switch-form__dialog ml-1"
              inset
              hide-details
            />
          </v-col>
        </v-row>
        <v-row
          v-if="type !== 'view'"
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onSubmitForm()"
            >
              <span v-if="type=== 'new'">Send Invite</span>
              <span v-else>Update Student</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="$v.$error"
          dense
          justify="end"
        >
          <v-col cols="auto">
            <h6 class="color-danger">
              Please check your inputs
            </h6>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
import {validationMixin} from "vuelidate/src";
import {email, numeric, required} from "vuelidate/lib/validators";
import GradeLevels from "@/api/shared/gradeLevels";
import functions from "@/api/shared/functions";
import {AdminStudentApi, AdminUserApi, StaffUserApi} from "@/api";

export default {
  name: "StudentForm",
  mixins: [validationMixin],
  props: {
    active: Boolean,
    fromUploadBulk: Boolean,
    fromClass: Boolean,
    type: {
      type: String,
      default: 'new',
    },
    studentProp: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    schoolId: {
      type: [Number, String],
      default: null
    },
    sectionId: {
      type: [Number, String],
      default: null
    },
  },

  data() {
    return {
      dialog: false,
      gradeLevelArray: [],
      loading: false,
      studentData: {
        firstName: '',
        lastName: '',
        email: '',
        studentIdNumber: '',
        gradeLevel: '',
        untimedAssessment: false,
      },
    }
  },

  validations: {
    studentData: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      studentIdNumber: {required, numeric},
    }
  },

  mounted() {
    this.cleanStudentForm()
    this.gradeLevelArray = GradeLevels.values()
    if (this.type === 'edit' || this.type === 'view') {
      this.studentData = functions.deepCopySync(this.studentProp)
      this.studentData.gradeLevel = Number(this.studentData.gradeLevel)
    } else {
      this.studentData.gradeLevel = 8
    }
  },

  methods: {
    ...mapGetters(["userRole"]),

    async onSubmitForm() {
      try {
        this.loading = true
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        if (this.fromUploadBulk) {
          this.$emit('closeDialogUpdate', this.studentData)
          return
        }

        this.studentData.role = 'Student'
        if (this.userRole() === 'Horizon Admin') {
          await this.sendStudentAsAdmin()
        } else {
          await this.sendStudentAsTeacher()
        }

        this.$emit('closeDialogUpdate')
      } catch (error) {
        const errorData = error.response?.data
        if (typeof errorData === 'object' && errorData !== null) {
          for (let key in errorData) {
            this.addNotification('error', errorData[key][0])
          }
        } else {
          this.addNotification('error', 'Failed to create/edit the user. Please try again.')
        }
      } finally {
        this.loading = false
      }
    },

    async sendStudentAsAdmin() {
      if (this.type === 'new') {
        this.studentData.schoolId = this.schoolId
        await AdminStudentApi.addNew(this.studentData)
        this.addNotification('success', 'Student created successfully')
      } else {
        await AdminUserApi.updateUser(this.studentData.id, this.studentData)
        this.addNotification('success', 'Student edited successfully')
      }
    },

    async sendStudentAsTeacher() {
      if (this.type === 'new') {
        this.studentData.schoolId = this.schoolId
        await StaffUserApi.createUser(this.studentData)
        this.addNotification('success', 'Student created successfully')
      } else {
        await StaffUserApi.updateUser(this.studentData.id, this.studentData)
        this.addNotification('success', 'Student edited successfully')
      }
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    cleanStudentForm() {
      this.$v.$reset()
      this.studentData = {
        firstName: '',
        lastName: '',
        email: '',
        studentIdNumber: '',
        gradeLevel: '',
        untimedAssessment: false,
      }
    },

  }
}
</script>