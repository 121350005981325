<template>
  <v-alert
    :type="notification.type"
    :icon="`$${notification.type}`"
    class="cursor-pointer"
    @click="onRemoveNotification()"
  >
    {{ notification.message }}
  </v-alert>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
const {mapActions} = createNamespacedHelpers('app_config')

export default {
  name: "NotificationMessage",
  props: {
    notification: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      timeout: null
    };
  },

  created() {
    this.timeout = setTimeout(() => {
      this.onRemoveNotification()
    }, this.notification.timeOnScreen)
  },

  beforeDestroy() {
    clearTimeout(this.timeout)
  },

  methods: {
    ...mapActions(['removeNotification']),

    onRemoveNotification(){
      this.removeNotification(this.notification)
    }
  }
}
</script>