<template>
  <v-container fluid>
    <app-header
      title="Score Reports"
    />
    <div v-if="loadingReports">
      <v-skeleton-loader
        type="list-item-avatar@2"
      />
    </div>
    <div v-else-if="scoreReports.length > 0">
      <assessment-section-list-item
        v-for="(report, i) in scoreReports"
        :key="i"
        :report="report"
        @goto-report="loadReport(report)"
      />
    </div>
    <div v-else>
      No data.
    </div>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
const {mapActions} = createNamespacedHelpers('app_config')
import AppHeader from "@/components/shared/AppHeader.vue";
import AssessmentSectionListItem from "@/components/student/widgets/ScoreReportListItem.vue";
import {ParentStudentsApi} from "@/api";

export default {
  name: "ParentScoreReports",
  components: {AssessmentSectionListItem, AppHeader},
  props: {
    studentId: {
      type: [Number, String],
      default: null
    },
    studentName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      scoreReports: [],
      loadingReports: false,
      studentNameTemp: ''
    }
  },

  mounted() {
    this.updateMenu({role: this.user().role, data: this.user(), showParentReportsNav: true})
    this.loadScoreReports()
  },

  methods: {
    ...mapGetters(["user"]),
    ...mapActions(['updateMenu',]),

    async loadScoreReports() {
      try {
        this.loadingReports = true
        const response = await ParentStudentsApi.getStudentsScoreReports(this.studentId)
        this.scoreReports = response.compositeReports
        if (this.studentName === '') {
          this.studentNameTemp = this.scoreReports[0].user.firstName + this.scoreReports[0].user.lastName
          this.updateStudentName()
        }
      } catch (e) {
        this.addNotification('error', "Sorry, we could not load the student reports.")
      } finally {
        this.loadingReports = false
      }
    },

    loadReport(assessment) {
      const route = {
        name: 'ParentStudentReportComposite',
        params: {
          assessmentTypeId: assessment.assessmentType.id,
          assessmentSetId: assessment.assessmentSet.id,
          studentId: assessment.user.id,
          studentName: this.studentName
        },
        query: {
          year: assessment.schoolYear.id
        }
      }

      this.$router.push(route);
    },

    async updateStudentName() {
      const studentIdTemp = this.studentId
      await this.$router.replace({params: {studentId: ' ', studentName: this.studentNameTemp}})
      await this.$router.replace({
        params: {studentId: studentIdTemp, studentName: this.studentNameTemp}
      })
    }

  }
}
</script>
