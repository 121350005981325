<template>
  <v-form
    ref="assessmentForm"
    v-model="formValid"
  >
    <v-container fluid>
      <app-header
        title="Assign Assessment"
      />

      <v-card
        flat
        :color="headersColor"
        class="type-container"
      >
        <h3>Assign by Grade Level or Classes?</h3>
        <div
          ref="assessmentTypeSelectionScroll"
          class="type-subheader font-weight-300"
        >
          Select whether you wish to assign the assessment section to entire grade levels of students or to specific
          classes at your school.
        </div>

        <div class="d-flex flex-wrap mt-3">
          <div v-if="assignByType === 1 || assignByType === 0">
            <assessment-radio-box-default
              v-model="assignByType"
              radio-name="assign-by-type"
              title="By Grade Level"
              :input-value="1"
              :has-logo="true"
              filename="logo-grade-level.png"
            />
          </div>
          <div v-if="assignByType === 2 || assignByType === 0">
            <assessment-radio-box-default
              v-model="assignByType"
              radio-name="assign-by-type"
              title="By Classes"
              :input-value="2"
              :has-logo="true"
              filename="logo-chair.png"
            />
          </div>

          <div
            v-if="assignByType !== 0"
            class="assign-type-options"
          >
            <div
              v-if="assignByType === 2"
              class="class-selection-container"
            >
              <span class="header">Which classes?</span>
              <v-autocomplete
                id="classes"
                v-model="sections"
                label="Select Classes"
                :menu-props="{ maxHeight: '200' }"
                dense
                multiple
                solo
                flat
                clearable
                class="input-default input-default__small input-default__height-unset mb-4"
                :return-object="true"
                item-value="id"
                item-text="displayName"
                :items="sectionList"
                :error-messages="!formValid && sections.length === 0 ? 'Please select a class!' : null"
                :hide-details="formValid || sections.length !== 0"
              />
            </div>
            <span class="header">Which grade level students?</span>
            <v-card
              flat
              outlined
              class="container-grade-levels"
            >
              <label
                for="selectAll"
                class="box-grade-level"
                :class="gradeIsChecked('all') ? 'box-grade-level__checked' : undefined"
              >
                <input
                  id="selectAll"
                  v-model="gradeLevelSelectAll"
                  type="checkbox"
                  @change="toggleAllCheckboxes()"
                >
                <span class="checkmark" />
                <span class="font-weight-500 font-size-14 ml-8">All Grade Levels</span>
              </label>
              <label
                v-for="(option, idx) in gradeLevelOptions"
                :key="idx"
                :for="option.name"
                class="box-grade-level"
                :class="gradeIsChecked(option.value) ? 'box-grade-level__checked' : undefined"
              >
                <input
                  :id="option.name"
                  v-model="selectedGradeLevels"
                  type="checkbox"
                  :value="option.value"
                  @change="updateCheckAll()"
                >
                <span class="checkmark" />
                <span class="font-weight-500 font-size-14 ml-8">{{ option.name }}</span>
              </label>
              <v-row
                v-if="!formValid && selectedGradeLevels.length === 0"
                dense
                justify="end"
              >
                <v-col cols="auto">
                  <span class="color-danger font-size-12">
                    Select at least one grade!
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-btn
              v-if="userRole() !== 'Teacher'"
              class="btn-default btn-default__secondary btn-default__smallest btn-default__white mt-1"
              @click="onBackToTypes()"
            >
              <v-icon size="16">
                mdi-arrow-left-thin
              </v-icon>
              Back
            </v-btn>
          </div>
        </div>
      </v-card>
      <div v-if="showAllOptions()">
        <app-header
          class="mt-5"
          title="Assessment Options"
          :is-secondary="true"
        />

        <!--Assessment Selection-->
        <v-row
          v-if="showAssessmentTypes"
          class="mt-2"
        >
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Which Assessment?
            </h5>
            <span class="options-subtitle font-weight-300">
              Select which assessment type you wish to assign.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="pt-0"
          >
            <v-row class="assessments-container" v-if="supportsAct">
              <div
                v-for="(type, idx) in getActAssessmentTypes()"
                :key="idx"
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <div class="assessments-title">
                    <span class="font-weight-600 font-size-16">Horizon Assessment
                      <br>
                      for the {{ type.courseType.toUpperCase() }}
                    </span>
                  </div>

                  <div class="radio-container">
                    <assessment-radio-box-small
                      v-for="(set, idx2) in getAvailableAssessmentSets()"
                      :key="idx2"
                      v-model="selectedAssessmentTypeSet"
                      radio-name="assessment-type"
                      :input-value="{ type: type, set: set }"
                      :title="set.name"
                    />
                  </div>
                </v-card>
              </div>
            </v-row>

            <v-row class="assessments-container" v-if="supportsSat">
              <div
                v-for="(type, idx) in getSatAssessmentTypes()"
                :key="idx"
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <div class="assessments-title">
                    <span class="font-weight-600 font-size-16">Horizon Assessment
                      <br>
                      for the {{ type.courseType.toUpperCase() }}
                    </span>
                  </div>

                  <div class="radio-container">
                    <assessment-radio-box-small
                      v-for="(set, idx2) in getAvailableAssessmentSets()"
                      :key="idx2"
                      v-model="selectedAssessmentTypeSet"
                      radio-name="assessment-type"
                      :input-value="{ type: type, set: set }"
                      :title="set.name"
                    />
                  </div>
                </v-card>
              </div>
            </v-row>

          </v-col>
        </v-row>

        <!--Section Selection-->
        <v-row v-if="showSectionList()">
          <v-col
            cols="12"
            class="pt-2 pb-5"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Which Sections?
            </h5>
            <span class="options-subtitle font-weight-300">
              Select which assessment sections you wish to assign. You may select one or multiple.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="d-flex flex-wrap pt-0"
          >
            <div
              v-for="category in assessmentCategoriesForSelection"
              :key="category.id"
            >
              <assessment-check-box-default
                v-model="selectedCategories"
                :input-value="category.id"
                :title="category.name"
                :has-logo="true"
                :has-subtitle-primary="true"
                :has-subtitle-secondary="true"
                :section-prop="getSectionDurationAndItems(category.id)"
                :filename="getSectionLogoFileName(category.name)"
                @inputChanged="testChange(category.id)"
              />
            </div>
          </v-col>
        </v-row>

        <!--Administration Type Selection-->
        <v-row v-if="showAdministrationTypeSelection()">
          <v-col
            cols="12"
            class="pt-2 pb-5"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Administration Type?
            </h5>
            <span class="options-subtitle font-weight-300">
              For a <span class="font-weight-500">Continuous Administration</span>, students are routed to Part 2 of the assessment section immediately after they complete Part 1.
            </span>
            <span class="options-subtitle font-weight-300">
              For a <span class="font-weight-500">Broken Administration</span>, set two start different dates and times for when each section will become available to students.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="d-flex flex-wrap pt-0"
          >
            <div>
              <assessment-radio-box-default
                v-model="selectedAdministrationType"
                radio-name="radio-group-administration-type"
                title="Continuous Administration"
                input-value="continuous"
                :has-logo="true"
                filename="logo-english.png"
              />
            </div>
            <div>
              <assessment-radio-box-default
                v-model="selectedAdministrationType"
                radio-name="radio-group-administration-type"
                title="Broken Administration"
                input-value="broken"
                :has-logo="true"
                filename="logo-pencil.png"
              />
            </div>
          </v-col>
        </v-row>

        <!--Assessment Time Options-->
        <v-row
          v-if="showOtherAssessmentOptions()"
          ref="assessmentTimeOptions"
        >
          <v-col
            cols="12"
            class="pt-0 pb-5"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Assessment Window
            </h5>
            <span class="options-subtitle font-weight-300">
              The assessment window open date/time is when the assessment section will be available for students to 
              access via their dashboards.
            </span>
            <span class="options-subtitle font-weight-300">
              The assessment window close date is an optional date/time in which the assessment section is no 
              longer available for students to access. If no close date is set, the assessment section will remain 
              available to the student indefinitely until they start and complete the section.
            </span>
            <h5 class="options-title">
              Reveal Score Report
            </h5>
            <span class="options-subtitle font-weight-300">
              Users have the functionality of choosing when student score reports become available for students to view.
              They can be accessed as soon as a student completes the assessment section, or at a specific date and time of your choosing.
            </span>
            <h5 class="options-title">
              Reveal Correct Answers
            </h5>
            <span class="options-subtitle font-weight-300">
              Users have the functionality of choosing when the full list of student responses and correct answers are available
              to students on their completed assessment score reports. They can be shown as soon as their score report is available,
              or at a specific date and time of your choosing.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="pt-0"
          >
            <multibuilder-section
              v-for="(sectionAssessment) in assessmentsToCreate"
              ref="multiBuilder"
              :key="sectionAssessment?.category?.name"
              :section-assessment="sectionAssessment"
              :course-type="selectedAssessmentTypeSet.type.assessmentType"
              :filename="getSectionLogoFileName(sectionAssessment?.category?.name)"
            />
          </v-col>
        </v-row>

        <!--Section Duration Options-->
        <v-row v-if="showOtherAssessmentOptions()">
          <v-col
            cols="12"
            class="pt-0 pb-5"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Section Timing
            </h5>
            <span class="options-subtitle font-weight-300">
              Select which timing option you would like for this assessment section.
            </span>
            <span class="options-subtitle font-weight-300">
              <span class="font-weight-500">Note:</span> Students designated at the user level with the
              <span class="font-weight-500">Untimed</span> status will
              always receive untimed assessments, regardless of the assignment selection.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="d-flex flex-wrap pt-0"
          >
            <div
              v-for="option in timingOptions"
              :key="option.value"
            >
              <assessment-radio-box-default
                v-model="selectedTimingOption"
                radio-name="radio-group-timing"
                :title="option.name"
                :input-value="option.value"
                :has-logo="false"
              />
            </div>
          </v-col>
        </v-row>

        <!--Section Auto Submit-->
        <v-row v-if="showOtherAssessmentOptions()">
          <v-col
            cols="12"
            class="pt-0 pb-5"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Section Auto-Submit
            </h5>
            <span class="options-subtitle font-weight-300">
              Select the amount of time after a student begins an assessment section in which our platform will
              automatically submit their assessment section for scoring.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="d-flex flex-wrap pt-0"
          >
            <div
              v-for="option in autoSubmitOptions"
              :key="option.value"
            >
              <assessment-radio-box-default
                v-model="selectedAutoSubmitOption"
                radio-name="radio-group-auto-submit"
                :title="option.name"
                :input-value="option.value"
                :has-logo="false"
              />
            </div>
          </v-col>
        </v-row>

        <!--Assessment Player Options-->
        <v-row v-if="showOtherAssessmentOptions()">
          <v-col
            cols="12"
            class="pt-0 pb-5"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pt-4"
          >
            <h5 class="options-title">
              Assessment Player Options
            </h5>
            <span class="options-subtitle font-weight-300">
              Select which optional functionality you would like made available to students.
            </span>
          </v-col>

          <v-col
            cols="12"
            sm="9"
            class="d-flex flex-wrap pt-0"
          >
            <div
              v-for="(option, idx) in playerSettingOptions"
              :key="idx"
            >
              <assessment-check-box-default
                v-model="selectedPlayerSettings"
                :input-value="option.value"
                :title="option.name"
                :has-logo="false"
                :has-subtitle-primary="false"
                :has-subtitle-secondary="false"
              />
            </div>
          </v-col>
        </v-row>
      </div>

      <v-row
        justify="end"
        dense
      >
        <v-col
          cols="12"
          class="pt-2 pb-5"
        >
          <v-divider v-if="showAllOptions()" />
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            dark
            class="btn-default btn-default__secondary"
            block
            @click="onCancel()"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
        >
          <v-btn
            :dark="!submitButtonDisabled()"
            class="btn-default btn-default__primary"
            block
            :disabled="submitButtonDisabled()"
            :loading="submittingForm"
            @click="onSaveAssessments()"
          >
            <v-img
              class="mr-2"
              contain
              :src="require('@/assets/icons/diskette.png')"
              alt="icon-save"
              max-width="19"
              max-height="19"
            />
            Assign Assessment
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import AppHeader from "@/components/shared/AppHeader.vue";
import MultibuilderSection from "./widgets/MultibuilderSection.vue";

import SectionAssessment from "@/models/section_assessment";
import AssessmentCheckBoxDefault from "@/components/staff/AssessmentsAssign/AssessmentCheckBoxDefault.vue";
import AssessmentRadioBoxSmall from "@/components/staff/AssessmentsAssign/AssessmentRadioBoxSmall.vue";
import AssessmentRadioBoxDefault from "@/components/staff/AssessmentsAssign/AssessmentRadioBoxDefault.vue";

import {AdminAssessmentApi, AdminSchoolApi, StaffAssessmentApi, StaffSchoolApi} from '@/api'
import SectionAssessmentGroup from "@/models/section_assessment_group";
// const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState, mapGetters: mapGettersUser} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')
const {mapGetters: mapAssessmentGetters} = createNamespacedHelpers('assessments')

import schoolMixin from '@/mixins/schoolMixin';

export default {
  name: "StaffAssessmentMultibuilder",

  components: {
    AssessmentRadioBoxDefault,
    AssessmentRadioBoxSmall,
    AssessmentCheckBoxDefault,
    AppHeader,
    MultibuilderSection
  },

  mixins: [
    schoolMixin
  ],

  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      formValid: true,
      submittingForm: false,
      assignByType: 0,
      sectionList: [],
      assessmentsToCreate: [],
      selectedAssessmentTypeSet: null,
      showAssessmentTypes: true,
      selectedCategories: [],
      selectedTimingOption: 'timed',
      timingOptions: [
        {name: 'Timed', value: 'timed'},
        {name: 'Time and a Half', value: 'time_and_a_half'},
        {name: 'Double Time', value: 'double'},
        {name: 'Untimed', value: 'untimed'},
      ],
      selectedAutoSubmitOption: 48,
      autoSubmitOptions: [
        {name: '24 Hours', value: 24},
        {name: '48 Hours', value: 48},
        {name: '72 Hours', value: 72},
        {name: 'Never', value: -1},
      ],
      selectedPlayerSettings: ['pauseEnabled', 'saveEnabled'],
      playerSettingOptions: [
        {name: 'Show Pause Button', value: 'pauseEnabled'},
        {name: 'Show Save/Exit Button', value: 'saveEnabled'},
        {name: 'Show Text-to-Speech Reader', value: 'readspeakerEnabled'},
      ],
      selectedGradeLevels: [],
      gradeLevelSelectAll: false,
      gradeLevelOptions: [
        {name: "8th Grade", value: 8},
        {name: "9th Grade", value: 9},
        {name: "10th Grade", value: 10},
        {name: "11th Grade", value: 11},
        {name: "12th Grade", value: 12},
      ],
      sections: [],
      headersColor: '#edf4fa',
      schoolId: null,
      school: {},
      selectedAdministrationType: 'continuous'
    }
  },

  computed: {
    ...mapUserState(["user"]),
    assessmentCategoriesForSelection() {
      if (this.selectedAssessmentTypeSet != null) {
        let a = this.getAvailableAssessmentCategories().filter( t => t.assessmentType === this.selectedAssessmentTypeSet.type.assessmentType )
        return a
      } else {
        return []
      }
    }
  },

  watch: {
    selectedAssessmentTypeSet() {
      // reset selections
      this.assessmentsToCreate = []
      this.selectedCategories = []

      this.updateAssessmentSettings()
    },

    // selectedCategories() {
    //   this.addAssessmentToCreate()
    // },

    selectedPlayerSettings() {
      this.updateAssessmentSettings()
    },

    selectedAutoSubmitOption() {
      this.updateAssessmentSettings()
    },

    selectedTimingOption() {
      this.updateAssessmentSettings()
    },

    selectedGradeLevels() {
      this.updateAssessmentSettings()
    },

    selectedAdministrationType() {
      this.updateAssessmentSettings()
    },

    sections() {
      this.updateAssessmentSettings()
    }
  },

  mounted() {
    this.initSetup()
  },

  methods: {
    ...mapSectionGetters(["getPrimarySections"]),
    ...mapAssessmentGetters(["getAvailableAssessmentTypes", "getAvailableAssessmentSets", "getAvailableAssessmentCategories", "getAvailableAssessments"]),
    ...mapGettersUser(["userRole", "schoolSupportsSat", "schoolSupportsAct"]),

    testChange(categoryId) {
      this.addAssessmentToCreate(categoryId)
    },

    async initSetup() {
      if (this.userRole() === "Horizon Admin") {
        this.schoolId = this.id
        this.getSections()
        await this.getSchool()
      } else if (this.userRole() === "Teacher Admin" || this.userRole() === "District Admin") {
        await this.getSections()
      } else {
        this.assignByType = 2
        this.sectionList = this.getPrimarySections()
      }
    },

    async getSchool() {
      try {
        let response = await AdminSchoolApi.get(this.$route.params.id)
        this.school = response.school
      } catch {
        this.addNotification('error', 'Failed to load this school.')
      }
    },

    async getSections() {
      try {
        const api = (this.userRole() === 'Teacher Admin' || this.userRole() === 'District Admin') ? StaffSchoolApi : AdminSchoolApi
        const response = await api.getAllClassesMinimal({
          schoolId: this.id,
        })
        this.sectionList = response.sections
      } catch {
        this.addNotification('error', 'Failed to load classes of this school.')
      }
    },

    getActAssessmentTypes() {
      return this.getAvailableAssessmentTypes().filter(a => a.assessmentType == "ACT")
    },

    getSatAssessmentTypes() {
      return this.getAvailableAssessmentTypes().filter(a => a.assessmentType == "SAT")
    },

    toggleAllCheckboxes() {
      this.selectedGradeLevels = []

      if (this.gradeLevelSelectAll) {
        for (const grade in this.gradeLevelOptions) {
          this.selectedGradeLevels.push(this.gradeLevelOptions[grade].value)
        }
      }
    },

    updateCheckAll: function () {
      this.gradeLevelSelectAll = this.selectedGradeLevels.length === this.gradeLevelOptions.length
    },

    gradeIsChecked(value) {
      if (value === 'all') {
        return this.selectedGradeLevels.length === this.gradeLevelOptions.length
      }

      return this.selectedGradeLevels.includes(value)
    },

    onBackToTypes() {
      this.onResetForm()
      this.assignByType = 0
    },

    getSectionLogoFileName(category) {
      if (category.includes('Math')) {
        return 'logo-math.png'
      }

      if (category.includes('Science')) {
        return 'logo-science.png'
      }

      if (category.includes('Reading')) {
        return 'logo-reading.png'
      }

      return 'logo-english.png'
    },

    getSectionDurationAndItems(categoryId) {
      for (const assessment of this.getAvailableAssessments()) {
        if (assessment?.categoryId === categoryId
            && assessment?.assessmentSetId === this.selectedAssessmentTypeSet?.set?.id
            && assessment?.assessmentTypeId === this.selectedAssessmentTypeSet?.type?.id) {

          const sectionTemp = {
            questionCount: assessment.questionsCount,
            duration: assessment.duration,
            id: assessment.id
          }
          return sectionTemp
        }
      }
    },

    addAssessmentToCreate(categoryId) {
      if (this.selectedAssessmentTypeSet && this.selectedCategories) {
        const indexOfFoundAssessment = this.assessmentsToCreate.findIndex(e => e.category.id === categoryId);
        if (indexOfFoundAssessment > -1) {
          this.assessmentsToCreate.splice(indexOfFoundAssessment, 1)
          return
        }
        let category = this.getAvailableAssessmentCategories().find((c) => c.id == categoryId)
        let sa

        if (this.selectedAssessmentTypeSet.type.assessmentType === 'SAT') {
          sa = new SectionAssessmentGroup()
        } else {
          sa = new SectionAssessment()
        }
        sa.assessmentType = this.selectedAssessmentTypeSet.type
        sa.assessmentSet = this.selectedAssessmentTypeSet.set
        sa.category = category
        sa.sections = this.sections
        sa.assessmentAdministrationType = this.selectedAdministrationType
        sa.settings.gradeLevels = [...new Set(this.selectedGradeLevels.flat())]
        this.assessmentsToCreate.push(sa)
      }
    },

    updateAssessmentSettings() {
      this.assessmentsToCreate.forEach(sectionAssessment => {

        // Assessment Type and Set
        sectionAssessment.assessmentType = this.selectedAssessmentTypeSet.type
        sectionAssessment.assessmentSet = this.selectedAssessmentTypeSet.set

        // autosubmit
        if (this.selectedAutoSubmitOption) {
          sectionAssessment.settings.autosubmitDelay = this.selectedAutoSubmitOption
        }

        // timing
        if (this.selectedTimingOption) {
          sectionAssessment.settings.timing = this.selectedTimingOption
        }

        // player options
        this.playerSettingOptions.forEach(option => {
          sectionAssessment.settings[option.value] = this.selectedPlayerSettings.includes(option.value)
        })

        // grade levels
        sectionAssessment.settings.gradeLevels = [...new Set(this.selectedGradeLevels.flat())]

        // sections
        sectionAssessment.sections = this.sections

        // Administration Type
        sectionAssessment.assessmentAdministrationType = this.selectedAdministrationType
      })
    },

    showAllOptions() {
      return this.assignByType !== 0
    },

    resetAssessmentTypes() {
      this.showAssessmentTypes = false
      this.$nextTick(() => {
        this.showAssessmentTypes = true
      })
    },

    showSectionList() {
      return this.selectedAssessmentTypeSet !== null
    },

    showAdministrationTypeSelection() {
      return this.supportsSat && this.selectedCategories.length !== 0 && this.selectedAssessmentTypeSet.type.assessmentType === "SAT"
    },

    showOtherAssessmentOptions() {
      return this.selectedCategories.length !== 0
    },

    onResetForm() {
      this.resetAssessmentTypes()
      this.assessmentsToCreate = []
      this.sections = []
      this.gradeLevelSelectAll = false
      this.selectedGradeLevels = []
      this.selectedAssessmentTypeSet = null
      this.selectedCategories = []
      this.selectedTimingOption = 'timed'
      this.selectedAutoSubmitOption = 48
      this.selectedPlayerSettings = ['pauseEnabled', 'saveEnabled']
    },

    submitButtonDisabled() {
      return this.assessmentsToCreate.length === 0
    },

    async onSaveAssessments() {
      try {
        this.submittingForm = true
        if (this.submitButtonDisabled()) return

        if (this.selectedGradeLevels.length === 0 || (this.assignByType === 2 && this.sections.length === 0)) {
          setTimeout(() => {
            this.$refs.assessmentTypeSelectionScroll.scrollIntoView({behavior: 'smooth'})
          }, 200)
          this.formValid = false
          return
        } else {
          this.formValid = true
        }

        const assessmentWindowFormIsInvalid = this.$refs.multiBuilder.some(el => {
          return !el.validateForm()
        })

        if (assessmentWindowFormIsInvalid) {
          setTimeout(() => {
            this.$refs.assessmentTimeOptions.scrollIntoView({behavior: 'smooth'})
          }, 200)
          return
        }

        let api = StaffAssessmentApi
        if (this.userRole() === "Horizon Admin") {
          api = AdminAssessmentApi
        }

        let successCount = 0
        for (const sectionAssessment of this.assessmentsToCreate) {
          try {
            if (this.selectedAssessmentTypeSet.type.assessmentType === "SAT") {
              await api.createSat(sectionAssessment, this.schoolId)
            } else {
              await api.create(sectionAssessment, this.schoolId)
            }
            successCount += 1
          } catch (e) {
            console.log(e)
          }
        }

        if (successCount === this.assessmentsToCreate.length) {
          this.addNotification('success', 'Assessments assigned.');
        } else {
          this.addNotification('warning', 'Some assessments could not be created. Please check if the assessments already exist.');
        }
        if (this.userRole() === 'Horizon Admin') {
          this.$router.push({name: "AdminDashboard/Assessments"})
        } else {
          this.$router.push({name: "StaffAssessmentSchedules"})
        }
      } catch {
        this.addNotification('error', 'Failed to assign assessment. Please try again reload and try again.')
      } finally {
        this.submittingForm = false
      }
    },

    onCancel() {
      this.$router.back()
    }

  }
}
</script>

<style lang="scss">

.options-title {
  font-size: 16px !important;
  color: #2a3446;
  margin-bottom: 4px;
}

.options-subtitle {
  display: block;
  margin-bottom: 16px;
  font-size: 14px;
  color: #344054;
}

.type-container {
  padding: 25px;

  h3 {
    color: #2a3446;
    line-height: 20px;
    margin-top: 1px;
    margin-bottom: 7px;
  }

  .type-subheader {
    display: block;
    color: #344054;
    font-size: 14px;
    padding-top: 5px;
  }

  .assign-type-options {
    padding-left: 18px;
    padding-right: 18px;

    .class-selection-container {
      max-width: 682px;
    }

    .header {
      font-family: Inter-SemiBold, sans-serif;
      display: block;
      font-size: 16px;
      color: #2a3446;
      margin-bottom: 9px;
    }

    .container-grade-levels {
      background-color: #fff;
      border: 1px solid #eaecf0;
      border-radius: 5px;
      flex-direction: row;
      align-items: flex-start;
      margin-right: 16px;
      padding: 16px 16px 6.4px 0;
      display: flex;
      flex-wrap: wrap;
      max-width: 666px;
    }

    .box-grade-level {
      width: 200px;
      min-height: 2.5rem;
      cursor: pointer;
      background-color: #f5f5f5;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 9.5px;
      margin-left: 16px;
      padding: 3px 12px;
      display: flex;
      color: #333333;
      position: relative;
      border-radius: 0 !important;

      &__checked {
        color: white;
        background-color: #1378d1;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: white;
        border: 1px solid #ccc;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 5px;
        width: 8px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      input:checked ~ .checkmark {
        background-color: #3898ec;
        border-color: #3898ec;
      }
    }
  }
}

.assessments-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  .assessments-title {
    padding: 16px;
    text-align: center;
    background-color: #edf4fa;
    color: #2a3446;
  }

}

.radio-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

</style>
