import HorizonApi from '@/api/horizon_api'

const USERS_PATH = `/api/staff/users`

export default {

  list(roles) {
    let params = roles.map(role => `role[]=${role}`).join("&")

    return HorizonApi.get(`${USERS_PATH}?${params}`)
  },

  createUser(userObj){
    return HorizonApi.post(USERS_PATH, {user: userObj})
  },

  updateUser(userId, userObj) {
    return HorizonApi.patch(`${USERS_PATH}/${userId}`, {user: userObj})
  },

  deleteUser(userId) {
    return HorizonApi.delete(`${USERS_PATH}/${userId}`)
  },

  deleteUserBulk(userIdArray) {
    return HorizonApi.post(`${USERS_PATH}/bulk_delete`, {userIds: userIdArray})
  },

  setUntimedBulk(userIdArray) {
    return HorizonApi.post(`${USERS_PATH}/bulk_update`, {userIds: userIdArray, untimed_assessment: true})
  },

  setTimedBulk(userIdArray) {
    return HorizonApi.post(`${USERS_PATH}/bulk_update`, {userIds: userIdArray, untimed_assessment: false})
  },

  getTeachersMinimal() {
    return HorizonApi.get(`${USERS_PATH}/minimal_index`)
  },

  getAssignedClasses(userId, page, perPage, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    };
    return HorizonApi.get(`${USERS_PATH}/${userId}/assigned_classes`, {params: params})
  },

  getAssignedAssessments(userId) {
    return HorizonApi.get(`${USERS_PATH}/${userId}/assigned_assessments` )
  },

  getStudentsScoreReport(userId) {
    return HorizonApi.get(`${USERS_PATH}/${userId}/score_reports` )
  }

}