<template>
  <v-container fluid>
    <app-header
      :title="user.school?.name"
      :has-logo="true"
    />

    <v-row justify="space-between">
      <v-col
        class="flex-grow-1"
      >
        <app-header
          :is-secondary="true"
          :from-dashboard="true"
          title="Assigned Assessments"
        />
        <div v-if="loadingAssessments">
          <v-skeleton-loader
            type="list-item-avatar@2"
          />
        </div>
        <div v-else-if="activeStudentAssessments.length > 0">
          <AssessmentSectionListItem
            v-for="assessment in activeStudentAssessments"
            :key="assessment.id"
            :student-assessment="assessment"
            :untimed="user.untimedAssessment"
            @start-exam="loadExamView(assessment)"
          />
        </div>
        <div v-else>
          No data.
        </div>

        <app-header
          class="mt-5"
          :is-secondary="true"
          :from-dashboard="true"
          title="Score Reports"
        />
        <div v-if="loadingReports">
          <v-skeleton-loader
            type="list-item-avatar@2"
          />
        </div>
        <div v-else-if="scoreReports.length > 0">
          <ScoreReportListItem
            v-for="(report, i) in scoreReports"
            :key="i"
            :report="report"
            @goto-report="loadReport(report)"
          />
        </div>
        <div v-else>
          No data.
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="auto"
        class="box-container"
      >
        <v-card
          to="/"
          flat
          class="box"
        >
          <div class="d-flex flex-column align-center justify-center">
            <img
              :src="require('@/assets/img/staff-no-classes.jpeg')"
              width="50%"
              height="auto"
              alt="student-logo"
            >
            <h3 class="card-title">
              Student FAQ <span class="highlight arrow">→</span>
            </h3>
            <span class="description">Help Documentation</span>
          </div>
        </v-card>

        <v-card
          v-if="hasCourseActivity"
          to="/student/course_activities"
          flat
          class="box"
        >
          <div class="d-flex flex-column align-center justify-center">
            <img
              :src="require('@/assets/img/assessments_calendar.png')"
              width="50%"
              height="auto"
              alt="courses-logo"
            />
            <h3 class="card-title">
              Course Activities <span class="highlight arrow">→</span>
            </h3>
            <span class="description">Course Lessons, Presentations, Videos</span>
          </div>
        </v-card>

        <v-card
          to="/student/score-reports"
          flat
          class="box"
        >
          <div class="d-flex flex-column align-center justify-center">
            <img
              :src="require('@/assets/img/course_activities.png')"
              width="50%"
              height="auto"
              alt="score-logo"
            />
            <h3 class="card-title">
              Score Reports <span class="highlight arrow">→</span>
            </h3>
            <span class="description">View Practice Assessment Results</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapState: mapUserState} = createNamespacedHelpers('user')

import {StudentAssessmentApi, StudentScoreReportApi} from '@/api'
import {StudentAssessment} from '@/models'

import AppHeader from "@/components/shared/AppHeader.vue";
import AssessmentSectionListItem from './widgets/AssessmentSectionListItem.vue'
import ScoreReportListItem from './widgets/ScoreReportListItem.vue'

export default {
  components: {
    AppHeader,
    AssessmentSectionListItem,
    ScoreReportListItem
  },
  data() {
    return {
      activeStudentAssessments: [],
      scoreReports: [],
      loadingReports: true,
      loadingAssessments: true,
      hasCourseActivity: false,
    }
  },
  computed: {
    ...mapUserState(["user"])
  },
  mounted() {
    this.loadData()
    this.checkCourseActivity()
  },
  methods: {
    async loadData() {

      try {
        const response = await StudentAssessmentApi.list('active')
        this.activeStudentAssessments = response.studentAssessments.map(assessment => new StudentAssessment(assessment))
      } catch (e) {
        this.addNotification('error', "Sorry, we could not load the assessment data")
      } finally {
        this.loadingAssessments = false
      }

      try {
        const reportsResponse = await StudentScoreReportApi.list()
        this.scoreReports = reportsResponse.compositeReports
      } catch (e) {
        this.addNotification('error', "Sorry, we could not load the student reports.")
      } finally {
        this.loadingReports = false
      }
    },

    loadExamView(assessment) {
      this.$router.push({name: 'StudentExamView', params: {id: assessment.id}});
    },

    loadReport(assessment) {
      this.$router.push({
        name: 'StudentReportComposite',
        params: {assessmentTypeId: assessment.assessmentType.id, assessmentSetId: assessment.assessmentSet.id},
        query: {year: assessment.schoolYear.id}
      });
    },

    checkCourseActivity(){
      const studUser = this.user
      this.hasCourseActivity = studUser?.school?.activitiesAvailable || false
    },

  }
}
</script>

<style scoped lang="scss">

.box-container {
  margin-top: 48px;
}

.box {
  width: 100%;
  height: 180px;
  max-width: 240px;
  min-width: 240px;
  box-shadow: none;
  color: #3d5d74;
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 15px;
  padding: 15px;
  text-decoration: none;
  display: flex;
}

.box:hover {
  background-color: #fff !important;
  box-shadow: 0 1px 3px 3px rgba(187, 187, 187, .13) !important;
}

.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 4px;
}

.card-title {
  font-weight: 600;
  font-size: 18px;
  color: #3d5d74;
  line-height: 20px;
  margin-top: 10px;
}

.description {
  color: #505050;
  font-size: 10px;
}
</style>