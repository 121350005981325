<template>
  <v-dialog
    :value="dialog"
    max-width="600px"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="titleProp"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col cols="12">
            We're sorry you're encountering this error. Please copy the following text to the clipboard and send it to our customer support.

            <v-sheet 
              height="150px"
              class="mt-5 overflow-auto" 
              color="grey lighten-3">
              {{ bodyTextProp }}
            </v-sheet>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="copyToClipboard()"
            >
              Copy to Clipboard
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onConfirmActionDialog()"
            >
              Okay
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";

export default {
  name: "DialogDiagnostic",
  components: {AppHeader},
  props: {
    active: Boolean,
    loading: Boolean,
    titleProp: {
      type: String,
      default: 'Diagnostic Information',
    },
    bodyTextProp: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      dialog: false,
    }
  },

  watch: {
    active(value) {
      this.dialog = value
    }
  },

  methods: {
    onCloseDialog() {
      this.$emit('closeDialog')
    },

    onConfirmActionDialog(){
      this.$emit('confirmDialog')
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.bodyTextProp);
    },
  }
}
</script>

<style scoped>

</style>