<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <!-- start Active Assignments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col>
        <h3>Active Assignments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(activeAssignments)"
      :server-items-length="totalActiveAssignments"
      :options.sync="activeTableOptions"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="activeAssignments"
      :loading="activeTableLoading ? '#3d5d74' : false"
      loading-text="Loading Assessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions: [itemsPerPage],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template
        #[`item.assignment_schedule.start_at`]="{ item }"
      >
        <td>
          <span>{{ formatDate(item.assignment_schedule.start_at) }}</span>
        </td>
      </template>

      <template
        #[`item.assignment_schedule.end_at`]="{ item }"
      >
        <td>
          <span>{{ formatDate(item.assignment_schedule.end_at) }}</span>
        </td>
      </template>

      <template
        v-if="!isMobile"
        #[`item.action`]="{ item }"
      >
        <td v-if="isEditable(item)">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Edit assignment"
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Delete assignment"
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </template>

      <template
        v-else
        #[`item.action`]="{ item }"
      >
        <td v-if="isEditable(item)">
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Edit assignment"
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Delete assignment"
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end Active Assignments -->

    <!-- start upcoming Assignments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col>
        <h3>Upcoming Assignments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(upcomingAssignments)"
      :server-items-length="totalUpcomingAssignments"
      :options.sync="upcomingTableOptions"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="upcomingAssignments"
      :loading="upcomingTableLoading ? '#3d5d74' : false"
      loading-text="Loading Assessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions: [itemsPerPage],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template
        #[`item.assignment_schedule.start_at`]="{ item }"
      >
        <td>
          <span>{{ formatDate(item.assignment_schedule.start_at) }}</span>
        </td>
      </template>

      <template
        #[`item.assignment_schedule.end_at`]="{ item }"
      >
        <td>
          <span>{{ formatDate(item.assignment_schedule.end_at) }}</span>
        </td>
      </template>

      <template
        v-if="!isMobile"
        #[`item.action`]="{ item }"
      >
        <td v-if="isEditable(item)">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Edit assignment"
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Delete assignment"
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </template>
      <template
        v-else
        #[`item.action`]="{ item }"
      >
        <td v-if="isEditable(item)">
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Edit assignment"
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Delete assignment"
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end upcoming Assignments -->

    <!-- start past Assignments -->
    <v-row
      justify="space-between"
      class="mb-2 mt-8"
    >
      <v-col>
        <h3>Past Assignments</h3>
      </v-col>
    </v-row>
    <v-data-table
      :height="getTableHeight(pastAssignments)"
      :server-items-length="totalPastAssignments"
      :options.sync="pastTableOptions"
      :headers="headers"
      :headers-length="headers.length + 1"
      :items="pastAssignments"
      :loading="pastTableLoading ? '#3d5d74' : false"
      loading-text="Loading Assessments"
      class="table-default table-default__header-fixed"
      fixed-header
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions: [itemsPerPage],
        disableItemsPerPage: true,
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template
        #[`item.assignment_schedule.start_at`]="{ item }"
      >
        <td>
          <span>{{ formatDate(item.assignment_schedule.start_at) }}</span>
        </td>
      </template>

      <template
        #[`item.assignment_schedule.end_at`]="{ item }"
      >
        <td>
          <span>{{ formatDate(item.assignment_schedule.end_at) }}</span>
        </td>
      </template>

      <template
        v-if="!isMobile"
        #[`item.action`]="{ item }"
      >
        <td v-if="isEditable(item)">
          <v-row dense>
            <v-col class="d-flex justify-end">
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Edit assignment"
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Delete assignment"
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </td>
      </template>
      <template
        v-else
        #[`item.action`]="{ item }"
      >
        <td v-if="isEditable(item)">
          <ul class="flex-content">
            <v-col
              cols="12"
              class="d-flex pl-0 pr-0"
            >
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Edit assignment"
                @click.stop="editAssignLesson(item)"
              >
                <v-icon size="18">
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn
                class="btn-table btn-table__icon"
                outlined
                icon
                aria-label="Delete assignment"
                @click.stop="openDialogDeleteConfirmation(item)"
              >
                <v-icon size="18">
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </v-col>
          </ul>
        </td>
      </template>
    </v-data-table>
    <!-- end past assignments -->

    <dialog-assign-lesson
      :title="dialogAssignLessonTitle"
      :active="dialogAssignLesson"
      :lesson="lesson"
      :section-list="sectionList"
      :new-assignment="false"
      @assignLesson="updateLessonTiming($event)"
      @closeDialog="closeDialogAssignLesson()"
    />

    <dialog-delete-confirmation
      :active="dialogDeleteLessonAssignmentConfirmation"
      :deletion-loader-prop="deletionLoader"
      :message="deleteMessage"
      @closeDialog="closeDialogDeleteConfirmation()"
      @confirmDeletion="confirmDeleteAssignLesson()"
    />
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapGetters: mapSectionGetters} = createNamespacedHelpers('staffSections')

import {StaffAssignmentSchedulesApi, StaffCourseActivitiesApi, StaffSchoolApi, StaffSectionApi} from '@/api'
import DialogAssignLesson from '@/components/staff/course/shared/DialogAssignLesson.vue'
import DialogDeleteConfirmation from '@/components/shared/DialogDeleteConfirmation.vue'

export default {
  name: "StaffCourseActivitiesAssignments",

  components: {
    DialogAssignLesson,
    DialogDeleteConfirmation
  },

  data() {
    return {
      activeTableLoadedOnce: false,
      upcomingTableLoadedOnce: false,
      pastTableLoadedOnce: false,
      tableLoading: false,
      activeTableLoading: false,
      upcomingTableLoading: false,
      pastTableLoading: false,
      courseId: null,
      isSectionSpecific: undefined,
      sectionId: null,
      courseActivity: {},
      lesson: {},
      lessonsData: [],
      lessonsWithAssignments: [],
      multipleCourses: false,
      dialogAssignLesson: false,
      dialogAssignLessonTitle: '',
      dialogDeleteLessonAssignmentConfirmation: false,
      deletionLoader: false,
      deleteMessage: 'Are you sure you want to Delete the Lesson Assignment?',
      editMode: false,
      primaryTeacher: "Primary Teacher",
      teacherAdmin: "Teacher Admin",
      tableHeight: 'calc(90vh - 540px)',
      sectionList: [],
      activeAssignments: [],
      totalActiveAssignments: 0,
      upcomingAssignments: [],
      totalUpcomingAssignments: 0,
      pastAssignments: [],
      totalPastAssignments: 0,
      itemsPerPage: 100,
      activeTableOptions: {},
      upcomingTableOptions: {},
      pastTableOptions: {},
      headers: [
        {
          text: 'Lesson Name',
          value: 'lesson_name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Class',
          value: 'section_name',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Assigned By',
          value: 'created_by',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Start Time',
          value: 'assignment_schedule.start_at',
          align: 'left',
          sortable: false,
          width: 210,
        },
        {
          text: 'End Time',
          value: 'assignment_schedule.end_at',
          align: 'left',
          sortable: false,
          width: 210,
        },
        {
          text: '',
          value: 'action',
          align: 'left',
          sortable: false,
          width: 180,
        }
      ],
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
    ...mapUserState(["user"]),
  },

  watch: {
    activeTableOptions: {
      handler() {
        if (!this.onTableSearch && this.activeTableLoadedOnce) {
          this.getActiveAssignments()
        }
      },
      deep: true,
    },
    upcomingTableOptions: {
      handler() {
        if (!this.onTableSearch && this.upcomingTableLoadedOnce) {
          this.getUpcomingAssignments()
        }
      },
      deep: true,
    },
    pastTableOptions: {
      handler() {
        if (!this.onTableSearch && this.pastTableLoadedOnce) {
          this.getPastAssignments()
        }
      },
      deep: true,
    },
  },

  async mounted() {
    this.isSectionSpecific = this.$route.meta.isSectionSpecific
    this.courseId = this.$route.params.courseTemplate;
    this.sectionId = this.$route.params.sectionId;
    await this.getAllAssignments()
    this.getSections()
    this.setHeaders();
  },

  methods: {
    ...mapSectionGetters(["getAllSections"]),

    async getAllAssignments() {
      this.getActiveAssignments()
      this.getUpcomingAssignments()
      await this.getPastAssignments()
    },

    async getActiveAssignments() {
      try {
        this.activeTableLoading = true
        this.activeAssignments = []
        const {sortBy, sortDesc, page} = this.activeTableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        let paramsObject = {
          page: page,
          perPage: this.itemsPerPage,
          sort: sortBy[0],
          order: order,
          searchProp: '',
          status: 'active'
        }
        let response = {}
        if (this.isSectionSpecific) {
          paramsObject.sectionId = this.sectionId
          response = await StaffCourseActivitiesApi.getSectionAssignments(paramsObject)
        } else {
          paramsObject.courseId = this.courseId
          response = await StaffCourseActivitiesApi.getCourseAssignments(paramsObject)
        }

        this.activeAssignments = response.assigned_lessons
        this.totalActiveAssignments = response?.meta?.totalCount
      } catch {
        this.addNotification('error', 'Failed to load assignments.')
      } finally {
        this.activeTableLoading = false
        this.activeTableLoadedOnce = true
      }
    },

    async getUpcomingAssignments() {
      try {
        this.upcomingTableLoading = true
        this.upcomingAssignments = []
        const {sortBy, sortDesc, page} = this.upcomingTableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        let paramsObject = {
          page: page,
          perPage: this.itemsPerPage,
          sort: sortBy[0],
          order: order,
          searchProp: '',
          status: 'upcoming'
        }
        let response = {}
        if (this.isSectionSpecific) {
          paramsObject.sectionId = this.sectionId
          response = await StaffCourseActivitiesApi.getSectionAssignments(paramsObject)
        } else {
          paramsObject.courseId = this.courseId
          response = await StaffCourseActivitiesApi.getCourseAssignments(paramsObject)
        }

        this.upcomingAssignments = response.assigned_lessons
        this.totalUpcomingAssignments = response?.meta?.totalCount
      } catch {
        this.addNotification('error', 'Failed to load assignments.')
      } finally {
        this.upcomingTableLoading = false
        this.upcomingTableLoadedOnce = true
      }
    },

    async getPastAssignments() {
      try {
        this.pastTableLoading = true
        this.pastAssignments = []
        const {sortBy, sortDesc, page} = this.pastTableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        let paramsObject = {
          page: page,
          perPage: this.itemsPerPage,
          sort: sortBy[0],
          order: order,
          searchProp: '',
          status: 'past'
        }
        let response = {}
        if (this.isSectionSpecific) {
          paramsObject.sectionId = this.sectionId
          response = await StaffCourseActivitiesApi.getSectionAssignments(paramsObject)
        } else {
          paramsObject.courseId = this.courseId
          response = await StaffCourseActivitiesApi.getCourseAssignments(paramsObject)
        }

        this.pastAssignments = response.assigned_lessons
        this.totalPastAssignments = response?.meta?.totalCount
        this.multipleCourses = response.courses_count > 1
      } catch {
        this.addNotification('error', 'Failed to load assignments.')
      } finally {
        this.pastTableLoading = false
        this.pastTableLoadedOnce = true
      }
    },

    async getSections() {
      try {
        let response = {}
        if (this.user.role === 'Teacher') {
          response = await StaffSectionApi.getAllClassesMinimal()
        } else {
          response = await StaffSchoolApi.getAllClassesMinimal()
        }
        this.sectionList = response.sections
      } catch {
        this.addNotification('error', 'Failed to load classes of this school.')
      }
    },

    setHeaders() {
      if (this.isSectionSpecific) {
        this.headers.splice(1, 1)
      }

      if (this.multipleCourses) {
        this.headers = [
          {
            text: 'Course Name',
            value: 'course_name',
            align: 'left',
            sortable: false
          },
          ...this.headers
        ]
      }
    },

    formatDate(dateString) {
      if (!dateString) return ''

      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      }
      return new Date(dateString).toLocaleString(undefined, options)
    },

    openDialogAssignLesson() {
      this.dialogAssignLesson = true
    },

    closeDialogAssignLesson() {
      this.dialogAssignLesson = false
    },

    editAssignLesson(lesson) {
      this.lesson = lesson
      this.dialogAssignLessonTitle = 'Edit Lesson: ' + lesson.lesson_name
      this.openDialogAssignLesson()
    },

    openDialogDeleteConfirmation(lesson) {
      this.lesson = lesson
      this.dialogDeleteLessonAssignmentConfirmation = false
      this.$nextTick(() => {
        this.dialogDeleteLessonAssignmentConfirmation = true
      })
    },

    closeDialogDeleteConfirmation() {
      this.dialogDeleteLessonAssignmentConfirmation = false
    },

    async confirmDeleteAssignLesson() {
      try {
        this.deletionLoader = true
        await StaffAssignmentSchedulesApi.deleteAssignmentSchedule(this.lesson.schedule_id, this.lesson.section_id)
        this.getAllAssignments()
        this.closeDialogDeleteConfirmation()
      } catch {
        this.addNotification('error', "Something went wrong. Please try again.")
      } finally {
        this.deletionLoader = false
      }
    },

    async updateLessonTiming(data) {
      try {
        const timingData = {
          'start_at': data.start_at,
          'end_at': data.end_at,
          'section_id': this.lesson.section_id
        }
        await StaffAssignmentSchedulesApi.updateAssignmentSchedule(this.lesson.schedule_id, timingData)
        this.getAllAssignments()
      } catch {
        this.addNotification('error', "Something went wrong. Please try again.")
      }
    },

    isEditable(assignment) {
      if (['Teacher Admin', 'District Admin'].includes(this.user.role)) return true

      const sectionParent = this.getAllSections().find(section => section.id === assignment?.section_id)
      return sectionParent?.role === 'Primary Teacher'
    },

    getTableHeight(array) {
      if (array.length < 6) return undefined

      if (this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 900) return '400px'

      return this.tableHeight
    },
  },
}
</script>