<template>
  <v-sheet class="sheet-main">
    <div class="d-flex align-center flex-wrap">
      <div class="sheet-container">
        <v-sheet
          class="sheet-inner"
          height="80px"
          width="100px"
          outlined
        >
          <div
            class="d-flex justify-center"
            style="width: 95%; height: 100%;"
          >
            <mini-donut-chart
              :chart-id="reportChartId()"
              :average-score="report.compositeScore"
              :is-average-score="false"
              :min-score="report.minScore"
              :max-score="report.maxScore"
              :color="'#f4701f'"
            />
          </div>
        </v-sheet>
      </div>

      <div class="flex-grow-1">
        <h4 class="font-weight-500 mb-2">
          {{ reportName() }}
        </h4>
        <div>
          <v-chip
            v-if="report?.schoolYear"
            class="chip-small mr-1"
            color="#fee5b4"
          >
            <span class="chip-text">
              {{ report.schoolYear.shortName }}
            </span>
          </v-chip>
        </div>
      </div>

      <v-btn
        class="btn-default btn-default__secondary btn-default__white"
        outlined
        @click="sendReportClick"
      >
        View Score Report <span class="highlight arrow">→</span>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import MiniDonutChart from '@/components/shared/charts/MiniDonutChart.vue'

export default {
  name: "ScoreReportListItem",
  components: {
    MiniDonutChart
  },
  props: {
    report: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    sendReportClick() {
      this.$emit('goto-report', this.report)
    },

    reportName() {
      return `Horizon Assessment for the ${this.report.assessmentType.course_type.toUpperCase()} #${this.report.assessmentSet.name.slice(-1)}`
    },

    reportChartId() {
      let r = Math.floor(Math.random() * 100)
      return `${this.report.assessmentType.course_type.toUpperCase()}_${this.report.assessmentSet.name.slice(-1)}-${r}`
    }
  }
}
</script>

<style scoped>

.sheet-main {
  background-color: #f2f4f8;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  padding: 13px 20px;
  margin-bottom: 10px;
}

.sheet-container {
  font-family: Open Sans,sans-serif;
  font-size: 11px;
  margin-right: 13px;
}

.sheet-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 8px;
}

h4{
  font-size: 20px;
}


.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 10px;
}
</style>