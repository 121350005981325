<template>
  <v-dialog
    :value="dialog"
    max-width="900px"
    persistent
  >
    <v-card
      height="525px"
      class="card-dialog"
    >
      <app-header
        :title="`${studentData.firstName} ${studentData.lastName}`"
        :is-dialog="true"
        :from-student-profile="true"
        :user-prop="studentData"
        :teacher-role="teacherRole"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pr-4 pl-4 pr-sm-6 pl-sm-6 pb-0"
      >
        <app-tabs-primary
          :is-dialog="true"
          :tabs="tabs"
          :active-tab="tabSelected"
          @onTabChange="onLoadCategory(...arguments)"
        />
      </v-container>
      <div v-if="tabSelected === 0">
        <student-form
          :type="studentFormType"
          :student-prop="studentData"
          :school-id="schoolId"
          @closeDialog="onCloseDialog()"
          @closeDialogUpdate="onCloseDialog(true)"
        />
      </div>
      <div v-if="tabSelected === 1">
        <student-classes :student-id="studentData.id" />
      </div>
      <v-container
        v-if="tabSelected === 2"
        fluid
        class="pt-0"
      >
        <student-assessment-schedules
          :student-id="studentData.id"
          :school-id="schoolId"
          :section-id="sectionId"
          :section-editable="false"
        />
      </v-container>
      <v-container
        v-if="tabSelected === 3"
        fluid
        class="pt-0"
      >
        <student-score-reports
          :student-id="studentData.id"
          :from-students-dialog="true"
        />
      </v-container>
      <v-container
        v-if="tabSelected === 4"
        fluid
        class="pt-0"
      >
        <parent-list :student-id="studentData.id" />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')

import AppHeader from "@/components/shared/AppHeader.vue";
import AppTabsPrimary from "@/components/shared/AppTabsPrimary.vue";
import functions from "@/api/shared/functions";
import StudentAssessmentSchedules from "@/components/shared/StudentAssessmentSchedules.vue";
import StudentForm from "@/components/shared/StudentForm.vue";
import StudentClasses from "@/components/shared/StudentClasses.vue";
import StudentScoreReports from "@/components/student/StudentScoreReports.vue";
import ParentList from "@/components/shared/ParentList.vue";


export default {
  name: "DialogStudentProfile",
  components: {
    ParentList,
    StudentScoreReports, StudentClasses, StudentForm, StudentAssessmentSchedules, AppTabsPrimary, AppHeader},

  props: {
    active: Boolean,
    fromClass: Boolean,
    fromStudentPerformance: Boolean,
    studentProp: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    schoolId: {
      type: [String, Number],
      default: null
    },
    sectionId: {
      type: [Number, String],
      default: null
    },
    teacherRole: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      dialog: false,
      gradeLevelArray: [],
      loading: false,
      studentData: {},
      tabSelected: null,
      tabs: [
        {
          id: 1,
          name: 'Student Profile',
        },
        {
          id: 2,
          name: 'Classes',
        },
        {
          id: 3,
          name: 'Assessment',
        },
        {
          id: 4,
          name: 'Score Reports',
        },
        {
          id: 5,
          name: 'Parents/Guardians',
        }
      ],
    }
  },

  computed: {
    studentFormType() {
      if (this.canEditStudent()) {
        return 'edit'
      } else {
        return 'view'
      }
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.studentData = functions.deepCopySync(this.studentProp)
        this.tabSelected = 0

        // TODO implement functionality when user opens this dialog from student individual performance
        if(this.fromStudentPerformance) {
          this.studentData.id = this.studentProp.studentId
          setTimeout(() => {
            this.onLoadCategory(3)
          }, 200)
        }
        this.dialog = value
      } else {
        this.tabSelected = null
        this.dialog = value
      }
    }
  },
  
  methods: {
    ...mapGetters(["userRole", "user"]),
    onCloseDialog(update) {
      if(update) {
        this.$emit('closeDialogUpdate')
        return
      }
      this.$emit('closeDialog')
    },

    onLoadCategory(tab) {
      if(this.tabSelected === null) return
      this.tabSelected = tab
    },

    canEditStudent() {
      return !(this.user().school?.schoolAdminOnly && this.userRole() === "Teacher")
    }
  },

}
</script>

<style scoped>
.card-dialog {
  overflow-y: auto;
}
</style>