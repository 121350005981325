import HorizonApi from '@/api/horizon_api'
import store from '@/store'

export default {
  supportedAssessments ({ schoolId } = {}) {
    return HorizonApi.get(`/api/admin/schools/${schoolId}/assessments`)
  },

  list (sectionId, page = 1) {
    return HorizonApi.get(`/api/admin/sections/${sectionId}/class_assessments?page=${page}`)
  },

  schedule ({ schoolId } = {}) {
    return HorizonApi.get(`/api/admin/schools/${schoolId}/assessments/schedule`)
  },

  create (sectionAssessment, schoolId) {
    let assessment = store.state.assessments.assessments.find(a => a.assessmentTypeId == sectionAssessment.assessmentType.id && a.assessmentSetId == sectionAssessment.assessmentSet.id && a.categoryId == sectionAssessment.category.id)

    if (!assessment) {
      return Promise.reject(new Error('Assessment does not exist'))
    }

    // bulk create endpoint
    let sectionAssessmentParams = {
      school_id: schoolId,
      assessment_id: assessment.id,
      section_ids: sectionAssessment.sections.map(s => s.id),
      start_at: sectionAssessment.startAt,
      end_at: sectionAssessment.endAt,
      reveal_assessments_at: sectionAssessment.revealAt,
      assessment_assignment_setting_attributes: sectionAssessment.settings.requestParamsJson
    }

    return HorizonApi.post(`/api/admin/sections/class_assessments/bulk_assign_assessment`, sectionAssessmentParams)
  },

  createSat (sectionAssessmentGroup, schoolId) {
    let assessment = store.state.assessments.assessments
      .find(a => a.assessmentTypeId == sectionAssessmentGroup.assessmentType.id && a.assessmentSetId == sectionAssessmentGroup.assessmentSet.id && a.categoryId == sectionAssessmentGroup.category.id && a.difficulty == "default")

    if (!assessment) {
      return Promise.reject(new Error('Assessment does not exist'))
    }

    // bulk create endpoint
    let sectionAssessmentGroupParams = {
      school_id: schoolId,
      section_ids: sectionAssessmentGroup.sections.map(s => s.id),
      section_assessment_group: {
        default_assessment_id: assessment.id,
        assessment_administration_type: sectionAssessmentGroup.assessmentAdministrationType,
        p1_start_at: sectionAssessmentGroup.p1StartAt,
        p1_end_at: sectionAssessmentGroup.p1EndAt,
        p2_start_at: sectionAssessmentGroup.p2StartAt,
        p2_end_at: sectionAssessmentGroup.p2EndAt,
        reveal_start_at: sectionAssessmentGroup.revealAt,
        assessment_assignment_setting_attributes: sectionAssessmentGroup.settings.requestParamsJson
      }
    }

    return HorizonApi.post(`/api/admin/class_assessments_sat/bulk_assign_assessment`, sectionAssessmentGroupParams)
  },

  update (sectionAssessment) {
    if (sectionAssessment.sections.length == 1) {
      let section = sectionAssessment.sections[0].id
      let sectionAssessmentParams = {
        start_at: sectionAssessment.startAt,
        end_at: sectionAssessment.endAt,
        reveal_assessments_at: sectionAssessment.revealAt,
        assessment_assignment_setting_attributes: sectionAssessment.settings.requestParamsJson
      }

      return HorizonApi.patch(`/api/admin/sections/${section}/class_assessments/${sectionAssessment.id}`, sectionAssessmentParams)
    }
  },

  updateSat (sectionAssessmentGroup) {
    let section = sectionAssessmentGroup.sections[0].id
    let sectionAssessmentGroupParams = {
      p1_start_at: sectionAssessmentGroup.p1StartAt,
      p1_end_at: sectionAssessmentGroup.p1EndAt,
      p2_start_at: sectionAssessmentGroup.p2StartAt,
      p2_end_at: sectionAssessmentGroup.p2EndAt,
      reveal_start_at: sectionAssessmentGroup.revealAt,
      assessment_assignment_setting_attributes: sectionAssessmentGroup.settings.requestParamsJson
    }

    return HorizonApi.patch(`/api/admin/sections/${section}/class_assessments_sat/${sectionAssessmentGroup.id}`, sectionAssessmentGroupParams)
  },

  getAssessmentGroup (assessment) {
    let section = assessment.sections[0].id
    let sectionAssessmentGroupId = assessment.sectionAssessmentGroupId
    return HorizonApi.get(`/api/admin/sections/${section}/class_assessments_sat/${sectionAssessmentGroupId}`)
  },

  delete (sectionAssessment) {
    return HorizonApi.delete(`/api/admin/sections/${sectionAssessment.sections[0].id}/class_assessments/${sectionAssessment.id}`)
  }, 

  deleteSat (sectionAssessment) {
    let section = sectionAssessment.sections[0].id
    let sectionAssessmentGroupId = sectionAssessment.sectionAssessmentGroupId
    return HorizonApi.delete(`/api/admin/sections/${section}/class_assessments_sat/${sectionAssessmentGroupId}`)
  },

  get (assessment) {
    let section = assessment.sections[0].id
    let sectionAssessmentId = assessment.id
    return HorizonApi.get(`/api/admin/sections/${section}/class_assessments/${sectionAssessmentId}`)
  },

}