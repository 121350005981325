<template>
  <v-card
    class="box"
    @click="onBoxClicked()"
  >
    <img
      class="box__img"
      :width="isMobile ? '61px' : 'auto'"
      :src="require(`@/assets/img/${filename}`)"
      :alt="`${title}-image`"
    >
    <div>
      <h3
        class="card-title"
        :class="isMobile ? 'font-size-16' : undefined"
      >
        {{ title }}
      </h3>
      <span class="description">{{ description }}</span>
    </div>
  </v-card>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
export default {
  name: "StaffDashboardBoxes",
  props: {
    toNewWindow: Boolean,
    link: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    filename: {
      type: String,
      required: true
    },

  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    })
  },

  methods: {
    onBoxClicked() {
      if(this.link === '') return

      if (this.toNewWindow) window.open(this.link, '_blank')

      this.$router.push({path: this.link})
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100px;
  color: #3d5d74;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2) !important;
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 15px;
  text-decoration: none;

  &:hover {
    background-color: #fff !important;
    box-shadow: 0 1px 3px 3px rgba(187, 187, 187, .13) !important;
  }

  &__img {
    max-width: 40%;
    height: auto;
    max-height: 90%;
    margin-right: 16px;
  }

}

.highlight {
  color: #f47e26
}

.arrow {
  font-size: 18px;
  padding-left: 4px;
}

.card-title {
  font-weight: 600;
  font-size: 18px;
  color: #3d5d74;
  line-height: 20px;
  margin-top: 10px;
}

.description {
  color: #505050;
  font-size: 10px;
}

</style>