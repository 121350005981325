import HorizonApi from '@/api/horizon_api'

const COURSE_ACTIVITY_PATH = `/api/student/course_activities`

export default {
  list (filter = null) {
    if (filter == null) {
      return HorizonApi.get(`${COURSE_ACTIVITY_PATH}`)
    } else {
      return HorizonApi.get(`${COURSE_ACTIVITY_PATH}?filter=${filter}`)
    }
  },

  getCourseActivity () {
    return HorizonApi.get(`${COURSE_ACTIVITY_PATH}`)
  },

  getLearnosityResponses (lesson_id) {
    return HorizonApi.get(`${COURSE_ACTIVITY_PATH}/learnosity_responses/${lesson_id}`)
  },

  getLearnosityResponse (lesson_id, activity_id) {
    return HorizonApi.get(`${COURSE_ACTIVITY_PATH}/learnosity_response/${lesson_id}/${activity_id}`)
  },

  getReviewQuestions (lesson_id, activity_id) {
    return HorizonApi.get(`${COURSE_ACTIVITY_PATH}/review_questions/${lesson_id}/${activity_id}`)
  },

}
