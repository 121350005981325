import store from '@/store'
import SectionAssessmentGroup from '@/models/section_assessment_group'
import AssessmentAssignmentSettings from '@/models/assessment_assignment_settings'

export default {
  createFromJson(json) {
    let sa = new SectionAssessmentGroup()

    sa.id = json.id
    sa.p1StartAt = new Date(json.p1StartAt)
    sa.p1EndAt = json.p1EndAt == null ? null : new Date(json.p1EndAt)
    sa.p2StartAt = new Date(json.p2StartAt)
    sa.p2EndAt = json.p2EndAt == null ? null : new Date(json.p2EndAt)
    sa.revealAt = json.revealStartAt == null ? null : new Date(json.revealStartAt)
    sa.assessment = store.state.assessments.assessments.find(a => a.id == json.defaultAssessment.id)
    sa.assessmentAdministrationType = json.assessmentAdministrationType
    sa.category = store.state.assessments.assessmentCategories.find(a => a.id == sa.assessment.categoryId)
    sa.assessmentSet = store.state.assessments.assessmentSets.find(a => a.id == sa.assessment.assessmentSetId)
    sa.assessmentType = store.state.assessments.assessmentTypes.find(a => a.id == sa.assessment.assessmentTypeId)

    let section = store.state.staffSections.allSections.find(a => a.id == json.section.id)
    if (section) {
      sa.sections = [section]
    } else {
      sa.sections = [ {id: json.section.id, displayName: json.section.name } ]
    }

    let settings = json.settings
    sa.settings = AssessmentAssignmentSettings.fromJson(settings)

    return sa
  }
}