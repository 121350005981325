import moment from "moment";
import AssessmentAssignmentSettings from '@/models/assessment_assignment_settings'

export default class SectionAssessmentGroup {
  constructor() {
    this.id = null
    this.p1StartAt = null
    this.p2StartAt = null
    this.p1EndAt = null
    this.p2EndAt = null
    this.assessmentAdministrationType = null
    this.revealAt = null
    this.assessment = null
    this.sections = null
    this.assessmentType = null
    this.assessmentSet = null
    this.category = null
    this.settings = new AssessmentAssignmentSettings()

    this.initializeRules()
  }

  initializeRules() {
    this.rules = {
      assessmentName: [
        v => !!v || 'Assessment Name is required'
      ],
      assessmentSection: [
        v => !!v || 'Assessment Section is required'
      ],
      assessmentAdministrationType: [
        v => !!v || 'Assessment administration type is required'
      ],
      p1StartAt: [
        v => !!v || 'Start date is required'
      ],
      p1EndAt: [
        v => {
          const condition = v == null || v == "" || ((v != null && v != "") && this.p1StartAt && this.p1EndAt > this.p1StartAt)
          return condition || 'End date must be after start date'
        }
      ],
      p2StartAt: [
        v => {
          if(!v){
            return 'Part 2 start date is required'
          }
          const condition =  this.p2StartAt > this.p1StartAt
          return condition || 'Part 2 start date must be after Part 1 start date'
        }
      ],
      p2EndAt: [
        v => {
        if(v == null || v == "") return true
          if(!this.p1StartAt || this.p2EndAt < this.p1StartAt) return 'End date must be after start date'
          if(this.p2StartAt && this.p2EndAt < this.p2StartAt) return 'End date must be after part 2 start date'
          return true
          // const condition = v == null || v == "" || ((v != null && v != "") && this.p1StartAt && this.p2EndAt > this.p1StartAt)
          // return condition || 'End date must be after start date'
        }
      ],
      classes: [
        v => !!v || 'Please select at least one class.'
      ]
    }
  }

  getStatus() {
    var s = ""
    let startAt = this.p1StartAt

    if (this.p1EndAt == null) {
      if (startAt < Date.now()) {
        s = "active"
      } else if (startAt > Date.now()) {
        s = "upcoming"
      }
    } else {
      let endAt = this.p1EndAt
      if (endAt < Date.now()) {
        s = "past"
      } else {
        if (startAt < Date.now()) {
          s = "active"
        } else if (startAt > Date.now()) {
          s = "upcoming"
        }
      }
    }

    return s
  }

  // Assessment Schedule table properties
  get formattedP1StartAt() {
    return this.p1StartAt.toLocaleString()
  }

  get formattedP1EndAt() {
    return this.p1EndAt == null ? "(not set)" : this.p1EndAt.toLocaleString()
  }

  get formattedP2StartAt() {
    return this.p1StartAt == null ? "(not set)" : this.p1StartAt.toLocaleString()
  }

  get formattedP2EndAt() {
    return this.p2EndAt == null ? "(not set)" : this.p2EndAt.toLocaleString()
  }

  get formattedRevealAt() {
    return this.revealAt == null ? "(not set)" : this.revealAt.toLocaleString()
  }

  get formattedClassName() {
    return this.sections[0].displayName
  }

  get status() {
    return this.getStatus()
  }

  // property get/setters for dialog
  get assessmentName() {
    if (this.assessmentType != null) {
      return `${this.assessmentType.courseType.toUpperCase()} ${this.assessmentSet.name}`
    } else {
      return ""
    }
  }

  set assessmentName(value) {
    this.assessmentSet = value.assessmentSet
    this.assessmentType = value.assessmentType
  }

  get formattedP1StartAtDate() {
    if (this.p1StartAt != null) {
      return this.p1StartAt.toDateString()
    } else {
      return ""
    }
  }

  get p1StartAtDate() {
    if (this.p1StartAt != null) {
      return moment(this.p1StartAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set p1StartAtDate(value) {
    const prevDate = this.p1StartAt
    const dateTemp = new Date(value)
    this.p1StartAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

    if (prevDate != null) {
      this.p1StartAt.setHours(prevDate.getHours())
      this.p1StartAt.setMinutes(prevDate.getMinutes())
    } else {
      this.p1StartAt.setHours(new Date().getHours())
      this.p1StartAt.setMinutes(new Date().getMinutes())
    }
  }

  get p1StartAtTime() {
    if (this.p1StartAt != null) {
      return `${this.p1StartAt.getHours().toString().padStart(2, '0')}:${this.p1StartAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set p1StartAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.p1StartAt != null) {
        this.p1StartAt = new Date(this.p1StartAt.getFullYear(), this.p1StartAt.getMonth(), this.p1StartAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        let today = new Date()
        this.p1StartAt = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.getHours(), time.getMinutes())
      }
    }
  }

  get formattedP1EndAtDate() {
    if (this.p1EndAt != null) {
      return this.p1EndAt.toDateString()
    } else {
      return ""
    }
  }

  set formattedP1EndAtDate(value) {
    this.p1EndAt = value
  }

  get p1EndAtDate() {
    if (this.p1EndAt != null) {
      return moment(this.p1EndAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set p1EndAtDate(value) {
    if (value != null) {
      const prevDate = this.p1EndAt
      const dateTemp = new Date(value)
      this.p1EndAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

      if (prevDate != null) {
        this.p1EndAt.setHours(prevDate.getHours())
        this.p1EndAt.setMinutes(prevDate.getMinutes())
      } else {
        this.p1EndAt.setHours(new Date().getHours())
        this.p1EndAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.p1EndAt = null
    }
  }

  get p1EndAtTime() {
    if (this.p1EndAt != null) {
      return `${this.p1EndAt.getHours().toString().padStart(2, '0')}:${this.p1EndAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set p1EndAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.p1EndAt != null) {
        this.p1EndAt = new Date(this.p1EndAt.getFullYear(), this.p1EndAt.getMonth(), this.p1EndAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        const nextWeek = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        this.p1EndAt = new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.p1EndAt = null
    }
  }

  get formattedP2StartAtDate() {
    if (this.p2StartAt != null) {
      return this.p2StartAt.toDateString()
    } else {
      return ""
    }
  }

  set formattedP2StartAtDate(value) {
    this.p2StartAt = value
  }

  get p2StartAtDate() {
    if (this.p2StartAt != null) {
      return moment(this.p2StartAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set p2StartAtDate(value) {
    if (value != null) {
      const prevDate = this.p2StartAt
      const dateTemp = new Date(value)
      this.p2StartAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

      if (prevDate != null) {
        this.p2StartAt.setHours(prevDate.getHours())
        this.p2StartAt.setMinutes(prevDate.getMinutes())
      } else {
        this.p2StartAt.setHours(new Date().getHours())
        this.p2StartAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.p2StartAt = null
    }
  }

  get p2StartAtTime() {
    if (this.p2StartAt != null) {
      return `${this.p2StartAt.getHours().toString().padStart(2, '0')}:${this.p2StartAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set p2StartAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.p2StartAt != null) {
        this.p2StartAt = new Date(this.p2StartAt.getFullYear(), this.p2StartAt.getMonth(), this.p2StartAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        const nextWeek = new Date(Date.now() + 8 * 24 * 60 * 60 * 1000)
        this.p2StartAt = new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.p2StartAt = null
    }
  }

  get formattedP2EndAtDate() {
    if (this.p2EndAt != null) {
      return this.p2EndAt.toDateString()
    } else {
      return ""
    }
  }

  set formattedP2EndAtDate(value) {
    this.p2EndAt = value
  }

  get p2EndAtDate() {
    if (this.p2EndAt != null) {
      return moment(this.p2EndAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set p2EndAtDate(value) {
    if (value != null) {
      const prevDate = this.p2EndAt
      const dateTemp = new Date(value)
      this.p2EndAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )

      if (prevDate != null) {
        this.p2EndAt.setHours(prevDate.getHours())
        this.p2EndAt.setMinutes(prevDate.getMinutes())
      } else {
        this.p2EndAt.setHours(new Date().getHours())
        this.p2EndAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.p2EndAt = null
    }
  }

  get p2EndAtTime() {
    if (this.p2EndAt != null) {
      return `${this.p2EndAt.getHours().toString().padStart(2, '0')}:${this.p2EndAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set p2EndAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.p2EndAt != null) {
        this.p2EndAt = new Date(this.p2EndAt?.getFullYear(), this.p2EndAt?.getMonth(), this.p2EndAt?.getDate(), time.getHours(), time.getMinutes())
      } else {
        const nextWeek = new Date(Date.now() + 9 * 24 * 60 * 60 * 1000)
        this.p2EndAt = new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate(), time.getHours(), time.getMinutes())
      }
    }else {
      this.p2EndAt = null
    }
  }

  get formattedRevealAtDate() {
    if (this.revealAt != null) {
      return this.revealAt.toDateString()
    } else {
      return ""
    }
  }

  set formattedRevealAtDate(value) {
    this.revealAt = value
  }

  get revealAtDate() {
    if (this.revealAt != null) {
      return moment(this.revealAt).format().toString().substring(0, 10)
    } else {
      return ""
    }
  }

  set revealAtDate(value) {
    if (value != null) {
      const prevDate = this.revealAt
      const dateTemp = new Date(value)
      this.revealAt = new Date( dateTemp.getTime() + Math.abs(dateTemp.getTimezoneOffset()*60000) )
      if (prevDate != null) {
        this.revealAt.setHours(prevDate.getHours())
        this.revealAt.setMinutes(prevDate.getMinutes())
      } else {
        this.revealAt.setHours(new Date().getHours())
        this.revealAt.setMinutes(new Date().getMinutes())
      }
    } else {
      this.revealAt = null
    }
  }

  get revealAtTime() {
    if (this.revealAt != null) {
      return `${this.revealAt.getHours().toString().padStart(2, '0')}:${this.revealAt.getMinutes().toString().padStart(2, '0')}`
    } else {
      return ""
    }
  }

  set revealAtTime(value) {
    if (value != null) {
      let str = value.split(":")
      let time = new Date(0,0,0, str[0], str[1])
      if (this.revealAt != null) {
        this.revealAt = new Date(this.revealAt.getFullYear(), this.revealAt.getMonth(), this.revealAt.getDate(), time.getHours(), time.getMinutes())
      } else {
        const nextWeek = new Date(Date.now() + 10 * 24 * 60 * 60 * 1000)
        this.revealAt = new Date(nextWeek.getFullYear(), nextWeek.getMonth(), nextWeek.getDate(), time.getHours(), time.getMinutes())
      }
    } else {
      this.revealAt = null
    }
  }
}