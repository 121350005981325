export default function (value, requiredLength = 15) {
  if (!value) { return '' }

  value = value.toString()
  if (value.length > requiredLength) {
    return value.slice(0, requiredLength - 3) + '...'
  }

  return value
}
