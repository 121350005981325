function initialState () {
  return {
    allTerms: []
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    getAllTerms: (state) => (state.allTerms)
  },
  mutations: {
    resetState: (state) => {
      const s = initialState()
      Object.keys(s).forEach( key => {
        state[key] = s[key]
      })
      sessionStorage.clear();
    },
    updateStoredTerms: (state, terms) => {
      state.allTerms = terms
    }
  },
  actions: {
    storeTerms({commit}, terms) {
      commit('updateStoredTerms', terms)
    }
  }
}
