<template>
  <v-dialog
    :value="dialog"
    max-width="700px"
    persistent
  >
    <v-card class="card-dialog">
      <app-header
        title="Create Class"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-form>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pr-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="name"
              >
                Class Name
              </label>
              <v-text-field
                id="name"
                v-model="classData.name"
                :hide-details="!$v.classData.name.$error"
                error-messages="Please fill the class name"
                solo
                flat
                class="input-default input-default__small mt-1"
                @change="onClassNameChange()"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0 pl-sm-6"
            >
              <label
                class="label-default label-default__small"
                for="displayName"
              >
                Display Name
              </label>
              <v-text-field
                id="displayName"
                v-model="classData.displayName"
                :hide-details="!$v.classData.displayName.$error"
                error-messages="Please fill the display name"
                solo
                flat
                class="input-default input-default__small mt-1"
              />
            </v-col>
            <v-col
              cols="12"
              class="pt-0 pb-0"
            >
              <label
                class="label-default label-default__small"
              >
                Term
              </label>
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <div class="d-flex align-center">
                <v-autocomplete
                  v-model="classData.term"
                  hide-details
                  placeholder="Select Term"
                  item-text="name"
                  item-value="id"
                  solo
                  dense
                  return-object
                  flat
                  class="input-default"
                  :items="termsList"
                />
              </div>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 pb-0"
            >
              <label
                class="label-default label-default__small"
              >
                Primary Teacher
              </label>
            </v-col>
            <v-col
              v-for="(primaryTeacher, index) in classData.primaryTeachers"
              :key="primaryTeacher?.id"
              cols="12"
              class="pt-0"
            >
              <div class="d-flex">
                <v-autocomplete
                  v-model="classData.primaryTeachers[index]"
                  hide-details
                  placeholder="Select Teacher"
                  :item-text="item => `${item.firstName} ${item.lastName}`"
                  item-value="id"
                  solo
                  dense
                  return-object
                  flat
                  class="input-default input-default__small mt-1"
                  :items="filteredPrimaryTeachers(index)"
                />
                <v-icon
                  v-if="index !== 0"
                  color="#3d5d74"
                  class="ml-2 icon-no-ripple"
                  @click="onRemovePrimaryTeacher(index)"
                >
                  mdi-close
                </v-icon>
              </div>
              <v-btn
                v-if="classData.primaryTeachers.length - 1 === index"
                class="btn-default btn-default__secondary mt-2"
                @click="onAddPrimaryTeacher()"
              >
                <v-img
                  class="mr-2"
                  contain
                  :src="require('@/assets/icons/user-add.svg')"
                  alt="icon-add"
                  max-width="19"
                  max-height="19"
                />
                <span class="font-weight-400 font-size-14">Add Primary Teacher</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              class="pt-0 pb-0"
            >
              <label
                class="label-default label-default__small"
              >
                Secondary Teacher
              </label>
            </v-col>
            <v-col
              v-for="(secondaryTeacher, index) in classData.secondaryTeachers"
              :key="secondaryTeacher?.id"
              cols="12"
              class="pt-0"
            >
              <div class="d-flex">
                <v-autocomplete
                  v-model="classData.secondaryTeachers[index]"
                  hide-details
                  placeholder="Select Teacher"
                  :item-text="item => `${item.firstName} ${item.lastName}`"
                  item-value="id"
                  solo
                  dense
                  return-object
                  flat
                  class="input-default input-default__small mt-1"
                  :items="filteredSecondaryTeachers(index)"
                />
                <v-icon
                  v-if="index !== 0"
                  color="#3d5d74"
                  class="ml-2 icon-no-ripple"
                  @click="onRemoveSecondaryTeacher(index)"
                >
                  mdi-close
                </v-icon>
              </div>
              <v-btn
                v-if="classData.secondaryTeachers.length - 1 === index"
                class="btn-default btn-default__secondary mt-2"
                @click="onAddSecondaryTeacher()"
              >
                <v-img
                  class="mr-2"
                  contain
                  :src="require('@/assets/icons/user-add.svg')"
                  alt="icon-add"
                  max-width="19"
                  max-height="19"
                />
                <span class="font-weight-400 font-size-14">Add Secondary Teacher</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            dense
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
              class="pr-sm-2"
            >
              <v-btn
                class="btn-default btn-default__secondary btn-default__small"
                block
                @click="onCloseDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pl-sm-2"
            >
              <v-btn
                dark
                class="btn-default btn-default__primary btn-default__small"
                block
                @click="onSubmitForm()"
              >
                Create Class
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="$v.$error"
            dense
            justify="end"
          >
            <v-col cols="auto">
              <h6 class="color-danger">
                Please check your inputs
              </h6>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
const {mapGetters: mapTermGetters} = createNamespacedHelpers('terms')
import {validationMixin} from "vuelidate/src";
import {required} from "vuelidate/lib/validators";
import AppHeader from "@/components/shared/AppHeader.vue";
import {AdminSchoolApi, AdminSectionApi, AdminUserApi, StaffSectionApi, StaffUserApi} from "@/api";

export default {
  name: "DialogCreateClass",
  components: {AppHeader},
  mixins: [validationMixin],

  props: {
    active: Boolean,
    schoolId: {
      type: [String, Number],
      default: null
    },
  },

  data() {
    return {
      dialog: false,
      secondaryTeacherSelects: [{}],
      classData: {
        name: '',
        displayName: '',
        primaryTeachers: [{}],
        secondaryTeachers: [{}],
      },
      teachersList: [],
      termsList: []
    }
  },

  validations: {
    classData: {
      name: {required},
      displayName: {required},
    }
  },

  computed: {},

  watch: {
    active(value) {
      if (value) {
        this.cleanClassForm()
        this.loadAvailableTeachers()
        this.dialog = value
      } else {
        this.dialog = value
      }

    }
  },

  mounted() {
    this.fetchTerms()
  },

  methods: {
    ...mapGetters(["userRole"]),
    ...mapTermGetters(["getAllTerms"]),

    async loadAvailableTeachers() {
      try {
        if (this.userRole() === "Horizon Admin") {
          const response = await AdminUserApi.getTeachersMinimal(this.schoolId)
          this.teachersList = response.users
        } else {
          const response = await StaffUserApi.getTeachersMinimal()
          this.teachersList = response.users
        }
      } catch {
        this.addNotification('error', 'Failed to load teachers.')
      }
    },

    async fetchTerms() {
      if (this.userRole() === "Horizon Admin") {
        this.termsList = (await AdminSchoolApi.getSchoolTermsAll(this.schoolId)).terms
      } else {
        this.termsList = this.getAllTerms()
      }
      this.termsList = this.termsList.filter(t => !t.archived)
    },

    async onSubmitForm() {
      try {
        this.loading = true
        this.$v.$reset()
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        // Handling teachers array
        const primaryTeachersList = this.classData.primaryTeachers.flatMap(item => {
          if (item && item.id) {
            return {
              userId: item.id,
              role: "Primary Teacher"
            }
          }
          return []
        })
        const secondaryTeachersList = this.classData.secondaryTeachers.flatMap(item => {
          if (item && item.id) {
            return {
              userId: item.id,
              role: "Secondary Teacher"
            }
          }
          return []
        })

        const teachersList = [...primaryTeachersList, ...secondaryTeachersList]
        const objToSend = {
          displayName: this.classData.displayName,
          className: this.classData.name,
          schoolId: this.schoolId,
          membershipsAttributes: teachersList,
          termId: this.classData.term.id
        }

        if (this.userRole() === 'Horizon Admin') {
          await AdminSectionApi.createSection(objToSend)
        } else {
          await StaffSectionApi.create(objToSend)
        }
        this.addNotification('success', 'Class created successfully')
        this.$emit('closeDialogUpdate')
      } catch {
        this.addNotification('error', 'Failed to create class. Please try again.')
      } finally {
        this.loading = false
      }
    },

    onClassNameChange() {
      this.classData.displayName = this.classData.name
    },

    filteredPrimaryTeachers(index) {
      const selectedPrimaryTeachers = this.classData.primaryTeachers
          .filter((_, i) => i !== index)

      const selectedTeachers = [...selectedPrimaryTeachers, ...this.classData.secondaryTeachers]
      return this.teachersList.filter(item => !selectedTeachers.includes(item));
    },

    filteredSecondaryTeachers(index) {
      const selectedSecondaryTeachers = this.classData.secondaryTeachers
          .filter((_, i) => i !== index)

      const selectedTeachers = [...selectedSecondaryTeachers, ...this.classData.primaryTeachers]
      return this.teachersList.filter(item => !selectedTeachers.includes(item));
    },

    onAddPrimaryTeacher() {
      this.classData.primaryTeachers.push({})
    },

    onAddSecondaryTeacher() {
      this.classData.secondaryTeachers.push({})
    },

    onRemovePrimaryTeacher(index) {
      this.classData.primaryTeachers.splice(index, 1)
    },

    onRemoveSecondaryTeacher(index) {
      this.classData.secondaryTeachers.splice(index, 1)
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },

    cleanClassForm() {
      this.$v.$reset()
      this.classData = {
        name: '',
        displayName: '',
        primaryTeachers: [{}],
        secondaryTeachers: [{}],
      }
    }
  }
}
</script>

<style scoped>

</style>