<template>
  <div>
    <v-container fluid>
      <app-header
        title="Assessments"
      />
    </v-container>

    <app-tabs-secondary
      :tabs="tabs"
      :active-tab="tabSelected"
      @onTabChange="onLoadCategory(...arguments)"
    />

    <router-view />
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue";
import AppTabsSecondary from "@/components/shared/AppTabsSecondary.vue";

export default {
  name: "StaffAssessments",
  components: {
    AppTabsSecondary,
    AppHeader
  },

  data() {
    return {
      tabSelected: 0,
      tabs: [
        {
          tabText: 'Assessment Schedule',
          text: 'Assessment Schedule',
          path: '/staff/assessments'
        },
        {
          tabText: 'Assessment Monitoring',
          text: 'Assessment Monitoring',
          path: '/staff/assessments/monitoring'
        }
      ],
    }
  },

  watch: {
    $route() {
      this.setActiveTab()
    }
  },

  mounted() {
    this.setActiveTab()
  },

  methods: {
    onLoadCategory(tab) {
      if (this.tabSelected != tab) {
        this.tabSelected = tab
        this.$router.push({path: this.tabs[tab].path})
      }
    },

    setActiveTab() {
      let path = this.$route.path
      if (path.slice(0, 29) == "/staff/assessments/monitoring") {
        this.tabSelected = 1
      } else {
        this.tabSelected = 0
      }
    }
  },
}
</script>

<style scoped>

</style>