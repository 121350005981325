<template>
  <v-container>
    <div v-if="reportLoading">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <div v-else-if="compositeReport?.studentAssessments?.length > 0">
      <student-report-composite
        :composite-report-prop="compositeReport"
        :student-id="studentId"
        @downloadPdf="downloadPdf()"
      />
    </div>
    <div
      v-else
      class="pt-4"
    >
      <h4>This report does not exist, or the student did not complete both parts of the assessment section.</h4>
    </div>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
const {mapActions} = createNamespacedHelpers('app_config')
import StudentReportComposite from "@/components/student/reports/StudentReportComposite.vue";
import {ParentStudentsApi} from "@/api";

export default {
  name: "ParentReportComposite",
  components: {StudentReportComposite},

  props: {
    studentId: {
      type: [Number, String],
      required: true
    },
    sectionId: {
      type: [Number, String],
      required: false,
      default: null
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    studentName: {
      type: String,
      default: ''
    },
    year: {
      type: [Number, String],
      required: false,
      default: null
    }

  },

  data() {
    return {
      reportLoading: true,
      compositeReport: null,
      questionsAndAnswers: null,
      studentNameLocal: ''
    }
  },

  mounted() {
    this.updateMenu({role: this.user().role, data: this.user(), showParentReportsNav: true})
    this.loadReport()
  },

  methods: {
    ...mapGetters(["user"]),
    ...mapActions(['updateMenu',]),

    async loadReport() {
      this.reportLoading = true
      try {
        const response = await ParentStudentsApi.getCompositeReport({
          studentId: this.studentId,
          assessmentType: this.assessmentTypeId,
          assessmentSet: this.assessmentSetId,
          schoolYearId: this.year
        })
        this.compositeReport = response.compositeReport
        if (this.studentName === '') {
          this.studentNameLocal = `${this.compositeReport.user.firstName} ${this.compositeReport.user.lastName}`
          this.updateStudentName()
        }
      } catch {
        this.addNotification('error', "The report could not be loaded.")
      } finally {
        this.reportLoading = false
      }


    },

    downloadPdf() {
      this.addNotification('info', 'Generating PDF report...')
      ParentStudentsApi.downloadPdf({
        studentId: this.studentId,
        assessmentSetId: this.assessmentSetId,
        assessmentTypeId: this.assessmentTypeId,
        schoolYearId: this.year
      })
          .then(({filename, blob}) => {
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click();
          }, (responseError) => {
            this.addNotification('error', responseError)
          })
    },

    async updateStudentName() {
      const studentIdTemp = this.studentId
      await this.$router.replace({params: {studentId: ' ', studentName: this.studentNameLocal}})
      await this.$router.replace({
        params: {studentId: studentIdTemp, studentName: this.studentNameLocal}
      })
    }
  }
}
</script>
