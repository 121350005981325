import HorizonApi from '@/api/horizon_api'

export default {
  getCompositeReport({ studentId, sectionId, assessmentSetId, assessmentTypeId, schoolYearId } = {}) {
    let params = {
      params: {
        student_id: studentId,
        assessment_set: assessmentSetId,
        assessment_type: assessmentTypeId,
        school_year_id: schoolYearId
      }
    }

    if (sectionId == null) {
      return HorizonApi.get(`/api/staff/school/student_assessments/composite_report`, params)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_assessments/composite_report`, params)
    }
  },

  downloadPdf ({ studentId, sectionId, assessmentSetId, assessmentTypeId, schoolYearId } = {}) {
    let params = {
      assessment_type: assessmentTypeId,
      assessment_set: assessmentSetId,
      school_year_id: schoolYearId,
      student_id: studentId        
    }
    if (sectionId == null) {
      return HorizonApi.downloadInline(`/api/staff/school/student_assessments/pdf_report`, params)    
    } else {
      return HorizonApi.downloadInline(`/api/staff/sections/${sectionId}/student_assessments/pdf_report`, params)    
    }
  },

  questions_and_answers ({ sectionId, assessmentId, multipart } = {}) {
    if (sectionId == null) {
      return HorizonApi.get(`/api/staff/school/student_assessments/${assessmentId}/questions_and_answers?multipart=${multipart}`)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_assessments/${assessmentId}/questions_and_answers?multipart=${multipart}`)
    }
  },

  reset ({ sectionId, assessmentId } = {}) {
    if (sectionId == null) {
      return HorizonApi.get(`/api/staff/school/student_assessments/${assessmentId}/reset`)    
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_assessments/${assessmentId}/reset`)    
    }
  },

  generateReport({ sectionId, assessmentId } = {}) {
    if (sectionId == null) {
      return HorizonApi.get(`/api/staff/school/student_assessments/${assessmentId}/generate_report`)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_assessments/${assessmentId}/generate_report`)
    }
  },

  learnositySessionDetailByItem({ sectionId, assessmentId } = {}) {
    if (sectionId == null) {
      return HorizonApi.get(`/api/staff/school/student_assessments/${assessmentId}/learnosity_session_detail_by_item`)
    } else {
      return HorizonApi.get(`/api/staff/sections/${sectionId}/student_assessments/${assessmentId}/learnosity_session_detail_by_item`)
    }
  },


}