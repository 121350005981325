function initialState () {
  return {
    allSections: [],
    primarySections: [],
    secondarySections: []
  }
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    getAllSections: (state) => (state.allSections),
    getPrimarySections: (state) => state.primarySections,
    getSecondarySections: (state) => state.secondarySections
  },
  mutations: {
    resetState: (state) => {
      const s = initialState()
      Object.keys(s).forEach( key => {
        state[key] = s[key]
      })
      sessionStorage.clear();
    },
    updateStoredSections: (state, sections) => {
      state.allSections = sections
      state.primarySections = sections.filter(section => section.role == "Primary Teacher")
      state.secondarySections = sections.filter(section => section.role == "Secondary Teacher")
    }
  },
  actions: {
    storeSections({commit}, sections) {
      commit('updateStoredSections', sections)
    }
  }
}
