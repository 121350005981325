import HorizonApi from '@/api/horizon_api'

const LEARNOSITY_SHARED_PATH = `/api/learnosity`

export default {
  getQuestionPreview(questionId) {
    let params = {
      params: {
        id: questionId
      }
    }

    return HorizonApi.get(`${LEARNOSITY_SHARED_PATH}/question_preview`, params)
  },
}
