import HorizonApi from '@/api/horizon_api'

const MULTIPLE_SCHOOLS_PATH = `/api/multiple_schools`

export default {

  list() {
    return HorizonApi.get(`${MULTIPLE_SCHOOLS_PATH}`)
  },

  switchSchool({ schoolId }) {
    let params = {
      school_id: schoolId
    }
    return HorizonApi.patch(`${MULTIPLE_SCHOOLS_PATH}`, params)
  },

  unlink() {
    return HorizonApi.post(`${MULTIPLE_SCHOOLS_PATH}/unlink`)
  }

}