import HorizonApi from '@/api/horizon_api'

const SCHOOL_PATH = `/api/admin/schools`

export default {
  list (page = 1, perPage, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    };
    return HorizonApi.get(SCHOOL_PATH, {params: params})
  },

  listAll () {
    return HorizonApi.get(`${SCHOOL_PATH}?render_all=true`)
  },

  create (school) {
    return HorizonApi.post(SCHOOL_PATH, school)
  },

  get (school_id) {
    return HorizonApi.get(`${SCHOOL_PATH}/${school_id}`)
  },

  update (school_id, school) {
    return HorizonApi.patch(`${SCHOOL_PATH}/${school_id}`, {school: school})
  },

  delete (school_id) {
    return HorizonApi.delete(`${SCHOOL_PATH}/${school_id}`)
  },

  cleverSync (school_id) {
    return HorizonApi.post(`${SCHOOL_PATH}/${school_id}/clever_sync`)
  },

  classlinkSync (school_id) {
    return HorizonApi.post(`${SCHOOL_PATH}/${school_id}/classlink_sync`)
  },

  getSisSyncRecordList (school_id) {
    return HorizonApi.get(`${SCHOOL_PATH}/${school_id}/sis_sync_record_list`)
  },

  getSisSyncReport (school_id, record_id) {
    const params = {
      'record_id': record_id
    };

    return HorizonApi.get(`${SCHOOL_PATH}/${school_id}/sis_sync_report`, {params: params})
  },

  getUsersByRole(schoolId, role, page, perPage, sort, order, searchProp, filters, archived){
    let params = {
      'role[]': role,
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'archived': archived,
    }

    for(let filter of Object.keys(filters)){
      params[filter] = filters[filter]
    }

    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/users`, {params: params})
  },

  getStudents (schoolId) {
    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/users?role[]=Student`)
  },

  getTeachers (schoolId) {
    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/users?role[]=Teacher`)
  },

  getAdminTeachers (schoolId) {
    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/users?role[]=Teacher Admin`)
  },

  createTeacher(schoolId, userObj){
    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/users`, {user: userObj})
  },

  importUsers (schoolId, role, csvFile) {
    let fd = new FormData();

    fd.append("file", csvFile)
    fd.append("role", role)

    let headers = {
      "Content-Type": "multipart/form-data"
    }

    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/users/import`, fd, headers)
  },

  importParents (schoolId, role, csvFile) {
    let fd = new FormData();

    fd.append("file", csvFile)
    fd.append("role", role)

    let headers = {
      "Content-Type": "multipart/form-data"
    }

    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/users/import_parents`, fd, headers)
  },

  uploadAssessmentResults(schoolId, csvFile, typeId, setId){
    let fd = new FormData();

    fd.append("file", csvFile)
    fd.append("assessment_type_id", typeId)
    fd.append("assessment_set_id", setId)

    let headers = {
      "Content-Type": "multipart/form-data"
    }
    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/assessments/import_results`, fd, headers)
  },

  createMultipleUsers (schoolId, role, usersArray) {
    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/users/bulk_create?role=${role}`, {users: usersArray})
  },

  getClasses (schoolId, page, perPage, sort, order, searchProp, filters, active, archived) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'active': active,
      'archived': archived
    };

    for(let filter of Object.keys(filters)){
      params[filter] = filters[filter]
    }

    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/sections`, {params: params})
  },

  getAllClassesMinimal ({schoolId, schoolYear = null, editable = null, active = true, archived = null, terms} = {}) {
    const params = {
      'school_year_id': schoolYear,
      'editable' : editable,
      'active': active,
      'archived': archived,
      'term': terms,
    }
    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/sections/minimal_index`, {params: params})
  },

  studentRosterExport({ schoolId, role }) {
      return HorizonApi.downloadInline(`${SCHOOL_PATH}/${schoolId}/users/export?role=${role}`)
  },

  getSchoolTerms (school_id, page, perPage, sort, order, searchProp, archived) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
      'archived': archived
    };
    return HorizonApi.get(`${SCHOOL_PATH}/${school_id}/terms`, {params: params})
  },

  getSchoolTermsAll (school_id) {
    return HorizonApi.get(`${SCHOOL_PATH}/${school_id}/terms/render_all`)
  },

  bulkDeleteTerms({schoolId, idArray} = {}) {
    const params = { data: {
        'term_ids': idArray
      }
    }
    return HorizonApi.delete(`${SCHOOL_PATH}/${schoolId}/terms/bulk_delete`, params)
  },

  statusChangeTermsBulk({schoolId, idArray, status} = {}) {
    const params = {
      term_ids: idArray,
      archive_state: status
    }

    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/terms/bulk_archive`, params)
  },

  deleteTerm({schoolId, termId} = {}) {
    return HorizonApi.delete(`${SCHOOL_PATH}/${schoolId}/terms/${termId}`)
  },

  showTerm(schoolId, termId) {
    return HorizonApi.get(`${SCHOOL_PATH}/${schoolId}/terms/${termId}`)
  },

  createTerm({schoolId, termProp} = {}) {
    const params = {
      term: {
        'name': termProp.name,
        'start_date': termProp.startDate,
        'end_date': termProp.endDate,
      }
    }
    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/terms`, params)
  },

  updateTerm({schoolId, termId, termProp} = {}) {
    const params = {
      term: {
        'name': termProp.name,
        'start_date': termProp.startDate,
        'end_date': termProp.endDate,
      }
    }
    return HorizonApi.patch(`${SCHOOL_PATH}/${schoolId}/terms/${termId}`, params)
  },

  archiveUsers (schoolId, userIds) {
    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/users/archive_users`, {'user_ids': userIds})
  },

  unarchiveUsers (schoolId, userIds) {
    return HorizonApi.post(`${SCHOOL_PATH}/${schoolId}/users/unarchive_users`, {'user_ids': userIds})
  },

}
