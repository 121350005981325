<template>
  <v-dialog
    :value="dialog"
    max-width="700px"
    persistent
  >
    <v-card
      v-if="dialog"
      class="card-dialog"
    >
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="title"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-form ref="termForm">
          <v-row>
            <v-col
              cols="12"
              class="pt-0"
            >
              <label
                class="label-default label-default__small"
                for="termName"
              >
                Term Name
              </label>
              <v-text-field
                id="termName"
                v-model="termData.name"
                :rules="rules.name"
                solo
                flat
                class="input-default input-default__small mt-1"
                placeholder="Name"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0"
            >
              <assessment-datepicker
                :date="termData.startDate"
                :formatted-date="formattedDate(termData.startDate)"
                :rules="rules.startDate"
                label="Start Date"
                @update:date="e => termData.startDate = e"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pt-0"
            >
              <assessment-datepicker
                :date="termData.endDate"
                :formatted-date="formattedDate(termData.endDate)"
                :rules="rules.endDate"
                label="End Date"
                @update:date="e => termData.endDate = e"
              />
            </v-col>
          </v-row>
          <v-row
            dense
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
              class="pr-sm-2"
            >
              <v-btn
                class="btn-default btn-default__secondary btn-default__small"
                block
                @click="onCloseDialog()"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              class="pl-sm-2"
            >
              <v-btn
                dark
                class="btn-default btn-default__primary btn-default__small"
                block
                @click="onSubmitForm()"
              >
                <span v-if="type === 'new'">Create Term</span>
                <span v-else>Update Term</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="!formValid"
            dense
            justify="end"
          >
            <v-col cols="auto">
              <h6 class="color-danger">
                Please check your inputs
              </h6>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters} = createNamespacedHelpers('user')
import AppHeader from "@/components/shared/AppHeader.vue";
import AssessmentDatepicker from "@/components/shared/AssessmentDatepicker.vue";
import {AdminSchoolApi, StaffSchoolApi} from "@/api";
import moment from "moment/moment";

export default {
  name: "DialogTermForm",
  components: {AssessmentDatepicker, AppHeader},

  props: {
    active: Boolean,
    type: {
      default: 'new',
      type: String
    },
    schoolId: {
      type: [Number, String],
      default: null
    },
    title: {
      default: '',
      type: String,
      required: true,
    },
    termProp: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      formValid: true,
      rules: {
        name: [
          v => !!v || 'Term name is required'
        ],
        startDate: [
          v => !!v || 'Start date is required'
        ],
        endDate: [
          v => {
            let condition = !!v && (this.termData.startDate && this.termData.endDate > this.termData.startDate)
            return condition || 'End date must be after start date'
          }
        ],
      },
      termData: {
        name: '',
        startDate: '',
        endDate: '',
      }
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.cleanForm()
        if (this.type === 'edit') {
          this.termData.id = this.termProp.id
          this.termData.name = this.termProp.name
          this.termData.startDate = moment(this.termProp.startDate).format().toString().substring(0, 10)
          this.termData.endDate = moment(this.termProp.endDate).format().toString().substring(0, 10)
        }

        this.dialog = value
      } else {
        this.dialog = value
      }
    },

    'termData.startDate'() {
      this.$refs.termForm?.validate()
    },

    'termData.endDate'() {
      this.$refs.termForm?.validate()
    },

  },

  methods: {
    ...mapGetters(['userRole']),

    async onSubmitForm() {
      try {
        this.loading = true
        if (!this.$refs.termForm.validate()) {
          this.formValid = false
          return
        }

        const objToSend = {
          name: this.termData.name,
          startDate: new Date(this.termData.startDate).toISOString(),
          endDate: new Date(this.termData.endDate).toISOString(),
        }

        const api = (this.userRole() === 'Horizon Admin') ? AdminSchoolApi : StaffSchoolApi
        if (this.type === 'new') {
          await api.createTerm({
            schoolId: this.schoolId,
            termProp: objToSend
          })
        } else {
          await api.updateTerm({
            schoolId: this.schoolId,
            termId: this.termData.id,
            termProp: objToSend
          })
        }

        this.$emit('closeDialogUpdate')
      } catch {
        this.addNotification('error', 'Failed to create/edit the term. Please try again.')
      } finally {
        this.loading = false
      }
    },

    cleanForm() {
      this.$refs.termForm?.resetValidation()
      this.formValid = true
      this.termData = {
        name: '',
        startDate: '',
        endDate: '',
      }
    },

    onCloseDialog() {
      this.$emit('closeDialog')
    },


    formattedDate(dateProp) {
      if (dateProp !== '') {
        const date = new Date(dateProp)
        return date.toDateString()
      } else {
        return ""
      }
    }
  }

}
</script>

<style scoped>

</style>