<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <!-- Overall Assessment Performance -->
    <v-card
      outlined
    >
      <v-card-title>
        <h3 class="font-weight-700">
          Overall Assessment Performance
        </h3>
      </v-card-title>
      <v-divider />
      <v-card-subtitle class="d-flex align-center">
        <v-icon
          size="20"
          color="#38566b"
          class="mr-1"
        >
          mdi-account-group
        </v-icon>
        <span class="font-size-16">
          Number of Students Assessed: <span class="font-weight-700 color-dark-orange">{{ studentAssessedCount }} Students</span>
        </span>
      </v-card-subtitle>
      <v-card-text>
        <v-row class="mt-2">
          <v-col
            cols="12"
            sm="4"
          >
            <donut-chart
              chart-id="summaryDonutChart"
              :average-score="overview.composite?.score"
              :is-average-score="true"
              :min-score="overview.composite?.minScore"
              :max-score="overview.composite?.maxScore"
            />
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="bar-chart-dashboard"
          >
            <bar-chart
              chart-id="summaryBarChart"
              :data-set="overview.composite?.segments"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- end Overall Assessment Performance -->

    <!-- Section Scores -->
    <v-card
      class="mt-6"
      flat
      outlined
    >
      <v-card-title>
        <h3 class="font-weight-700">
          Section Scores
        </h3>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div
          v-for="category in overview.categories"
          :key="category.category"
        >
          <h4 class="section-title font-weight-700">
            {{ category.category }}
          </h4>
          <div
            v-for="benchmarkScore in category.benchmarkScores"
            :key="benchmarkScore.name"
            class="section-subtitle"
          >
            {{ benchmarkScore?.name }}: {{ benchmarkScore?.score }}
          </div>
          <v-row
            class="mt-2"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <donut-chart
                :chart-id="`section-score-${category?.category?.split(' ')[0]}-donut`"
                :average-score="category?.score"
                :is-average-score="true"
                :min-score="category?.minScore"
                :max-score="category?.maxScore"
                :color="setSectionColor(category?.category)"
              />
            </v-col>
            <v-col
              cols="12"
              sm="8"
              class="bar-chart-dashboard"
            >
              <bar-chart
                :include-benchmark="assessmentTypeId != 5"
                :chart-id="`section-score-${category?.category.split(' ')[0]}-bar`"
                :benchmark-placement="category?.benchmarkPlacement"
                :data-set="category?.segments"
                :color="setSectionColor(category?.category)"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <!-- end Section Scores -->

    <!-- Strength and Improvements -->
    <v-card
      class="mt-6"
      flat
      outlined
    >
      <v-card-title>
        <h3 class="font-weight-700">
          Strength and Improvement Areas
        </h3>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <h4 class="section-title font-weight-700">
          Strength Areas
        </h4>
        <v-row class="mb-3">
          <v-col
            cols="12"
            sm="8"
          >
            <v-list-item
              v-for="(area, idx) in strengthAndImprovements.strengthAreas"
              :key="idx"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    class="category-highlight"
                  >
                    Highest {{ area.categoryName }} Category
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span
                    class="subsection-area-highlight"
                  >
                    {{ area.subsectionAreaName }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span
                    class="topic-area-highlight"
                  >
                    {{ area.topicAreaName }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <span
              class="category-highlight"
            >
              Top 5 Correct Questions
            </span>
            <v-simple-table class="table-default mt-4">
              <template #default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-left">
                      Question
                    </th>
                    <th class="text-left">
                      % Correct
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in strengthAndImprovements.topCorrectQuestions"
                    :key="item.name"
                  >
                    <td>{{ item.question }}</td>
                    <td>{{ item.averageCorrect }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <h4 class="section-title font-weight-700">
          Improvement Areas
        </h4>
        <v-row>
          <v-col cols="8">
            <v-list-item
              v-for="(area, idx) in strengthAndImprovements.improvementAreas"
              :key="idx"
              three-line
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span
                    class="category-highlight"
                  >
                    Lowest {{ area.categoryName }} Category
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span
                    class="subsection-area-highlight"
                  >
                    {{ area.subsectionAreaName }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <span
                    class="topic-area-highlight"
                  >
                    {{ area.topicAreaName }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col>
            <span
              class="category-highlight"
            >
              Top 5 Incorrect Questions
            </span>
            <v-simple-table class="table-default mt-4">
              <template #default>
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-left">
                      Question
                    </th>
                    <th class="text-left">
                      % Incorrect
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in strengthAndImprovements.topIncorrectQuestions"
                    :key="item.name"
                  >
                    <td>{{ item.question }}</td>
                    <td>{{ item.averageCorrect }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- end Strength and Improvements -->

    <!-- Individual Student Performance -->
    <v-card
      v-if="individualStudentPerformanceAvailable"
      class="mt-6"
      flat
      outlined
    >
      <v-card-title>
        <h3 class="font-weight-700">
          Individual Student Performance
        </h3>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row
          dense
          class="table-header-extension"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            xl="3"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="tableSearch"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              class="input-default input-default__table"
              clearable
              hide-details
              solo
              flat
            />
          </v-col>
        </v-row>
        <v-data-table
          :height="getTableHeight()"
          item-key="id"
          :headers="studentHeaders"
          :headers-length="studentHeaders.length + 1"
          :options.sync="tableOptions"
          :server-items-length="totalStudents"
          :items="studentList"
          loading-text="Loading Students"
          :loading="tableLoading ? '#3d5d74' : false"
          class="table-default table-radius-top-none table-default__header-fixed"
          fixed-header
          :footer-props="{
            showCurrentPage: true,
            itemsPerPageOptions:[itemsPerPage],
            disableItemsPerPage: true,
          }"
        >
          <template
            v-for="header in studentHeaders"
            #[`header.${header.value}`]
          >
            <div
              :key="header.text"
              class="d-flex flex-column justify-center h-100"
            >
              <div class="d-flex align-center justify-space-between">
                <span class="mr-3">{{ header.text }}</span>
                <app-table-filter
                  v-if="header.value.includes('Part2')"
                  :filter-options="getFilterOptions(header?.value)"
                  :column="header?.value"
                  :loading="tableLoading"
                  @onFilterChange="onFilterChange"
                />
              </div>
            </div>
          </template>

          <template #[`footer.page-text`]="items">
            <span class="font-weight-700">{{ items.pageStart }}</span> to
            <span class="font-weight-700">{{ items.pageStop }}</span> of
            <span class="font-weight-700">{{ items.itemsLength }}</span>
          </template>

          <template
            v-if="!isMobile"
            #[`item.lastName`]="{ item }"
          >
            <td>
              <span class="mr-3">
                {{ item.firstName }} {{ item.lastName }}
              </span>
            </td>
          </template>

          <template
            #[`item.assessment2`]="{ item }"
          >
            <span
              v-if="item.assessment2 != null"
              v-html="formatAssessmentDifference(item.assessment1, item.assessment2)"
            />
            <span v-else>-</span>
          </template>

          <template
            #[`item.assessment3`]="{ item }"
          >
            <span
              v-if="item.assessment3 != null"
              v-html="formatAssessmentDifference(item.assessment2, item.assessment3)"
            />
            <span v-else>-</span>
          </template>

          <template
            #[`item.actions`]="{ item }"
          >
            <td class="text-center">
              <v-btn
                class="btn-table btn-table__text"
                outlined
                @click="viewCompositeReport(item)"
              >
                View Score Report
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- end Individual Student Performance -->
  </v-container>
</template>

<script>
import DonutChart from "@/components/shared/charts/DonutChart"
import BarChart from "@/components/shared/charts/BarChart"
import AppTableFilter from "@/components/shared/AppTableFilter.vue";

import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapState: mapUserState} = createNamespacedHelpers('user')
const {mapState: mapAssessmentState} = createNamespacedHelpers('assessments')

import {StaffStudentResultsApi} from "@/api"
import debounce from 'lodash/debounce'


export default {
  name: "StaffStudentResultsDashboard",
  components: {
    AppTableFilter,
    DonutChart,
    BarChart
  },

  props: {
    sectionId: {
      type: [Number, String],
      default: null
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
  },

  data() {
    return {
      tableLoading: false,
      onTableSearch: false,
      pageLoadedOnce: false,
      studentAssessedCount: 0,
      totalStudents: 0,
      overview: {},
      strengthAndImprovements: {},
      studentList: [],
      tableSearch: '',
      tableOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      tableActiveFilters: {},
      itemsPerPage: 100,
      tableHeight: 'calc(90vh - 315px)',
      studentHeaders: [
        {
          text: 'Name',
          value: 'lastName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Grade',
          value: 'gradeLevel',
          align: 'left',
          sortable: true,
        },
        {
          text: 'A.1',
          value: 'assessment1',
          align: 'left',
          sortable: true,
        },
        {
          text: 'A.2',
          value: 'assessment2',
          align: 'left',
          sortable: true,
        },
        {
          text: 'A.3',
          value: 'assessment3',
          align: 'left',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 180,
        }
      ]
    }
  },

  computed: {
    ...mapUserState(["user", "userRole"]),
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
    ...mapAssessmentState(['assessmentSets', 'assessmentTypes']),
    assessmentName() {
      let set = this.assessmentSets.find(a => a.id == this.assessmentSetId)
      let aType = this.assessmentTypes.find(a => a.id == this.assessmentTypeId)
      return `${aType.displayName} ${set.name}`
    },
    courseType() {
      return this.user.school.courseType
    },
    individualStudentPerformanceAvailable() {
      if (this.userRole === "Teacher Admin" || this.userRole === "District Admin") {
        return true
      } else {
        return this.sectionId != null
      }
    }
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        this.onTableSearch = true
        this.tableOptions.page = 1
        this.loadStudentPerformance()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.loadStudentPerformance()
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.loadSummary()
  },

  methods: {
    async loadSummary() {
      var response = await StaffStudentResultsApi.getOverview(
          {
            sectionId: this.sectionId,
            assessmentSetId: this.assessmentSetId,
            assessmentTypeId: this.assessmentTypeId
          }
      )

      this.studentAssessedCount = response.overview.studentAssessedCount
      this.overview = response.overview
      this.strengthAndImprovements = response.strengthAndImprovements

      if (this.individualStudentPerformanceAvailable) {
        this.loadStudentPerformance()
      }
    },

    async loadStudentPerformance() {
      try {
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        let {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy[0]) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        this.tableLoading = true
        const response = await StaffStudentResultsApi.getIndividualStudentPerformance(
            this.sectionId,
            this.assessmentSetId,
            this.assessmentTypeId,
            page,
            this.itemsPerPage,
            sortBy[0],
            order,
            searchText,
            this.tableActiveFilters)
        this.studentList = response.individualStudentPerformance
        this.totalStudents = response?.meta?.totalCount
        this.updateStudentPerformanceHeaders()
      } catch {
        this.addNotification('error', 'Failed to load Student List')
      } finally {
        this.tableLoading = false
        this.onTableSearch = false
        this.pageLoadedOnce = true
      }
    },

    viewCompositeReport(assessment) {
      this.$router.push({
        name: "StaffReportComposite",
        params: {
          studentId: assessment.studentId,
          sectionId: this.sectionId,
          assessmentTypeId: this.assessmentTypeId,
          assessmentSetId: this.assessmentSetId
        }
      })
    },

    setSectionColor(section) {
      let greenCharts = ["Evidence-Based Reading and Writing", "English", "Reading"]
      let blueCharts = ["Math", "Science"]

      if (greenCharts.includes(section)) {
        return "#32c443"
      }
      if (blueCharts.includes(section)) {
        return "#6199d4"
      }
    },

    formatAssessmentDifference(assessment1, assessment2) {
      let diff = assessment2 - assessment1
      if (diff < 0) {
        return `<span class="font-weight-600 color-danger">${assessment2}(${diff})</span>`
      } else if (diff > 0) {
        return `<span class="font-weight-600 color-primary">${assessment2}(+${diff})</span>`
      } else {
        return `${assessment2}(-)`
      }
    },

    getTableHeight() {
      if (this.studentList.length < 6) return undefined

      if(this.$vuetify.breakpoint.smAndUp && this.$vuetify.breakpoint.height < 800) return '400px'

      return this.tableHeight
    },

    updateStudentPerformanceHeaders() {
      let detailsKeys = Object.keys(this.studentList[0]).filter(t => t.includes("Details"))
      let headersToInsert = detailsKeys.reverse()
      if (headersToInsert.every(h => this.studentHeaders.map(a=>a.value).includes(h))) {
        return
      }
      let keys = Object.keys(this.studentList[0])
      let expandedAssessmentKey = keys.find(t => t.includes("Details"))
      let insertAfter = expandedAssessmentKey.substr(0, expandedAssessmentKey.indexOf("Details"))
      let insertAfterIndex = this.studentHeaders.findIndex(t => t.value == insertAfter) + 1

      headersToInsert.forEach( (t) => {
        let headerTitle = t.substr(t.indexOf("Details")+7, t.length)
        if (headerTitle.includes("Part2")) {
          headerTitle = "Part 2"
        }
        if (headerTitle == "R&w") {
          headerTitle = "R&W"
        }

        this.studentHeaders.splice(insertAfterIndex, 0, {
          text: headerTitle,
          value: t,
          align: 'left',
          sortable: true
        })
      })
    },

    getFilterOptions() {
      return ['Easy', 'Hard']
    },

    onFilterChange(column, filterArray) {
      this.tableActiveFilters[column] = filterArray
      this.loadStudentPerformance()
    },    
  }
}
</script>

<style lang="scss" scoped>
.category-highlight {
  border-bottom: 1px dotted #dee2e6;
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 14px;
}

.subsection-area-highlight {
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 14px;
}

.topic-area-highlight {
  color: #f4701f;
  font-weight: 600;
  font-size: 14px;
}

.v-card__title {
  background-color: #38566b;
  color: #fff;

  h3 {
    color: #fff;
  }
}

.section-title {
  color: #345165;
}

.section-subtitle {
  color: #333;
  font-size: 16px;
}

</style>
