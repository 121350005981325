import HorizonApi from '@/api/horizon_api'

const COURSE_ACTIVITIES_SHARED_PATH = `/api/course_activities`

export default {
  getLearnosityActivity(options){
    const { schedule_id = 0, template_id = 0, course_id = 0, activity_id = 0 } = options;
    return HorizonApi.get(`${COURSE_ACTIVITIES_SHARED_PATH}/preview/${schedule_id}/${template_id}/${course_id}/${activity_id}`);
  },

  answerLearnosityActivity(lesson_id, activity_id){
    return HorizonApi.get(`${COURSE_ACTIVITIES_SHARED_PATH}/activity/${lesson_id}/${activity_id}`)
  },

}
