import HorizonApi from '@/api/horizon_api'

const STUDENT_PATH = `/api/admin/students`

export default {
    addNew(studentObj){
        return HorizonApi.post(STUDENT_PATH, {student: studentObj})
    },

    getParents(studentId) {
        return HorizonApi.get(`${STUDENT_PATH}/${studentId}/parents`)
    },

    updateParent(studentId, parentId, userObj) {
        return HorizonApi.patch(`${STUDENT_PATH}/${studentId}/parents/${parentId}`, {parent: userObj})
    },

    deleteParent(studentId, parentId) {
        return HorizonApi.delete(`${STUDENT_PATH}/${studentId}/parents/${parentId}`)
    }
}
