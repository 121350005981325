<template>
  <div>
    <v-container fluid>
      <app-header title="Settings" />
      <user-settings />
    </v-container>
  </div>
</template>

<script>
import AppHeader from "@/components/shared/AppHeader.vue"
import UserSettings from "@/components/shared/UserSettings.vue"

export default {
  name: "StaffSettings",
  components: {AppHeader, UserSettings},
}
</script>

<style scoped lang="scss">
</style>
