import { Assessment } from '@/models'
import moment from "moment/moment";

export default class StudentAssessment {
  constructor (json) {
    this.id = json.id
    this.hasNextPart = json.hasNextPart
    this.nextPartId = json.nextPartId
    this.availabilities = json.availabilities
    this.displayedAvailability = this.availabilities[0] // default
    this.completed = json.completed
    this.assessment = new Assessment(json.assessment)
    this.report = json.report
    this.learnosityStatus = json.learnosityStatus
    this.settings = json.settings
    this.schoolYear = json.schoolYear
    this.status = this.getStatus()
  } 

  getStatus() {
    if (this.completed) {
      return "completed"
    } else {
      let statuses = []
      statuses = this.availabilities.map( availability =>  {
        if (Date.parse(availability.startAt) < Date.now() && (availability.endAt == null || (availability.endAt != null && Date.parse(availability.endAt) > Date.now()))) {
          this.displayedAvailability = availability
          return "active"
        } else {
          if (availability.endAt != null && Date.parse(availability.endAt) < Date.now()) {
            this.displayedAvailability = availability
            return "past"
          } else {
            this.displayedAvailability = availability
            return "upcoming"
          }
        }
      })

      if (statuses.includes("active")) {
        return "active"
      } else if (statuses.includes("upcoming")) {
        return "upcoming"
      } else if (statuses.includes("past")) {
        return "past"
      } else {
        return ""
      }
    }
  }

  earliestAvailability() {
    let startDate = String(this.availabilities[0].startAt)
    if (this.displayedAvailability != null) {
      startDate = String(this.displayedAvailability.startAt)
    }
    return `${moment(startDate).format('MMMM Do, YYYY')} at ${moment(startDate).format('h:mm a')}`
  }

  get availabilityStart() {
    if (this.displayedAvailability == null || this.displayedAvailability.startAt === null) {
      return ""
    } else {
      return `${moment(this.displayedAvailability.startAt).format('MMMM Do, YYYY')} ${moment(this.displayedAvailability.startAt).format('h:mm a')}`
    }
  }

  get availabilityEnd() {
    if (this.displayedAvailability == null || this.displayedAvailability.endAt === null) {
      return ""
    } else {
      return `${moment(this.displayedAvailability.endAt).format('MMMM Do, YYYY')} ${moment(this.displayedAvailability.endAt).format('h:mm a')}`
    }
  }

}