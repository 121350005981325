import { render, staticRenderFns } from "./DialogAssessmentAssign.vue?vue&type=template&id=eb44b978&scoped=true&"
import script from "./DialogAssessmentAssign.vue?vue&type=script&lang=js&"
export * from "./DialogAssessmentAssign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb44b978",
  null
  
)

export default component.exports