<template>
  <v-dialog
    :value="dialog"
    max-width="400px"
    persistent
  >
    <v-card class="card-dialog">
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <app-header
        :title="titleProp"
        :is-dialog="true"
        @closeDialog="onCloseDialog()"
      />
      <v-container
        fluid
        class="pt-6 pr-4 pb-6 pl-4 pr-sm-6 pl-sm-6"
      >
        <v-row>
          <v-col cols="12">
            <h5>{{ bodyTextProp }}</h5>
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-6"
        >
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-2"
          >
            <v-btn
              class="btn-default btn-default__secondary btn-default__small"
              block
              @click="onCloseDialog()"
            >
              Cancel
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="pl-sm-2"
          >
            <v-btn
              dark
              class="btn-default btn-default__primary btn-default__small"
              block
              @click="onConfirmActionDialog()"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DialogSetUntimed",
  components: {
    AppHeader: () => import('@/components/shared/AppHeader.vue')
  },
  props: {
    active: Boolean,
    loading: Boolean,
    titleProp: {
      type: String,
      default: 'Confirmation Dialog',
    },
    bodyTextProp: {
      type: String,
      default: 'Are you sure you want to do this?'
    },
  },
  data() {
    return {
      dialog: false,
    }
  },

  watch: {
    active(value) {
      this.dialog = value
    }
  },

  methods: {
    onCloseDialog() {
      this.$emit('closeDialog')
    },

    onConfirmActionDialog(){
      this.$emit('confirmDialog')
    }
  }
}
</script>

<style scoped>

</style>