import HorizonApi from '@/api/horizon_api'

const DISTRICT_PATH = `/api/admin/school_districts`

export default {
  list (page = 1, perPage, sort, order, searchProp) {
    const params = {
      'page': page,
      'per_page': perPage,
      'sort': sort,
      'order': order,
      'search': searchProp,
    };
    return HorizonApi.get(DISTRICT_PATH, {params: params})
  },

  selectList(){
    return HorizonApi.get('/api/resource_lists/districts')
  },

  create (district) {
    return HorizonApi.post(DISTRICT_PATH, district)
  },

  update(districtId, district){
    return HorizonApi.patch(`${DISTRICT_PATH}/${districtId}`, {schoolDistrict: district})
  },

  delete(districtId){
    return HorizonApi.delete(`${DISTRICT_PATH}/${districtId}`)
  },

  getAdministrators (districtId){
    return HorizonApi.get(`${DISTRICT_PATH}/${districtId}/administrators`)
  },

  addAdministrator (districtId, userProp){
    return HorizonApi.post(`${DISTRICT_PATH}/${districtId}/administrators`, {administrator: userProp})
  },

  getSchools (districtId){
    return HorizonApi.get(`${DISTRICT_PATH}/${districtId}/schools`)
  },

  createAdministrators (administrators, district_id) {
    return HorizonApi.post(`${DISTRICT_PATH}/${district_id}/aadministrators/bulk_create`, administrators)
  },

  get (school_district_id) {
    return HorizonApi.get(`${DISTRICT_PATH}/${school_district_id}`)
  },

  deleteAdmin(districtId, userId){
    return HorizonApi.delete(`${DISTRICT_PATH}/${districtId}/administrators/${userId}`)
  },

  updateClasslinkTenant (districtId, data) {
    return HorizonApi.post(`${DISTRICT_PATH}/${districtId}/classlink_tenant_update`, {tenant: data})
  },

  getClasslinkTenant (districtId) {
    return HorizonApi.get(`${DISTRICT_PATH}/${districtId}/classlink_tenant_data`)
  },

}
