import HorizonApi from '@/api/horizon_api'

export default {

    forgotPassword(email){
        return HorizonApi.post('/api/forgot_password', { email })
    },

    updatePassword(params) {
        return HorizonApi.put('/api/forgot_password', params)
    },

    activateAccount(token) {
        return HorizonApi.get(`/api/account/${token}/activate`)
    },

    activateParentEmail(email){
        return HorizonApi.post('/users/verify_account', { email })
    },
}