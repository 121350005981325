import HorizonApi from '@/api/horizon_api'

const EXPORT_PATH = `/api/district_admin/exports`

export default {
    listExports (page = 1, perPage, sort, order) {
        const params = {
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
        };
        return HorizonApi.get(EXPORT_PATH, {params: params})
    },

    generateExports (exportParam) {
        return HorizonApi.post(EXPORT_PATH, exportParam)
    },

    downloadExport (exportId) {
        return HorizonApi.get(`${EXPORT_PATH}/${exportId}/download`)
    },

    retryExport (exportId) {
        return HorizonApi.post(`${EXPORT_PATH}/${exportId}/retry`)
    },


}