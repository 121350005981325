export default class School {
  constructor (json) {
    this.id = json.id
    this.name = json.name
    this.state = json.state

    // TODO
    this.schoolDistrict = json.schoolDistrict?.name
    this.seatsPurchased = json.seatsPurchased ? json.seatsPurchased : 0
    this.activeStudents = json.activeStudents ? json.activeStudents : 0
    this.studentsEnrolled = json.studentsEnrolled ? json.studentsEnrolled : 0
  } 
}