<template>
  <div>
    <label
      class="label-default label-default__small"
    >
      {{ label }}
    </label>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          id="date"
          ref="endAtDate"
          v-model="localFormattedDate"
          class="input-default input-default__small mt-1"
          placeholder="Select Date"
          :rules="localRules"
          dense
          flat
          :clearable="clearableProp"
          readonly
          v-bind="attrs"
          hide-details="auto"
          v-on="on"
        >
          <template #append>
            <v-icon
              @click="menu = !menu"
            >
              mdi-calendar
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="localDate"
        min="2023-01-01"
        @input="menu = false"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    clearableProp: Boolean,
    label: {
      type: String,
      required: true
    },

    date: {
      type: String,
      required: true
    },

    formattedDate: {
      type: String,
      required: false
    },

    rules: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      menu: false,
      localDate: this.date,
      localFormattedDate: this.formattedDate,
      localRules: this.rules
    }
  },

  watch: {
    localDate() {
      this.$emit('update:date', this.localDate)
    },

    rules() {
      this.localRules = this.rules
    },

    date() {
      if (this.date !== '') {
        this.localDate = this.date
      } else {
        this.localDate = null
      }
    },

    formattedDate() {
      this.localFormattedDate = this.formattedDate
    },

    localFormattedDate() {
      if (this.localFormattedDate === null) this.localDate = this.localFormattedDate
    }
  }
}
</script>