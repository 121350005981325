import HorizonApi from '@/api/horizon_api'

export default {

  getGrowthReportStudentComparison (sectionId, assessmentSetId, assessmentTypeId) {
    let params = {
      params: {
        section_id: sectionId,
        assessment_set_id: assessmentSetId,
        assessment_type_id: assessmentTypeId
      }
    }

    return HorizonApi.get('/api/reports/growth_report_student_comparison', params)
  }

}
