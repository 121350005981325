<template>
  <v-container
    fluid
    class="pt-0 pb-0"
  >
    <app-header
      title="Individual Comparison"
    />

    <v-form
      ref="form"
      v-model="valid"
    >
      <v-container
        fluid
        class="filters-container"
      >
        <v-row>
          <v-col
            cols="12"
            class="pt-0"
          >
            <h3 class="mb-3">
              Student
            </h3>
            <label
              class="label-default label-default__small"
              for="student"
            >
              Student*
            </label>
            <v-select
              v-model="selectedStudent"
              :loading="studentsLoading"
              :items="studentsList"
              class="input-default input-default__small mt-1"
              dense
              attach
              placeholder="Student Name"
              item-text="fullName"
              return-object
              item-value="id"
              hide-details="auto"
              :rules="[v => !!v || 'Student is required']"
            >
              <template #prepend-item>
                <div class="inner-search-container">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="search"
                        :loading="studentsLoading"
                        dense
                        height="30px"
                        hide-details
                        placeholder="Search Student"
                        @input="getStudents"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row class="mt-3">
          <v-col
            cols="12"
            sm="6"
            class="pr-sm-12"
          >
            <h3 class="mb-3">
              Assessment #1
            </h3>
            <label
              class="label-default label-default__small"
              for="selectedAssessment1"
            >
              Assessment Name*
            </label>
            <v-select
              id="selectedAssessment1"
              v-model="selectedAssessment1"
              placeholder="Select Assessment"
              dense
              flat
              solo
              class="input-default input-default__small mt-1 mb-4"
              item-text="name"
              :return-object="true"
              :items="getAssessmentChoices()"
              hide-details="auto"
              required
              :rules="[v => !!v || 'Assessment#1 is required']"
            />
            <label
              class="label-default label-default__small"
              for="selectedSchoolYear1"
            >
              School Year*
            </label>
            <v-select
              id="selectedSchoolYear1"
              v-model="selectedSchoolYear1"
              placeholder="Select School Year"
              dense
              flat
              solo
              class="input-default input-default__small mt-1"
              item-text="name"
              :return-object="true"
              :items="schoolYearsList"
              hide-details="auto"
              required
              :loading="schoolYearsLoading"
              :rules="[v => !!v || 'School Year is required']"
            />
          </v-col>

          <v-divider
            vertical
            class="filter-divider d-none d-sm-inline-flex"
          />

          <v-col
            cols="12"
            sm="6"
            class="pl-sm-12"
          >
            <h3 class="mb-3">
              Assessment #2
            </h3>
            <div>
              <label
                class="label-default label-default__small"
                for="selectedAssessment2"
              >
                Assessment Name*
              </label>
              <v-select
                id="selectedAssessment2"
                v-model="selectedAssessment2"
                placeholder="Select Assessment"
                dense
                flat
                solo
                class="input-default input-default__small mt-1 mb-4"
                item-text="name"
                :return-object="true"
                :items="getAssessmentChoices()"
                hide-details="auto"
                required
                :rules="[v => !!v || 'Assessment#2 is required']"
              />
            </div>
            <label
              class="label-default label-default__small"
              for="selectedSchoolYear2"
            >
              School Year*
            </label>
            <v-select
              id="selectedSchoolYear2"
              v-model="selectedSchoolYear2"
              placeholder="Select School Year"
              dense
              flat
              solo
              class="input-default input-default__small mt-1"
              item-text="name"
              :return-object="true"
              :items="schoolYearsList"
              hide-details="auto"
              required
              :loading="schoolYearsLoading"
              :rules="[v => !!v || 'School Year is required']"
            />
          </v-col>
        </v-row>

        <v-row class="justify-end pb-3">
          <v-col
            cols="12"
            sm="auto"
            class="pr-sm-1"
          >
            <v-btn
              class="btn-default btn-default__secondary"
              block
              @click.stop="resetForm"
            >
              Reset
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            class="pl-sm-1"
          >
            <v-btn
              dark
              block
              class="btn-default btn-default__primary"
              @click.stop="generateReport"
            >
              <v-img
                class="mr-4"
                contain
                :src="require('@/assets/icons/diskette.png')"
                alt="icon-add"
                max-width="19"
                max-height="19"
              />
              <span>
                Generate Report
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <div
      v-if="reportsLoading && !reportsLoaded"
      class="mt-8"
    >
      <v-skeleton-loader
        type="card-avatar, table"
      />
    </div>

    <v-card
      v-if="reportsLoaded"
      class="cards-container"
      outlined
    >
      <!-- Composite Score Comparison -->
      <v-card-title class="card-title">
        <h3 class="font-weight-700">
          Composite Score Comparison
        </h3>
      </v-card-title>
      <div class="item-container">
        <!-- First Result -->
        <v-card
          flat
          class="card-item"
        >
          <v-card-title class="card-subtitle flex-column align-start">
            <h3 class="font-weight-700">
              {{ assessment1Report.assessmentType?.course_type?.toUpperCase() }}
              {{ assessment1Report.assessmentSet?.name }}
            </h3>

            <h5
              class="assessment-subtitle font-weight-900"
            >
              {{ assessment1Report.schoolYear.shortName }}
            </h5>
          </v-card-title>

          <div v-if="isEmptyObject(assessment1Report)">
            <div class="container-reports ma-6">
              No data.
            </div>
          </div>
          <div v-else>
            <v-card-text>
              <v-row
                class="mt-2"
                justify="center"
              >
                <v-col
                  ref="donutChartContainer"
                  cols="12"
                  sm="7"
                  lg="8"
                >
                  <donut-chart
                    chart-id="summaryDonutChart"
                    :chart-title="'Composite Score'"
                    :is-average-score="false"
                    :average-score="assessment1Report.compositeScore"
                    :min-score="assessment1Report.minScore"
                    :max-score="assessment1Report.maxScore"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
        <!-- /First Result -->

        <!-- Second Result -->
        <v-card
          outlined
          class="card-item"
        >
          <v-card-title class="card-subtitle flex-column align-start">
            <h3 class="font-weight-700">
              {{ assessment2Report.assessmentType?.course_type?.toUpperCase() }}
              {{ assessment2Report.assessmentSet?.name }}
            </h3>

            <h5
              class="assessment-subtitle font-weight-900"
            >
              {{ assessment2Report.schoolYear.shortName }}
            </h5>
          </v-card-title>

          <div v-if="isEmptyObject(assessment2Report)">
            <div class="container-reports ma-6">
              No data.
            </div>
          </div>
          <div v-else>
            <v-card-text>
              <v-row
                class="mt-2"
                justify="center"
              >
                <v-col
                  ref="donutChartContainer"
                  cols="12"
                  sm="7"
                  lg="8"
                >
                  <donut-chart
                    chart-id="summaryDonutChartSecond"
                    :chart-title="'Composite Score'"
                    :is-average-score="false"
                    :average-score="assessment2Report.compositeScore"
                    :min-score="assessment2Report.minScore"
                    :max-score="assessment2Report.maxScore"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </div>
      <!-- /Composite Score Comparison -->

      <!-- Section Scores Comparison -->
      <v-card-title class="card-title">
        <h3 class="font-weight-700">
          Section Scores Comparison
        </h3>
      </v-card-title>
      <div class="item-container">
        <!-- First Result -->
        <v-card
          flat
          class="card-item"
        >
          <v-card-title class="card-subtitle flex-column align-start">
            <h3 class="font-weight-700">
              {{ assessment1Report.assessmentType?.course_type?.toUpperCase() }}
              {{ assessment1Report.assessmentSet?.name }}
            </h3>

            <h5
              class="assessment-subtitle font-weight-900"
            >
              {{ assessment1Report.schoolYear.shortName }}
            </h5>
          </v-card-title>

          <div v-if="isEmptyObject(assessment1Report)">
            <div class="container-reports ma-6">
              No data.
            </div>
          </div>
          <div v-else>
            <v-card-text>
              <div
                v-for="(studentAssessment, idx) in assessment1Report.studentAssessments"
                :key="idx"
              >
                <h4 class="section-title font-weight-700">
                  {{ studentAssessment?.report?.summary?.category }}
                </h4>
                <div
                  v-for="benchmarkScore in studentAssessment?.report?.summary?.benchmarkScores"
                  :key="benchmarkScore.name"
                  class="section-subtitle"
                >
                  {{ benchmarkScore?.name }}: {{ benchmarkScore?.score }}
                </div>
                <v-row
                  class="mt-2"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    sm="7"
                    lg="8"
                  >
                    <donut-chart
                      :chart-id="`section-score-${studentAssessment?.report?.summary?.category?.split(' ')[0]}-donut`"
                      :average-score="studentAssessment?.report?.summary?.convertedScore"
                      :is-average-score="false"
                      :min-score="studentAssessment?.report?.summary?.minScore"
                      :max-score="studentAssessment?.report?.summary?.maxScore"
                      :color="getCategoryColor(studentAssessment?.report?.summary?.category)"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </div>
        </v-card>
        <!-- /First Result -->

        <!-- Second Result -->
        <v-card
          outlined
          class="card-item"
        >
          <v-card-title class="card-subtitle flex-column align-start">
            <h3 class="font-weight-700">
              {{ assessment2Report.assessmentType?.course_type?.toUpperCase() }}
              {{ assessment2Report.assessmentSet?.name }}
            </h3>

            <h5
              class="assessment-subtitle font-weight-900"
            >
              {{ assessment2Report.schoolYear.shortName }}
            </h5>
          </v-card-title>

          <div v-if="isEmptyObject(assessment2Report)">
            <div class="container-reports ma-6">
              No data.
            </div>
          </div>
          <div v-else>
            <v-card-text>
              <div
                v-for="(studentAssessment, idx) in assessment2Report.studentAssessments"
                :key="idx"
              >
                <h4 class="section-title font-weight-700">
                  {{ studentAssessment?.report?.summary?.category }}
                </h4>
                <div
                  v-for="benchmarkScore in studentAssessment?.report?.summary?.benchmarkScores"
                  :key="benchmarkScore.name"
                  class="section-subtitle"
                >
                  {{ benchmarkScore?.name }}: {{ benchmarkScore?.score }}
                </div>
                <v-row
                  class="mt-2"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    sm="7"
                    lg="8"
                  >
                    <donut-chart
                      :chart-id="`section-score-${studentAssessment?.report?.summary?.category?.split(' ')[0]}-donut-second`"
                      :average-score="studentAssessment?.report?.summary?.convertedScore"
                      :is-average-score="false"
                      :min-score="studentAssessment?.report?.summary?.minScore"
                      :max-score="studentAssessment?.report?.summary?.maxScore"
                      :color="getCategoryColor(studentAssessment?.report?.summary?.category)"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </div>
      <!-- /Section Scores Comparison -->

      <!-- Subsection Details Comparison -->
      <v-card
        class="summary-container cards-container"
        outlined
      >
        <v-card-title class="card-title">
          <h3 class="font-weight-700">
            Subsection Details Comparison
          </h3>
        </v-card-title>
        <div class="">
          <v-card-subtitle class="instructions">
            Click on each tab to view individual subsection details.
          </v-card-subtitle>
          <v-card-text class="pb-0">
            <v-tabs
              v-model="tab"
              :vertical="isMobile"
              :grow="isMobile"
              active-class="active-tab"
              hide-slider
              show-arrows
            >
              <v-tab
                v-for="category in categoriesList"
                :key="category"
                @click="onTabClicked(category)"
              >
                {{ category }}
              </v-tab>
            </v-tabs>
          </v-card-text>
        </div>
        <div class="item-container">
          <!-- First Result -->
          <v-card
            flat
            class="card-item"
          >
            <v-card-text class="pt-0 pr-0">
              <v-sheet
                outlined
                class="border-right-remove"
              >
                <v-card-title class="card-subtitle flex-column align-start">
                  <h3 class="font-weight-700">
                    {{ assessment1Report.assessmentType?.course_type?.toUpperCase() }}
                    {{ assessment1Report.assessmentSet?.name }}
                  </h3>

                  <h5
                    class="assessment-subtitle font-weight-900"
                  >
                    {{ assessment1Report.schoolYear.shortName }}
                  </h5>
                </v-card-title>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="(studentAssessment, idx) in assessment1Categories"
                    :key="idx"
                    :transition="false"
                  >
                    <student-report-individual
                      :student-assessment-id="studentAssessment?.id"
                      :score-report-prop="studentAssessment?.report"
                      :multipart="false"
                      :show-header="false"
                      :show-questions-list="false"
                      :show-summary-in-rows="true"
                      :from-composite="true"
                      :assessment-type="assessment1Report.assessmentType.assessment_type"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-sheet>
            </v-card-text>
          </v-card>
          <!-- /First Result -->

          <!-- Second Result -->
          <v-card
            flat
            class="card-item"
          >
            <v-card-text class="pt-0 pl-0">
              <v-sheet
                outlined
              >
                <v-card-title class="card-subtitle flex-column align-start">
                  <h3 class="font-weight-700">
                    {{ assessment2Report.assessmentType?.course_type?.toUpperCase() }}
                    {{ assessment2Report.assessmentSet?.name }}
                  </h3>

                  <h5
                    class="assessment-subtitle font-weight-900"
                  >
                    {{ assessment2Report.schoolYear.shortName }}
                  </h5>
                </v-card-title>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="(studentAssessment, idx) in assessment2Categories"
                    :key="idx"
                    :transition="false"
                  >
                    <student-report-individual
                      :student-assessment-id="studentAssessment?.id"
                      :score-report-prop="studentAssessment?.report"
                      :multipart="false"
                      :show-header="false"
                      :show-questions-list="false"
                      :show-summary-in-rows="true"
                      :from-composite="true"
                      :assessment-type="assessment2Report.assessmentType.assessment_type"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-sheet>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
      <!-- /Subsection Details Comparison -->
    </v-card>
  </v-container>
</template>

<script type="text/javascript">
import {createNamespacedHelpers} from 'vuex'

const {mapState} = createNamespacedHelpers('app_config')
const {mapGetters: mapAssessmentGetters} = createNamespacedHelpers('assessments')

import AppHeader from "@/components/shared/AppHeader.vue";
import DonutChart from '@/components/shared/charts/DonutChart'
import StudentReportIndividual from '@/components/student/reports/StudentReportIndividual.vue'

import {StaffSchoolApi, StaffStudentAssessmentApi} from "@/api"
import Mixin from "@/mixins/mixin.js"

export default {
  name: "IndividualStudentComparison",
  components: {
    AppHeader,
    DonutChart,
    StudentReportIndividual
  },

  mixins: [Mixin],

  data() {
    return {
      tab: null,
      studentsLoading: false,
      reportsLoading: false,
      schoolYearsLoading: false,
      reportsLoaded: false,
      valid: true,
      studentSearch: null,
      studentsList: [],
      schoolYearsList: [],
      selectedStudent: null,
      selectedAssessment1: null,
      selectedAssessment2: null,
      selectedSchoolYear1: null,
      selectedSchoolYear2: null,
      assessment1Report: null,
      assessment2Report: null,
      categoriesList: [],
      assessment1Categories: null,
      assessment2Categories: null,
      search: '',
      cache: {},
      lastSearch: '',
      timeout: null,
      counter: 0
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    }),
  },

  mounted() {
    this.getStudents()
    this.getSchoolYears()
  },

  destroyed() {
    if (this.timeout) clearTimeout(this.timeout)
  },

  methods: {
    ...mapAssessmentGetters(["getAssessmentChoices"]),

    resetForm() {
      this.$refs.form.reset()
      this.assessment1Report = null
      this.assessment2Report = null
      this.reportsLoaded = false
    },

    async generateReport() {
      if (!this.$refs.form.validate()) {
        return
      }

      // check that the assessment types are the same, ie. disallow comparison between ACT and SAT exams
      if (this.selectedAssessment1.assessmentType.assessmentType != this.selectedAssessment2.assessmentType.assessmentType) {
        this.addNotification('error', 'Please make sure Assessment#1 and Assessment#2 are the same exam type.')
        return
      }

      // check that the assessments and periods are not exactly the same
      if (this.selectedAssessment1 == this.selectedAssessment2 && this.selectedSchoolYear1 == this.selectedSchoolYear2) {
        this.addNotification('error', 'Please choose different assessments and/or school years for comparison.')
        return
      }

      this.assessment1Report = null
      this.assessment2Report = null
      this.reportsLoaded = false

      this.reportsLoading = true

      let report1Response = {}
      let report2Response = {}

      try {
        report1Response = await StaffStudentAssessmentApi.getCompositeReport({
          studentId: this.selectedStudent.id,
          assessmentSetId: this.selectedAssessment1.assessmentSet.id,
          assessmentTypeId: this.selectedAssessment1.assessmentType.id,
          schoolYearId: this.selectedSchoolYear1.id
        })
        this.assessment1Report = report1Response.compositeReport
      } catch (e) {
        this.addNotification('error', 'Report for Assessment#1 not found.')
      }

      try {
        report2Response = await StaffStudentAssessmentApi.getCompositeReport({
          studentId: this.selectedStudent.id,
          assessmentSetId: this.selectedAssessment2.assessmentSet.id,
          assessmentTypeId: this.selectedAssessment2.assessmentType.id,
          schoolYearId: this.selectedSchoolYear2.id
        })
        this.assessment2Report = report2Response.compositeReport
      } catch (e) {
        this.addNotification('error', 'Report for Assessment#2 not found.')
      } finally {
        this.reportsLoading = false
      }

      let reportsPresent = this.assessment1Report && this.assessment2Report

      this.mergeCategories()

      this.reportsLoaded = reportsPresent
    },

    mergeCategories() {
      let categories1 = this.assessment1Report.studentAssessments.map(a => a.report.summary.category)
      let categories2 = this.assessment2Report.studentAssessments.map(a => a.report.summary.category)

      this.categoriesList = [...new Set(categories1.concat(categories2).flat())]

      this.assessment1Categories = Array.apply(null, {length: this.categoriesList.length}).map(function () {
      })
      this.assessment2Categories = Array.apply(null, {length: this.categoriesList.length}).map(function () {
      })

      for (let i = 0; i < this.categoriesList.length; i++) {
        let category = this.categoriesList[i]
        this.assessment1Categories[i] = this.assessment1Report.studentAssessments.find(a => a.report.summary.category == category)
        this.assessment2Categories[i] = this.assessment2Report.studentAssessments.find(a => a.report.summary.category == category)
      }
    },

    timer: function () {
      setInterval(this.tikTok, 1000);
    },
    tikTok: function () {
      ++this.counter;
    },

    getStudents: function (query) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        if (query === this.lastSearch) return;
        this.lastSearch = query;
        if (this.cache[query]) {
          this.students = this.cache[query];
          return;
        }
        this.studentsLoading = true
        try {
          StaffSchoolApi.getUsersByRole(null, "Student", 1, 100, 'lastName', 'asc', query, {}, null).then(response => {
            this.studentsList = response.users
          })
        } catch {
          if (query) {
            this.addNotification('error', `No students matching "${query}" found.`)
          } else {
            this.addNotification('error', 'No students loaded.')
          }
        } finally {
          this.studentsLoading = false
        }
      }, 700)
    },

    getCategoryColor(section) {
      section = section.toLowerCase()
      return section == 'math' || section == 'science' || section == 'stem' ? '#568fce' : '#2cbc3b'
    },

    async getSchoolYears() {
      try {
        this.schoolYearsLoading = true
        const response = await StaffSchoolApi.getSchoolYears()
        this.schoolYearsList = response.schoolYears
      } catch {
        this.addNotification('error', 'Failed to load school year list.')
      } finally {
        this.schoolYearsLoading = false
      }
    },
  }
}
</script>

<style scoped lang="scss">

.title-select {
  color: $primaryColor;
}

.cards-container {

  .item-container {
    display: flex;
  }

  .card-item {
    border-radius: 0 !important;
    width: 50% !important;
  }

  .card-title {
    background-color: #38566b;
    color: #fff;
    min-height: 68px;

    h3 {
      color: #fff;
    }
  }

  .card-subtitle {
    background-color: #F3F3F3;

    h3 {
      font-size: 18px !important;
      color: #345165;
    }
  }

  .section-title {
    color: #345165;
  }

  .section-subtitle {
    color: #333;
    font-size: 16px;
  }
}


.v-tab {
  text-transform: none !important;
  letter-spacing: 0;
  cursor: pointer;
  transition: .2s;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #aaa !important;
  line-height: 24px;
  padding: 13px 32px;
  text-align: center;
  font-size: 18px;
}

.v-card__title {
  background-color: #38566b;
  color: #fff;

  h3 {
    color: #fff;
  }
}

.active-tab {
  background-color: #38566b;
  color: #fff !important;
}

.instructions {
  font-size: 16px;
  color: #6b6b6b;
}

.border-right-remove {
  border-right: none
}

.border-right {
  border-right: 1px solid #EAECF0
}

.assessment-subtitle {
  color: #f4701f;
  text-transform: uppercase;
}

.filters-container {
  margin-bottom: 30px;
  border-bottom: 1px solid #eaecf0;
}

.filter-divider {
  height: 241px;
  margin-top: 8px;
  border-color: #EAECF0;
}

.inner-search-container {
  position: sticky;
  top: 0;
  background: white;
  z-index: 9;
}

</style>