<template>
  <div>
    <v-row v-if="dataAvailable && selectedGroup">
      <v-col>
        <h3>{{ selectedGroup.groupName }}</h3>
        <div class="d-flex align-center mt-1">
          <v-icon
            size="20"
            color="#38566b"
            class="mr-1"
          >
            mdi-account-group
          </v-icon>
          <span class="font-size-16">
            Number of Students Assessed:
            <span class="font-weight-700 color-dark-orange">
              {{ selectedGroup.studentAssessedCount }} Student{{ selectedGroup.studentAssessedCount > 1 ? "s" : "" }}
            </span>
          </span>
        </div>
      </v-col>
      <v-col
        class="d-flex align-center col-auto"
      >
        <label
          class="label-default mr-4"
          for="class"
        >
          Class:
        </label>
        <v-select
          id="class"
          v-model="selectedGroup"
          :items="groups"
          item-text="groupName"
          return-object
          solo
          dense
          flat
          hide-details
          class="input-default input-default__small mt-1"
          @change="setSelectedGroup"
        />
      </v-col>
    </v-row>

    <div class="mt-8">
      <div v-if="dataAvailable">
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <donut-chart
              :chart-id="`donut-chart-${assessmentTypeId}-${assessmentSetId}`"
              :average-score="selectedGroup?.score"
              :is-average-score="true"
              :min-score="selectedGroup?.minScore"
              :max-score="selectedGroup?.maxScore"
            />
          </v-col>
          <v-col
            cols="12"
            sm="8"
            class="bar-chart-dashboard"
          >
            <bar-chart
              :chart-id="`bar-chart-${assessmentTypeId}-${assessmentSetId}`"
              :data-set="selectedGroup?.segments"
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
          <v-skeleton-loader 
            type="image" />
          </v-col>

          <v-col
            cols="12"
            sm="8"
          >
          <v-skeleton-loader 
            type="image" />
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row
      dense
      class="table-header-extension mt-4"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
        xl="3"
        class="d-flex align-center"
      >
        <v-text-field
          v-model="tableSearch"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          class="input-default input-default__table"
          clearable
          hide-details
          solo
          flat
        />
      </v-col>
    </v-row>

    <v-data-table
      item-key="id"
      :loading="tableLoading"
      :must-sort="true"
      :headers="headers"
      :headers-length="headers.length + 1"
      :options.sync="tableOptions"
      :server-items-length="totalGroups"
      :items="groups" 
      class="table-default table-radius-top-none"
      :item-class="itemRowBackground"
      :footer-props="{
        showCurrentPage: true,
        itemsPerPageOptions:[10],
        disableItemsPerPage: true
      }"
    >
      <template #[`footer.page-text`]="items">
        <span class="font-weight-700">{{ items.pageStart }}</span> to
        <span class="font-weight-700">{{ items.pageStop }}</span> of
        <span class="font-weight-700">{{ items.itemsLength }}</span>
      </template>

      <template
        #[`item.score`]="{ item }"
      >
        <td class="text-center">
          <span class="font-weight-600 color-orange">{{ item.score }}</span>
        </td>
      </template>
      <template
        #[`item.actions`]="{ item }"
      >
        <td class="text-center">
          <v-btn
            class="btn-table btn-table__text"
            outlined
            @click="viewReport(item)"
          >
            View Report
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DonutChart from "@/components/shared/charts/DonutChart"
import BarChart from "@/components/shared/charts/BarChart"

import { StaffStudentResultsApi } from "@/api"

import debounce from 'lodash/debounce'

export default {
  components: {
    DonutChart,
    BarChart,
  },

  props: {
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },

    assessmentSetId: {
      type: [Number, String],
      required: true
    },

    assessmentSummariesCount: {
      type: [Number, String],
      required: true
    },
  },

  data() {
    return {
      pageLoadedOnce: false,
      onTableSearch: false,
      tableLoading: false,
      assessmentSummary: undefined,
      groups: [],
      selectedGroupId: 0,
      selectedGroup: {},
      headers: [],
      tableSearch: '',
      totalGroups: 0,
      tableOptions: {},

    }
  },

  watch: {
    tableSearch: {
      handler: debounce(function () {
        // Set page to 1 before searching
        this.resetPage()
        this.onTableSearch = true
        this.loadDashboardSummary()
      }, 500),
    },

    tableOptions: {
      handler() {
        if (!this.onTableSearch && this.pageLoadedOnce) {
          this.loadDashboardSummary()
        }
      },
      deep: true,
    },
  },

  computed: {
    dataAvailable() {
      return this.assessmentSummary !== undefined
    }
  },

  mounted() {
    this.loadDashboardSummary()
  },

  methods: {
    async loadDashboardSummary() {
      try {
        this.tableLoading = true
        const {sortBy, sortDesc, page} = this.tableOptions
        let order = undefined
        if (sortBy && sortBy.length && sortBy.find( (a) => a == "groupName")) {
          if (sortDesc[0]) {
            order = 'desc'
          } else {
            order = 'asc'
          }
        }
        const searchText = this.tableSearch === '' ? undefined : this.tableSearch
        const response = await StaffStudentResultsApi.getDashboardSummaryDetails({
          assessmentTypeId: this.assessmentTypeId,
          assessmentSetId: this.assessmentSetId,
          page: page === undefined ? 1 : page,
          perPage: 10,
          search: searchText,
          order: order
        })

        this.assessmentSummary = response
        this.groups = response.groups
        this.totalGroups = response.meta?.totalCount

        this.loadHeaders()
      } catch (e) {
        if (this.pageLoadedOnce) {
          this.addNotification('error', 'There was a problem getting results')
        }
      } finally {
        this.pageLoadedOnce = true
        this.tableLoading = false
        this.onTableSearch = false
      }
    },

    loadHeaders() {
      let categories = this.groups[0].categoryScores
      this.setSelectedGroup(this.groups[0])
      let categoryHeaders = []
      Object.keys(categories).forEach(key => {
        categoryHeaders.push({
          text: categories[key].name,
          value: `categoryScores.${key}.score`,
          align: 'center',
          sortable: false
        })
      })
      this.headers = [
        {
          text: 'Class',
          value: 'groupName',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Students',
          value: 'studentAssessedCount',
          align: 'center',
          width: 20,
          sortable: false,
        },
        {
          text: 'Overall Score',
          value: 'score',
          align: 'center',
          sortable: false,
        },
        categoryHeaders,
        {
          text: '',
          value: 'actions',
          align: 'left',
          sortable: false,
          width: 150,
        }
      ].flat()
    },

    viewReport(item) {
      if (item.sectionId == 0) {
        this.$router.push({
          name: 'Staff/StudentResults/Summary',
          params: {
            assessmentSetId: this.assessmentSetId,
            assessmentTypeId: this.assessmentTypeId,
            assessmentSummariesCount: this.assessmentSummariesCount
          }
        })
      } else {
        this.$router.push({
          name: 'Staff/Section/StudentResults/Summary',
          params: {
            sectionId: item.sectionId,
            assessmentSetId: this.assessmentSetId,
            assessmentTypeId: this.assessmentTypeId,
            assessmentSummariesCount: this.assessmentSummariesCount
          }
        })
      }
    },

    setSelectedGroup(group) {
      this.selectedGroupId = this.groups.findIndex(g => g.sectionId === group.sectionId)
      this.selectedGroup = group
    },

    resetPage() {
      this.tableOptions.page = 1
    },

    itemRowBackground(item) {
      return item.groupName === "Entire School" ? "entire-school-row" : ""
    }

  }
}
</script>

<style>
.entire-school-row {
  background-color: #fff9ed !important;
}
</style>
