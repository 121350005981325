import HorizonApi from '@/api/horizon_api'

const STAFF_BATCH_PDF_EXPORTS_PATH = `/api/staff/batch_pdf_exports`

export default {
    list(page, perPage, sort, order) {
        const params = {
            'page': page,
            'per_page': perPage,
            'sort': sort,
            'order': order,
        }
        return HorizonApi.get(`${STAFF_BATCH_PDF_EXPORTS_PATH}`, {params: params})
    },

    create({sectionId, assessmentTypeId, assessmentSetId, containerType, sortOrder} = {}) {

        let params = {
            assessment_type_id: assessmentTypeId,
            assessment_set_id: assessmentSetId,
            container_type: containerType,
            sort_order: sortOrder,
            section_id: sectionId
        }

        return HorizonApi.post(`${STAFF_BATCH_PDF_EXPORTS_PATH}`, params)
    },

    download(reportId) {
        return HorizonApi.post(`${STAFF_BATCH_PDF_EXPORTS_PATH}/${reportId}/download`)
    },

    retryExport(reportId) {
        return HorizonApi.post(`${STAFF_BATCH_PDF_EXPORTS_PATH}/${reportId}/retry`)
    }

}