import HorizonApi from '@/api/horizon_api'

const COURSE_ACTIVITIES_PATH = `/api/admin/course_activities`

export default {
  listTemplates() {
    return HorizonApi.get(`${COURSE_ACTIVITIES_PATH}/templates`)
  },
  getTemplate(template_id) {
    return HorizonApi.get(`${COURSE_ACTIVITIES_PATH}/templates/${template_id}`)
  },
  createTemplate(template) {
    return HorizonApi.post(`${COURSE_ACTIVITIES_PATH}/templates`, template)
  },
  duplicateTemplate(template) {
    return HorizonApi.post(`${COURSE_ACTIVITIES_PATH}/templates/duplicate`, template)
  },
  deleteTemplate(template) {
    return HorizonApi.delete(`${COURSE_ACTIVITIES_PATH}/templates/${template.he_course_template_id}`)
  },
  createSection(courseActivityId,section) {
    return HorizonApi.post(`${COURSE_ACTIVITIES_PATH}/${courseActivityId}/sections`, section)
  },
  deleteSection(heCourseId,sectionId) {
    return HorizonApi.delete(`${COURSE_ACTIVITIES_PATH}/${heCourseId}/sections/${sectionId}`)
  },
  listLernosityActivity(){
    return HorizonApi.get(`${COURSE_ACTIVITIES_PATH}/lessons/learnosity_activity`)
  },
  createLesson(section_id,lesson){
    return HorizonApi.post(`${COURSE_ACTIVITIES_PATH}/${section_id}/lessons`,lesson)
  },
  deleteLesson(lesson){
    return HorizonApi.delete(`${COURSE_ACTIVITIES_PATH}/${lesson.he_course_section_id}/lessons/${lesson.id}`)
  },
  updateCourse(course_id,data){
    return HorizonApi.put(`${COURSE_ACTIVITIES_PATH}/${course_id}`,data)
  },
  editLesson(lesson){
    return HorizonApi.put(`${COURSE_ACTIVITIES_PATH}/${lesson.he_course_section_id}/lessons`,lesson)
  }
}