<template>
  <v-container
    fluid
  >
    <div v-if="loading">
      <v-skeleton-loader
        type="card-avatar, table"
      />
    </div>
    <div v-else>
      <div class="chart_container">
        <div v-for="(chartOpt, idc) in chartArray"
        :key='idc' :class="`chart-${chartArray.length}`">
          <ag-charts-vue :options="chartOpt"/>
        </div>
      </div>

      <ag-grid-vue
        style="width: 100%; height: 800px"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :defaultColDef="defaultColDef"
        :sideBar="sideBar"
        :enableCharts="true"
        :enableRangeSelection="true"
        @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>

    </div>
  </v-container>
</template>

<script>
import { GrowthReportsApi } from '@/api'
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import { AgChartsVue } from 'ag-charts-vue';
import {createNamespacedHelpers} from 'vuex';
const {mapState: mapAssessmentState} = createNamespacedHelpers('assessments');


export default {
  name: "StaffStudentResultsGrowthReportStudentComparison",

  components: {
    AgGridVue,
    AgChartsVue,
  },

  props: {
    sectionId: {
      type: [Number, String],
      required: false
    },
    assessmentTypeId: {
      type: [Number, String],
      required: true
    },
    assessmentSetId: {
      type: [Number, String],
      required: true
    },
  },

  data() {
    return {
      rowData: null,
      columnDefs: null,
      defaultColDef: {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        sortable: true,
        filter: 'agMultiColumnFilter'
      },
      sideBar: { toolPanels: ['filters'] },
      chartArray: [],
      assessmentData: null,
      meta: null,
      gridApi: null,
      gridColumnApi: null,
      loading: false,
      assessmentSetFilters: ["assessment_1", "assessment_2", "assessment_3"]
    }
  },

  computed: {

    ...mapAssessmentState(['assessmentSets']),

  },

  async mounted() {
    let set = this.assessmentSets.find(a => a.id == this.assessmentSetId)
    var selected_set = [set.name.toLowerCase().replace('#', '_')]
    var set_options = ["assessment_1", "assessment_2", "assessment_3"]

    this.assessmentSetFilters = [...new Set(selected_set.concat(set_options))]
  },

  methods: {

    async loadGridData() {
      await this.loadAdvancedReport()
      this.setUpColumns()
    },

    // AG Grid callbacks
    onGridReady(params) {
      this.loading = true
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.loadGridData()
      this.loading = false
    },

    onFirstDataRendered() {
      this.chartArray = [];
      let available_assessments = this.assessmentSetFilters.filter( (a) => this.checkAssessmentGroupExists(a));

      // create chart for available assessments
      available_assessments.forEach( (assessment) => {
        this.createHistChart(assessment, `${this.meta.assessment_type.toUpperCase()} #${assessment.replace('assessment_','')} Composite Score`)
      })

    },

    async loadAdvancedReport() {
      let res = await GrowthReportsApi.getGrowthReportStudentComparison(
        this.sectionId, this.assessmentSetId, this.assessmentTypeId)
      this.assessmentData = res.data
      this.rowData = this.assessmentData
      this.meta = res.meta
    },

    setAt(index, num_only = false) {
      var set = this.assessmentSetFilters[index]
      if (num_only) {
        set = set.replace('assessment_', '')
      }

      return set
    },

    setUpColumns() {
      var defs = [
        { width: 140, field: 'last_name', pinned: 'left', headerName: "Last Name" },
        { width: 140, field: 'first_name', pinned: 'left', headerName: "First Name" },
        { width: 140, field: 'student_id_number', headerName: "Student ID" },
        { width: 90, field: 'grade_level', headerName: "Grade" },
        { width: 120, field: 'schedule', headerName: "Schedule" }
      ];

      this.assessmentSetFilters.forEach( (assessment) => {
        if (this.checkAssessmentGroupExists(assessment)) {
          defs.push(
              this.generateAssessmentColumns(
                  `${this.meta.assessment_type} #${assessment.replace('assessment_', '')}`.toUpperCase(),
                  assessment,
                  this.meta.keys)
          )
        }
      })

      // generate comparisons
      var comparisonKeys = this.meta.keys.filter( (key) => key.search("score") > 0 )
      if (this.checkAssessmentGroupExists(this.setAt(0)) && this.checkAssessmentGroupExists(this.setAt(1))) {
        defs.push(this.generateComparisons(`${this.meta.assessment_type.toUpperCase()} #${this.setAt(0, true)} to #${this.setAt(1, true)} Comparison`, this.setAt(0), this.setAt(1), comparisonKeys))
      }

      if (this.checkAssessmentGroupExists(this.setAt(1)) && this.checkAssessmentGroupExists(this.setAt(2))) {
        defs.push(this.generateComparisons(`${this.meta.assessment_type.toUpperCase()} #${this.setAt(1, true)} to #${this.setAt(2, true)} Comparison`, this.setAt(1), this.setAt(2), comparisonKeys))
      }

      if (this.checkAssessmentGroupExists(this.setAt(0)) && this.checkAssessmentGroupExists(this.setAt(2))) {
        defs.push(this.generateComparisons(`${this.meta.assessment_type.toUpperCase()} #${this.setAt(0, true)} to #${this.setAt(2, true)} Comparison`, this.setAt(0), this.setAt(2), comparisonKeys))
      }

      this.columnDefs = defs
    },

    generateAssessmentColumns(title, assessment, keys) {
      let children = keys.map( (key) => {
        let isStatusField = key.search("status") > 0

        if (isStatusField) {
          return {
            field: `${assessment}.${key}`,
            headerName: this.humanize(key),
            width: 120,
            columnGroupShow: "open",
            filter: null,
            valueFormatter: (params) => {
              if (params.value  == "completed") {
                return  "Completed"
              } else {
                return "Not Started"
              }
            }
          }
        } else {
          let columnHeader = this.humanize(key)

          if (key.search("difficulty") > 0) {
            columnHeader = columnHeader.replace('Difficulty', 'Part 2')
          }

          return {
            field: `${assessment}.${key}`,
            headerName: columnHeader,
            width: 95,
            filterParams: {
              filters: [
                { filter: 'agNumberColumnFilter' },
                { filter: 'agSetColumnFilter' },
              ],
            },
            chartDataType: 'series',
            valueFormatter: (params) => {
              if (params.value == null) {
                return "n/a"
              } else {
                return params.value
              }
            },
            valueGetter: (params) => {
              var val = null
              if (Object.hasOwn(params.data, assessment)) {
                val = params.data[assessment][key]
              }

              return val
            },
            cellClass: params => this.checkIfNotZero(params.value)  ? 'default-font' : 'blank-font'
          }
        }

      })

      return {
        headerName: title,
        children: children
      }
    },

    checkAssessmentGroupExists(key) {
      var ret = false
      this.assessmentData.some( (row) => {
        if (Object.hasOwn(row, key)) {
          ret = true
          return true
        }
      })

      return ret
    },

    generateComparisons(title, set1, set2, keys) {
      var comparisons = {};
      comparisons["headerName"] = title
      comparisons["children"] = [];

      let fields = keys

      fields.forEach( (field) => {
        comparisons["children"].push({
          headerName: this.humanize(field),
          width: 95,
          sortable: true,
          resizable: true,
          colId: `${title}_${field}`,
          filterParams: {
            filters: [
              { filter: 'agNumberColumnFilter' },
              { filter: 'agSetColumnFilter' },
            ],
          },
          valueGetter: (params) => {
            if (!(Object.hasOwn(params.data, set1) && Object.hasOwn(params.data, set2))
              || (params.data[set1][field] == "" || params.data[set2][field] == "")
              || (params.data[set1][field] == null || params.data[set2][field] == null)) {
              return "n/a"
            } else {
              return params.data[set2][field] - params.data[set1][field]
            }
          },
          valueFormatter: (params) => {
            if (params.value > 0) {
              return "+"+params.value
            } else {
              return params.value
            }
          },
          cellClass: params => this.getComparisonCellClass(params),
        })
      })

      return comparisons
    },

    exportCsv() {
      this.gridApi.exportDataAsCsv({
        allColumns: true, // export even hidden columns
      });
    },

    createHistChart(assessmentProp, title){

      // filter out 0 scores and save them as objects in the scores array
      const scores  = this.assessmentData
          .filter(student => this.checkIfNotZero(student[assessmentProp]?.composite_score))
          .map(student => ({ compositeScore: student[assessmentProp].composite_score }));

      const chartOptions = {
        title: {
          text: title,
        },
        data: scores,
        series: [
          {
            bins: this.meta.segments,
            type: 'histogram',
            xKey: 'compositeScore',
            xName: 'Composite Score',
            tooltip: {
              renderer: (params) => {
                var bin = params.datum;
                var binSize = bin.frequency;
                return {
                  content: `Students: ${binSize}`
                };
              },
            },
          },
        ],
        legend: {
          enabled: false,
        },
      }
      this.chartArray.push(chartOptions)
    },

    humanize(key) {
      return key.split("_").map( (word) => word[0].toUpperCase() + word.substring(1) ).join(" ")
    },

    getComparisonCellClass(params) {
      if (params.value == "n/a") {
        return "blank-font"
      } else if (params.value < 0) {
        return "negative-font"
      } else if (params.value > 0) {
        return "positive-font"
      } else {
        return "default-font"
      }
    },

    checkIfNotZero(value){
      return !(value === undefined || value === null || value === 0)
    }

  },
}
</script>


<style lang="scss">

.ag-theme-alpine {
  --ag-borders: none;
  --ag-font-size: 12px;
  --ag-font-family: "Open Sans", sans-serif;
  --ag-odd-row-background-color: #f9f9f9;
}

.ag-theme-alpine .ag-header-cell {
  font-size: 12px;
  font-weight: bold;
}

.ag-header-group-cell, .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover, .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover).ag-column-resizing {
  background-color: #345165;
  color: #fff;
}

.ag-header-group-cell .ag-header-icon {
  color: #fff;
}

.default-font {
  color: --ag-foreground-color;
  text-align: center;
}

.blank-font {
  color: #b3b7bb;
  text-align: center;
}

.positive-font {
  color: green;
  text-align: center;
}

.negative-font {
  color: red;
  text-align: center;
}

.chart_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-1 {
  width: 80%;
}

.chart-2 {
  width: 45%;
}

.chart-3 {
  width: 32%;
}
</style>
