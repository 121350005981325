<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-tabs
          v-model="tabSelected"
          :grow="isMobile"
          hide-slider
          :height="!isMobile? 51 : undefined"
          :class="isMobile ? 'tabs-secondary-mobile' : undefined"
          class="tabs-secondary"
          :vertical="isMobile"
          @change="onTabChange()"
        >
          <v-tab
            v-for="tab of tabs"
            :key="tab.tabText"
          >
            {{ tab.tabText }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('app_config')

export default {
  name: "AppTabsSecondary",
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    activeTab: {
      type: Number,
      default: () => {
        return 0
      },
      required: false
    }
  },

  data() {
    return {
      tabSelected: ''
    }
  },

  computed: {
    ...mapState({
      isMobile: app_config => app_config.isMobile,
    })
  },

  watch: {
    activeTab() {
      this.tabSelected = this.activeTab
    }
  },

  methods: {
    onTabChange() {
      this.$emit('onTabChange', this.tabSelected)
    }
  }
}
</script>

<style scoped lang="scss">

.tabs-secondary {

  .v-tab {
    font-family: Inter-SemiBold, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #4d535f !important;
    letter-spacing: normal;
    border: solid #d0d5dd 1px;
    text-transform: none;
    padding: 15px 25px;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right-style: none;

      &::before {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-left-style: none;

      &::before {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

  }

  .v-tab--active {
    background-color: #eaecf0;
    color: #101828 !important;
  }
}

.tabs-secondary-mobile {
  border: solid #d0d5dd 1px;
  border-radius: 10px;

  &::before {
    border-radius: 10px;
  }

  .v-tab {
    height: 51px;
    border: none;
    border-bottom: solid #d0d5dd 1px;
    width: 100%;
    justify-content: start;

    &:first-child {
      border-radius: 10px 10px 0 0;

      &::before {
        border-radius: 10px 10px 0 0 !important;
      }
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
      border: none;

      &::before {
        border-radius: 0 0 10px 10px !important;
      }
    }

  }
}

</style>
