const GRADE_8 = Object.freeze({ value: 8, name: '8th Grade' })
const GRADE_9 = Object.freeze({ value: 9, name: '9th Grade' })
const GRADE_10 = Object.freeze({ value: 10, name: '10th Grade' })
const GRADE_11 = Object.freeze({ value: 11, name: '11th Grade' })
const GRADE_12 = Object.freeze({ value: 12, name: '12th Grade' })


export default {

    GRADE_8,
    GRADE_9,
    GRADE_10,
    GRADE_11,
    GRADE_12,

    values () {
        return [GRADE_8, GRADE_9, GRADE_10, GRADE_11, GRADE_12]
    }

}
